import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
    InputAdornment,
	FormControl,
    FormControlLabel,
    Grid,
    Switch,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignStyle, 
    getPdfAlignStyle,
    useStyles, 
    texts, 
    PdfStyles,
    getDisplayValue,
    limits,
    componentTypes,
} from '../../helper';

const FormInputTextProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [limit, setLimit] = useState(limits.INPUT_TEXT);
    const [required, setRequired] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [reference, setReference] = useState('');
    const [errors, setErrors] = useState({});

    const handleLabelChange = (event) => {
		const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
		errors['label'] = checkRequired(label, texts.labelComponentLabel);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleLimitChange = (event) => {
        const limit = parseInt(event.target.value || 0);
        if (limit >= 1) {
            setLimit(limit);
            props.updateLimit(limit);
        }
    }

    const handleRequiredChange = (event, checked) => { 
        setRequired(checked);
        props.updateRequired(checked);
    }

    const handlePrefixChange = (event) => {
        const prefix = event.target.value;
        setPrefix(prefix);
        props.updatePrefix(prefix);
    }

    const handleSuffixChange = (event) => {
        const suffix = event.target.value;
        setSuffix(suffix);
        props.updateSuffix(suffix);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }
    
    useEffect(() => {
        setLabel(props.label);
        setAlign(props.align);
        setLimit(((props.limit && props.limit > 0) ? props.limit : limits.INPUT_TEXT));
        setRequired(props.required || false);
        setPrefix(props.prefix || '');
        setSuffix(props.suffix || '');
        setReference(props.reference || '');
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentLabel);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeInputText}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-label'
                            label={texts.labelComponentLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={required} onChange={handleRequiredChange} disabled={props.isReadOnly} />}
                        label={required ? texts.infoValueRequired : texts.infoValueOptional}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-limit'
                            type='number'
                            label={texts.labelComponentLimit}
                            variant='outlined'
                            value={limit || ''}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={handleLimitChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-prefix'
                            label={texts.labelComponentPrefix}
                            variant='outlined'
                            value={prefix || ''}
                            onChange={handlePrefixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-suffix'
                            label={texts.labelComponentSuffix}
                            variant='outlined'
                            value={suffix || ''}
                            onChange={handleSuffixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormInputTextDesigner = (props) => {
	const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [limit, setLimit] = useState(0);
    const [required, setRequired] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [reference, setReference] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateLimit = (limit) => {
        setLimit(limit);
        props.handleTrackChange();
    }

    const handleUpdateRequired = (required) => {
        setRequired(required);
        props.handleTrackChange();
    }

    const handleUpdatePrefix = (prefix) => {
        setPrefix(prefix);
        props.handleTrackChange();
    }

    const handleUpdateSuffix = (suffix) => {
        setSuffix(suffix);
        props.handleTrackChange();
    }

    const handleUpdateReference = (reference) => {
        setReference(reference);
        props.handleFormulaReference(props.id, reference);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormInputTextProperties 
                                            id={props.id} 
                                            label={label} 
                                            align={align} 
                                            limit={limit}
                                            required={required}
                                            prefix={prefix}
                                            suffix={suffix}
                                            reference={reference}
                                            updateLabel={handleUpdateLabel}
                                            updateAlign={handleUpdateAlign}
                                            updateLimit={handleUpdateLimit}
                                            updateRequired={handleUpdateRequired}
                                            updatePrefix={handleUpdatePrefix}
                                            updateSuffix={handleUpdateSuffix}
                                            updateReference={handleUpdateReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
		errors['label'] = checkRequired(label);
        return (!!errors['label']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMINPUTTEXT, 
                                    label, 
                                    align,
                                    limit,
                                    required,
                                    prefix,
                                    suffix,
                                    reference }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setAlign(props.align);
            setLimit(props.limit);
            setRequired(props.required);
            setPrefix(props.prefix);
            setSuffix(props.suffix);
            setReference(props.reference);
            if (props.reference) {
                props.handleFormulaReference(props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-input-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeInputText}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <TextField 
                            id={props.id}
                            label={label || ''}
                            variant='outlined'
                            value={label || texts.infoConfigInputText}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : null),
                                endAdornment:  (suffix ? <InputAdornment position='end'>{suffix}</InputAdornment> : null),
                                inputProps: { style: getAlignStyle(align) },
                            }} />
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleInputText} 
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigInputText}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormInputTextPreview = (props) => {
	const classes = useStyles();
    const [value, setValue] = useState('');
    const [errors, setErrors] = useState({});
    const [isInitialize, setIsInitialize] = useState(false);

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.handleInputData(props.id, value,false, false, true);
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
            setErrors(errors);
        }
	}

    const doValidate = () => {
        const errors = {};
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
        }
        setErrors(errors);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent(props.id, value, doValidate());
        } else {
            setIsInitialize(true);
            setValue(props.inputData[props.id] || '');
            if (props.reference) {
                props.handleFormulaReference(props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
            <TextField 
                id={props.id}
                label={props.label || ''}
                variant='outlined'
                value={value || ''}
                error={errors['value']}
                helperText={errors['value']}
                InputProps={{
                    startAdornment: (props.prefix ? <InputAdornment position='start'>{props.prefix}</InputAdornment> : null),
                    endAdornment:  (props.suffix ? <InputAdornment position='end'>{props.suffix}</InputAdornment> : null),
                    inputProps: { style: getAlignStyle(props.align),
                                    maxLength: (props.limit ? props.limit : limits.INPUT_TEXT), },
                }}
                onChange={handleValueChange} />
        </FormControl>
	)
}

export const FormInputTextPdf = (props) => {
	return (
        <View style={PdfStyles.groupInputColumn}>
            <Text style={PdfStyles.groupInputLabel}>{props.label || ''}</Text>
            <Text style={getPdfAlignStyle(props.align || aligns.LEFT, PdfStyles.groupInputValue)}>{getDisplayValue((props.inputData[props.id] || ''), '', props.prefix, props.suffix)}</Text>
        </View>
	)
}