import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Dialog,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
  Paper,
  Radio,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import Dropzone from 'react-dropzone'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiLoading, mdiFileTableOutline, mdiMagnify } from '@mdi/js';
import LoanService from '../../services/LoanService';
import SearchUtil from '../../utils/SearchUtil'
import Cities from '../../constants/Cities'
import Pagination from '@material-ui/lab/Pagination';
import ContentLoader from "react-content-loader"
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px'
		}
		// "& .MuiPaginationItem-root": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .MuiPaginationItem-rounded": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .Mui-selected": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .MuiButtonBase-root": {
		// 	backgroundColor: '#F5922F'
		// }
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	table: {
		fontFamily: 'Inter'
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			// backgroundColor: '#FDF3E5'
		},
		cursor: 'pointer'
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);



const EditBrand = (props) => {

	let history = useHistory();
	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("All")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [productImage, setProductImage] = useState("")
  const [productLogo, setProductLogo] = useState("")
  const [selectedIcon, setSelectedIcon] = useState(null)
	const [productIcons, setProductIcons] = useState([])
	const [successDialog, setSuccessDialog] = useState(false)
	const [failedDialog, setFailedDialog] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [gettingData, setGettingData] = useState(false)
	const [uploadingData, setUploadingData] = useState(false)
	const [selectedImage, setSelectedImage] = useState(null)
	const [fileObject, setFileObject] = useState({})

    const OrangeRadio = withStyles({
        root: {
          color: '#2B2D33',
          '&$checked': {
            color: '#F47721',
          },
        },
        checked: {},
      })((props) => <Radio color="default" {...props} />);

    function getMIMEType(char) {
        switch (char) {
            case '/': return ("data:image/jpg;base64,")
            case 'i': return ("data:image/png;base64,")
            case 'P': return ("data:image/svg+xml;base64,")
            default: return null
        }
		}
		
		function onDropHandler(acceptedFiles) {
			setProductImage(acceptedFiles[0].preview)
		}

		function renderLazyLoadField(height, width) {
			return (
				<ContentLoader
					speed={1.5}
					width={width ? width : 170}
					height={height ? height : 14}
					viewBox={`0 0 ${width ? width : 170} ${height ? height : 14}`}
					backgroundColor="#f3f3f3"
					foregroundColor="#9e9e9e">
					<rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
				</ContentLoader>
			)
		}

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setGettingData(true)
        let product = props.selectedProduct
        let productAssets = await LoanService.getProductAssets(product.topOrg, product.code, product.version);
        let productIcons = await LoanService.getProductIcons();
				if (productAssets && productAssets.result) {
					productAssets.result.map((asset, index) => {
						if (asset.name.toLowerCase() == "picture") {
							setProductImage(getMIMEType(asset.value[0]) + asset.value)
						}
						else if (asset.name.toLowerCase() == "icon") {
							setProductLogo(asset.value)
						}
					})
				}
        if (productIcons && productIcons.result) {
            setProductIcons(productIcons.result)
				}
		setGettingData(false)
	}

	async function uploadData() {
		let fileObjData = new FormData()
		selectedIcon && fileObjData.append("icon", selectedIcon);
		selectedImage && fileObjData.append("picture", selectedImage);
		// let fileObj = {
		// 	icon: selectedIcon,
		// 	picture: fileObjData
		// }
		let product = props.selectedProduct
		setUploadingData(true)
		let res = await LoanService.updateProductImageIcon(product.topOrg, product.code, product.version, fileObjData)
		setUploadingData(false)
		if (res && res.status == 200 || res && res.status == 201) {
			setSuccessDialog(true)
		} else {
			setErrorMessage(res && res.message && res.message)
			setFailedDialog(true)
		}
	}

	if (!initialized) {
    getData()
	}

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<Dialog onClose={() => setSuccessDialog(false)} aria-labelledby="simple-dialog-title" open={successDialog}>
				<div style={{ display: 'flex', flexDirection: 'column', margin: '24px 32px 24px 32px' }}>
					<div style={{ display: 'flex', fontSize: 20, fontWeight: 600, lineHeight: '24px', fontFamily: 'Inter', marginBottom: 24 }}>Updated Loan Product</div>
					<div style={{ display: 'flex', fontSize: 16, fontWeight: 'normal', lineHeight: '24px', fontFamily: 'Inter', marginBottom: 48 }}>
						Loan product has been successfully updated
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button variant="outlined" style={{ backgroundColor: '#FFFFFF', color: uploadingData ? '#C4C4C4' : '#F47721', width: 145, marginRight: 10, borderColor: uploadingData ? '#C4C4C4' : '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => { setSuccessDialog(false); props.setSelectedProduct(""); history.push("/brandDetails") }}>OK</Button>
					</div>
				</div>
			</Dialog>
			<Dialog onClose={() => setFailedDialog(false)} aria-labelledby="simple-dialog-title" open={failedDialog}>
				<div style={{ display: 'flex', flexDirection: 'column', margin: '24px 32px 24px 32px' }}>
					<div style={{ display: 'flex', fontSize: 20, fontWeight: 600, lineHeight: '24px', fontFamily: 'Inter', marginBottom: 24 }}>Failed to update loan product</div>
					<div style={{ display: 'flex', fontSize: 16, fontWeight: 'normal', lineHeight: '24px', fontFamily: 'Inter', marginBottom: 48 }}>
						{errorMessage}
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button variant="outlined" style={{ backgroundColor: '#FFFFFF', color: uploadingData ? '#C4C4C4' : '#F47721', width: 145, marginRight: 10, borderColor: uploadingData ? '#C4C4C4' : '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => { setFailedDialog(false) }}>OK</Button>
					</div>
				</div>
			</Dialog>
			<div style={{ margin: 20 }}>
				<div style={{ fontWeight: 'normal', fontSize: 16, lineHeight: '24px', color: '#636363' }}>{props.brand.brand}</div><br />
				<Paper style={{ height: 800 }}>
					<div style={{ margin: '0 64px 56px 64px'}}>
                        <div style={{ fontStyle: 'Inter', fontSize: 28, color: '#F47721', fontWeight: 600, lineHeight: '32px', padding: '56px 0 44px 0' }}>{props.selectedProduct.name}</div>
                        <div style={{ borderBottom: '1px solid #C4C4C4', flexBasis: '100%', fontFamily: 'Inter', color: '#F5922F', fontSize: 12, fontWeight: 'bold', height: 25, marginBottom: 24 }}>
                            Featured Image
				        </div>
                        <div style={{ fontStyle: 'Inter', fontSize: 14, color: '#636363', fontWeight: 'normal', lineHeight: '24px', marginBottom: 24 }}>This image will be used on the Loan Product page on the app.</div>
                        <div style={{ display: 'flex' }}>
													<Dropzone onDrop={(acceptedFiles, rejectedFiles) => {
														let attchPrev
															acceptedFiles.map(file => {
																attchPrev = Object.assign(file, {
																preview: URL.createObjectURL(file)
															})})
															setProductImage(attchPrev.preview)
															setSelectedImage(acceptedFiles[0])
														}} disabled={gettingData}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div style={{
                                            height: 248,
                                            width: 610,
                                            border: "2px dashed",
                                            borderColor: '#C4C4C4',
                                            marginBottom: 28
                                        }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            { productImage ?
                                                <img src={productImage} height={248} width={610} /> :
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F6F6F6', margin: 10, height: '86%', textAlign: 'center', fontSize: 12, fontFamily: 'Inter', lineHeight: '16px' }}>
																										{gettingData ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontStyle: 'Inter', width: 403, color: '#2B2D33', fontSize: 16, lineHeight: '24px', paddingTop: 35 }}>Getting product image...
                                                    <div style={{ color: '#636363', fontSize: 14, lineHeight: '24px' }}><MdIcon path={mdiLoading} size="2.5em" color="#F47721" spin={2} style={{ marginBottom: 25, marginTop: 10 }} /></div></div> :
																											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontStyle: 'Inter', width: 403, color: '#2B2D33', fontSize: 16, lineHeight: '24px' }}>Click here to select a file or drag the file into this box
                                                    <div style={{ color: '#636363', fontSize: 14, lineHeight: '24px' }}>(Supported File Format: .jpeg, .jpg, .svg)</div></div>}
                                                </div>
                                            }
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
						</div>
                        <div style={{ borderBottom: '1px solid #C4C4C4', flexBasis: '100%', fontFamily: 'Inter', color: '#F5922F', fontSize: 12, fontWeight: 'bold', height: 25, marginBottom: 24 }}>
                            Loan Product Icon
				        </div>
                        <div style={{ fontStyle: 'Inter', fontSize: 14, color: '#636363', fontWeight: 'normal', lineHeight: '24px', marginBottom: 24 }}>Select the type of icon that will be used for this product.</div>
                        <div style={{ display: 'flex' }}>
                            { productIcons ? productIcons.map((icon, index) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 24 }}>
                                        <img src={icon.base64} height={40} width={42} />
                                        <OrangeRadio
                                            checked={selectedIcon == icon.url}
                                            onChange={() => setSelectedIcon(icon.url)}
                                            value={icon.url}
                                            inputProps={{ 'aria-label': icon.url }}
                                        />
                                    </div>)
                            }) : 
                                <div style={{ fontStyle: 'Inter', fontSize: 14, color: '#636363', fontWeight: 'normal', lineHeight: '24px', marginBottom: 24 }}>No product icons</div>
                            }
                            
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant="outlined" style={{ marginRight: 10, color: '#F47721', borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => { props.setSelectedProduct(""); history.push("/brandDetails")}}>Cancel</Button>
							<Button variant="contained" style={{ marginRight: 10, color: '#FFFFFF', backgroundColor: uploadingData || (!selectedIcon && !selectedImage) ? '#C4C4C4' : '#F47721', borderColor: uploadingData || (!selectedIcon && !selectedImage) ? '#C4C4C4' : '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => uploadData()} disabled={uploadingData || (!selectedIcon && !selectedImage)}>Update Loan Product</Button></div>
                    </div>
				</Paper>
			</div>
		</div>
	)
}

export default EditBrand;