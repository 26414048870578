const basePath = "";

const Paths = {

	BASE: basePath,

	LOAN_AGENTS: `${basePath}/agents`,
	LOAN_APPLICATIONS: `${basePath}/loanApplications`,
	REVIEW_APPLICATION: `${basePath}/reviewApplication`,
	LOGS: `${basePath}/agencyLogs`,
	CHANGE_REQUEST: `${basePath}/changeRequest`,
	REVIEW_CHANGE_REQUEST: `${basePath}/changeRequest/review/:id`,
	CUSTOMER_PROFILE: `${basePath}/customerProfile`,
	CUSTOMER_PROFILE_PAGE: `${basePath}/customerProfile/:id`,
	CREATE_CUSTOMER_PROFILE: `${basePath}/createProfile`,

	CASHFLOW_FORM_BUILDER_LIST: `${basePath}/cashflow`,
	CASHFLOW_FORM_BUILDER_DESIGNER: `${basePath}/cashflow/designer/:id`,
	CASHFLOW_FORM_BUILDER_PREVIEW: `${basePath}/cashflow/preview/:id`,
	CASHFLOW_FORM_BUILDER_PDF: `${basePath}/cashflow/pdf/:id`,
	CASHFLOW_FORM_BUILDER_DROPDOWN_LIST: `${basePath}/datasource`,
	CASHFLOW_FORM_BUILDER_DROPDOWN_DATA: `${basePath}/datasource/data/:id`,
	CASHFLOW_ANALYSIS: `${basePath}/reviewApplication/cashFlow/:id`

};

export default Paths;