import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Grid,
	TextField,
	InputAdornment,
	Select,
	MenuItem,
	FormControl,
	FormControlLabel,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Modal
} from '@material-ui/core/'
import Pagination from '@material-ui/lab/Pagination';
import { Icon as MdIcon } from '@mdi/react'
import { mdiPlusCircleOutline, mdiMagnify, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js';
import Paths from '../../../../enums/Paths';
import { 
	Alert, 
	toggleDisplay, 
	useStyles,
	StyledTableCell, 
	StyledTableRow, 
	texts,
	formatDateTime,
	getKeyValue,
    setKeyValue,
	keys,
	Api,
} from '../../helper';
import { DropdownForm } from '.';

const DropdownList = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const [searchText, setSearchText] = useState(getKeyValue(keys.SOURCE_SEARCH));
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [pageLimit, setPageLimit] = useState(10);
	const [pageStatus, setPageStatus] = useState(texts.infoNoRecord);
	const [sourceList, setSourceList] = useState([]);
	const [detail, setDetail] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [openNewSource, setOpenNewSource] = useState(false);
	const [openEditSource, setOpenEditSource] = useState(false);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

	const onSearchTextPress = (event) => {
		if (event.key === 'Enter') {
			handleDataLoad(event.target.value);
		}
	};

	const onSearchTextChange = (event) => {
		const value = event.target.value;
		setSearchText(value);
		setKeyValue(keys.SOURCE_SEARCH, value);
	}

	const onPageLimitChange = (event) => {
		const value = event.target.value;
		setPageLimit(value);
		handleDataLoad('', value);
	}

	const onPageChange = (event, value) => { 
		handleDataLoad('', '', value);
	}

	const toggleNewSource = () => { 
		setOpenNewSource(!openNewSource);
	}

	const handleNewSource = (id) => { 
		toggleNewSource();
		if (id > 0) {
			handleDataLoad();
		}
	}

	const toggleEditSource = () => { 
		setOpenEditSource(!openEditSource);
	}

	const doEditSource = (detail) => { 
		setDetail(detail);
		toggleEditSource();
	}

	const handleEditSource = (id) => { 
		toggleEditSource();
		if (id > 0) {
			handleDataLoad();
		}
	}
	
	const toggleDeleteSource = () => { 
		setIsDeleteAlertOpen(!isDeleteAlertOpen);
	}

	const doDeleteSource = (detail) => { 
		setDetail(detail);
		toggleDeleteSource();
	}

	const handleDeleteSource = async () => { 
		setIsLoading(true);
		const response = await Api.saveSource(detail.id, 
											detail.name, 
											detail.description, 
											detail.columns,
											detail.payload,
											true);
		setIsLoading(false);
		toggleDeleteSource();
		if (response) {
			const result = (response.result || 0);
			if (result > 0) {
				handleDataLoad();
			}
		}
	}

	const handleDataLoad = async (text, limit, page) => {
		setIsLoading(true);
		const response = await Api.getSources((text || searchText), (limit || pageLimit), (page || 1));
		console.log(response);
		if (response) {
			const currentPage = response.page || 0;
			const totalPages = response.totalPages || 0;
			const totalRecords = response.count || 0;
			let currentRecords = 0;
			let sourceList = [];
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			if (response.list) {
				sourceList = response.list;
				currentRecords = sourceList.length;
			} 
			setSourceList(sourceList);
			
			if (response.count > 0) {
				setPageStatus(`${texts.pageFrom} ${currentPage != totalPages ? 
								(currentRecords * currentPage - (pageLimit - 1) + ` ${texts.pageTo} ` + currentRecords * currentPage) : 
									(((((currentPage - 1) * pageLimit) + 1) != totalRecords) ? 
										(((currentPage - 1) * pageLimit) + 1 + ` ${texts.pageTo} ` + totalRecords) : 
										(((currentPage - 1) * pageLimit) + 1))} ${texts.pageOf} ${totalRecords}`);
			} else {
				setPageStatus(texts.infoNoRecord);
			}
		}
		setIsLoading(false);
	}

	const doShowSource = (id) => { 
		history.push(Paths.CASHFLOW_FORM_BUILDER_DROPDOWN_DATA.replace(':id', id)); 
		props.setTick(Math.random());
	}

	useEffect(() => {
		handleDataLoad();
	}, []);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1} style={{ marginBottom: 16 }}>
				<Grid item xs={12} sm={6}>
					<span className={classes.pageTitle}>{texts.headerDropdownList}</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.controlEnd}>
					<Button variant='contained' className={classes.primaryButton} onClick={toggleNewSource}>
						<MdIcon path={mdiPlusCircleOutline} size='1.5em' className={classes.buttonIconLeft} />{texts.buttonCreateNewSource}
					</Button>
				</Grid>
			</Grid>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Paper className={classes.paperWrapper}>
				<Grid container className={classes.controlWrapper} spacing={3}>
					<Grid item xs={12} sm={9}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={5} md={4} lg={3}>
								<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
								<TextField 
									id='search-source'
									label={texts.labelSearchSources}
									variant='outlined'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<MdIcon path={mdiMagnify} size='24px' />
											</InputAdornment>
										),
									}}
									value={searchText || ''}
									onKeyPress={onSearchTextPress}
									onChange={onSearchTextChange}
								/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={3} className={classes.controlEnd}>
						<FormControl variant='outlined' className={classes.formControl}>
							<FormControlLabel
								control={<Select
									id='list-items-top'
									className={classes.listItems}
									defaultValue={10}
									onChange={onPageLimitChange}>
									<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
									<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
									<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
								</Select>}
								label={texts.labelShowItems}
								labelPlacement='start'/>
						</FormControl>
					</Grid>
				</Grid>
				<div className={classes.tableWrapper}>	
						<TableContainer>
							<Table className={classes.table} aria-label='dropdown source table'>
								<TableHead>
									<TableRow>
										<StyledTableCell width='20%' className={classes.plCell}>{texts.columnSourceName}</StyledTableCell>
										<StyledTableCell>{texts.columnSourceDesc}</StyledTableCell>
										<StyledTableCell width='25%'>{texts.columnSourceCols}</StyledTableCell>
										<StyledTableCell width='15%'>{texts.columnDateCreated}</StyledTableCell>
										<StyledTableCell width='10%' align='center' className={classes.prCell}>{texts.columnActions}</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sourceList && sourceList.map((data) => (
									<StyledTableRow>
										<StyledTableCell className={classes.plCell}><span className={classes.rowSelector} onClick={() => doShowSource(data.id)}>{data.name}</span></StyledTableCell>
										<StyledTableCell>{data.description}</StyledTableCell>
										<StyledTableCell>{data.columns}</StyledTableCell>
										<StyledTableCell>{formatDateTime(data.createdAt)}</StyledTableCell>
										<StyledTableCell align='center' className={classes.prCell}>
											<IconButton aria-label='Edit' onClick={() => doEditSource(data)}>
												<MdIcon path={mdiPencilOutline} size='0.8em' />
											</IconButton>
											<IconButton aria-label='Delete' onClick={() => doDeleteSource(data)}>
												<MdIcon path={mdiDeleteOutline} size='0.8em' />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
				</div>
			</Paper>
			<Grid container className={classes.footerWrapper}>
				<Grid item xs={12} sm={9}>
					<FormControl variant='outlined' className={classes.formControl}>
						<FormControlLabel
							control={((totalPages > 0) ? <Pagination className={classes.root} page={currentPage} count={totalPages} shape='rounded' onChange={onPageChange} /> : <span/>)}
							label={<span className={classes.pageStatus}>{pageStatus}</span>}
							labelPlacement='start'
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={3} className={classes.controlEnd}>
					<FormControl variant='outlined' className={classes.formControl}>
						<FormControlLabel
							control={<Select
								id='list-items-bottom'
								className={classes.listItems}
								defaultValue={10}
								onChange={onPageLimitChange}>
								<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
								<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
								<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
							</Select>}
							label={texts.labelShowItems}
							labelPlacement='start'/>
					</FormControl>
				</Grid>
			</Grid>
			<Modal open={openNewSource}>
				<DropdownForm onFormCancel={toggleNewSource}  onFormSuccess={handleNewSource} />
			</Modal>
			<Modal open={openEditSource}>
				<DropdownForm onFormCancel={toggleEditSource}  onFormSuccess={handleEditSource} detail={detail}  id={(detail?.id || 0)}/>
			</Modal>
			<Alert isOpen={isDeleteAlertOpen}
				title={texts.deleteAlertTitleDropdown} 
				message={`${texts.deleteAlertMessageStart}${detail?.name || ''}${texts.deleteAlertMessageDataEnd}`}
				cancelButton={texts.buttonCancel}
				confirmButton={texts.buttonDelete}
				confirm={handleDeleteSource}
				cancel={toggleDeleteSource} />
		</div>
	)
}

export default DropdownList;
