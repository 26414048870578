import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStyles } from './../cashFlow/helper';
import { PDFViewer, Document, Page, pdf } from '@react-pdf/renderer';
import {
	LinearProgress,
	Grid,
	Card,
	CardContent,
} from '@material-ui/core/'
import {
	StyledTabs,
	StyledTab,
	Api,
	toggleDisplay,
	getKeyValue,
	setKeyValue,
	keys
} from '../cashFlow/helper'
import FormGroupPreview from './reviewApplicationComponents/FormGroupPreview';
import LoanService from '../../services/LoanService';
import { createHash } from 'crypto';
import CryptoJS from 'crypto-js';
import AWSService from '../../services/AWSService';

const tabProps = (id) => {
	return {
		id: `group-tab-${id}`,
		'aria-controls': `group-tabpanel-${id}`,
	};
}

const CashFlowAnalysis = props => {
	const { id } = props;
	const { userData } = props;

	const history = useHistory();
	const classes = useStyles();
	const { REACT_APP_CRYPTO_SECRET_KEY } = process.env;
	const decryptedAgencyId = CryptoJS.AES.decrypt(
		userData.agentOf.agencyId,
		REACT_APP_CRYPTO_SECRET_KEY
	);
	const agencyId = decryptedAgencyId.toString(CryptoJS.enc.Utf8);
	const [detail, setDetail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [formulaReferences, setFormulaReferences] = useState({});
	const [dropdownReferences, setDropdownReferences] = useState({});
	const [tableReferences, setTableReferences] = useState({});
	const [tab, setTab] = useState(0);
	const [children, setChildren] = useState(null);
	const [sources, setSources] = useState([]);
	const referenceId = JSON.parse(localStorage.getItem("@@agency/USER")).selectedApplication.referenceId;
	const inputKey = `${referenceId}_${id}`;
	const inputData = useRef({});
	const inputStore = useRef({});
	const formulaTickCtr = useRef(0);
	const [formulaTick, setFormulaTick] = useState('');
	const tableTickCtr = useRef(0);
	const [tableTick, setTableTick] = useState('');
	const [formName, setFormName] = useState('');
	const [disableNext, setDisableNext] = useState(true);

	const handleGeneratePdf = async (blob, recommendedAmountId) => {
		let data = {};
		let recommendedAmount = 0;
		const dateTime = new Date();
		const timestamp = (Date.parse(dateTime)) / 1000;

		if (recommendedAmountId != 0) {
			const storeData = JSON.parse(getKeyValue(inputKey));
			recommendedAmount = parseFloat(storeData[recommendedAmountId]);
		}

		blob.lastModifiedDate = new Date();
		blob.name = `attchSalesProof${timestamp}.pdf`;

		const fieldCode = `attchSalesProof${timestamp}`;
		const reader = new FileReader();

		// await AWSService.upload(blob, { agencyId, referenceId, fieldCode });

		reader.readAsArrayBuffer(blob);
		reader.onload = async (event) => {
			const fileBuffer = event.target.result;
			const hash = await createHash('sha256')
				.update(
					Buffer.from(fileBuffer, 'utf8')
						? Buffer.from(fileBuffer, 'utf8')
						: null
				)
				.digest('hex');

			const res = await LoanService.uploadAttachment(referenceId, {
				file: blob,
				fieldCode,
			});

			await new Promise((resolve) => setTimeout(resolve, 3000));

			data = {
				s3Object: `i2i/${agencyId.trim()}/${referenceId}/${blob.name}`,
				hash: hash,
				recommendedAmount: recommendedAmount
			};

			localStorage.removeItem('cashflow_' + inputKey);
			await LoanService.saveCashFlowAnalysis(referenceId, data);
			history.push("/reviewApplication");
			props.setTick(Math.random());
		}
	}

	const handleTabChange = (event, tab) => {
		setTab(tab);
	};

	const handleNextTab = async (props) => {
		setTab(props);
	};

	const handleFormulaReference = (id, reference) => {
		formulaReferences[id] = reference;
		setFormulaReferences(formulaReferences);
	}

	const handleDropdownReference = (id, reference) => {
		dropdownReferences[id] = reference;
		setDropdownReferences(dropdownReferences);
	}

	const handleTableReference = (id, reference) => {
		tableReferences[id] = reference;
		setTableReferences(tableReferences);
	}

	const handleInputSave = async (data, id, rowId) => {
		for (const key of Object.keys(data)) {
			inputStore.current[key] = data[key];
			inputData.current[key] = data[key];
		}

		setKeyValue(inputKey, JSON.stringify(inputStore.current));
		await validateData();

		if (id && rowId) {
			handleTableData(id, rowId);
		}
	}

	const handleInputUndo = async (rowId) => {
		let isFound = false;

		let foundViewMode = Object.keys(inputStore.current).filter(key => {
			return (typeof inputStore.current[key] === "object")
				&& inputStore.current[key].length;
		}).map(key => {
			const rowIndex = inputData.current[key].map(data => data.rowId)
				.indexOf(rowId);
			return rowIndex;
		}).filter(data => data > -1).length > 0;

		for (const key of Object.keys(inputStore.current)) {
			if (key.endsWith(rowId)) {
				if (!foundViewMode) {
					delete inputStore.current[key];
				} else {
					inputData.current[key] = inputStore.current[key];
					isFound = foundViewMode;
				}
				const id = key.replace('-' + rowId);
				sendFormulaTick(id);
			}
		}
		if (!isFound) {
			for (const key of Object.keys(inputData.current)) {
				if (key.endsWith(rowId)) {
					delete inputData.current[key];
					const id = key.replace('-' + rowId);
					sendFormulaTick(id);
				}
			}
		}
		setKeyValue(inputKey, JSON.stringify(inputStore.current));
		await validateData();
	}

	const handleInputDelete = (id, table, rowId) => {
		inputStore.current[id] = table;
		for (const key of Object.keys(inputData.current)) {
			if (key.endsWith(rowId)) {
				delete inputData.current[key];
			}
			else if ((typeof inputData.current[key]) === "object"
				&& inputData.current[key].length) {
				const rowIndex = inputData.current[key].map(data => data.rowId)
					.indexOf(rowId);
				if (rowIndex > -1) {
					inputData.current[key].splice(rowIndex, 1);
				}
			}
		}
		for (const key of Object.keys(inputStore.current)) {
			if (key.endsWith(rowId)) {
				delete inputStore.current[key];
			}
			else if ((typeof inputStore.current[key]) === "object"
				&& inputStore.current[key].length) {
				const rowIndex = inputStore.current[key].map(data => data.rowId)
					.indexOf(rowId);
				if (rowIndex > -1) {
					inputStore.current[key].splice(rowIndex, 1);
				}
			}
		}
		handleInputSave(inputData.current);
		setKeyValue(inputKey, JSON.stringify(inputStore.current));
		sendFormulaTick(id);
		handleTableData(id, rowId, true);
	}

	const handleInputData = async (id, value, isReadOnly, noTick, stopUpdate) => {
		inputData.current[id] = value;
		if (!stopUpdate) {
			handleInputSave(inputData.current);
		} else {
			validateData();
		}
		if (!noTick) {
			sendFormulaTick(id);
		}
	}

	const sendFormulaTick = (id) => {
		formulaTickCtr.current = formulaTickCtr.current + 1;
		setFormulaTick(id + '-' + formulaTickCtr.current);
	}

	const handleTableData = (source, rowId, isDelete) => {
		tableTickCtr.current = tableTickCtr.current + 1;
		setTableTick((rowId ? (isDelete ? '-' : '+') : '!') + (source.key ? source.key : source) +
			'-' + (rowId ? rowId : tableTickCtr.current));
	}

	const handleAddRowDataTable = async (id, newRowId) => {
		// const localInputData = getKeyValue(inputKey);
		// if (localInputData && localInputData.length > 0) {
		// 	inputData.current = JSON.parse(localInputData);
		// }
		if (children) {
			let tableElements = [];
			children[tab].children.filter(child => child.type === "FormTable")
				.forEach(child => {
					child.children.filter(child => child.id === id)
						.forEach(child => {
							tableElements = tableElements.concat(child.children);
						});
				});

			tableElements.forEach(element => {
				let key = element.id + '-' + newRowId;
				if (element.type.includes("Numeric")) {
					inputData.current[key] = 0;
				} else {
					inputData.current[key] = "";
				}
			});

			await validateData();
		}
	}

	const initialize = async (schema) => {
		const data = JSON.parse(schema.payload);
		if (data) {
			let children2 = [];
			for (let child of data) {
				children2.push(child);
			}
			setChildren(children2);
		}
	}

	const handleDataLoad = async () => {
		setIsLoading(true);

		// Since we are just on preview mode, try to load form data from local storage
		const localInputData = getKeyValue(inputKey);

		if (localInputData && localInputData.length > 0) {
			inputStore.current = JSON.parse(localInputData);
			inputData.current = JSON.parse(localInputData);
		}

		const resSources = await Api.getSources();

		if (resSources && resSources.list) {
			const sources = {};
			for (const source of resSources.list) {
				if (source.payload) {
					sources[source.id] = JSON.parse(source.payload);
				}
			}
			setSources(sources)
		}

		const response = await Api.getForm(id);
		if (response) {
			setFormName(response.name);
			if (response.schema) {
				await initialize(response.schema);
				response.schemaId = (response?.schema?.id || 0);
				delete response.schema;
			}
			setDetail(response);
		}
		setIsLoading(false);
	}


	const validateData = async () => {
		// const localInputData = getKeyValue(inputKey);
		// if (loadInputStore && localInputData && localInputData.length > 0) {
		// 	inputData.current = JSON.parse(localInputData);
		// } else {
		// 	inputData.current = inputData.current;
		// }

		let elements = [];
		let tableElements = [];

		if (children) {
			children[tab].children.map(child => {
				if (child.type === "FormTable") {
					tableElements = child.children[0] ? tableElements
						.concat(child.children[0].children ? child.children[0].children : []) : tableElements;
					tableElements = child.children[1] ? tableElements
						.concat(child.children[1].children ? child.children[1].children : []) : tableElements;
				} else {
					elements = elements.concat(child.children ? child.children : []);
				}
			});

			let dataValid = true;
			elements.map(el => {
				if (el.required && (inputData.current[el.id] == "" || !inputData.current[el.id])) {
					dataValid = false;
				}
			});

			let tableValid = true;
			tableElements.map(el => {
				for (const key of Object.keys(inputData.current)) {
					if (key.startsWith(el.id) &&
						(el.required && (inputData.current[key] == "" || !inputData.current[key]))) {
						tableValid = false;
					}
				}
			})
			setDisableNext(!(dataValid && tableValid));
		}
	};

	useEffect(() => {
		handleDataLoad();
	}, [id]);

	useEffect(() => {
		validateData();
	}, [tab, inputData, children]);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1}>
				<Grid item xs={12} sm={12} md={12}>
					<span className={classes.pageTitle}>{formName}</span>
				</Grid>
			</Grid>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Grid container className={classes.builderWrapper} spacing={3}>
				<Grid item xs={12}>
					<Card className={classes.cardWrapper}>
						{children &&
							<CardContent>
								<StyledTabs
									value={tab}
									onChange={handleTabChange}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="From Group">
									{children.map((child, index) => (
										<StyledTab label={child.title} disabled={true} {...tabProps(index)} />
									))}
								</StyledTabs>
								{children.map((child, index) => (
									<FormGroupPreview
										handleFormulaReference={handleFormulaReference}
										formulaReferences={formulaReferences}
										handleDropdownReference={handleDropdownReference}
										dropdownReferences={dropdownReferences}
										handleTableReference={handleTableReference}
										tableReferences={tableReferences}
										sources={sources}
										handleInputSave={handleInputSave}
										handleInputUndo={handleInputUndo}
										handleInputDelete={handleInputDelete}
										handleInputData={handleInputData}
										handleTableData={handleTableData}
										handleAddRowDataTable={handleAddRowDataTable}
										inputData={inputData.current}
										formulaTick={formulaTick}
										tableTick={tableTick}
										disableNext={disableNext}
										tab={tab}
										id={index}
										isLastPage={index === children.length - 1}
										handleNextTab={handleNextTab}
										handleGeneratePdf={handleGeneratePdf}
										formId={id}
										referenceId={referenceId}
										children={child.children}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
										fromAnalysis={true} />
								))}
							</CardContent>
						}
					</Card>
				</Grid>
			</Grid>
		</div>
	);

}

export default CashFlowAnalysis;