import React, { Fragment } from "react";
import { FormControl, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useStyles } from "../helpers/Styles";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-timezone";

const DateFromToButtons = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Button
              variant="contained"
              onClick={() => props.setOpenFrom(true)}
              style={{
                alignItems: "left",
                textAlign: "left",
                fontFamily: "Inter",
                fontWeight: 400,
                width: 180,
                backgroundColor: "#FFFFFF",
                marginRight: 24,
                disableTypography: true,
              }}
            >
              {props.fromDate ? "From " + props.fromDate : "From"}
            </Button>
            <DatePicker
              maxDate={moment()}
              open={props.isOpenFrom}
              onClose={() => props.setOpenFrom(false)}
              onChange={(value) => {
                props.setFromDate(moment(value).format("YYYY-MM-DD"));
                props.setPage(1);
                props.setInitialized(false);
              }}
              style={{ visibility: "hidden" }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Button
              variant="contained"
              onClick={() => props.setOpenTo(true)}
              style={{
                alignItems: "left",
                textAlign: "left",
                fontFamily: "Inter",
                fontWeight: 400,
                width: 180,
                backgroundColor: "#FFFFFF",
                marginRight: 24,
                disableTypography: true,
              }}
            >
              {props.toDate ? "To " + props.toDate : "To"}
            </Button>
            <DatePicker
              minDate={moment(props.fromDate).format("YYYY-MM-DD")}
              open={props.isOpenTo}
              onClose={() => props.setOpenTo(false)}
              onChange={(value) => {
                props.setToDate(moment(value).format("YYYY-MM-DD"));
                props.setPage(1);
                props.setInitialized(false);
              }}
              style={{ visibility: "hidden" }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
    </Fragment>
  );
};

export default DateFromToButtons;
