import React, { Fragment } from "react";
import { Paper } from "@material-ui/core/";
import LinearProgressCustom from "./LinearProgessCustom";

const PaperCustom = (props) => {
  return (
    <Fragment>
      <LinearProgressCustom updatingData={props.updatingData} />
      <Paper style={{ height: "auto" }}>{props.children}</Paper>
    </Fragment>
  );
};

export default PaperCustom;
