import React, { useState } from "react";
import HeaderTitle from "./logsComponents/ui/HeaderTitle";
import Wrapper from "./logsComponents/ui/Wrapper";
import PaperCustom from "./logsComponents/ui/PaperCustom";
import LogsGroupActions from "./LogsGroupActions";
import LogDisplayTable from "./LogsDisplayTable";
import LoanService from "../../services/LoanService";
import { useHistory } from "react-router-dom";
import Paths from "../../enums/Paths";
import DialogCustom from "./logsComponents/ui/DialogCustom";

let rows = [];

const createData = (
  dateTime,
  referenceId,
  brandName,
  agentName,
  userFullName,
  status,
  remarks,
  agentId
) => {
  return {
    dateTime,
    referenceId,
    brandName,
    agentName,
    userFullName,
    status,
    remarks,
    agentId,
  };
};

const Logs = (props) => {
  let history = useHistory();

  const [updatingData, setUpdatingData] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [statusKey, setStatusKey] = useState("");
  const [page, setPage] = useState(1);
  const [initialized, setInitialized] = useState(false);
  const [isOpenFrom, setOpenFrom] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [isOpenTo, setOpenTo] = useState(false);
  const [toDate, setToDate] = useState("");
  const [showItems, setShowItems] = useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [count, setCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  async function getAdditionalData(row) {
    setUpdatingData(true);
    history.push(Paths.REVIEW_APPLICATION);
    let additionalData = await LoanService.getApplications(
      row.agentId,
      1,
      1,
      row.referenceId
    );

    if (
      additionalData &&
      additionalData.result &&
      additionalData.result.result[0]
    ) {
      row.loanProduct = additionalData.result.result[0].loanProduct;
      row.applicantName = additionalData.result.result[0].applicantName;
      row.requestedAmount = additionalData.result.result[0].requestedAmount;
      props.setApplication(row);
      props.setNavHistory("logs");
    } else {
      setConfirmDialog(true);
    }
    setUpdatingData(false);
  }

  async function getData() {
    setInitialized(true);
    setUpdatingData(true);
    let response = await LoanService.getLogs(
      showItems,
      page,
      fromDate,
      toDate,
      props.globalStatusKey ? props.globalStatusKey : statusKey,
      props.globalSearchKey ? props.globalSearchKey : searchKey
    );
    rows = [];
    response &&
      response.result &&
      response.result.logs.map((data, index) => {
        rows.push(
          createData(
            data.dateTime,
            data.referenceId,
            data.brandName,
            data.agentName,
            data.userFullName,
            data.status,
            data.remarks,
            data.agentId
          )
        );
      });
    setCount(response && response.result ? response.result.count : 0);
    setMaxPage(response && response.result ? response.result.maxPage : 0);
    setTotalCount(
      response && response.result ? response.result.logs.length : 0
    );
    setHoveredRow(-1);
    setUpdatingData(false);
    setHoveredRow(null);
  }

  if (!initialized) {
    getData();
  }

  return (
    <Wrapper>
      <HeaderTitle>Logs</HeaderTitle>
      <PaperCustom updatingData={updatingData}>
        <LogsGroupActions
          setSearchKey={setSearchKey}
          globalSearchKey={props.globalSearchKey}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          setStatusKeyGlobal={props.setStatusKeyGlobal}
          setSearchKeyGlobal={props.setSearchKeyGlobal}
          setPage={setPage}
          setInitialized={setInitialized}
          setStatusKey={setStatusKey}
          statusKey={statusKey}
          setOpenFrom={setOpenFrom}
          setOpenTo={setOpenTo}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
          isOpenFrom={isOpenFrom}
          isOpenTo={isOpenTo}
          setShowItems={setShowItems}
          showItems={showItems}
        />
        <LogDisplayTable
          rows={rows}
          hoveredRow={hoveredRow}
          setHoveredRow={setHoveredRow}
          updatingData={updatingData}
          getAdditionalData={getAdditionalData}
          totalCount={totalCount}
          page={page}
          showItems={showItems}
          setMaxPage={setMaxPage}
          setInitialized={setInitialized}
          setPage={setPage}
          count={count}
          maxPage={maxPage}
        />
      </PaperCustom>
      <DialogCustom
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
};

export default Logs;
