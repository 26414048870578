import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	IconButton,
    Grid,
    Menu,
    MenuItem,
    TextField,
	FormControl,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline, mdiPlusCircleOutline } from '@mdi/js';
import { View } from '@react-pdf/renderer';
import { ReactSortable } from "react-sortablejs";
import { 
    Alert, 
    newId, 
    useStyles, 
    texts, 
    PdfStyles, 
    componentTypes, 
    aligns, 
    limits
} from '../../helper';
import { 
    FormInputTextDesigner, 
    FormInputTextPreview, 
    FormInputTextPdf,
    FormInputNumericDesigner, 
    FormInputNumericPreview, 
    FormInputNumericPdf,
    FormInputDropdownDesigner,
    FormInputDropdownPreview,
    FormInputDropdownPdf,
    FormInputDisplayDesigner,
	FormInputDisplayPreview,
    FormInputDisplayPdf,
    FormInputFormulaDesigner,
	FormInputFormulaPreview, 
    FormInputFormulaPdf,
} from '.';

const FormInputProperties = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeInput}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.propertyInfo}>{texts.infoPropertyInput}</span>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormInputDesigner = (props) => {
	const classes = useStyles();
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const [isInitialize, setIsInitialize] = useState(false);
    const [children, setChildren] = useState(null);
	const [tick, setTick] = useState(0);
	const components = useRef([]);
	const tracker = useRef({});
	const withError = useRef(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleInsertText = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMINPUTTEXT, 
                        label: '',
                        align: aligns.LEFT,
                        limit: limits.INPUT_TEXT,
                        required: false,
                        prefix: '',
                        suffix: '',
                        reference: '' };
		handleChildInsert(child);
	}

    const handleInsertNumeric = () => { 
        const child = { id: newId(), 
                        type: componentTypes.FORMINPUTNUMERIC, 
                        label: '',
                        align: aligns.LEFT,
                        limit: limits.INPUT_NUMERIC,
                        required: false,
                        prefix: '',
                        suffix: '',
                        decimal: false,
                        reference: '' };
        handleChildInsert(child);           
	}

    const handleInsertDropdown = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMINPUTDROPDOWN, 
                        label: '',
                        align: aligns.LEFT,
                        required: false,
                        source: '',
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertDisplay = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMINPUTDISPLAY, 
                        label: '',
                        align: aligns.LEFT,
                        value: '',
                        prefix: '',
                        suffix: '',
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertFormula = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMINPUTFORMULA, 
                        label: '',
                        align: aligns.LEFT,
                        formula: [],
                        prefix: '',
                        suffix: '',
                        decimal: false,
                        reference: '' };
        handleChildInsert(child);
	}

    const handleComponentSelect = (event) => { 
        if (event) {
			event.stopPropagation();
		}
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormInputProperties id={props.id} />);
        }
	}

    const handleChildInsert = (child) => {
        const data = [...(children || [])];
		data.push(child);
		setChildren(data);
		components.current.push(child);
        handleToggleMenu();
        props.handleTrackChange();
    }

    const handleComponentDelete = (id) => {
        handleComponentSelect();
		components.current = components.current.filter((component) => (component.id !== id));
		const data = [...(children || [])].filter((child) => (child.id !== id));
		setChildren(data);
		delete tracker.current[id];
        props.handleReferenceDelete(id);
        props.handleTrackChange();
	}

    const sendTick = () => {
        if (children && children.length > 0) {
            for (const key of Object.keys(tracker.current)) {
                tracker.current[key] = false;
            }
            withError.current = false;
            const nextTick = tick + 1;
            setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

    const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		const payload = [];
		// Follow the sorting of the components
		for (const child of children) {
			const groupComp = components.current.find(comp => comp.id === child.id);
			if (groupComp) {
				payload.push(groupComp);
			}
        }

		props.updateComponent({ id: props.id, type: componentTypes.FORMINPUT, children: payload }, false);
	}

	const updateComponent = (update, error) => {
		if (!withError.current) {
			tracker.current[update.id] = true;
			if (error) {
				withError.current = true;
                // We have an error notify parent component immediately
                props.updateComponent({ id: props.id, type: componentTypes.FORMINPUT }, true);
			} else {
				components.current = components.current.map((component) => {
					if (component.id === update.id) {
						return update;
					} else {
						return component;
					}
				});
				trackUpdates();
			}
		}
	}

	const trackComponent = (id) => {
		tracker.current[id] = false;
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMINPUTTEXT) {
            return (
                <div key={child.id} className={classes.groupInputCompContainer}>
                    <FormInputTextDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}	
                        tick={tick}
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        required={child.required}
                        limit={child.limit}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMINPUTNUMERIC) {
            return (
                <div key={child.id} className={classes.groupInputCompContainer}>
                    <FormInputNumericDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleTrackChange={props.handleTrackChange}		
                        tick={tick}
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        limit={child.limit}
                        required={child.required}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        decimal={child.decimal}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMINPUTDROPDOWN) {
            return (
                <div key={child.id} className={classes.groupInputCompContainer}>
                    <FormInputDropdownDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        handleDropdownReference={props.handleDropdownReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}
                        sources={props.sources}	
                        tick={tick}
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        required={child.required}
                        source={child.source}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMINPUTDISPLAY) {
            return (
                <div key={child.id} className={classes.groupInputCompContainer}>
                    <FormInputDisplayDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}
                        tick={tick}
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        value={child.value}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMINPUTFORMULA) {
            return (
                <div key={child.id} className={classes.groupInputCompContainer}>
                    <FormInputFormulaDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        formulaReferences={props.formulaReferences}
                        dropdownReferences={props.dropdownReferences}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}
                        tick={tick}
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        formula={child.formula}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        decimal={child.decimal}
                        reference={child.reference} />
                </div>
            );
        } else {
            return <span/>
        }
    }

    const initialize = (data) => {
        const children = [];
        components.current = [];
        for (const child of data) {
            children.push(child);
            components.current.push(child);
        }
        setChildren(children);
	}

    useEffect(() => {
        if (isInitialize) {
            sendTick();
        } else {
            setIsInitialize(true);
            initialize(props.children || []);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.componentTitleWrapper}>
                            <div className={classes.componentTitleWithIcon}>{texts.infoTypeInput}</div>
                            {!props.isReadOnly && (!children || children.length < 3) && 
                            <Fragment>
                                <IconButton ref={menuAnchorRef} aria-label='Add' size='small' onClick={handleToggleMenu}>
                                    <MdIcon path={mdiPlusCircleOutline} size='1em' />
                                </IconButton>
                                <Menu id='component-input-menu' 
                                    className={classes.componentMenu}
                                    anchorEl={menuAnchorRef.current} 
                                    keepMounted 
                                    open={isMenuOpen} 
                                    elevation={1}
                                    onClose={handleToggleMenu} 
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    <MenuItem onClick={handleInsertText}>{texts.menuInputText}</MenuItem>
                                    <MenuItem onClick={handleInsertNumeric}>{texts.menuInputNumeric}</MenuItem>
                                    <MenuItem onClick={handleInsertDropdown}>{texts.menuInputDropdown}</MenuItem>
                                    <MenuItem onClick={handleInsertDisplay}>{texts.menuInputDisplay}</MenuItem>
                                    <MenuItem onClick={handleInsertFormula}>{texts.menuInputFormula}</MenuItem>
                                </Menu>	
                            </Fragment>
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                    {(children && children.length > 0) && 
                        <ReactSortable 
                            list={children} 
                            setList={setChildren} 
                            animation={200}
                            handle='.group-comp-input-sort-handler'	
                            className={classes.groupInputCompWrapper}
                            onUpdate={props.handleTrackChange}>
                            {children.map((child) => (
                                renderChild(child)
                            ))}
                        </ReactSortable>
                    }
                    </Grid>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleInput} 
                message={texts.deleteAlertMessageInput}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormInputPreview = (props) => {
    const [isInitialize, setIsInitialize] = useState(false);
    const [tick, setTick] = useState(0);
    const values = useRef({});
    const tracker = useRef({});
    const withError = useRef(false);

    const sendTick = () => {
        if (props.children && props.children.length > 0) {
            for (const key of Object.keys(tracker.current)) {
                tracker.current[key] = false;
            }
            values.current = {};
            withError.current = false;
            const nextTick = tick + 1;
            setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

    const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		props.handleInputSave(values.current);
	}

    const updateComponent = (id, value, error) => {
		if (!withError.current) {
			tracker.current[id] = true;
			if (error) {
				withError.current = true;
            } else {
                values.current[id] = value;
				trackUpdates();
			}
		}
	}

    const trackComponent = (id) => {
		tracker.current[id] = false;
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMINPUTTEXT) {
            return (
                <FormInputTextPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    tick={tick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    reference={child.reference} />
            );
        } else if (child.type === componentTypes.FORMINPUTNUMERIC) {
            if (child.label == "Recommended Amount"){
                if (props.recommendedAmountId==0){
                    props.setRecommendedAmountId(child.id);
                }
            }
            return (
                <FormInputNumericPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    tick={tick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    limit={child.limit}
                    required={child.required}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    decimal={child.decimal}
                    reference={child.reference} />
            );
        } else if (child.type === componentTypes.FORMINPUTDROPDOWN) {
            return (
                <FormInputDropdownPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    sources={props.sources}	
                    tick={tick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    source={child.source}
                    reference={child.reference} />
            );
        } else if (child.type === componentTypes.FORMINPUTDISPLAY) {
            return (
                <FormInputDisplayPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    value={child.value}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    reference={child.reference} />
            );
        } else if (child.type === componentTypes.FORMINPUTFORMULA) {
            return (
                <FormInputFormulaPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    sources={props.sources}	
                    formulaTick={props.formulaTick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    decimal={child.decimal}
                    reference={child.reference}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else {
            return <span/>
        }
    }

    useEffect(() => {
        if (isInitialize) {
            sendTick();
        } else {
            setIsInitialize(true);
        }
    }, [props.tick]);

	return (
        <Grid container spacing={3}>
            {(props.children || []).map((child) => (
            <Grid item xs={12} sm={12} md={4}>
                {renderChild(child)}
            </Grid>
            ))}
         </Grid>
	)
}

export const FormInputPdf = (props) => {

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMINPUTTEXT) {
            return (
                <FormInputTextPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix} />
            );
        } else if (child.type === componentTypes.FORMINPUTNUMERIC) {
            return (
                <FormInputNumericPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    limit={child.limit}
                    required={child.required}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    decimal={child.decimal} />
            );
        } else if (child.type === componentTypes.FORMINPUTDROPDOWN) {
            return (
                <FormInputDropdownPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    source={child.source} />
            );
        } else if (child.type === componentTypes.FORMINPUTDISPLAY) {
            return (
                <FormInputDisplayPdf 
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    value={child.value}
                    prefix={child.prefix}
                    suffix={child.suffix} />
            );
        } else if (child.type === componentTypes.FORMINPUTFORMULA) {
            return (
                <FormInputFormulaPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    decimal={child.decimal} />
            );
        } else {
            return <span/>
        }
    }

	return (
        <View style={PdfStyles.groupInput}>
            {(props.children || []).map((child) => (
                renderChild(child)
            ))}
        </View>
	)
}