import React, { Component } from 'react';
import { Main, LoanAgents, LoanApplications, ReviewApplication } from "./components/";
import { Paths } from "./enums";
import './App.css';
import { BrowserRouter } from 'react-router-dom';

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Main location={this.props.location.pathname} state={this.props.state} />
			</BrowserRouter>
			// <Router>
			// 	<div>
			// 		{/* <Header /> */}
			// 		<Switch>
			// 			{/* <Redirect
			//         exact
			//         from="/"
			//         to={Paths.HOME}
			//         component={Home}
			//       /> */}
			// 			<Route exact path="/" component={LoanAgents} />
			// 			<Route path={Paths.LOAN_APPLICATIONS} component={LoanApplications} />
			// 			<Route path={Paths.REVIEW_APPLICATION} component={ReviewApplication} />
			// 		</Switch>
			// 	</div>
			// </Router>
		);
	}
}

export default App;
