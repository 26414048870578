import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	Button,
	IconButton,
	LinearProgress,
	Grid,
	Drawer,
	Divider,
	Modal,
	Badge,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiArrowRight, mdiPlusCircleOutline, mdiPencilOutline } from '@mdi/js';
import { ReactSortable } from "react-sortablejs";
import Paths from '../../../enums/Paths';
import { 
	Alert, 
	toggleDisplay, 
	newId, 
	useStyles, 
	texts, 
	clone, 
	Api, 
	saveMode 
} from '../helper';
import { FormCreate } from '.';
import { FormGroupDesigner } from './formComponents';

const FormDesigner = (props) => {
	// const { id } = useParams();
	const { id } = props;
	const history = useHistory();
	const classes = useStyles();

	const [detail, setDetail] = useState(null);
	const [typeList, setTypeList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isFinishAlertOpen, setIsFinishAlertOpen] = useState(false);
	const [isOpenEditForm, setIsOpenEditForm] = useState(false);
	const [savingMode, setSavingMode] = useState(null);
	const [isValidationAlertOpen, setIsValidationAlertOpen] = useState(false);
	const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
	
	const [selectedComponent, setSelectedComponent] = useState('');
	const [selectedProperties, setSelectedProperties] = useState(null);
	const [formulaReferences, setFormulaReferences] = useState({});
	const [dropdownReferences, setDropdownReferences] = useState({});
	const [tableReferences, setTableReferences] = useState({});
	const [isReadOnly, setIsReadOnly] = useState(true);

	const [sources, setSources] = useState([]);

	const [children, setChildren] = useState(null);
	const [tick, setTick] = useState(0);
	const components = useRef([]);
	const tracker = useRef({});
	const withError = useRef(false);
	const isErrorNotified = useRef(false);
	const [withChange, setWithChange] = useState(false);

	const handlePreview = () => { 
		if (withChange) {
			setSavingMode(saveMode.PREVIEW);
			sendTick();
		} else {
			history.push(Paths.CASHFLOW_FORM_BUILDER_PREVIEW.replace(':id', id));
			props.setTick(Math.random);
		}
	}

	const handleSaveDraft = () => { 
		if (withChange) {
			setSavingMode(saveMode.DRAFT);
			sendTick();
		}
	}

	const handleFinishForm = async () => { 
		setIsFinishAlertOpen(false);
		if (withChange) {
			setSavingMode(saveMode.FINAL);
			sendTick();
		} else {
			const isFinalized = await handleFormFinalize();
			setIsErrorAlertOpen(!isFinalized);
		}
	}

	const handleToggleDrawer = () => { 
		setIsDrawerOpen(!isDrawerOpen)
	}

	const handleAddGroup = () => { 
		const data = [...(children || [])];
		const child = { id: newId(), title: '' };
		data.push(child);
		setChildren(data);
		components.current.push(child);
		setWithChange(true);
	}

	const toggleEditForm = () => { 
		setIsOpenEditForm(!isOpenEditForm);
	}

	const handleEditForm = (id) => { 
		toggleEditForm();
		if (id > 0) {
			handleDataLoad();
		}
	}
	
	const handleDesignerSelect = (event) => { 
		if (event) {
			event.stopPropagation();
		}
        setSelectedComponent('');
		setSelectedProperties(null);
	}

	const handleComponentSelect = (id, properties) => {
		setSelectedComponent(id);
		setSelectedProperties(properties);
		if (properties) {
			setIsDrawerOpen(true);
		}
	}

	const handleComponentDelete = (id) => {
		handleDesignerSelect();
		components.current = components.current.filter((component) => (component.id !== id));
		const data = [...(children || [])].filter((child) => (child.id !== id));
		setChildren(data);
		delete tracker.current[id];
		handleTrackChange();
	}

	const handleReferenceDelete = (id) => {
		for (const key of Object.keys(formulaReferences)) {
            if (key.endsWith(id)) {
				delete formulaReferences[key];
			}
        }
		for (const key of Object.keys(tableReferences)) {
            if (key.endsWith(id)) {
				delete tableReferences[key];
			}
        }
		for (const key of Object.keys(dropdownReferences)) {
            if (key.endsWith(id)) {
				delete dropdownReferences[key];
			}
        }
	}

	const handleTrackChange = () => {
		setWithChange(true);
	}

	const sendTick = () => {
		setIsLoading(true);
		isErrorNotified.current = false;
		if (children && children.length > 0) {
			for (const key of Object.keys(tracker.current)) {
				tracker.current[key] = false;
			}
			withError.current = false;
			const nextTick = tick + 1;
			setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

	const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		const payload = [];
		// Follow the sorting of the components
		for (const child of children) {
			const group = components.current.find(comp => comp.id === child.id);
			if (group) {
				payload.push(group);
			}
        }

		// Now we can do saving
		handleFormSave(JSON.stringify(payload));
	}

	const handleFormSave = async (payload) => {
		let withError = false;
		const resSchema = await Api.saveSchema((detail?.schemaId || 0), 
											  (detail?.id || 0), 
											  payload);
		const schemaId = (resSchema || 0);
		if (schemaId > 0) {
			setWithChange(false);
			if (savingMode === saveMode.FINAL) {
				const isFinalized = await handleFormFinalize(schemaId);
				withError = !isFinalized;
			} else {
				detail.schemaId = schemaId;
				setDetail(detail);
			}
		} else {
			withError = true;
		}

		setIsLoading(false);
		setIsErrorAlertOpen(withError);

		if (!withError && (savingMode === saveMode.PREVIEW)) {
			history.push(Paths.CASHFLOW_FORM_BUILDER_PREVIEW.replace(':id', id));
			props.setTick(Math.random);
		}
	}

	const handleFormFinalize = async (schemaId) => {
		const resForm = await Api.saveForm((detail?.id || 0), 
												(detail?.name || ''), 
												(detail?.typeId || 0), 
												(detail?.productIds || ''),
												0, true);
		if (resForm) {
			const formId = (resForm.result || 0);
			if (formId > 0) {
				if (schemaId) {
					detail.schemaId = schemaId;
				}
				detail.isActive = true;
				setDetail(detail);
				setIsReadOnly(true);
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	const updateComponent = (update, error) => {
		if (!withError.current) {
			tracker.current[update.id] = true;
			if (error) {
				withError.current = true;
				setIsLoading(false);
				setIsValidationAlertOpen(true);
			} else {
				components.current = components.current.map((component) => {
					if (component.id === update.id) {
						return update;
					} else {
						return component;
					}
				});
				trackUpdates();
			}
		}
	} 

	const trackComponent = (id) => {
		tracker.current[id] = false;
	}

	const handleFormulaReference = (id, reference, prevId) => { 
		if (prevId) {
			delete formulaReferences[prevId];
		}
    formulaReferences[id] = reference;
		setFormulaReferences(formulaReferences);
	}

	const handleDropdownReference = (id, reference) => { 
        dropdownReferences[id] = reference;
		setDropdownReferences(dropdownReferences);
	}
	
	const handleTableReference = (id, reference, manual) => { 
        tableReferences[id] = { reference, manual };
		setTableReferences(tableReferences);
	}

	const initialize = (schema) => {
		const data = JSON.parse(schema.payload);
		if (data) {
			const children = [];
			components.current = [];
			for (const child of data) {
				children.push(child);
				components.current.push(child);
			}
			setChildren(children);
		}
	}

	const handleDataLoad = async (isLoadLookups, isReloadSchema) => {
		setIsLoading(true);
		if (isLoadLookups) {
			const resTypes = await Api.getTypes();
			if (resTypes) {
				setTypeList(resTypes);
			}
			const resProducts = await Api.getProducts();
			if (resProducts) {
				setProductList(resProducts);
			}
			const resSources = await Api.getSources();
			if (resSources && resSources.list) {
				const sources = resSources.list.map((source) => {
									return { id: source.id,
											 name: source.name,
											 columns: source.columns.split(', ') };
								});
				setSources(sources)
			}
		}

		const response = await Api.getForm(id);
		if (response) {
			if (response.schema) {
				if (isReloadSchema) {
					initialize(response.schema);
				}
				response.schemaId = (response?.schema?.id || 0);
				delete response.schema;
			}
			setIsReadOnly(response.isActive || false);
			setDetail(response);
		}
		setIsLoading(false);
	}

	useEffect(() => {
		handleDataLoad(true, true);
	}, [id]);

	return (
		<Fragment>
			<div className={clsx(classes.pageWrapperDesigner, {[classes.pageWrapperDrawer]: isDrawerOpen})} onClick={handleDesignerSelect}>
				{detail &&
				<Grid container className={classes.pageHeader} spacing={1}>
					<Grid item xs={12} sm={12} md={6} className={classes.pageTitleWrapper}>
						<span className={classes.pageTitle}>{(detail?.name || '')}</span>
						{!isReadOnly  && 
						<IconButton aria-label='Edit' className={classes.pageTitleIcon} onClick={toggleEditForm}>
							<MdIcon path={mdiPencilOutline} size='0.8em' />
						</IconButton>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={6} className={classes.controlEnd}>
						{(components.current && components.current.length > 0) &&
						<Fragment>
							<Button variant='outlined' className={classes.secondaryButton} onClick={handlePreview}>{texts.buttonPreview}</Button>	
							{!isReadOnly  && 
							<Fragment>
								<Badge color="secondary" badgeContent=" " variant="dot" invisible={!withChange}>
									<Button variant='outlined' className={classes.secondaryButton} onClick={handleSaveDraft}>{texts.buttonSaveDraft}</Button>	
								</Badge>
								<Button variant='contained' className={classes.primaryButton} onClick={() => setIsFinishAlertOpen(true)}>{texts.buttonFinish}</Button>	
							</Fragment>
							}
						</Fragment>
						}
					</Grid>
				</Grid>}
				<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
				{detail && 
				<Grid container className={classes.builderWrapper} spacing={3}>
					{children && 
						<ReactSortable 
							list={children} 
							setList={setChildren} 
							animation={200}
							handle='.group-sort-handler'	
							className={classes.groupWrapper}
							onUpdate={handleTrackChange}>
							{children.map((child) => (
							<div key={child.id} className={classes.groupContainer}>
								<FormGroupDesigner 
									handleSelect={handleComponentSelect} 
									selected={selectedComponent}
									handleFormulaReference={handleFormulaReference}
									formulaReferences= {formulaReferences}
									handleDropdownReference={handleDropdownReference}
									dropdownReferences={dropdownReferences}
									handleTableReference={handleTableReference}
									tableReferences= {tableReferences}
									sources={sources}
									trackComponent={trackComponent}			
									updateComponent={updateComponent}	
									handleComponentDelete={handleComponentDelete}
									handleReferenceDelete={handleReferenceDelete}
									handleTrackChange={handleTrackChange}	
									isReadOnly={isReadOnly}
									tick={tick}
									id={child.id} 
									title={child.title || ''}
									children={child.children} />
							</div>
							))}
						</ReactSortable>
					}
					{!isReadOnly  && 
					<Grid item xs={12}>
						<div className={classes.addGroupWrapper}>
							<Divider />
							<Button  color='default' className={classes.addGroupButton} startIcon={<MdIcon path={mdiPlusCircleOutline} size='1em' />} onClick={handleAddGroup}>
								{texts.buttonAddGroup}
						  	</Button>
						</div>
					</Grid>
					}
				</Grid>
				}
			</div>
			{detail && 
			<Drawer	className={classes.drawer}	variant='persistent' anchor='right' open={isDrawerOpen}	classes={{ paper: clsx(classes.drawerPaper, {[classes.drawerPaperWith]: selectedProperties})}}>
				<IconButton aria-label='hide' className={clsx(classes.hideDrawer, {[classes.hideDrawerWith]: selectedProperties})} size='small' onClick={handleToggleDrawer}>
					<MdIcon path={mdiArrowRight} size='1em' />
        		</IconButton>
				<div className={classes.drawerWrapper}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<div className={clsx(classes.drawerTitle, {[classes.drawerTitleWith]: selectedProperties})}>{texts.infoPropertyTitle}</div>
						</Grid>
						{!selectedProperties && <Grid item xs={12}>
							<span className={classes.drawerNoComponent}>{texts.infoNoComponent}</span>
						</Grid>}
						{selectedProperties}
					</Grid>
				</div>
			</Drawer>
			}
			<Modal open={isOpenEditForm}>
				<FormCreate onFormCancel={toggleEditForm} onFormSuccess={handleEditForm} detail={detail} id={(detail?.id || 0)} types={typeList} products={productList} />
			</Modal>
			<Alert isOpen={isFinishAlertOpen}
				title={texts.infoFinishAlertTitle} 
				message={`${texts.infoFinishAlertMessageStart}${(detail?.name || '')}${texts.infoFinishAlertMessageEnd}`}
				cancelButton={texts.buttonCancel}
				confirmButton={texts.buttonConfirmFinish}
				confirm={handleFinishForm}
				cancel={() => setIsFinishAlertOpen(false)} />
			<Alert isOpen={isValidationAlertOpen}
				title={texts.infoValidationAlertTitle} 
				message={(savingMode === saveMode.PREVIEW) ? texts.infoValidationAlertPreview : texts.infoValidationAlertSave}
				confirmButton={texts.buttonGotIt}
				confirm={() => setIsValidationAlertOpen(false)} />
			<Alert isOpen={isErrorAlertOpen}
				title={texts.infoErrorAlertTitle} 
				message={texts.infoErrorAlertMessage}
				confirmButton={texts.buttonGotIt}
				confirm={() => setIsErrorAlertOpen(false)} />
		</Fragment>
	)
}

export default FormDesigner;