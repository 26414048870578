import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
	FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Switch,
    Select,
	InputLabel,
    InputAdornment,
	MenuItem,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignBoldStyle, 
    getAlignBoldWidthStyle,
    useStyles, 
    texts,
    StyledTableDetailCell,
    getDisplayValue,
    modes,
    PdfStyles,
    getPdfAlignBoldWidthStyle,
    componentTypes,
} from '../../helper';

const FormTableDetailDropdownProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [required, setRequired] = useState(false);
    const [source, setSource] = useState({});
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [reference, setReference] = useState('');
    const [errors, setErrors] = useState({});

    const handleLabelChange = (event) => {
		const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
		errors['label'] = checkRequired(label, texts.labelComponentColumnLabel);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleRequiredChange = (event, checked) => { 
        setRequired(checked);
        props.updateRequired(checked);
    }

    const handleSourceChange = (event, value) => {
        setSource(value);
        props.updateSource(value);
        errors['source'] = checkRequired(value, texts.labelDataSource);
		setErrors(errors);
    }

    const handleBoldChange = (event, checked) => { 
        setBold(checked);
        props.updateBold(checked);
    }

    const handleWidthChange = (event) => {
        const width = parseInt(event.target.value || 0);
        if (width >= 0 && width <= 100) {
            setWidth(width);
            props.updateWidth(width);  
        }
    }

    const handleFillerChange = (event) => {
        const filler = event.target.value;
        setFiller(filler);
        props.updateFiller(filler);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }
   
    useEffect(() => {
        setLabel(props.label);
        setAlign(props.align);
        setRequired(props.required || false);
        setSource(props.source);
        setBold(props.bold || false);
        setWidth(props.width || '');
        setFiller(props.filler || '');
        setReference(props.reference || '');
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentColumnLabel);
        errors['source'] = checkRequired(props.source, texts.labelDataSource);
		setErrors(errors);
    }, [props.id]);
    
    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableDetailDropdown}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-label'
                            label={texts.labelComponentColumnLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={required} onChange={handleRequiredChange} disabled={props.isReadOnly} />}
                        label={required ? texts.infoValueRequired : texts.infoValueOptional}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <Autocomplete
                            id='component-source'
                            options={props.sources}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            value={source || {}}
                            filterSelectedOptions
                            disableClearable={true}
                            noOptionsText={texts.infoNoSource}
                            onChange={handleSourceChange}
                            disabled={props.isReadOnly}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={texts.labelDataSource}
                                    error={errors['source']}
                                    helperText={errors['source']}
                                />
                            )}
                            classes={{
                                popper: classes.formControl,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={bold} onChange={handleBoldChange} disabled={props.isReadOnly} />}
                        label={bold ? texts.infoFontBold : texts.infoFontNormal}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-width'
                            type='number'
                            label={texts.labelComponentWidth}
                            variant='outlined'
                            value={width || ''}
                            onChange={handleWidthChange}
                            InputProps={{
                                inputProps: { min: 1, max: 100 },
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-filler'
                            label={texts.labelComponentFiller}
                            variant='outlined'
                            value={filler || ''}
                            onChange={handleFillerChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableDetailDropdownDesigner = (props) => {
	const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [required, setRequired] = useState(false);
    const [source, setSource] = useState({});
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [reference, setReference] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id); 
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateRequired = (required) => {
        setRequired(required);
        props.handleTrackChange();
    }

    const handleUpdateSource = (source) => {
        setSource(source);
        props.handleDropdownReference(props.id, source);
        props.handleTrackChange();
    }

    const handleUpdateBold = (bold) => {
        setBold(bold);
        props.handleTrackChange();
    }

    const handleUpdateWidth = (width) => {
        setWidth(width);
        props.handleTrackChange();
    }

    const handleUpdateFiller = (filler) => {
        setFiller(filler);
        props.handleTrackChange();
    }

    const handleUpdateReference = (reference) => {
        setReference(reference);
        props.handleFormulaReference('!&' + props.id, reference);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableDetailDropdownProperties 
                                            id={props.id} 
                                            label={label} 
                                            align={align} 
                                            required={required}
                                            source={source}
                                            bold={bold}
                                            width={width}
                                            filler={filler}
                                            reference={reference}
                                            sources={props.sources}
                                            updateLabel={handleUpdateLabel}
                                            updateAlign={handleUpdateAlign}
                                            updateRequired={handleUpdateRequired}
                                            updateSource={handleUpdateSource}
                                            updateBold={handleUpdateBold}
                                            updateWidth={handleUpdateWidth}
                                            updateFiller={handleUpdateFiller}
                                            updateReference={handleUpdateReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
        errors['label'] = checkRequired(label);
        errors['source'] = checkRequired(source);
        return (!!errors['label'] || !!errors['source']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLEDETAILDROPDOWN, 
                                    label, 
                                    align,
                                    required,
                                    source,
                                    bold,
                                    width,
                                    filler,
                                    reference }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setAlign(props.align);
            setRequired(props.required);
            setSource(props.source);
            if (props.source && props.source) {
                props.handleDropdownReference(props.id, props.source);
            }
            setBold(props.bold);
            setWidth(props.width);
            setFiller(props.filler);
            setReference(props.reference);
            if (props.reference) {
                props.handleFormulaReference('!&' + props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-detail-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableDetailDropdown}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <InputLabel id='component-type-label'>{label || ''}</InputLabel>
                        <Select
                            labelId='component-type-label'
                            label={label || ''}
                            id='component-type'
                            style={getAlignBoldStyle(align, bold)}
                            value={label || texts.infoConfigTableDetailDropdown}>
                            <MenuItem className={classes.menuItem} value={label || texts.infoConfigTableDetailDropdown}>{label || texts.infoConfigTableDetailDropdown}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableDetailDropdown} 
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigTableDetailDropdown}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

const FormTableDetailDropdownHeader = (props) => {
    useEffect(() => {
        if (props.reference) {
            props.handleFormulaReference('!&' + props.id, props.reference);
        }
    }, []);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {props.label || ''}
        </StyledTableDetailCell>
    )
}

const FormTableDetailDropdownView = (props) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        const key = (props.inputData[props.id + '-' + props.rowId] || '');
        const data = ((props.sources[props.source.id] || []).find((option) => option.Key === key)?.Value || '');
        setValue(data);
    }, [props.id]);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {getDisplayValue(value, props.filler, props.prefix, props.suffix)}
        </StyledTableDetailCell>
    )
}

const FormTableDetailDropdownEdit = (props) => {
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [errors, setErrors] = useState({});
    const [isInitialize, setIsInitialize] = useState(false);

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.handleInputData((props.id + '-' + props.rowId), value, false, false, true);
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
            setErrors(errors);
        }
	}

    const doValidate = () => {
        const errors = {};
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
        }
        setErrors(errors);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            if (props.tick.startsWith(props.rowId)) {
                props.updateComponent(props.rowId, props.id, value, doValidate());
            }
        } else {
            setIsInitialize(true);
        }
    }, [props.tick]);

    useEffect(() => {
        setOptions(props.sources[props.source.id] || []);
        setValue(props.inputData[props.id + '-' + props.rowId] || '');
        props.trackComponent(props.rowId, props.id);
    }, [props.rowId, props.id]);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`} error={errors['value']}>
                <Select
                    id={props.id}
                    value={value || ''}
                    displayEmpty
                    style={getAlignBoldStyle(props.align, props.bold)}
                    onChange={handleValueChange}>
                    <MenuItem className={classes.menuItem} value={''}><em>{texts.infoDropdownEmptyOption}</em></MenuItem>
                    {(options || []).map((option) => (
                    <MenuItem className={classes.menuItem} value={option.Key}>{option.Value}</MenuItem>
                    ))}
                </Select>	
                {(errors['value'] && <FormHelperText>{errors['value']}</FormHelperText>)}
            </FormControl>
        </StyledTableDetailCell>
    )
}

export const FormTableDetailDropdownPreview = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailDropdownHeader {...props} />;
    } else if (props.mode === modes.VIEW) {
        return <FormTableDetailDropdownView {...props} />;
    } else if (props.mode === modes.EDIT) {
        return <FormTableDetailDropdownEdit {...props} />;
    } else {
        return <span />
    }
}

const FormTableDetailDropdownPdfHeader = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailHeaderCol)} wrap={true}>
            <Text>{props.label || ''}</Text>
        </View>
    )
}

const FormTableDetailDropdownPdfView = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailDataCol)} wrap={true}>
            <Text>{getDisplayValue((props.inputData[props.id + '-' + props.rowId] || ''), props.filler, props.prefix, props.suffix)}</Text>
        </View>
    )
}

export const FormTableDetailDropdownPdf = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailDropdownPdfHeader {...props} />;
    } else if (props.mode === modes.VIEW) {
        return <FormTableDetailDropdownPdfView {...props} />;
    } else {
        return <span />
    }
}