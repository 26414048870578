import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { TableCell, TableRow } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiPaginationItem-page.Mui-selected ": {
      backgroundColor: "#F5922F",
      color: "#FFFFFF",
      fontFamily: "Inter",
    },
    "& .MuiPaginationItem-rounded": {
      fontFamily: "Inter",
    },
    "& .MuiInputBase-input": {
      height: 0,
      lineHeight: "0px",
    },
    "& .MuiInputBase-root": {
      height: 40,
      lineHeight: "2px",
    },
    "& .MuiFormLabel-root": {
      lineHeight: "0px",
      fontFamily: "Inter",
      fontSize: 14,
    },
    "& .MuiSelect-selectMenu": {
      minHeight: "0px",
      fontFamily: "Inter",
      fontSize: 14,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#F5922F",
    },
  },
  formControl: {
    "& .MuiFormLabel-root": {
      lineHeight: "0px",
      fontFamily: "Inter",
      fontSize: 14,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  table: {
    fontFamily: "Inter",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#636363",
    fontSize: 12,
    fontFamily: "Inter",
  },
  body: {
    fontSize: 14,
    fontFamily: "Inter",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 72,
    "&:hover": {
      // backgroundColor: '#FDF3E5'
    },
  },
  body: {
    fontFamily: "Inter",
  },
}))(TableRow);
