import axios from "./axios";

const LoanService = {
	getStubData: async (productId) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/products/${productId}/form`);
			return response.data;
		} catch (error) {
		}
	},
	getApplications: async (cognitoId, showItems, page, search, loanType, searchStatusKey) => {
		try {
			let query = ""
			if (search) query += `&search=${search}`
			if (loanType) query += `&loanProduct=${loanType}`
			if (searchStatusKey) query += `&status=${searchStatusKey}`
			const response = await axios.get_i2i(`/agency/loans/users/${cognitoId}/applications?limit=${showItems}&page=${page}${query}`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationList: async (cognitoId, customerId,  showItems, page, search, product, searchStatusKey) => {
		try {
			let params = {};
			if (cognitoId) params.cognitoId = `${cognitoId}`;
			if (customerId) params.customerId = `${customerId}`;
			if (search) params.search = `${search}`;
			if (product) params.product = `${product}`;
			if (searchStatusKey) params.status = `${searchStatusKey}`;
			const response = await axios.get_i2i(`/agency/loans/users/applications?limit=${showItems}&page=${page}`, { params });
			return response.data;
		} catch (error) {
		}
	},
	getApplicationAttachments: async (refId, fileName, isThumbnail, isDraft, startByte, endByte) => {
		let query = ""
		let partialData
		if (fileName) {
			query += `?fileName=${fileName}`
		}
		if (isThumbnail) {
			query += `&thumbnail=true`
		}
		if (isDraft) {
			query += `&draft=true`
		}
		if (startByte == 0) {
			query += `&startByte=0`
		}
		else if (startByte) {
			query += `&startByte=${startByte}`
		}
		if (endByte) {
			query += `&endByte=${endByte}`
		}

		try {
			const response = await axios.get_i2i(`/agency/loans/applications/${refId}/attachments${query}`, {
				onDownloadProgress: progressEvent => {
					partialData = progressEvent.currentTarget.response
				}
			});
			return response.data;
		} catch (error) {
			return ({ code: 'F', partialData: partialData })
		}
	},
	getApplicationData: async (appRef) => {
		try {
			const response = await axios.get_i2i(`/r1/agency/loans/applications/${appRef}`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationProductID: async (appRef) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/application/${appRef}/product`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationGroupedFields: async (productId) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/products/${productId}/grouped`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationSignature: async (appRef) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/applications/${appRef}/signature`);
			return response.data && response.data.result && response.data.result.value;
		} catch (error) {
		}
	},
	getApplicationStatus: async (appRef) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/logs/${appRef}/status`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationSummary: async (appRef) => {
		try {
			const response = await axios.get_vas(`/agency/loans/logs/${appRef}/summary`);
			return response.data;
		} catch (error) {
		}
	},
	getDownloadablePDF: async (appRef) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/applications/${appRef}/pdf`);
			return response.data;
		} catch (error) {
		}
	},
	getLoanAgents: async () => {
		try {
			const response = await axios.get_vas("/agency/loans/agents");
			return response.data;
		} catch (error) {
		}
	},
	getLogs: async (limit, page, fromDate, toDate, statusKey, search) => {
		try {
			let query = ""
			if (search) query += `&search=${search}`
			if (statusKey) query += `&status=${statusKey}`
			if (fromDate) query += `&dateFrom=${fromDate}`
			if (toDate) query += `&dateTo=${toDate}`
			const response = await axios.get_i2i(`/agency/loans/logs/allLogs?limit=${limit}&page=${page}${query}`);
			return response.data;
		} catch (error) {
		}
	},
	getLoanAgentsApplications: async (limit, page, search) => {
		try {
			let query = ""
			if (search) query += `&search=${search}`
			const response = await axios.get_vas(`/agency/loans/agents/applications?limit=${limit}&page=${page}${query}`);
			return response.data;
		} catch (error) {
		}
	},
	getProducts: async () => {
		try {
			const response = await axios.get_i2i(`/agency/loans/products/brand`);
			return response.data;
		} catch (error) {
		}
	},
	getProductAssets: async (topOrg, code, version) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/products/assets/images?topOrg=${topOrg}&code=${code}&version=${version}`);
			return response.data
		} catch (error) {
		}
	},
	getProductIcons: async () => {
		try {
			const response = await axios.get_i2i(`/agency/loans/products/assets/icons`);
			return response.data
		} catch (error) {
		}
	},
	getRemarks: async (referenceId) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/${referenceId}/remarks`);
			return response.data;
		} catch (error) {
		}
	},
	getSupervisors: async () => {
		try {
			const response = await axios.get_vas(`agency/loans/supervisors`);
			return response.data;
		} catch (error) {
		}
	},
	getApplicationHighLevelSummary: async (from, to, limit, page) => {
		try {
			let query = "";
			if (from) query += `from=${from}`;
			if (to) query += `&to=${to}`;
			if (limit) query += `&limit=${limit}`;
			if (page) query += `&page=${page}`;
			const response = await axios.get_i2i(`/agency/loans/application/summary?${query}`);
			return response.data
		} catch (error) {
		}
	},
	getExportSettings: async (type, requestObj) => {
		const query = `type=${type}`
		try {
			const response = await axios.postAgency(`/agency/loans/product/settings?${query}`, requestObj);
			return response.data
		} catch (error) {
		}
	},
	exportApplications: async (from, to, exportType, loanProductId, exportSettingId) => {
		let query = "";
		query += `from=${from}`
		query += `&to=${to}`
		query += `&exportType=${exportType}`
		if (loanProductId) query += `&loanProductId=${loanProductId}`
		if (exportSettingId) query += `&exportSettingId=${exportSettingId}`

		try {
			const response = await axios.get_i2i(`/agency/loans/applications/csv?${query}`);
			return response.data
		} 
		catch (error) {
		}
	},
	editApplication: async (referenceId, applicationData) => {
		try {
			const response = await axios.postAgency(`/agency/loans/applications/${referenceId}/edit`, applicationData);
			return response.data
		} 
		catch (error) {
		}
	},
	postRemarks: async (remarksObject) => {
		try {
			const response = await axios.postAgency('/agency/loans/remarks', remarksObject);
			return response.data;
		} catch (error) {
		}
	},
	reassignAgent: async (agentId, supervisorId) => {
		try {
			const response = await axios.patch(`/users/${agentId}/assign?supervisorId=${supervisorId}`);
			return response.data;
		} catch (error) {
		}
	},
	setLog: async (logObject) => {
		try {
			const response = await axios.postAgency('/agency/loans/applications/logs', logObject);
			return response.data;
		} catch (error) {
		}
	},
	submitApplication: async (appRef, cashFlowVersion) => {
		try {
			let version = '';
			if (cashFlowVersion) version+= `?version=${cashFlowVersion}`
			const response = await axios.postAgency(`/agency/loans/applications/${appRef}/submit${version}`);
			return response.data;
		} catch (error) {
			return error.response && error.response.data
		}
	},
	updateProductImageIcon: async (topOrg, code, version, fileObject) => {
		try {
			const response = await axios.postAgency(`/agency/loans/products/image/upload?topOrg=${topOrg}&code=${code}&version=${version}`, fileObject);
			return response.data;
			} catch (error) {
				return error.response && error.response.data
		}
	},
	getDraftSummary: async (search, from, to, limit, page) => {
		try {
			let query = "";
			
			if (from) query += `from=${from}`;
			if (to) query += `&to=${to}`;
			if (limit) query += `&limit=${limit}`;
			if (page) query += `&page=${page}`;
			if (search) query += `&search=${search}`
			const response = await axios.get_i2i(`/agency/loans/application/draft/summary?${query}`);
			return response.data
		} catch (error) {
		}
	},
	getChangeRequests: async (referenceId) => {
		try {
			const response = await axios.get_i2i(`/agency/loans/applications/${referenceId}/draft`);
			return response.data
		} catch (error) {
		}
	},
	approveRejectChangeRequest: async (referenceId, action, requestBody) => {
		try {
			const response = await axios.postAgency(`/agency/loans/applications/${referenceId}/draft?action=${action}`, { fields: requestBody });
			return response.data
		} catch (error) {
		}
	},
	getCashFlowAnalysisList: async (referenceId, limit, page) => {
		try {
			let query = "";

			if (limit) query += `&limit=${limit}`;
			if (page) query += `&page=${page}`;
			const response = await axios.get_i2i(`/agency/loans/cashflow/list/${referenceId}?${query}`);
			return response.data
		} catch (error) {
		}
	},
	getCustomerProfileList: async (search, customerBirthdate, customerEmail, customerMobile, limit, page) => {
		try {
			let query = "";

			if (search) query += `&search=${search}`
			if (customerBirthdate) query += `&birthdate=${customerBirthdate}`
			if (customerEmail) query += `&email=${customerEmail}`
			if (customerMobile) query += `&mobileNum=${customerMobile}`
			if (limit) query += `&limit=${limit}`;
			if (page) query += `&page=${page}`;
			
			const response = await axios.get_i2i_v2(`/agency/loans/customer/list?${query}`);
			return response.data
		} catch (error) {
		}
	},
	addCustomerProfile: async (requestBody) => {
		try {
			const response = await axios.post_i2i_v2(`agency/loans/customer`, requestBody);
			return response.data
		} catch (error) {
		}
	},
	updateCustomerProfile: async (requestBody, customerId) => {
		try {
			const response = await axios.patch_i2i_v2(`agency/loans/customer/${customerId}`, requestBody);
			return response.data
		} catch (error) {
		}
	},
	assignCustomerProfile: async (referenceId, customerId) => {
		try {
			const response = await axios.post_i2i_v2(`agency/loans/customer/profile/${referenceId}/link?customerId=${customerId}`);
			return response.data
		} catch (error) {
		}
	},
	getCustomerSubgroupFields: async () => {
		try {
			const response = await axios.get_i2i_v2(`/agency/forms/subgroups/fields`);
			return response.data
		} catch (error) {
		}
	},
	getCustomerSubgroupFieldData: async (customerId) => {
		try {
			const response = await axios.get_i2i_v2(`/agency/loans/customer/${customerId}`);
			return response.data
		} catch (error) {
		}
	},
	saveCashFlowAnalysis: async (referenceId,data) => {
		try{
			const response = await axios.postAgency(`/agency/loans/cashflow/${referenceId}`,data)
			return response.data;
		}catch (error){
		}
	},
	getCashFlowForm: async (productId) => {
		try{
			const response = await axios.get_cashflow(`/cashflow/form/list/application?product=${productId}`);
			return response.data;
		} catch (error){
		}
	},
	setDefaultCashflow: async (referenceId, version) => {
		try {
			const response = await axios.postAgency(`/agency/loans/cashflow/set/${referenceId}`, version);
			return response.data;
		} catch (error) {
		}
	},
	uploadAttachment: async (referenceId, requestObj) => {
		try {
			const formData = new FormData();
			formData.append(requestObj.fieldCode, requestObj.file)
			const response = await axios.postAgency(`/agency/loans/applications/${referenceId}/attachments`, formData);
			return response.data;
		} catch (error) {
		}
	},
	deleteAttachment: async (referenceId, fieldCode) => {
		const requestObj = {
			referenceId,
			fieldCode
		}
		try {
			const response = await axios.postAgency(`/agency/loans/attachments`, requestObj);
			return response.data;
		} catch (error) {
		}
	},
};

export default LoanService