import React from "react";
import { useStyles, StyledTableCell, StyledTableRow } from "../helpers/Styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import moment from "moment-timezone";

const DisplayTable = (props) => {
  const classes = useStyles();

  return (
    <div style={{}}>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date and Time</StyledTableCell>
              <StyledTableCell>Reference ID</StyledTableCell>
              {props.role == "Agency Banking Product Owner" && (
                <StyledTableCell align="center">Brand</StyledTableCell>
              )}
              {props.role == "Loan Head" || props.role == "Loan Manager" ? (
                <StyledTableCell align="center">User</StyledTableCell>
              ) : (
                <StyledTableCell align="center">Agent</StyledTableCell>
              )}
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows.map((row, index) => (
                <StyledTableRow
                  style={{
                    backgroundColor:
                      props.hoveredRow == index ? "#FDF3E5" : "#FFFFFF",
                  }}
                  onMouseOver={() => props.setHoveredRow(index)}
                  onMouseOut={() => props.setHoveredRow(null)}
                >
                  <StyledTableCell>
                    {row.dateTime
                      ? moment(
                          row.dateTime[row.dateTime.length - 1] === "Z"
                            ? row.dateTime
                            : row.dateTime + "Z"
                        )
                          .tz("Asia/Singapore")
                          .format("DD MMMM YYYY, hh:mm:ss A")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={async () => {
                      !props.updatingData && props.getAdditionalData(row);
                    }}
                    style={{
                      cursor: "pointer",
                      color: props.updatingData ? "#909196" : "#F5922F",
                    }}
                  >
                    {row.referenceId ? row.referenceId : "-"}
                  </StyledTableCell>
                  {props.role == "Agency Banking Product Owner" && (
                    <StyledTableCell align="center" style={{ fontWeight: 600 }}>
                      {row.brandName ? row.brandName : "-"}
                    </StyledTableCell>
                  )}
                  {props.role == "Loan Head" || props.role == "Loan Manager" ? (
                    <StyledTableCell align="center" style={{ fontWeight: 600 }}>
                      {row.userFullName ? row.userFullName : "-"}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" style={{ fontWeight: 600 }}>
                      {row.agentName ? row.agentName : "-"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" style={{ fontWeight: 600 }}>
                    {row.status ? row.status : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.remarks ? row.remarks : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DisplayTable;
