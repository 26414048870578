import React, {forwardRef, Fragment, useImperativeHandle, useState} from 'react';
import {useStyles, componentTypes,  texts, getKeyValue,keys} from './../../cashFlow/helper';
import { Grid, Badge, Button } from '@material-ui/core';
import {FormDisplayPreview,FormInputPreview,FormTablePreview, FormLineBreakPreview} from './../../cashFlow/formBuilder/formComponents'
import PreviewPdf from './PreviewPdf'

const TabPanel = (props) => {
    const classes = useStyles();
    return (
        <div
            className={classes.pageWrapperGroup}
            role="tabpanel"
            hidden={props.tab !== props.id}
            id={`group-tabpanel-${props.id}`}
            aria-labelledby={`group-tab-${props.id}`}>
            {props.children}
        </div>
    );
}

const FormGroupPreview = (props) => {

    const classes = useStyles();
    const [tick, setTick] = useState(0);
    const [withChange, setWithChange] = useState(false);
    const referenceId = props.referenceId;
    const [recommendedAmountId,setRecommendedAmountId] = useState(0);

    const handleInputSave = async (data) => {
		props.handleInputSave(data);
        setWithChange(false);
	}

    const handleSaveChanges = async () => {
        const nextTick = tick + 1;
        setTick(nextTick);
        if (!props.isLastPage) {
            props.handleNextTab(props.tab+1);
        }
    }

    const handleBack = async () => {
        const nextTick = tick + 1;
        setTick(nextTick);
        if (props.tab > 0) {
            props.handleNextTab(props.tab - 1);
        }
    }
        
    const handleInputData = (id, value, isReadOnly, noTick, stopUpdate) => { 
        props.handleInputData(id, value,isReadOnly, noTick, stopUpdate);
        if (!isReadOnly) {
            setWithChange(true);
        }
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMDISPLAY) {
            return (
                <FormDisplayPreview 
                    id={child.id}
                    align={child.align}
                    value={child.value} />
            );
        } else if (child.type === componentTypes.FORMINPUT) {
            return (
                <FormInputPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    formulaReferences= {props.formulaReferences}
                    handleDropdownReference={props.handleDropdownReference}
                    dropdownReferences={props.dropdownReferences}
                    sources={props.sources}	
                    handleInputData={handleInputData}
                    inputData={props.inputData}
                    handleInputSave={handleInputSave}	
                    tick={tick}
                    formulaTick={props.formulaTick}
                    id={child.id}
                    children={child.children}
                    setRecommendedAmountId={setRecommendedAmountId}
                    recommendedAmountId={recommendedAmountId}
                    fromAnalysis={props.fromAnalysis}/>
            );
        } else if (child.type === componentTypes.FORMTABLE) {
            return (
                <FormTablePreview 
                    handleFormulaReference={props.handleFormulaReference}
                    formulaReferences= {props.formulaReferences}
                    handleDropdownReference={props.handleDropdownReference}
                    dropdownReferences={props.dropdownReferences}
                    handleTableReference={props.handleTableReference}
                    tableReferences= {props.tableReferences}
                    sources={props.sources}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    handleInputSave={props.handleInputSave}
                    handleInputUndo={props.handleInputUndo}
                    handleInputDelete={props.handleInputDelete}
                    formulaTick={props.formulaTick}
                    tableTick={props.tableTick}	
                    handleAddRowDataTable={props.handleAddRowDataTable}
                    handleTableData={props.handleTableData}
                    id={child.id}
                    children={child.children}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMLINEBREAK) {
            return (
                <FormLineBreakPreview 
                    id={child.id} />
            );
        } else {
            return <span/>
        }
    }

    const handleGeneratePdf = (blob) => {
        props.handleGeneratePdf(blob, recommendedAmountId);
    }

    const showSaveButton = () => {
        return ((props.children || []).find((child) => child.type === componentTypes.FORMINPUT) || (props.children || []).find((child) => child.type === componentTypes.FORMTABLE) || false);
    }

    const setIsLoading = (data) => {
        props.setIsLoading(data);
    }

    return (
        <TabPanel tab={props.tab} id={props.id}>
            <Grid container spacing={3}>
                {(props.children || []).map((child) => (
                <Grid item xs={12}>
                    {renderChild(child)}
                </Grid>
                ))}
                {showSaveButton() &&
                <Grid item xs={12} className={classes.controlEndNoWrap}>
                    {props.isLastPage?(
                    <Fragment>
                        <Button variant='contained' disabled={props.isLoading} className={classes.primaryButton} style={{ marginRight: 12 }} onClick={handleBack}>{texts.buttonBack}</Button>
                        <PreviewPdf id={props.formId} setIsLoading={setIsLoading} isLoading={props.isLoading} handleSaveChanges={handleSaveChanges} handleGeneratePdf={handleGeneratePdf} disabled={props.disableNext}
                            referenceId={referenceId}/>
						{/* <Button variant='contained' className={classes.primaryButtonNoWrap} onClick={props.handleGeneratePdf}>{texts.buttonGeneratePdf}</Button> */}
                    </Fragment>)
                    :(
                        <Fragment>
                            <Button variant='contained' className={classes.primaryButton} style={{ marginRight: 12 }} onClick={handleBack} disabled={props.tab <= 0}>{texts.buttonBack}</Button>
                            <Badge color="secondary" badgeContent=" " variant="dot" invisible={!withChange}>
                                <Button variant='contained' className={classes.primaryButton} onClick={handleSaveChanges} disabled={props.disableNext}>{texts.buttonNext}</Button>
                            </Badge>
                        </Fragment>)
                    
                    }
                    
                </Grid>
                }
            </Grid>   
        </TabPanel>
	)
};

export default FormGroupPreview;