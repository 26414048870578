import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Grid,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Modal,
} from '@material-ui/core/';
import Pagination from '@material-ui/lab/Pagination';
import { Icon as MdIcon } from '@mdi/react';
import { mdiPlusCircleOutline, mdiMagnify, mdiContentDuplicate, mdiDeleteOutline } from '@mdi/js';
import Paths from '../../../enums/Paths';
import { 
	Alert,
	toggleDisplay, 
	useStyles, 
	StyledTableCell, 
	StyledTableRow, 
	texts, 
	formatDateTime,
	getKeyValue,
    setKeyValue,
	removeFiller,
	keys,
	Api,
} from '../helper';
import { FormCreate } from '.';

const FormList = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const [searchText, setSearchText] = useState(getKeyValue(keys.FORM_SEARCH));
	const [searchType, setSearchType] = useState(getKeyValue(keys.FORM_TYPE));
	const [searchProduct, setSearchProduct] = useState(getKeyValue(keys.FORM_PRODUCT));
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [pageLimit, setPageLimit] = useState(10);
	const [pageStatus, setPageStatus] = useState(texts.infoNoRecord);
	const [formList, setFormList] = useState([]);
	const [typeList, setTypeList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [detail, setDetail] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isOpenNewForm, setIsOpenNewForm] = useState(false);
	const [isOpenDuplicateForm, setIsOpenDuplicateForm] = useState(false);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [tick, setTick] = useState(0);

	const onSearchTextPress = (event) => {
		if (event.key === 'Enter') {
			handleDataLoad(false, event.target.value);
		}
	};

	const onSearchTextChange = (event) => {
		const value = event.target.value;
		setSearchText(value);
		setKeyValue(keys.FORM_SEARCH, value);
	}

	const onSearchTypeChange = (event) => {
		const value = event.target.value;
		setSearchType(value);
		setKeyValue(keys.FORM_TYPE, value);
		handleDataLoad(false, '', value);
	}

	const onSearchProductChange = (event) => {
		const value = event.target.value;
		setSearchProduct(value);
		setKeyValue(keys.FORM_PRODUCT, value);
		handleDataLoad(false, '', '', value);
	}

	const onPageLimitChange = (event) => {
		const value = event.target.value;
		setPageLimit(value);
		handleDataLoad(false, '', '', '', value);
	}

	const onPageChange = (event, value) => { 
		handleDataLoad(false, '', '', '', '', value);
	}

	const toggleNewForm = () => { 
		setIsOpenNewForm(!isOpenNewForm);
	}

	const handleNewForm = (id, withEdit) => { 
		toggleNewForm();
		if (id > 0) {
			if (withEdit) {
				doShowForm(id);
			} else {
				handleDataLoad(false);
			}
		}
	}

	const toggleDuplicateForm = () => { 
		setIsOpenDuplicateForm(!isOpenDuplicateForm);
	}

	const doDuplicateForm = (detail) => { 
		setDetail(detail);
		toggleDuplicateForm();
	}

	const handleDuplicateForm = (id, withEdit) => { 
		toggleDuplicateForm();
		if (id > 0) {
			if (withEdit) {
				doShowForm(id);
			} else {
				handleDataLoad(false);
			}
		}
	}

	const toggleDeleteForm = () => { 
		setIsDeleteAlertOpen(!isDeleteAlertOpen);
	}

	const doDeleteForm = (detail) => { 
		setDetail(detail);
		toggleDeleteForm();
	}

	const handleDeleteForm = async () => { 
		setIsLoading(true);
		const response = await Api.saveForm(detail.id, 
											detail.name, 
											detail.typeId, 
											detail.productIds,
											0,
											detail.isActive,
											true);
		setIsLoading(false);
		toggleDeleteForm();
		if (response) {
			const result = (response.result || 0);
			if (result > 0) {
				handleDataLoad(false);
			}
		}
	}

	const handleDataLoad = async (isLoadLookups, text, type, product, limit, page) => {
		setIsLoading(true);
		if (isLoadLookups) {
			const resTypes = await Api.getTypes();
			if (resTypes) {
				setTypeList(resTypes);
			}
			const resProducts = await Api.getProducts();
			if (resProducts) {
				setProductList(resProducts);
			}
		}

		const response = await Api.getForms((text || searchText),
											removeFiller(type || searchType), 
											removeFiller(product || searchProduct),
											(limit || pageLimit), (page || 1));
		if (response) {
			const currentPage = response.page || 0;
			const totalPages = response.totalPages || 0;
			const totalRecords = response.count || 0;
			let currentRecords = 0;
			let formList = [];
			setCurrentPage(currentPage);
			setTotalPages(totalPages);
			if (response.list) {
				formList = response.list;
				currentRecords = formList.length;
			} 
			setFormList(formList);
			
			if (response.count > 0) {
				setPageStatus(`${texts.pageFrom} ${currentPage != totalPages ? 
								(currentRecords * currentPage - (pageLimit - 1) + ` ${texts.pageTo} ` + currentRecords * currentPage) : 
									(((((currentPage - 1) * pageLimit) + 1) != totalRecords) ? 
										(((currentPage - 1) * pageLimit) + 1 + ` ${texts.pageTo} ` + totalRecords) : 
										(((currentPage - 1) * pageLimit) + 1))} ${texts.pageOf} ${totalRecords}`);
			} else {
				setPageStatus(texts.infoNoRecord);
			}
		}
		setIsLoading(false);
	}

	const doShowForm = (id) => { 
		history.push(Paths.CASHFLOW_FORM_BUILDER_DESIGNER.replace(':id', id)); 
		props.setTick(Math.random());
	}

	useEffect(() => {
		handleDataLoad(true);
	}, []);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1} style={{ marginBottom: 16 }}>
				<Grid item xs={12} sm={6}>
					<span className={classes.pageTitle}>{texts.headerFormList}</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.controlEnd}>
					<Button variant='contained' className={classes.primaryButton} onClick={toggleNewForm}>
						<MdIcon path={mdiPlusCircleOutline} size='1.5em' className={classes.buttonIconLeft} />{texts.buttonCreateNewForm}
					</Button>
				</Grid>
			</Grid>
			<Paper className={classes.paperWrapper} elevation={0}>
				<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
				<Grid container className={classes.controlWrapper} spacing={3}>
					<Grid item xs={12} sm={9}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={5} md={4} lg={3}>
								<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
								<TextField 
									id='search-form'
									label={texts.labelSearchForms}
									variant='outlined'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<MdIcon path={mdiMagnify} size='24px' />
											</InputAdornment>
										),
									}}
									value={searchText || ''}
									onKeyPress={onSearchTextPress}
									onChange={onSearchTextChange}
								/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={3} md={3} lg={2}>
								<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
									<InputLabel id='search-type-label'>{texts.labelType}</InputLabel>
									<Select
										labelId='search-type-label'
										label={texts.labelType}
										id='search-type'
										value={searchType}
										onChange={onSearchTypeChange}>
										<MenuItem className={classes.menuItem} value={texts.filler}><em>{texts.optionAll}</em></MenuItem>
										{typeList && typeList.map((data) => (
											<MenuItem className={classes.menuItem} value={data.id}>{data.name}</MenuItem>
										))}
									</Select>	
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={4} md={3} lg={2}>
								<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
									<InputLabel id='search-assigned-to-label'>{texts.labelAssignedTo}</InputLabel>
									<Select
										labelId='search-assigned-to-label'
										label={texts.labelAssignedTo}
										id='search-assigned-to'
										value={searchProduct}
										onChange={onSearchProductChange}>
										<MenuItem className={classes.menuItem} value={texts.filler}><em>{texts.optionAll}</em></MenuItem>
										<MenuItem className={classes.menuItem} value='0'>Global</MenuItem>
										{productList && productList.map((data) => (
											<MenuItem className={classes.menuItem} value={data.id}>{data.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={3} className={classes.controlEnd}>
						<FormControl variant='outlined' className={classes.formControl}>
							<FormControlLabel
								control={<Select
									id='list-items-top'
									className={classes.listItems}
									defaultValue={10}
									onChange={onPageLimitChange}>
									<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
									<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
									<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
								</Select>}
								label={texts.labelShowItems}
								labelPlacement='start'/>
						</FormControl>
					</Grid>
				</Grid>
				<div className={classes.tableWrapper}>	
						<TableContainer>
							<Table className={classes.table} aria-label='form builder table'>
								<TableHead>
									<TableRow>
										<StyledTableCell className={classes.plCell}>{texts.columnFormName}</StyledTableCell>
										<StyledTableCell width='18%'>{texts.columnType}</StyledTableCell>
										<StyledTableCell width='20%'>{texts.columnDateCreated}</StyledTableCell>
										<StyledTableCell width='30%'>{texts.columnAssignedTo}</StyledTableCell>
										<StyledTableCell width='6%'>{texts.columnStatus}</StyledTableCell>
										<StyledTableCell width='10%' align='center' className={classes.prCell}>{texts.columnActions}</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{formList && formList.map((data) => (
										<StyledTableRow>
											<StyledTableCell className={classes.plCell}><span className={classes.rowSelector} onClick={() => doShowForm(data.id)}>{data.name}</span></StyledTableCell>
											<StyledTableCell>{data.typeName}</StyledTableCell>
											<StyledTableCell>{formatDateTime(data.createdAt)}</StyledTableCell>
											<StyledTableCell>{data.productNames ? data.productNames : 'Global'}</StyledTableCell>
											<StyledTableCell>{data.isActive ? 'Active' : (<span className={classes.textInActive}>Draft</span>)}</StyledTableCell>
											<StyledTableCell align='center' className={classes.prCell}>
												<IconButton aria-label='Duplicate' onClick={() => doDuplicateForm(data)}>
													<MdIcon path={mdiContentDuplicate} size='0.8em' />
												</IconButton>
												<IconButton aria-label='Duplicate' onClick={() => doDeleteForm(data)}>
													<MdIcon path={mdiDeleteOutline} size='0.8em' />
												</IconButton>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
				</div>
			</Paper>
			<Grid container className={classes.footerWrapper}>
				<Grid item xs={12} sm={9}>
					<FormControl variant='outlined' className={classes.formControl}>
						<FormControlLabel
							control={((totalPages > 0) ? <Pagination className={classes.root} page={currentPage} count={totalPages} shape='rounded' onChange={onPageChange} /> : <span/>)}
							label={<span className={classes.pageStatus}>{pageStatus}</span>}
							labelPlacement='start'
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={3} className={classes.controlEnd}>
					<FormControl variant='outlined' className={classes.formControl}>
						<FormControlLabel
							control={<Select
								id='list-items-bottom'
								className={classes.listItems}
								defaultValue={10}
								onChange={onPageLimitChange}>
								<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
								<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
								<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
							</Select>}
							label={texts.labelShowItems}
							labelPlacement='start'/>
					</FormControl>
				</Grid>
			</Grid>
			<Modal open={isOpenNewForm}>
				<FormCreate onFormCancel={toggleNewForm} onFormSuccess={handleNewForm} types={typeList} products={productList} />
			</Modal>
			<Modal open={isOpenDuplicateForm}>
				<FormCreate onFormCancel={toggleDuplicateForm} onFormSuccess={handleDuplicateForm} detail={detail} types={typeList} products={productList} />
			</Modal>
			<Alert isOpen={isDeleteAlertOpen}
				title={texts.deleteAlertTitleForm} 
				message={`${texts.deleteAlertMessageStart}${detail?.name || ''}${texts.deleteAlertMessageDataEnd}`}
				cancelButton={texts.buttonCancel}
				confirmButton={texts.buttonDelete}
				confirm={handleDeleteForm}
				cancel={() => toggleDeleteForm()} />
		</div>
	)
}

export default FormList;
