import React, { useState, useEffect } from 'react';
import {
    IconButton,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Switch,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import {
    Alert,
    checkRequired,
    aligns,
    getAlignStyle,
    getPdfAlignStyle,
    useStyles,
    texts,
    PdfStyles,
    componentTypes,
} from '../../helper';

const FormInputDropdownProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [required, setRequired] = useState(false);
    const [source, setSource] = useState({});
    const [reference, setReference] = useState('');
    const [errors, setErrors] = useState({});

    const handleLabelChange = (event) => {
        const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
        errors['label'] = checkRequired(label, texts.labelComponentLabel);
        setErrors(errors);
    }

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleRequiredChange = (event, checked) => {
        setRequired(checked);
        props.updateRequired(checked);
    }

    const handleSourceChange = (event, value) => {
        setSource(value);
        props.updateSource(value);
        errors['source'] = checkRequired(value, texts.labelDataSource);
        setErrors(errors);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }

    useEffect(() => {
        setLabel(props.label);
        setAlign(props.align);
        setRequired(props.required || false);
        setSource(props.source);
        setReference(props.reference || '');
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentLabel);
        errors['source'] = checkRequired(props.source, texts.labelDataSource);
        setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeInputDropdown}
                            InputProps={{
                                readOnly: true,
                            }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField
                            id='component-label'
                            label={texts.labelComponentLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={required} onChange={handleRequiredChange} disabled={props.isReadOnly} />}
                        label={required ? texts.infoValueRequired : texts.infoValueOptional} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <Autocomplete
                            id='component-source'
                            options={props.sources}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            value={source || {}}
                            filterSelectedOptions
                            disableClearable={true}
                            noOptionsText={texts.infoNoSource}
                            onChange={handleSourceChange}
                            disabled={props.isReadOnly}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={texts.labelDataSource}
                                    error={errors['source']}
                                    helperText={errors['source']}
                                />
                            )}
                            classes={{
                                popper: classes.formControl,
                            }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }} />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormInputDropdownDesigner = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [required, setRequired] = useState(false);
    const [source, setSource] = useState({});
    const [reference, setReference] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => {
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
    }

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateRequired = (required) => {
        setRequired(required);
        props.handleTrackChange();
    }

    const handleUpdateSource = (source) => {
        setSource(source);
        props.handleDropdownReference(props.id, source);
        props.handleTrackChange();
    }

    const handleUpdateReference = (reference) => {
        setReference(reference);
        props.handleFormulaReference('&' + props.id, reference);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => {
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormInputDropdownProperties
                id={props.id}
                label={label}
                align={align}
                required={required}
                source={source}
                reference={reference}
                sources={props.sources}
                updateLabel={handleUpdateLabel}
                updateAlign={handleUpdateAlign}
                updateRequired={handleUpdateRequired}
                updateSource={handleUpdateSource}
                updateReference={handleUpdateReference}
                isReadOnly={props.isReadOnly} />);
        }
    }

    const doValidate = () => {
        const errors = {};
        errors['label'] = checkRequired(label);
        errors['source'] = checkRequired(source);
        return (!!errors['label'] || !!errors['source']);
    }

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({
                id: props.id,
                type: componentTypes.FORMINPUTDROPDOWN,
                label,
                align,
                required,
                source,
                reference
            }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setAlign(props.align);
            setRequired(props.required);
            setSource(props.source);
            if (props.source && props.source) {
                props.handleDropdownReference(props.id, props.source);
            }
            setReference(props.reference);
            if (props.reference) {
                props.handleFormulaReference('&' + props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

    return (
        <div className={clsx(classes.componentWrapper, { [classes.componentSelected]: (props.selected === props.id) })} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                                <IconButton aria-label='Drag' size='small' className='group-comp-input-sort-handler'>
                                    <MdIcon path={mdiCursorMove} size='1em' />
                                </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeInputDropdown}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                                <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                    <MdIcon path={mdiTrashCanOutline} size='1em' />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <InputLabel id='component-type-label'>{label || ''}</InputLabel>
                        <Select
                            labelId='component-type-label'
                            label={label || ''}
                            id='component-type'
                            style={getAlignStyle(align)}
                            value={label || texts.infoConfigInputDropdown}>
                            <MenuItem className={classes.menuItem} value={label || texts.infoConfigInputDropdown}>{label || texts.infoConfigInputDropdown}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleInputDropdown}
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigInputDropdown}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
    )
}

export const FormInputDropdownPreview = (props) => {
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [errors, setErrors] = useState({});
    const [isInitialize, setIsInitialize] = useState(false);

    const handleValueChange = (event) => {
        const value = event.target.value;
        setValue(value);
        props.handleInputData(props.id, value, false, false, true);
        if (props.required) {
            errors['value'] = checkRequired(value, (props.label || ''));
            setErrors(errors);
        }
    }

    const doValidate = () => {
        const errors = {};
        if (props.required) {
            errors['value'] = checkRequired(value, (props.label || ''));
        }
        setErrors(errors);
        return (!!errors['value']);
    }

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent(props.id, value, doValidate());
        } else {
            setIsInitialize(true);
            setOptions(props.sources[props.source.id] || []);
            setValue(props.inputData[props.id] || '');
            if (props.reference) {
                props.handleFormulaReference('&' + props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

    return (
        <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`} error={errors['value']}>
            <InputLabel id={`id-${props.id}-label`}>{props.label || ''}</InputLabel>
            <Select
                labelId={`id-${props.id}-label`}
                label={props.label || ''}
                id={props.id}
                value={value || ''}
                style={getAlignStyle(props.align)}
                onChange={handleValueChange}>
                <MenuItem className={classes.menuItem} value={''}>{texts.optionNone}</MenuItem>
                {(options || []).map((option) => (
                    <MenuItem className={classes.menuItem} value={option.Key}>{option.Value}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors['value']}</FormHelperText>
        </FormControl>
    )
}

export const FormInputDropdownPdf = (props) => {
    return (
        <View style={PdfStyles.groupInputColumn}>
            <Text style={PdfStyles.groupInputLabel}>{props.label || ''}</Text>
            <Text style={getPdfAlignStyle(props.align || aligns.LEFT, PdfStyles.groupInputValue)}>{(props.inputData[props.id] || '') || ''}</Text>
        </View>
    )
}
