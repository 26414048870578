import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import {
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Grid,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Modal
} from '@material-ui/core/';
import { Icon as MdIcon } from '@mdi/react';
import { mdiPlusCircleOutline, mdiPencilOutline, mdiDeleteOutline, mdiBackburger } from '@mdi/js';
import Paths from '../../../../enums/Paths';
import { 
	Alert, 
	toggleDisplay, 
	useStyles, 
	StyledTableCell, 
	StyledTableRow, 
	texts,
	Api,
} from '../../helper';
import { DropdownDataForm } from '.';

const TableDataRow = (props) => {
	const classes = useStyles();
	return (
		<StyledTableRow>
			{(
				Object.keys(props.row).map((key, index) => 
					<StyledTableCell className={(index === 0) ? classes.plCell : undefined}>
						{props.row[key]}
					</StyledTableCell>
				)
			)}
			<StyledTableCell align='center' className={classes.prCell}>
				<IconButton aria-label='Edit' onClick={() => props.onEdit(props.index, props.row)}>
					<MdIcon path={mdiPencilOutline} size='0.8em' />
				</IconButton>
				<IconButton aria-label='Delete' onClick={() => props.onDelete(props.index, props.row)}>
					<MdIcon path={mdiDeleteOutline} size='0.8em' />
				</IconButton>
			</StyledTableCell>
		</StyledTableRow>
	);
}

const DropdownData = (props) => {
	// const { id } = useParams();
	const { id } = props;
	const history = useHistory();
	const classes = useStyles();
	const [source, setSource] = useState(null);
	const [data, setData] = useState({});
	const [index, setIndex] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [openNewSource, setOpenNewSource] = useState(false);
	const [openEditSource, setOpenEditSource] = useState(false);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

	const toggleNewSource = () => { 
		setOpenNewSource(!openNewSource);
	}

	const doNewSource = () => {
		const data = {};
		for (const key of source.columns) {
			data[key] = '';
		}
		setIndex(-1);
		setData(data);
		toggleNewSource();
	}

	const handleNewSource = (id) => { 
		toggleNewSource();
		if (id > 0) {
			handleDataLoad();
		}
	}

	const toggleEditSource = () => { 
		setOpenEditSource(!openEditSource);
	}

	const doEditSource = (index, data) => { 
		setIndex(index);
		setData(data);
		toggleEditSource();
	}

	const handleEditSource = (id) => { 
		toggleEditSource();
		if (id > 0) {
			handleDataLoad();
		}
	}

	const toggleDeleteSource = () => { 
		setIsDeleteAlertOpen(!isDeleteAlertOpen);
	}

	const doDeleteSource = (index, data) => { 
		setIndex(index);
		setData(data);
		setIsDeleteAlertOpen(true);
	}

	const handleDeleteSource = async () => { 
		setIsLoading(true);
		const payload = [...(source?.payload || [])];
		payload.splice(index, 1);
		const response = await Api.saveSource((source?.id || 0), 
												(source?.name || ''), 
												(source?.description || ''), 
												(source?.columns || []).join(', '),
												JSON.stringify(payload));
		setIsLoading(false);
		toggleDeleteSource();
		if (response) {
			const result = (response.result || 0);
			if (result > 0) {
				handleDataLoad();
			}
		}
	}

	const handleDataLoad = async () => {
		setIsLoading(true);
		const response = await Api.getSource(id);
		if (response) {
			if (response.columns) {
				response.columns = response.columns.split(', ');
			}
			if (response.payload) {
				// Make sure payload and columns are matched
				const payload = JSON.parse(response.payload);
				for (const data of payload) {
					for (const key of response.columns) {
						data[key] = (data[key] || (key.startsWith('#') ? 0 : ''));
					}
				}
				response.payload = payload;
			}
			setSource(response);
		}
		setIsLoading(false);
	}

	const doShowList = () => {		
		history.push(Paths.CASHFLOW_FORM_BUILDER_DROPDOWN_LIST); 
		props.setTick(Math.random());
	}

	useEffect(() => {
		handleDataLoad();
	}, [id]);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1} style={{ marginBottom: 16 }}>
				<Grid item xs={12} sm={6} className={classes.pageTitleWrapper}>
					<span className={classes.pageTitle}>{(source?.name || '')}</span>
					<IconButton aria-label='Back' className={classes.pageTitleIcon} onClick={doShowList}>
							<MdIcon path={mdiBackburger} size='0.8em' />
						</IconButton>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.controlEnd}>
					<Button variant='contained' className={classes.primaryButton} onClick={doNewSource}>
						<MdIcon path={mdiPlusCircleOutline} size='1.5em' className={classes.buttonIconLeft} />{texts.buttonAddNewData}
					</Button>
				</Grid>
			</Grid>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Paper className={classes.paperWrapper}>
				<Grid container className={classes.controlWrapper} spacing={3}>
					<Grid item xs={12} className={classes.sourceDesc}>
						{(source?.description || '')}
					</Grid>
				</Grid>
				<div className={classes.tableWrapper}>	
						<TableContainer>
							<Table className={classes.table} aria-label='dropdown source table'>
								<TableHead>
									<TableRow>
									{(source?.columns || []).map((column, indx) =>
										<StyledTableCell className={(indx === 0) ? classes.plCell : undefined}>{column}</StyledTableCell>
									)}
									<StyledTableCell width='10%' align='center' className={classes.prCell}>{texts.columnActions}</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(source?.payload || []).map((row, indx) => {
										return (
											<TableDataRow row={row} index={indx} onEdit={doEditSource} onDelete={doDeleteSource} />
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
				</div>
			</Paper>
			<Modal open={openNewSource}>
				<DropdownDataForm data={data} source={source} onFormCancel={toggleNewSource}  onFormSuccess={handleNewSource} />
			</Modal>
			<Modal open={openEditSource}>
				<DropdownDataForm data={data} index={index} source={source} onFormCancel={toggleEditSource} onFormSuccess={handleEditSource} />
			</Modal>
			<Alert isOpen={isDeleteAlertOpen}
				title={texts.deleteAlertTitleSourceData} 
				message={`${texts.deleteAlertMessageStart}${data?.Value || ''}${texts.deleteAlertMessageDataEnd}`}
				cancelButton={texts.buttonCancel}
				confirmButton={texts.buttonDelete}
				confirm={handleDeleteSource}
				cancel={toggleDeleteSource} />
		</div>
	)
}

export default DropdownData;
