import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiFileTableOutline, mdiMagnify } from '@mdi/js';
import LoanService from '../../services/LoanService';
import SearchUtil from '../../utils/SearchUtil'
import Cities from '../../constants/Cities'
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	table: {
		fontFamily: 'Inter'
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(name, location, numApps, cognitoId) {
	return { name, location, numApps, cognitoId };
}

const mockData = {

}

let data = {}

let rows = [

]

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			// backgroundColor: '#FDF3E5'
		},
		cursor: 'pointer'
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

function filterValue(value, type) {
	if (value) {
		rows = SearchUtil.search(value, data.data, type)
	}
	else {
		rows = []
		data.data.map((data, index) => {
			rows.push(createData(data.name, data.location, data.totalLoanApplications, data.cognitoId))
		})
	}
}

const LoanAgents = (props) => {

	let history = useHistory()
	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const [updatingData, setUpdatingData] = useState(false)
	const [page, setPage] = useState(1)
	const [maxPage, setMaxPage] = useState(0)
	const [count, setCount] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [showItems, setShowItems] = useState(10)

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setUpdatingData(true)
		let response = await LoanService.getLoanAgentsApplications(showItems, page, props.globalSearchKey ? props.globalSearchKey : searchKey);

		data = {
			data: response && response.totalList
		}
		rows = []
		response && response.totalList.map((data, index) => {
			rows.push(createData(data.name, data.location, data.totalLoanApplications, data.cognitoId))
		})
		
		setCount(response ? response.count : 0)
		setMaxPage(response ? response.maxPage : 0)
		setTotalCount(response ? response.totalList.length : 0)
		props.globalLocationKey && filterValue(props.globalLocationKey, "LOCATION");
		setHoveredRow(-1)
		setUpdatingData(false)
		
		
		// response ? setApplicationFormData(response) : setIsFetchingFailed(true)
	}

	if (!initialized) {
		getData()
	}

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<div style={{ margin: 20 }}>
				<div style={{ fontWeight: 600, fontSize: 28, lineHeight: '32px' }}>Loan Agents</div><br />
				<LinearProgress className={classes.root} style={{ visibility: updatingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
				<Paper style={{ height: 'auto' }}>
					<div>
						<div style={{ display: 'flex', padding: '20px 24px 20px 24px' }}>
							<TextField
								className={classes.root}
								id="input-with-icon-textfield"
								label="Search by name of agent"
								variant="outlined"
								style={{ width: 340, minWidth: 250, height: 50, marginRight: 24 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<MdIcon path={mdiMagnify} size="24px" />
										</InputAdornment>
									),
								}}
								defaultValue={props.globalSearchKey ? props.globalSearchKey : searchKey}
								//onChange={(event) => { filterValue(event.target.value, "NAME"); setSearchKey(event.target.value) }}
								onKeyPress={(event) => { if (event.key === "Enter") { setSearchKey(event.target.value); props.setSearchKeyGlobal(event.target.value); setPage(1); setInitialized(false) } }}
							/>
							<div>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={props.globalLocationKey ? props.globalLocationKey : searchLocationKey}
										style={{ width: 160 }}
										onChange={(event) => { filterValue(event.target.value, "LOCATION"); setPage(1); props.setLocationKeyGlobal(event.target.value == null ? "All" : event.target.value); setSearchLocationKey(event.target.value == null ? "All" : event.target.value); }}
									>
										<MenuItem value={""}>All</MenuItem>
										{/* <MenuItem value="Laguna">Laguna</MenuItem>
									<MenuItem value="Makati">Makati</MenuItem>
									<MenuItem value="Mandaluyong">Mandaluyong</MenuItem>
									<MenuItem value="Manila">Manila</MenuItem>
									<MenuItem value="Cavite">Cavite</MenuItem> */}
										{Cities.map((city, index) => {
											return (<MenuItem value={city}>{city}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</div>
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', minWidth: 200 }}>
							<div style={{ marginRight: 16, color: '#909196', fontSize: 14, fontFamily: 'Inter' }}>Show items</div>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										labelId="demo-simple-select-filled-label"
										defaultValue={10}
										style={{ width: 72 }}
										onChange={(event) => { setShowItems(event.target.value); setPage(1); setInitialized(false); }}
									>
										<MenuItem value={10}>10</MenuItem>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={30}>30</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div style={{}}>
							<TableContainer>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Agent Name</StyledTableCell>
											<StyledTableCell>Location</StyledTableCell>
											<StyledTableCell align="right">No. of Applications</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows && rows.map((row, index) => (
											<StyledTableRow onClick={() => { props.setAgent(row); history.push("/loanApplications") }} style={{ backgroundColor: hoveredRow == index ? '#FDF3E5' : '#FFFFFF' }} onMouseOver={() => setHoveredRow(index)} onMouseOut={() => setHoveredRow(null)}>
												<StyledTableCell component="th" scope="row" style={{ fontWeight: 600 }}>
													{row.name}
												</StyledTableCell>
												<StyledTableCell>{row.location}</StyledTableCell>
												<StyledTableCell align="right">{row.numApps}</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
				</Paper>
				<div style={{ display: 'flex', alignItems: 'center', padding: '24px 15px 24px 15px', backgroundColor: '#F6F6F6' }}>
					<div style={{ marginRight: 16, fontSize: 14, width: 170 }}>Showing {count === 0 ? '-' : totalCount} of {count === 0 ? '-' : count}</div>
					<Pagination className={classes.root} page={page} count={maxPage} shape="rounded" onChange={(event, value) => { setPage(value); setInitialized(false) }} />
				</div>
			</div>
		</div>
	)
}

export default LoanAgents;