import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useStyles } from "../helpers/Styles";

const LinearProgressCustom = (props) => {
  const classes = useStyles();
  return (
    <LinearProgress
      className={classes.root}
      style={{
        visibility: props.updatingData ? "visible" : "hidden",
        backgroundColor: "#E5E5E5",
      }}
    />
  );
};

export default LinearProgressCustom;
