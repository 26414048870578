import React from "react";
import { useStyles } from "../helpers/Styles";
import { FormControl, Select, MenuItem } from "@material-ui/core";

const ShowItemsDropdown = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "50%",
        width: "100%",
        minWidth: 200,
      }}
    >
      <div
        style={{
          marginRight: 16,
          color: "#909196",
          fontSize: 14,
          fontFamily: "Inter",
        }}
      >
        Show items
      </div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          className={classes.root}
          labelId="demo-simple-select-filled-label"
          defaultValue={props.showItems}
          style={{ width: 72 }}
          onChange={(event) => {
            props.setShowItems(event.target.value);
            props.setPage(1);
            props.setInitialized(false);
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default ShowItemsDropdown;
