const ValidationService = {
  getRequiredFields: async (fields) => {
    const requiredFields = [];
    const allFields = [];
   if (fields) {
     console.log('fields', fields)
    fields.map((field) => {
      field.applicationTemplateTabFields.map((info) => {
        if (info.isRequired == true) {
        requiredFields.push(info.field.code);
        }
      });
      
    });
   }

    return requiredFields;
  },
  getMinMaxValue: async (fields) => {
    const minMax = {};
   if (fields) {
    fields.map((field) => {
      field.applicationTemplateTabFields.map((info) => {
        if (info.field){ 
          if (info.field.max && info.field.min) {
            minMax.max = info.field.max
            minMax.min = info.field.min  
          }
        }
      });
    });
   }
   return minMax;
  }
};

export default ValidationService