import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
  Button,
  AppBar,
  Toolbar,
  Drawer,
  MenuList,
  MenuItem,
  Popover,
  LinearProgress,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  InputLabel,
  FormControl,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths';
//import { withStyles } from '@material-ui/styles'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react';
import {
  mdiFileTableOutline,
  mdiMagnify,
  mdiAccountAlertOutline,
} from '@mdi/js';
import LoanService from '../../services/LoanService';
import Pagination from '@material-ui/lab/Pagination';
import { DatePicker } from 'material-ui';
import NumberFormat from 'react-number-format';
import { calendar_gray_logo, icon_download } from '../../assets/images';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import {
  filter,
  map,
  partialRight,
  pick,
  pull,
  remove,
  sortBy,
  union,
  uniqBy,
} from 'lodash';
import { useHistory } from 'react-router-dom';
import download from 'downloadjs';
import { convertHexToRGB } from 'material-ui/utils/colorManipulator';
import { AgentName } from '../common';

const drawerWidth = 240;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DropDownHeaderCell = withStyles({
  root: {
    fontSize: 10,
    color: '#909196',
    borderBottom: 'none',
  },
})(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    tooltip: {
      width: 190,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5922F',
    },
    '& .MuiPaginationItem-page.Mui-selected ': {
      backgroundColor: '#F5922F',
      color: '#FFFFFF',
      fontFamily: 'Inter',
    },
    '& .MuiPaginationItem-rounded': {
      fontFamily: 'Inter',
    },
    '& .MuiInputBase-input': {
      height: 0,
      lineHeight: '0px',
    },
    '& .MuiInputBase-root': {
      height: 40,
      lineHeight: '2px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiPaginationItem-rounded': {
      fontFamily: 'Inter',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter',
    },
    '& .MuiButton-label': {
      width: 240,
    },
  },
  formControl: {
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiInputBase-input': {
      height: 0,
      lineHeight: '0px',
    },
    '& .MuiInputBase-root': {
      height: 40,
      lineHeight: '2px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tooltip: {
    width: 190,
    maxWidth: 190,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#636363',
    fontSize: 12,
    fontFamily: 'Inter',
  },
  body: {
    fontSize: 14,
    fontFamily: 'Inter',
  },
}))(TableCell);

function createData(
  referenceId,
  dateRequested,
  applicantName,
  agentId,
  status
) {
  return { referenceId, dateRequested, applicantName, agentId, status };
}

let rows = [];

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 72,
    '&:hover': {
      backgroundColor: '#FDF3E5',
    },
    cursor: 'pointer',
  },
  body: {
    fontFamily: 'Inter',
  },
}))(TableRow);

const ChangeRequest = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [screenIndex, setScreenIndex] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [searchLocationKey, setSearchLocationKey] = useState('All');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [updatingData, setUpdatingData] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showItems, setShowItems] = useState(10);
  const [duplicateDates, setDuplicateDates] = useState([]);
  const [revision, setRevision] = useState(0);

  async function getData() {
    setIsFetchingFailed(false);
    setInitialized(true);
    setUpdatingData(true);

    let response = await LoanService.getDraftSummary(
      props.globalSearchKey,
      moment(fromDate).format('YYYY-MM-DD'),
      moment(toDate).format('YYYY-MM-DD'),
      showItems,
      page
    );
    setUpdatingData(false);

    let draftList = response && response.result && response.result.drafts;

    rows = [];
    draftList &&
      draftList.map((data, index) => {
        rows.push(
          createData(
            data.referenceId,
            data.dateRequested,
            data.applicantName,
            data.agentId,
            data.status
          )
        );
      });

    setCount(response && response.result ? response.result.count : 0);
    setMaxPage(response && response.result ? response.result.maxPage : 0);
    setTotalCount(
      response && response.result
        ? response.result.drafts && response.result.drafts.length
        : 0
    );
    setHoveredRow(-1);
    setHoveredRow(null);
  }

  if (!initialized) {
    getData();
  }

  const open = Boolean(dateAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeDate = () => {
    setPage(1);
    getData();
    setDateAnchorEl(null);
  };

  const handleClearDate = () => {
    setFromDate(new Date());
    setToDate(new Date());
  };

  return (
    <div style={{ fontFamily: 'Inter' }}>
      <div style={{ margin: 20 }}>
        <div
          style={{
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '32px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Change Requests
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}></div>
          </div>
        </div>
        <br />
        <LinearProgress
          className={classes.root}
          style={{
            visibility: updatingData ? 'visible' : 'hidden',
            backgroundColor: '#E5E5E5',
          }}
        />
        <Paper>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 24px 20px 24px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    className={classes.root}
                    id="input-with-icon-textfield"
                    variant="outlined"
                    label="Search by reference ID"
                    style={{
                      width: 340,
                      minWidth: 340,
                      height: 50,
                      marginRight: 24,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MdIcon path={mdiMagnify} size="24px" />
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={
                      props.globalSearchKey ? props.globalSearchKey : searchKey
                    }
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        setSearchKey(event.target.value);
                        props.setSearchKeyGlobal(event.target.value);
                        setPage(1);
                        setInitialized(false);
                      }
                    }}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    disabled={updatingData}
                    aria-describedby={id}
                    variant="outlined"
                    style={{
                      background: 'whitesmoke',
                      height: 40,
                      minWidth: 260,
                      fontSize: 14,
                      color: '#636363',
                      border: duplicateDates.length > 0 && '1px solid #E24C4C',
                    }}
                    onClick={(event) => setDateAnchorEl(event.currentTarget)}
                    endIcon={<img src={calendar_gray_logo} />}
                  >
                    {fromDate ? (
                      `${moment(fromDate).format('MM / DD / YYYY')} - ${
                        toDate ? moment(toDate).format('MM / DD / YYYY') : ''
                      }`
                    ) : (
                      <div style={{ marginRight: 151, width: 100 }}>
                        All time
                      </div>
                    )}
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={dateAnchorEl}
                    onClose={() => setDateAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: 200,
                        height: 180,
                        padding: '12px 16px',
                      }}
                    >
                      <DatePicker
                        hintText="Click to select"
                        floatingLabelText="From"
                        floatingLabelFixed={true}
                        fullWidth={true}
                        value={fromDate}
                        autoOk={true}
                        onChange={(event, value) => {
                          setFromDate(value);
                        }}
                        dialogContainerStyle={{ zIndex: 10000 }}
                        maxDate={new Date()}
                      />
                      <DatePicker
                        hintText="Click to select"
                        floatingLabelText="To"
                        disabled={!fromDate}
                        floatingLabelFixed={true}
                        fullWidth={true}
                        value={toDate}
                        autoOk={true}
                        onChange={(event, value) => setToDate(value)}
                        dialogContainerStyle={{ zIndex: 10000 }}
                        minDate={fromDate}
                        maxDate={new Date()}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          onClick={handleClearDate}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: 100,
                            height: 30,
                            fontSize: 16,
                            marginTop: 3,
                            marginRight: 5,
                          }}
                        >
                          CLEAR
                        </Button>
                        <Button
                          onClick={handleChangeDate}
                          variant="contained"
                          color="primary"
                          //disabled={!fromDate}
                          style={{
                            width: 100,
                            height: 30,
                            fontSize: 16,
                            marginTop: 3,
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  minWidth: 200,
                }}
              >
                <div
                  style={{
                    marginRight: 16,
                    color: '#909196',
                    fontSize: 14,
                    fontFamily: 'Inter',
                  }}
                >
                  Show items
                </div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    className={classes.root}
                    labelId="demo-simple-select-filled-label"
                    defaultValue={10}
                    style={{ width: 72 }}
                    onChange={(event) => {
                      setShowItems(event.target.value);
                      setPage(1);
                      setInitialized(false);
                    }}
                    disabled={updatingData}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Reference Number</StyledTableCell>
                      <StyledTableCell>Applicant Name</StyledTableCell>
                      <StyledTableCell>Date Requested</StyledTableCell>
                      <StyledTableCell>Agent</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((row, index) => (
                        <StyledTableRow
                          style={{
                            backgroundColor:
                              hoveredRow == index ? '#FDF3E5' : '#FFFFFF',
                          }}
                          onMouseOver={() => setHoveredRow(index)}
                          onMouseOut={() => setHoveredRow(null)}
                          onClick={() => {
                            props.setRevision(Math.random());
                            history.push(
                              Paths.REVIEW_CHANGE_REQUEST.replace(
                                ':id',
                                row.referenceId
                              )
                            );
                            props.setRevision(Math.random());
                          }}
                        >
                          <StyledTableCell>
                            <b>{row.referenceId}</b>
                          </StyledTableCell>
                          <StyledTableCell>{row.applicantName}</StyledTableCell>
                          <StyledTableCell>
                            {row.dateRequested
                              ? moment(
                                  row.dateRequested[
                                    row.dateRequested.length - 1
                                  ] === 'Z'
                                    ? row.dateRequested
                                    : row.dateRequested + 'Z'
                                )
                                  .tz('Asia/Singapore')
                                  .format('YYYY-MM-DD  hh:mm:ss A')
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <AgentName agentId={row.agentId} />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '24px 24px 24px 24px',
                backgroundColor: '#F6F6F6',
              }}
            >
              <div style={{ marginRight: 16, fontSize: 14, width: 170 }}>
                Showing{' '}
                {count === 0
                  ? '-'
                  : `${
                      page != maxPage
                        ? totalCount * page -
                          (showItems - 1) +
                          '-' +
                          totalCount * page
                        : (page - 1) * showItems + 1 != count
                        ? (page - 1) * showItems + 1 + '-' + count
                        : (page - 1) * showItems + 1
                    } `}{' '}
                of {count === 0 ? '-' : count}
              </div>
              <Pagination
                className={classes.root}
                page={page}
                count={maxPage}
                disabled={updatingData}
                shape="rounded"
                onChange={(event, value) => {
                  setPage(value);
                  setInitialized(false);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  minWidth: 200,
                }}
              >
                <div
                  style={{
                    marginRight: 16,
                    color: '#909196',
                    fontSize: 14,
                    fontFamily: 'Inter',
                  }}
                >
                  Show items
                </div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    className={classes.root}
                    labelId="demo-simple-select-filled-label"
                    defaultValue={10}
                    style={{ width: 72 }}
                    onChange={(event) => {
                      setShowItems(event.target.value);
                      setPage(1);
                      setInitialized(false);
                    }}
                    disabled={updatingData}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ChangeRequest;
