import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
	FormControl,
    FormControlLabel,
    Grid,
    Select,
    InputLabel,
    InputAdornment,
    MenuItem,
    Switch,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignBoldStyle, 
    getPdfAlignBoldStyle,
    getAlignBoldWidthStyle,
    getPdfAlignBoldWidthStyle,
    useStyles, 
    texts,
    StyledTableSummaryCell,
    PdfStyles,
    componentTypes,
} from '../../helper';

const FormTableSummaryDisplayProperties = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [help, setHelp] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [errors, setErrors] = useState({});

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.updateValue(value);
		errors['value'] = checkRequired(value, texts.labelComponentValue);
		setErrors(errors);
	}

    const handleHelpChange = (event) => {
        const help = event.target.value;
        setHelp(help);
        props.updateHelp(help);
    }

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleBoldChange = (event, checked) => { 
        setBold(checked);
        props.updateBold(checked);
    }

    const handleWidthChange = (event) => {
        const width = parseInt(event.target.value || 0);
        if (width >= 0 && width <= 100) {
            setWidth(width);
            props.updateWidth(width);  
        }
    }

    useEffect(() => {
        setValue(props.value);
        setHelp(props.help);
        setAlign(props.align);
        setBold(props.bold || false);
        setWidth(props.width || '');
        const errors = {};
        errors['value'] = checkRequired(props.value, texts.labelComponentValue);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableSummaryDisplay}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-value'
                            label={texts.labelComponentValue}
                            variant='outlined'
                            value={value || ''}
                            error={errors['value']}
                            helperText={errors['value']}
                            onChange={handleValueChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-help'
                            label={texts.labelComponentHelp}
                            variant='outlined'
                            value={help || ''}
                            onChange={handleHelpChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={bold} onChange={handleBoldChange} disabled={props.isReadOnly} />}
                        label={bold ? texts.infoFontBold : texts.infoFontNormal}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-width'
                            type='number'
                            label={texts.labelComponentWidth}
                            variant='outlined'
                            value={width || ''}
                            onChange={handleWidthChange}
                            InputProps={{
                                inputProps: { min: 1, max: 100 },
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableSummaryDisplayDesigner = (props) => {
	const classes = useStyles();
    const [value, setValue] = useState('');
    const [help, setHelp] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => {
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateValue = (value) => {
        setValue(value);
        props.handleTrackChange();
    }

    const handleUpdateHelp = (help) => {
        setHelp(help);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateBold = (bold) => {
        setBold(bold);
        props.handleTrackChange();
    }

    const handleUpdateWidth = (width) => {
        setWidth(width);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableSummaryDisplayProperties 
                                            id={props.id} 
                                            value={value} 
                                            help={help} 
                                            align={align} 
                                            bold={bold}
                                            width={width}
                                            updateValue={handleUpdateValue}
                                            updateHelp={handleUpdateHelp}
                                            updateAlign={handleUpdateAlign}
                                            updateBold={handleUpdateBold}
                                            updateWidth={handleUpdateWidth}
                                            isReadOnly={props.isReadOnly} />);
        }
	}


    const doValidate = () => {
        const errors = {};
        errors['value'] = checkRequired(value);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLESUMMARYDISPLAY, 
                                    value,
                                    help,
                                    align,
                                    bold,
                                    width }, doValidate());
        } else {
            setIsInitialize(true);
            setValue(props.value);
            setHelp(props.help);
            setAlign(props.align);
            setBold(props.bold);
            setWidth(props.width);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-summary-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableSummaryDisplay}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div 
                        className={classes.componentDisplayBox}
                        style={getAlignBoldStyle(align, bold)}>
                        {value || texts.infoConfigTableSummaryDisplay}
                    </div>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableSummaryDisplay} 
                message={`${texts.deleteAlertMessageStart}${value || texts.infoNoConfigTableSummaryDisplay}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormTableSummaryDisplayPreview = (props) => {
    const classes = useStyles();
    return (
        <StyledTableSummaryCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {props.value || ''}
            {props.help && <div className={classes.helpText}>{props.help}</div>}
        </StyledTableSummaryCell>
    )
}

export const FormTableSummaryDisplayPdf = (props) => {
    if (props.help) {
        return (
            <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, (props.attached ? PdfStyles.groupTableSummaryAttachedCol : PdfStyles.groupTableSummaryDetachedCol))} >
                <Text style={getPdfAlignBoldStyle(props.align, props.bold, (props.attached ? PdfStyles.groupTableSummaryPrimaryAttachedText : PdfStyles.groupTableSummaryPrimaryDetachedText))}>{props.value || ''}</Text>
                <Text style={getPdfAlignBoldStyle(props.align, props.bold, (props.attached ? PdfStyles.groupTableSummarySecondaryAttachedText : PdfStyles.groupTableSummarySecondaryDetachedText))}>{props.help}</Text>
            </View>
        );
    } else {
        return (
            <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, (props.attached ? PdfStyles.groupTableSummaryAttachedCol : PdfStyles.groupTableSummaryDetachedCol))} >
                <Text>{props.value || ''}</Text>
            </View>
        );
    }
}