import React, { useState, useEffect, forwardRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
	DialogContent,
	DialogContentText,
    DialogTitle,
    Slide,
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	alertTitle: {
		fontWeight: 600,
		fontSize: 20,
		lineHeight: '24px',
		color: '#424347',
	},
    alertMessage: {
		fontWeight: 400,
		fontSize: 14,
		lineHeight: '20px',
		color: '#2B2D33',
    },
	primaryButton: {
		backgroundColor: '#F47721', 
		color: '#FFFFFF', 
		borderColor: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: '#DC6B1E',
		},
	},
	secondaryButton: {
		border: '2px solid #F47721', 
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
	},
	scrollPaper: {
        alignItems: 'baseline',
        marginTop: 120,
        [theme.breakpoints.down('sm')]: {
			marginTop: 50,
		},
        '& .MuiDialogActions-root': {
            paddingBottom: 20,
            marginRight: 10,
        },
    }
}));

export default function Alert(props) {
    const classes = useStyles();
    const [isAlertOpen, setAlertOpen] = useState(false);

    const handleCancel = () => {
        if (props.cancel) {
            props.cancel();
        }
    };

    const handleConfirm = () => {
        if (props.confirm) {
            props.confirm();
        }
    };

    useEffect(() => {
        setAlertOpen(props.isOpen);
    });

    return (
        <div>
            <Dialog
                open={isAlertOpen}
                TransitionComponent={Transition}
                onClose={handleCancel}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                classes={{scrollPaper: classes.scrollPaper }}>
                <DialogTitle id='alert-dialog-title' className={classes.alertTitle}>{props.title || 'Alert Title'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description' className={classes.alertMessage}>
                        {props.message || 'Alert Message'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.cancelButton && 
                    <Button variant='outlined' className={classes.secondaryButton} onClick={handleCancel}>
                        {props.cancelButton}
                    </Button>
                    }
                    {props.confirmButton &&
                    <Button variant='contained' className={classes.primaryButton} onClick={handleConfirm}>
                        {props.confirmButton}
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
