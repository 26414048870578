import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiFileTableOutline, mdiMagnify } from '@mdi/js';
import LoanService from '../../services/LoanService';
import SearchUtil from '../../utils/SearchUtil'
import Cities from '../../constants/Cities'
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px'
		}
		// "& .MuiPaginationItem-root": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .MuiPaginationItem-rounded": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .Mui-selected": {
		// 	backgroundColor: '#F5922F'
		// },
		// "& .MuiButtonBase-root": {
		// 	backgroundColor: '#F5922F'
		// }
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	table: {
		fontFamily: 'Inter'
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(brand, branches, users) {
	return { brand, branches, users };
}

const mockData = {

}

let data = {}

let rows = [
	createData("Bangko Kabayan", 12, 12)
]

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			// backgroundColor: '#FDF3E5'
		},
		cursor: 'pointer'
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

function filterValue(value, type) {
	if (value) {
		rows = SearchUtil.search(value, mockData.data, type)
	}
	else {
		rows = []
		mockData.data.map((data, index) => {
			rows.push(createData(data.name, data.location, data.totalLoanApplications, data.cognitoId))
		})
	}
}

const AdminBrands = (props) => {

	let history = useHistory();
	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("All")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const [updateData, setUpdateData] = useState(false)

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)

		let response = await LoanService.getLoanAgentsApplications();
		console.log(response)
		data = {
			data: response
		}
		rows = []
		response && response.map((data, index) => {
			rows.push(createData(data.name, data.location, data.totalLoanApplications, data.cognitoId))
		})
		setUpdateData(true)
		// response ? setApplicationFormData(response) : setIsFetchingFailed(true)
	}

	if (!initialized) {

	}

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<div style={{ margin: 20 }}>
				<div style={{ fontWeight: 600, fontSize: 28, lineHeight: '32px' }}>Brands</div><br />
				<Paper style={{ height: 800 }}>
					<div>
						<div style={{ display: 'flex', padding: '20px 24px 20px 24px' }}>
							<TextField
								className={classes.root}
								id="input-with-icon-textfield"
								label="Search by brand name"
								variant="outlined"
								style={{ width: 340, minWidth: 250, height: 50, marginRight: 24 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<MdIcon path={mdiMagnify} size="24px" style={{ color: '#909196' }} />
										</InputAdornment>
									),
								}}
								// onChange={(event) => { filterValue(event.target.value, "NAME"); setSearchKey(event.target.value) }}
							/>
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', minWidth: 200 }}>
								<div style={{ marginRight: 16, color: '#909196', fontSize: 14, fontFamily: 'Inter' }}>Show items</div>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										className={classes.root}
										labelId="demo-simple-select-filled-label"
										defaultValue={10}
										style={{ width: 72 }}
									>
										<MenuItem value={10}>10</MenuItem>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={30}>30</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div style={{}}>
							<TableContainer>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Brand Name</StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell align="center">Branches</StyledTableCell>
											<StyledTableCell align="center">Users</StyledTableCell>
											<StyledTableCell></StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows && rows.map((row, index) => (
											<StyledTableRow key={row.brand} onClick={() => { props.setSelectedBrand(row); history.push("/brandDetails") }} style={{ backgroundColor: hoveredRow == index ? '#FDF3E5' : '#FFFFFF' }} onMouseOver={() => setHoveredRow(index)} onMouseOut={() => setHoveredRow(null)}>
												<StyledTableCell component="th" scope="row" style={{ fontWeight: 600 }}>
													{row.brand}
												</StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell align="center">{row.branches}</StyledTableCell>
												<StyledTableCell align="center">{row.users}</StyledTableCell>
												<StyledTableCell></StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', padding: '24px 15px 24px 15px', backgroundColor: '#F6F6F6' }}>
							<div style={{ marginRight: 16, fontSize: 14 }}>Showing 1 of 9</div>
							<Pagination className={classes.root} count={24} shape="rounded" />
						</div>
					</div>
				</Paper>
			</div>
		</div>
	)
}

export default AdminBrands;