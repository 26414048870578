import React, { useState } from 'react';
import {
	Button,
	MenuItem,
	Paper,
	LinearProgress,
	TextField,
	InputAdornment,
	Select,
	Snackbar,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip
} from '@material-ui/core/'
import NumberFormat from 'react-number-format';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiArrowLeft, mdiMagnify, mdiHelpCircleOutline } from '@mdi/js';
import LoanService from '../../services/LoanService';
import Pagination from '@material-ui/lab/Pagination';
import StatusDisplay from '../common/StatusDisplay';
import SearchUtil from '../../utils/SearchUtil'
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		tooltip: {
			width: 190
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	tooltip: {
		width: 190,
		maxWidth: 190
	}
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter',
	},
}))(TableCell);

function createData(referenceId, applicantName, loanProduct, requestedAmount, age, status) {
	return { referenceId, applicantName, loanProduct, requestedAmount, age, status };
}

let data = {}
let products = []
let rows = [

];

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:nth-of-type(odd)': {
			backgroundColor: '#FFFFFF',
		},
		body: {
			fontFamily: 'Inter'
		},
		cursor: 'pointer'
	},
}))(TableRow);

function filterValue(value, type) {
	if (value) {
		rows = SearchUtil.search(value, data.data.result, type)
	}
	else {
		rows = []
		data.data.result.map((data, index) => {
			rows.push(createData(data.referenceId, data.applicantName, data.loanProduct, data.requestedAmount, data.age, data.status))
		})
	}
}

const LoanApplicationList = (props) => {

	let history = useHistory()
	const classes = useStyles();
	const [searchKey, setSearchKey] = useState("")
	const [searchProductKey, setSearchProductKey] = useState("")
	const [searchStatusKey, setSearchStatusKey] = useState("")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [initialized, setInitialized] = useState(false);
	const [isFetchingFailed, setIsFetchingFailed] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [count, setCount] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [showItems, setShowItems] = useState(10);
	const [snackbarArchiveState, setSnackbarArchiveState] = useState(false);

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setUpdatingData(true)
		if (props.archiveSuccess) {
			setSnackbarArchiveState(true)
			props.setArchiveSuccess(false)
		}
		let response = await LoanService.getApplicationList(props.cognitoId, props.customerId,
			showItems, page, props.globalSearchKey ? props.globalSearchKey : searchKey,
			props.globalLoanProductKey ? props.globalLoanProductKey : searchProductKey,
			props.globalStatusKey ? props.globalStatusKey : searchStatusKey);
		products = await LoanService.getProducts();
		setUpdatingData(false)
		data = {
			data: response && response.result.result
		}
		rows = []
		response && response.result && response.result.result.map((data, index) => {
			rows.push(createData(data.referenceId, data.applicantName, data.loanProduct, data.requestedAmount, data.age, data.status))
		})
		setCount(response && response.result ? response.result.count : 0)
		setMaxPage(response && response.result ? response.result.maxPage : 0)
		setTotalCount(response && response.result ? response.result.result.length : 0)
		setHoveredRow(-1)
		setUpdatingData(false)
		setHoveredRow(null)
		// response ? setApplicationFormData(response) : setIsFetchingFailed(true)
	}

	if (!initialized) {
		if (props.role != "Loan Supervisor") { getData() }
	}

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<Snackbar open={snackbarArchiveState} autoHideDuration={6000} onClose={() => setSnackbarArchiveState(false)}>
				<Alert onClose={() => setSnackbarArchiveState(false)} severity={"success"}>
					<span>The loan application has been successfully archived.</span>
				</Alert>
			</Snackbar>
			<div className={classes.root}>
				<div style={{ margin: 20, width: '100%' }}>
					<LinearProgress className={classes.root} style={{ visibility: updatingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
					<Paper style={{ height: 800, width: '100%' }}>
						<div>
							<div style={{ display: 'flex', padding: '20px 24px 20px 24px' }}>
								<div style={{ display: props.showFilters ? 'flex' : 'none' }}>
									<TextField
										className={classes.root}
										id="text-field-search-filter"
										label="Search by applicant name / reference no."
										variant="outlined"
										style={{ width: 340, minWidth: 340, height: 50, marginRight: 24 }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<MdIcon path={mdiMagnify} size="24px" />
												</InputAdornment>
											),
										}}
										defaultValue={props.globalSearchKey ? props.globalSearchKey : searchKey}
										onKeyPress={(event) => { if (event.key === "Enter") { setSearchKey(event.target.value); props.setSearchKeyGlobal(event.target.value); setPage(1); setInitialized(false) } }}
									/>
									<div>
										<FormControl variant="outlined" className={classes.formControl}>
											<InputLabel id="select-product-filter-label">Loan Product</InputLabel>
											<Select
												key={searchProductKey}
												labelId="select-product-filter-label"
												id="select-product-filter"
												value={props.globalLoanProductKey ? props.globalLoanProductKey : searchProductKey}
												shrink={true}
												style={{ width: 200, marginRight: 24 }}
												onChange={(event) => { setSearchProductKey(event.target.value == null ? "All" : event.target.value); props.setLoanProductKeyGlobal(event.target.value == null ? "All" : event.target.value); setPage(1); setInitialized(false) }}
											>
												<MenuItem value="">All</MenuItem>
												{products && products.result && products.result.map((item, index) => {
													return <MenuItem value={item.id}>{item.name}</MenuItem>
												})}
											</Select>
										</FormControl>
									</div>
									<div>
										<FormControl variant="outlined" className={classes.formControl}>
											<InputLabel id="select-status-filter-label">Status</InputLabel>
											<Select
												className={classes.formControl}
												labelId="select-status-filter-label"
												id="select-status-filter"
												value={props.globalStatusKey ? props.globalStatusKey : searchStatusKey}
												style={{ width: 160 }}
												onChange={(event) => { setSearchStatusKey(event.target.value == null ? "All" : event.target.value); props.setStatusKeyGlobal(event.target.value == null ? "All" : event.target.value); setPage(1); setInitialized(false) }}
											>
												<MenuItem value="">All</MenuItem>
												<MenuItem value="CREATED">Created</MenuItem>
												<MenuItem value="MODIFIED">Modified</MenuItem>
												<MenuItem value="INCOMPLETE">Incomplete</MenuItem>
												<MenuItem value="EXPORTED">Exported</MenuItem>
												<MenuItem value="COMPLETED">Completed</MenuItem>
												<MenuItem value="SUBMITTED">Submitted</MenuItem>
												<MenuItem value="APPROVED">Approved</MenuItem>
												<MenuItem value="REJECTED">Rejected</MenuItem>
												<MenuItem value="RETURNED">Returned</MenuItem>
												<MenuItem value="TERMINATED">Terminated</MenuItem>
												<MenuItem value="FAILED">Failed</MenuItem>
												<MenuItem value="RESUBMITTED">Resubmitted</MenuItem>
												<MenuItem value="FOR RESUBMISSION">For Resubmission</MenuItem>
												<MenuItem value="ARCHIVED">Archived</MenuItem>
											</Select>
										</FormControl>
									</div>
								</div>
								<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', minWidth: 200 }}>
									<div style={{ marginRight: 16, color: '#909196', fontSize: 14, fontFamily: 'Inter' }}>Show items</div>
									<FormControl variant="outlined" className={classes.formControl}>
										<Select
											id='select-limit'
											className={classes.root}
											labelId="demo-simple-select-filled-label"
											defaultValue={10}
											style={{ width: 72, fontFamily: 'Inter' }}
											onChange={(event) => { setShowItems(event.target.value); setPage(1); setInitialized(false); }}
										>
											<MenuItem value={10}>10</MenuItem>
											<MenuItem value={20}>20</MenuItem>
											<MenuItem value={30}>30</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<div style={{}}>
								<TableContainer>
									<Table className={classes.table} aria-label="customized table">
										<TableHead>
											<TableRow>
												<StyledTableCell>Application Reference Number</StyledTableCell>
												<StyledTableCell>Applicant Name</StyledTableCell>
												<StyledTableCell>Loan Product</StyledTableCell>
												<StyledTableCell>Requested Amount</StyledTableCell>
												<StyledTableCell>Age <Tooltip arrow title={<span style={{ fontFamily: 'Inter' }}>This shows number of days between creation of application and submission to the office</span>} placement="top" ><MdIcon path={mdiHelpCircleOutline} size="14px" /></Tooltip></StyledTableCell>
												<StyledTableCell>Status</StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows && rows.map((row, index) => (
												<StyledTableRow key={row.name} onClick={() => { props.setApplication(row); props.setNavHistory(props.origin); history.push("/reviewApplication") }} style={{ backgroundColor: hoveredRow == index ? '#FDF3E5' : '#FFFFFF' }} onMouseOver={() => setHoveredRow(index)} onMouseOut={() => setHoveredRow(null)}>
													<StyledTableCell component="th" scope="row" style={{ fontWeight: 600 }}>
														{row.referenceId}
													</StyledTableCell>
													<StyledTableCell>{row.applicantName}</StyledTableCell>
													<StyledTableCell>{row.loanProduct ? row.loanProduct : "-"}</StyledTableCell>
													<StyledTableCell><b><NumberFormat value={row.requestedAmount} prefix={"Php "} decimalScale={2} fixedDecimalScale thousandSeparator displayType={'text'} /></b></StyledTableCell>
													<StyledTableCell>{row.age && row.age >= 14 ? <span style={{ color: '#E24C4C', fontWeight: 'bold' }}>{row.age}</span> : row.age && row.age < 14 ? <span style={{ fontWeight: 'bold' }}>{row.age}</span> : "-"}</StyledTableCell>
													<StyledTableCell><StatusDisplay status={row.status} /></StyledTableCell>
												</StyledTableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<div style={{ display: props.showPagination ? 'flex' : 'none', alignItems: 'center', padding: '24px 15px 24px 15px', backgroundColor: '#F6F6F6' }}>
								<div style={{ marginRight: 16, fontSize: 14, width: 170 }}>Showing {count === 0 ? '-' : `${page != maxPage ? totalCount * page - (showItems - 1) + "-" + totalCount * page : (((page - 1) * showItems) + 1) != count ? ((page - 1) * showItems) + 1 + "-" + count : ((page - 1) * showItems) + 1} `} of {count === 0 ? '-' : count}</div>
								<Pagination className={classes.root} page={page} count={maxPage} shape="rounded" onChange={(event, value) => { setPage(value); setInitialized(false) }} />
							</div>
						</div>
					</Paper>
				</div>
			</div>
		</div>
	)
}

export default LoanApplicationList;