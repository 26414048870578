import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Divider,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Switch
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiArrowLeft, mdiPencilOutline, mdiRefresh, mdiStar } from '@mdi/js';
import LoanService from '../../services/LoanService';
import SearchUtil from '../../utils/SearchUtil'
import Cities from '../../constants/Cities'
import Pagination from '@material-ui/lab/Pagination';
import Dropzone from 'react-dropzone'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ContentLoader from "react-content-loader"
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSwitch-colorSecondary.Mui-checked": {
			color: '#FFFFFF'
		},
		"& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: '#F5922F'
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	table: {
		fontFamily: 'Inter'
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(brand, branches, users) {
	return { brand, branches, users };
}

const mockData = {

}

let data = {}

let rows = [
	createData("Bangko Kabayan", 12, 12)
]

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 64,
		'&:hover': {
			// backgroundColor: '#FDF3E5'
		},
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

function filterValue(value, type) {
	if (value) {
		rows = SearchUtil.search(value, mockData.data, type)
	}
	else {
		rows = []
		mockData.data.map((data, index) => {
			rows.push(createData(data.name, data.location, data.totalLoanApplications, data.cognitoId))
		})
	}
}

const BrandDetails = (props) => {

	let history = useHistory();
	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("All")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const [updateData, setUpdateData] = useState(false)
	const [gettingData, setGettingData] = useState(false)
	const [products, setProducts] = useState([])

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setGettingData(true)

		let productsResponse = await LoanService.getProducts();
		setProducts(productsResponse && productsResponse.result)
		setGettingData(false)
		
	}

	function renderLazyLoadField(height, width) {
		return (
			<ContentLoader
				speed={1.5}
				width={width ? width : 170}
				height={height ? height : 14}
				viewBox={`0 0 ${width ? width : 170} ${height ? height : 14}`}
				backgroundColor="#f3f3f3"
				foregroundColor="#9e9e9e">
				<rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
			</ContentLoader>
		)
	}

	if (!initialized) {
		getData()
	}

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<div style={{ margin: 20 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: 28, lineHeight: '32px' }}>Bangko Kabayan<Button variant="outlined" onClick={() => { props.setSelectedBrand(""); history.push("/brands") }} style={{ backgroundColor: '#FFFFFF', color: '#F47721', width: 205, height: 40, marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }}><MdIcon path={mdiArrowLeft} size={1} color="#F47721" style={{ marginRight: 8 }} />Back to Brands List</Button></div><br />
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
				<Paper style={{ width: '49%', height: 300 }}>
						<div style={{ margin: '16px 24px 16px 24px', color: '#636363', fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>Brand Details</div>
						<Divider />
						<div style={{ borderBottom: '1px solid #C4C4C4', flexBasis: '100%', fontFamily: 'Inter', color: '#F5922F', fontSize: 12, fontWeight: 'bold', height: 25, margin: '28px 24px 28px 24px' }}>
							LOGO
						</div>
						<div style={{ display: 'flex' }}>
						<Dropzone
						>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div style={{
										height: 144,
										width: 144,
										border: "2px dashed",
										borderColor: '#C4C4C4',
										margin: '0 24px 0 24px',
									}} {...getRootProps()}>
										<input {...getInputProps()} />
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F6F6F6', margin: 10, height: '86%', textAlign: 'center', fontSize: 12, fontFamily: 'Inter', lineHeight: '16px' }}>
											<div style={{ width: 104 }}>Click here to select a file.</div>
										</div>
									</div>
								</section>
							)}
						</Dropzone>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
								<div style={{ fontSize: 14, fontFamily: 'Inter', lineHeight: '24px', color: '#636363'}}>This image will be used as the brand’s main logo on the app. (Supported File Format: .jpeg, .jpg)</div>
								<Button variant="outlined" style={{ backgroundColor: '#FFFFFF', color: '#F47721', width: 140, height: 48, marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }}>Save Logo</Button>
						</div>
						</div>
				</Paper>
				<Paper style={{ width: '49%', height: 300 }}>
						<div style={{ margin: '16px 24px 16px 24px', color: '#636363', fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>Form Sectioning</div>
						<Divider />
						<div style={{ border: '1px solid #C4C4C4', height: '65%', margin: 24}}>
							{["Field 1", "Field 2", "Field 3", "Field 4"].map((item, index) => (
								<div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', height: 48 }}>
									<div style={{ display: 'flex', alignItems: 'center', margin: '12px 16px 12px 16px' }}>{item} <MdIcon path={mdiStar} size={0.7} color="#C4C4C4" style={{ marginLeft: 16 }} /></div>
									<div style={{ display: 'flex', alignItems: 'center'}}><MdIcon path={mdiPencilOutline} size={1} color="#C4C4C4" style={{ marginRight: 16 }}/></div>
							</div>))}
						</div>
				</Paper>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
					<Paper style={{ width: '100%', height: 'auto' }}>
						<LinearProgress className={classes.root} style={{ visibility: gettingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
						<div style={{ margin: '16px 24px 16px 24px', color: '#636363', fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>Loan Products</div>
						<Divider />
						<div style={{ display: 'flex', padding: '0 24px 24px 24px', flexWrap: 'wrap' }}>
						{products && products.map((item, index) => (
							<div style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 600, lineHeight: '24px', border: '1px solid #C4C4C4', borderRadius: 4, margin: '24px 24px 0 0', padding: 16, width: 'auto', minWidth: 250, height: 17 }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
									<div>{item.name}</div> 
									<div style={{ display: 'flex', alignItems: 'center', color: '#1596DB', marginLeft: 16, cursor: 'pointer' }} onClick={() => { props.setSelectedProduct(item); history.push("/editBrand") }}><MdIcon path={mdiPencilOutline} size={0.7} color="#1596DB" style={{ marginRight: 9 }} /> EDIT</div>
								</div>
							</div>
						))}
						</div>
					</Paper>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
					<Paper style={{ width: '100%', height: 'auto'}}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<TableContainer>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell><div style={{ color: '#636363', fontSize: 18, fontWeight: 500, lineHeight: '24px' }}>Email / SMS Notifications</div></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell align="center">Send to Applicant</StyledTableCell>
											<StyledTableCell align="center">Send E-Mail</StyledTableCell>
											<StyledTableCell align="center">Send SMS</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{["Submitted", "Approved", "Rejected", "Returned", "Terminated"].map((row, index) => (
											<StyledTableRow key={row.name}>
												<StyledTableCell component="th" scope="row" style={{ fontWeight: 600 }}>
													{row}
												</StyledTableCell>
												<StyledTableCell><div style={{ display: 'flex', alignItems: 'center', color: '#1596DB', fontSize: 12, fontWeight: 500, lineHeight: '16px' }}><MdIcon path={mdiPencilOutline} size={0.7} color="#1596DB" style={{ marginRight: 9 }} /> EDIT MESSAGE</div></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell></StyledTableCell>
												<StyledTableCell align="center"><Switch className={classes.root} checked={false}/></StyledTableCell>
												<StyledTableCell align="center"><Switch className={classes.root} checked={true}/></StyledTableCell>
												<StyledTableCell align="center"><Switch className={classes.root} checked={true}/></StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</Paper>
				</div>
				<div style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Inter', color: '#636363', lineHeight: '24px', marginTop: 24}}>Users</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
					<Paper style={{ width: '100%', height: 216 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px 20px 24px' }}>
							<div>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									className={classes.root}
									labelId="demo-simple-select-filled-label"
									defaultValue={"Brands"}
									style={{ width: 158, marginRight: 24 }}
								>
									<MenuItem value={"Brands"}>Brands</MenuItem>
								</Select>
							</FormControl>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									className={classes.root}
									labelId="demo-simple-select-filled-label"
									defaultValue={"Location"}
									style={{ width: 158, marginRight: 24 }}
								>
									<MenuItem value={"Location"}>Location</MenuItem>
								</Select>
							</FormControl>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									className={classes.root}
									labelId="demo-simple-select-filled-label"
									defaultValue={"Status"}
									style={{ width: 158, marginRight: 24 }}
								>
									<MenuItem value={"Status"}>Status</MenuItem>
								</Select>
							</FormControl>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										className={classes.root}
										labelId="demo-simple-select-filled-label"
										defaultValue={"Date Enrolled"}
										style={{ width: 158, marginRight: 24 }}
									>
										<MenuItem value={"Date Enrolled"}>Date Enrolled</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div>
								<Button variant="outlined" style={{ backgroundColor: '#FFFFFF', color: '#F47721', width: 120, height: 40, marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }}>Reset</Button>
								<Button variant="contained" style={{ backgroundColor: '#F47721', color: '#FFFFFF', width: 120, height: 40, marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }}><MdIcon path={mdiRefresh} size={1} color="#FFFFFF" style={{ marginRight: 8 }}/> Update</Button>
							</div>
							
						</div>
						<Divider />
						<div style={{ display: 'flex', justifyContent: 'space-between', margin: '32px 100px 40px 100px'}}>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 150 }}>
								<div style={{ fontSize: 12, fontWeight: 500, fontFamily: 'Inter', lineHeight: '16px', color: '#636363', marginBottom: 16 }}>Heads</div>
								<div style={{ fontSize: 28, fontWeight: 600, fontFamily: 'Inter', lineHeight: '32px', color: '#2B2D33' }}>8</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 150 }}>
								<div style={{ fontSize: 12, fontWeight: 500, fontFamily: 'Inter', lineHeight: '16px', color: '#636363', marginBottom: 16 }}>Managers</div>
								<div style={{ fontSize: 28, fontWeight: 600, fontFamily: 'Inter', lineHeight: '32px', color: '#2B2D33' }}>89</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 150 }}>
								<div style={{ fontSize: 12, fontWeight: 500, fontFamily: 'Inter', lineHeight: '16px', color: '#636363', marginBottom: 16 }}>Supervisors</div>
								<div style={{ fontSize: 28, fontWeight: 600, fontFamily: 'Inter', lineHeight: '32px', color: '#2B2D33' }}>131</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 150 }}>
								<div style={{ fontSize: 12, fontWeight: 500, fontFamily: 'Inter', lineHeight: '16px', color: '#636363', marginBottom: 16 }}>Agents</div>
								<div style={{ fontSize: 28, fontWeight: 600, fontFamily: 'Inter', lineHeight: '32px', color: '#2B2D33' }}>3,381</div>
							</div>
						</div>
					</Paper>
				</div>
			</div>
		</div>
	)
}

export default BrandDetails;