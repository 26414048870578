import React from "react";
import DateFromToButtons from "./logsComponents/ui/DateFromToButtons";
import SearchField from "./logsComponents/ui/SearchField";
import ShowItemsDropdown from "./logsComponents/ui/ShowItemsDropdown";
import StatusDropdown from "./logsComponents/ui/StatusDropdown";

const LogsGroupActions = (props) => {
  return (
    <div style={{ display: "flex", padding: "20px 24px 20px 24px" }}>
      <SearchField
        setSearchKey={props.setSearchKey}
        globalSearchKey={props.globalSearchKey}
        searchKey={props.searchKey}
        setSearchKey={props.setSearchKey}
        setStatusKeyGlobal={props.setStatusKeyGlobal}
        setPage={props.setPage}
        setInitialized={props.setInitialized}
        setSearchKeyGlobal={props.setSearchKeyGlobal}
      />
      <StatusDropdown
        setStatusKey={props.setStatusKey}
        setStatusKeyGlobal={props.setStatusKeyGlobal}
        setPage={props.setPage}
        setInitialized={props.setInitialized}
        statusKey={props.statusKey}
        globalStatusKey={props.globalStatusKey}
      />
      <DateFromToButtons
        setOpenFrom={props.setOpenFrom}
        setFromDate={props.setFromDate}
        setToDate={props.setToDate}
        fromDate={props.fromDate}
        toDate={props.toDate}
        isOpenFrom={props.isOpenFrom}
        isOpenTo={props.isOpenTo}
        setOpenTo={props.setOpenTo}
        setPage={props.setPage}
        setInitialized={props.setInitialized}
      />
      <ShowItemsDropdown
        setShowItems={props.setShowItems}
        showItems={props.showItems}
        setPage={props.setPage}
        setInitialized={props.setInitialized}
      />
    </div>
  );
};

export default LogsGroupActions;
