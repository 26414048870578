export default {
	"salesOrg": {
		"code": "SM001"
	},
	"product": {
		"code": "P20191205000001",
		"version": "1"
	},
	"applyDate": 1580804568571,
	"applicationTabs": [
		{
			"id": 111,
			"fields": [
				{
					"code": "applicAmount",
					"value": 100000,
					"unit": null
				},
				{
					"code": "applicLoanPurposeD",
					"value": "general",
					"unit": null
				},
				{
					"code": "applicRepayType",
					"value": "EQ_C",
					"unit": null
				},
				{
					"code": "applicLoanTerm",
					"value": 3,
					"unit": null
				},
				{
					"code": "newToUBP",
					"value": "no",
					"unit": null
				}
			]
		},
		{
			"id": 106,
			"fields": [
				{
					"code": "personFirstName",
					"value": "Testing 2",
					"unit": null
				},
				{
					"code": "personLastName",
					"value": "Testing 2",
					"unit": null
				},
				{
					"code": "personMiddleName",
					"value": "testing middle name",
					"unit": null
				},
				{
					"code": "personBirthdate",
					"value": "1940-01-1 00:00:00",
					"unit": null
				},
				{
					"code": "personGenderD",
					"value": "male",
					"unit": null
				},
				{
					"code": "personBirthPlace",
					"value": "city_1st_cavite_in_estate",
					"unit": null
				},
				{
					"code": "personNationality",
					"value": "nat_philippines",
					"unit": null
				},
				{
					"code": "companyEmpDesig",
					"value": "privatesector",
					"unit": null
				},
				{
					"code": "companyEmpDesigO",
					"value": "",
					"unit": null
				},
				{
					"code": "personMobileNo",
					"value": "0912345678",
					"unit": null
				},
				{
					"code": "personCivilStatus",
					"value": "single",
					"unit": null
				},
				{
					"code": "addressLine1",
					"value": "123",
					"unit": null
				},
				{
					"code": "addressStreet",
					"value": "123",
					"unit": null
				},
				{
					"code": "addressSubdistrict",
					"value": "123",
					"unit": null
				},
				{
					"code": "addressProvince",
					"value": "prov_abra",
					"unit": null
				},
				{
					"code": "addressCity",
					"value": "city_1st_cavite_in_estate",
					"unit": null
				},
				{
					"code": "personSpouseFirstName",
					"value": "",
					"unit": null
				},
				{
					"code": "personSpouseLastName",
					"value": "",
					"unit": null
				},
				{
					"code": "personSpouseMiddleName",
					"value": "",
					"unit": null
				},
				{
					"code": "personSpouseBirthdate",
					"value": "",
					"unit": null
				},
				{
					"code": "companySpouseMobileNo",
					"value": "",
					"unit": null
				},
				{
					"code": "companySpouseDesig",
					"value": "",
					"unit": null
				},
				{
					"code": "companySpouseDesig",
					"value": "",
					"unit": null
				},
				{
					"code": "companySpouseDesigO",
					"value": "",
					"unit": null
				},
				{
					"code": "personDependent",
					"value": "1",
					"unit": null
				},
				{
					"code": "personEmail",
					"value": "martin@ubx.ph",
					"unit": null
				},
				{
					"code": "personFullNameID",
					"value": "Testing 2 testing middle name Testing 2",
					"unit": null
				},
				{
					"code": "personMonthlyIncome",
					"value": "123123",
					"unit": null
				},
				{
					"code": "personTaxReference",
					"value": "123123123",
					"unit": null
				},
				{
					"code": "personGSISID",
					"value": "",
					"unit": null
				},
				{
					"code": "personSSSID",
					"value": "2131231231",
					"unit": null
				}
			]
		},
		{
			"id": 107,
			"fields": [
				{
					"code": "companyName",
					"value": "Testing Business 2",
					"unit": null
				},
				{
					"code": "companyNature",
					"value": "accommodationfoodserviceactivities",
					"unit": null
				},
				{
					"code": "companyBizPeriod",
					"value": "1",
					"unit": null
				},
				{
					"code": "companyType",
					"value": "partnership",
					"unit": null
				},
				{
					"code": "companyTaxReference",
					"value": "123123213",
					"unit": null
				},
				{
					"code": "companyRegDt",
					"value": "1940-01-1 00:00:00",
					"unit": null
				},
				{
					"code": "companyDesignation",
					"value": "chiefexecutiveofficerpresident",
					"unit": null
				},
				{
					"code": "companyEmail",
					"value": "testing@testing.com",
					"unit": null
				},
				{
					"code": "companyTelNo",
					"value": "12312312",
					"unit": null
				},
				{
					"code": "companySuppName",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyPriBank",
					"value": "bank_aub",
					"unit": null
				},
				{
					"code": "companyAnnualRevenue",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyAssetSize",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyECommChannel",
					"value": "no",
					"unit": null
				},
				{
					"code": "companyECommChannelID",
					"value": "",
					"unit": null
				},
				{
					"code": "companyEWallet",
					"value": "no",
					"unit": null
				},
				{
					"code": "companyEWalletID",
					"value": "",
					"unit": null
				},
				{
					"code": "companyEmpCount",
					"value": "1",
					"unit": null
				},
				{
					"code": "companyOSDebt",
					"value": "1",
					"unit": null
				},
				{
					"code": "companyOfcMobileNo",
					"value": "0912345678",
					"unit": null
				}
			]
		},
		{
			"id": 108,
			"fields": [
				{
					"code": "companyLine1",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyStreet",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyPostal",
					"value": "123",
					"unit": null
				},
				{
					"code": "companyEstTyp",
					"value": "commercial",
					"unit": null
				},
				{
					"code": "companyCountry",
					"value": "cty_philippines",
					"unit": null
				},
				{
					"code": "companyProvince",
					"value": "prov_abra",
					"unit": null
				},
				{
					"code": "companyCity",
					"value": "city_aborlan",
					"unit": null
				},
				{
					"code": "companyAddrRegion",
					"value": "bizAreaCAR",
					"unit": null
				},
				{
					"code": "companyBizPeriodCurrAddr",
					"value": "123",
					"unit": null
				}
			]
		},
		{
			"id": 112,
			"fields": [
				{
					"code": "attchBillingProof",
					"value": "",
					"unit": null
				},
				{
					"code": "attchBizPermit",
					"value": "",
					"unit": null
				},
				{
					"code": "attchBizPlcPhotoExt",
					"value": "",
					"unit": null
				},
				{
					"code": "attchBizPlcPhotoInt",
					"value": "",
					"unit": null
				},
				{
					"code": "attchDTISECCert",
					"value": "",
					"unit": null
				},
				{
					"code": "attchImgId",
					"value": "",
					"unit": null
				},
				{
					"code": "attchImgSelfie",
					"value": "",
					"unit": null
				},
				{
					"code": "attchSalesProof",
					"value": "",
					"unit": null
				},
				{
					"code": "attchISP",
					"value": "",
					"unit": null
				}
			]
		}
	]
}