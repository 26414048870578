import new_i2i_logo from './new_i2i_logo.png'
import icon_previewUnavailable from '../images/icon_previewUnavailable.svg'
import icon_noDocumentUploaded from '../images/icon_noDocumentUploaded.svg'
import icon_export from './icon_export.svg'
import icon_export_primary from './icon_export_primary.svg'
import icon_download from './icon_download.svg'
import calendar_gray_logo from './calendar-gray-logo.png'
import icon_view from './icon_view.svg'
import icon_replace from '../images/icon_replace.svg'
import icon_delete from '../images/icon_delete.svg'
import icon_edit from '../images/icon_edit.svg'

export {
	new_i2i_logo,
	icon_previewUnavailable,
	icon_noDocumentUploaded,
	icon_export,
	icon_export_primary,
	icon_download,
	calendar_gray_logo,
	icon_view,
	icon_replace,
	icon_delete,
	icon_edit
}