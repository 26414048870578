import React from "react";
import { useStyles } from "../helpers/Styles";
import { TextField, InputAdornment } from "@material-ui/core/";
import { Icon as MdIcon } from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

const SearchField = (props) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      id="input-with-icon-textfield"
      label="Search by reference ID or user"
      variant="outlined"
      style={{
        width: 340,
        minWidth: 300,
        height: 50,
        marginRight: 24,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <MdIcon
              path={mdiMagnify}
              size="24px"
              style={{ color: "#909196" }}
            />
          </InputAdornment>
        ),
      }}
      defaultValue={
        props.globalSearchKey ? props.globalSearchKey : props.searchKey
      }
      // onChange={(event) => { filterValue(event.target.value, "NAME"); setSearchKey(event.target.value) }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          props.setSearchKey(event.target.value);
          props.setSearchKeyGlobal(event.target.value);
          props.setPage(1);
          props.setInitialized(false);
        }
      }}
    />
  );
};

export default SearchField;
