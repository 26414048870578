import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
    InputAdornment,
	FormControl,
    Grid,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignStyle,
    getPdfAlignStyle, 
    useStyles, 
    texts,
    PdfStyles,
    getDisplayValue, 
    componentTypes,
} from '../../helper';

const FormInputDisplayProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [reference, setReference] = useState('');
    const [errors, setErrors] = useState({});

    const handleLabelChange = (event) => {
		const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
		errors['label'] = checkRequired(label, texts.labelComponentLabel);
		setErrors(errors);
	}

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.updateValue(value);
		errors['value'] = checkRequired(value, texts.labelComponentValue);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handlePrefixChange = (event) => {
        const prefix = event.target.value;
        setPrefix(prefix);
        props.updatePrefix(prefix);
    }

    const handleSuffixChange = (event) => {
        const suffix = event.target.value;
        setSuffix(suffix);
        props.updateSuffix(suffix);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }
    
    useEffect(() => {
        setLabel(props.label);
        setValue(props.value);
        setAlign(props.align);
        setPrefix(props.prefix || '');
        setSuffix(props.suffix || '');
        setReference(props.reference || '');
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentLabel);
        errors['value'] = checkRequired(props.value, texts.labelComponentValue);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeInputDisplay}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-label'
                            label={texts.labelComponentLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-value'
                            label={texts.labelComponentValue}
                            variant='outlined'
                            value={value || ''}
                            error={errors['value']}
                            helperText={errors['value']}
                            onChange={handleValueChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-prefix'
                            label={texts.labelComponentPrefix}
                            variant='outlined'
                            value={prefix || ''}
                            onChange={handlePrefixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-suffix'
                            label={texts.labelComponentSuffix}
                            variant='outlined'
                            value={suffix || ''}
                            onChange={handleSuffixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormInputDisplayDesigner = (props) => {
	const classes = useStyles();
    const [label, setLabel] = useState('');
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [reference, setReference] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id); 
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateValue = (value) => {
        setValue(value);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdatePrefix = (prefix) => {
        setPrefix(prefix);
        props.handleTrackChange();
    }

    const handleUpdateSuffix = (suffix) => {
        setSuffix(suffix);
        props.handleTrackChange();
    }

    const handleUpdateReference = (reference) => {
        setReference(reference);
        props.handleFormulaReference(props.id, reference);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormInputDisplayProperties 
                                            id={props.id} 
                                            label={label} 
                                            value={value}
                                            align={align} 
                                            prefix={prefix}
                                            suffix={suffix}
                                            reference={reference}
                                            updateLabel={handleUpdateLabel}
                                            updateValue={handleUpdateValue}
                                            updateAlign={handleUpdateAlign}
                                            updatePrefix={handleUpdatePrefix}
                                            updateSuffix={handleUpdateSuffix}
                                            updateReference={handleUpdateReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
        errors['label'] = checkRequired(label);
        errors['value'] = checkRequired(value);
        return (!!errors['label'] || !!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMINPUTDISPLAY, 
                                    label, 
                                    value,
                                    align,
                                    prefix,
                                    suffix,
                                    reference }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setValue(props.value);
            setAlign(props.align);
            setPrefix(props.prefix);
            setSuffix(props.suffix);
            setReference(props.reference);
            if (props.reference) {
                props.handleFormulaReference(props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-input-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeInputDisplay}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={label || ''}
                            variant='outlined'
                            value={value || texts.infoConfigInputDisplay}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (prefix? <InputAdornment position='start'>{prefix}</InputAdornment> : null),
                                endAdornment:  (suffix ? <InputAdornment position='end'>{suffix}</InputAdornment> : null),
                                inputProps: { style: getAlignStyle(align) },
                            }} />
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleInputDisplay} 
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigInputDisplay}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormInputDisplayPreview = (props) => {
	const classes = useStyles();
    
    useEffect(() => {
        if (props.reference) {
            props.handleFormulaReference(props.id, props.reference);
        }
        props.handleInputData(props.id, (props.value || ''), true);
    }, []);

	return (
        <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
            <TextField 
                id={props.id}
                label={props.label || ''}
                variant='outlined'
                value={props.value || ''}
                InputProps={{
                    readOnly: true,
                    startAdornment: (props.prefix ? <InputAdornment position='start'>{props.prefix}</InputAdornment> : null),
                    endAdornment:  (props.suffix ? <InputAdornment position='end'>{props.suffix}</InputAdornment> : null),
                    inputProps: { style: getAlignStyle(props.align) },
                }} />
        </FormControl>
	)
}

export const FormInputDisplayPdf = (props) => {
	return (
        <View style={PdfStyles.groupInputColumn}>
            <Text style={PdfStyles.groupInputLabel}>{props.label || ''}</Text>
            <Text style={getPdfAlignStyle(props.align || aligns.LEFT, PdfStyles.groupInputValue)}>{getDisplayValue(props.value, '', props.prefix, props.suffix)}</Text>
        </View>
	)
}