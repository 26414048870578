import React, { Component, useState, useEffect, useRef } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import Dropzone from 'react-dropzone';
import NumberFormat from 'react-number-format';
import AddCustomerProfile from './AddCustomerProfile';
import { Document, Page, PDFDownloadLink, pdfjs } from 'react-pdf';
import {
  Button,
  Box,
  AppBar,
  CardMedia,
  CircularProgress,
  Toolbar,
  Dialog,
  Drawer,
  MenuList,
  MenuItem,
  Modal,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
  InputAdornment,
  Select,
  Snackbar,
  Switch,
  InputLabel,
  FormControl,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Typography,
  Radio,
} from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Paths from '../../enums/Paths';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react';
import {
  mdiArrowLeft,
  mdiFileExportOutline,
  mdiCurrencyPhp,
  mdiDownload,
  mdiHexagonSlice1,
  mdiAutorenew,
  mdiAlertOctagonOutline,
  mdiMagnifyPlusOutline,
  mdiRedoVariant,
  mdiTextBoxRemoveOutline,
  mdiContentSave,
  mdiFormatLetterCaseLower,
  mdiDownloadOutline,
  mdiPlusCircleOutline,
} from '@mdi/js';
import StatusDisplay from '../common/StatusDisplay';
import LoanService from '../../services/LoanService';
import MockStubDatav2 from '../../constants/MockStubDatav2';
import MockStubValue from '../../constants/MockStubValue';
import Person from '@material-ui/icons/Person';
import {
  icon_previewUnavailable,
  icon_noDocumentUploaded,
  icon_download,
  icon_view,
  icon_replace,
  icon_delete,
} from '../../assets/images';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { ButtonStyles, ContainerStyles, texts } from './helper';
import ValidationService from '../../services/ValidationService';
import MaskedInput from 'react-text-mask';
import AWSService from '../../services/AWSService';
import EmitterService from '../../services/EmitterService';
import { createHash } from 'crypto';
import FormSelector from './reviewApplicationComponents/FormSelector'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFDocumentWrapper = styled.div`
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
  }
`;

const PDFDocumentWrapper2 = styled.div`
  .react-pdf__Page__canvas {
    width: 80vh !important;
    height: 80vh !important;
    border: 'none';
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5922F',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '24px',
      fontWeight: 500,
    },
    '& .MuiInputBase-root .Mui-disabled': {
      color: '#000000',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '24px',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
    },
    '* .MuiTypography-body1': {
      color: '#000000',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '24px',
    },
    ' & .MuiPaginationItem-page': {
      fontFamily: 'Inter',
    },
    '& .MuiPaginationItem-page.Mui-selected ': {
      backgroundColor: '#F5922F',
      color: '#FFFFFF',
      fontFamily: 'Inter',
    },
    '& .MuiPaginationItem-rounded': {
      fontFamily: 'Inter',
    },
    '& .MuiInputBase-input': {
      lineHeight: '21px',
    },
    '& .MuiInputBase-root': {
      height: 40,
      lineHeight: '2px',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .Mui-checked': {
      color: '#F47721',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#F47721',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  table: {
    fontFamily: 'Inter',
    '& .MuiTableCell-body': {
      fontFamily: 'Inter',
    },
    '& .MuiTableCell-head': {
      fontFamily: 'Inter',
    },
    fontFamily: 'Inter',
  },
  formControl: {
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#636363',
    fontSize: 12,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 72,
    '&:nth-of-type(odd)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const OrangeRadio = withStyles({
  root: {
    '&$checked': {
      color: 'rgb(245, 146, 47)',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

let appAttachments,
  attachmentNames = [],
  failedAttachments = [],
  requiredDocuments = [],
  appSummary,
  appSignature,
  formData,
  formStructure;
let loanTerm, purposeD, paymentAcc, approvalInfo;
let attachments = {};
let attachmentThumbnails = {};
let attachmentUploadFail = false;
let attachmentDeleteFail = false;
let appStatus;
let globalModifiedFormData;
let globalApplicationFields;
let firstName, middleName, lastName, applicantEmail, applicantBirthdate, applicantMobile
let attachmentsToRemove = [];

const LoanApplications = (props) => {
  let history = useHistory();
  const { userData } = props;
  const { REACT_APP_CRYPTO_SECRET_KEY } = process.env;
  const classes = useStyles();
  const { last, orderBy, find, findIndex, remove, rest } = require('lodash');
  const [screenIndex, setScreenIndex] = useState(0);
  const [value, setValue] = useState(0);
  const [applicationFormData, setApplicationFormData] = useState(null);
  const [applicationFormValues, setApplicationFormValues] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [remark, setRemark] = useState('');
  const [remarksData, setRemarksData] = useState(null);
  const [submittingApp, setSubmittingApp] = useState(false);
  const [archivingApp, setArchivingApp] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [confirmSubmitDialog, setConfirmSubmitDialog] = useState(false);
  const [confirmArchiveDialog, setConfirmArchiveDialog] = useState(false);
  const [archiveSuccess, setArchiveSuccess] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarArchiveState, setSnackbarArchiveState] = useState(false);
  const [snackbarRequiredFieldState, setSnackbarRequiredFieldState] =
    useState(false);
  const [selfieBase64, setSelfieBase64] = useState('');
  const [selectedBase64, setSelectedBase64] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [gettingAttachment, setGettingAttachment] = useState(false);
  const [gettingFullAttch, setGettingFullAttch] = useState(false);
  const [gettingSelfie, setGettingSelfie] = useState(false);
  const [loanProductAvailable, setLoanProductAvailable] = useState(true);
  const [initializeAttachments, setInitializeAttachments] = useState(false);
  const [initializeSelfie, setInitializeSelfie] = useState(false);
  const [previewAttachments, setPreviewAttachments] = useState({});
  const [attachmentsv2, setAttachmentsv2] = useState({});
  const [attachmentsr1, setAttachmentsr1] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dlPDF, setDlPDF] = useState('');
  const [gettingData, setGettingData] = useState(false);
  const [applicationLogs, setApplicationLogs] = useState(null);
  const [gettingApplicationLogs, setGettingApplicationLogs] = useState(false);
  const [retrievingFailed, setRetrievingFailed] = useState(false);
  const [appArchived, setAppArchived] = useState(false);
  const [lastBase64, setLastBase64] = useState('');
  const [lastByte, setLastByte] = useState(0);
  const [numTabs, setNumTabs] = useState(-9);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showItems, setShowItems] = useState(10);
  const [modifiedData, setModifiedData] = useState('');
  const [revision, setRevision] = useState(0);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [snackbarEditState, setSnackbarEditState] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [loadedApplicationFormData, setLoadedApplicationFormData] = useState(false);
  const [requiredFieldList, setRequiredFieldList] = useState([]);
  const [requiredFieldStat, setrequiredFieldStat] = useState(false);
  const [validationMessage, setValidationMessage] = useState([]);
  const [modifiedAttachmentData, setModifiedAttachmentData] = useState('');
  const [validationType, setValidationType] = useState('');
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [hoveredAttachment, setHoveredAttachment] = useState('');
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [deletingDialogOpen, setDeletingDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [updatingData, setUpdatingData] = useState(false);
  const [snackbarUploadState, setSnackbarUploadState] = useState(false);
  const [snackbarDeleteState, setSnackbarDeleteState] = useState(false);
  const [snackbarReachedFileSizeLimit, setSnackbarReachedFileSizeLimit] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [invalidFieldCount, setInvalidFieldCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [validationList, setValidationList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [deleteStatus, setDeleteStatus] = useState('');
  const [fileHash, setFileHash] = useState('');
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [documentValidationState, setDocumentValidationState] = useState(false);
  const [deleteEmitted, setDeleteEmitted] = useState(false);
  const [cashFlowPage, setCashFlowPage] = useState(1);
  const [cashFlowMaxPage, setCashFlowMaxPage] = useState(1);
  const [cashFlowCount, setCashFlowCount] = useState(5);
  const [cashFlowTotalCount, setCashFlowTotalCount] = useState(5);
  const [cashFlowShowItems, setCashFlowShowItems] = useState(10);
  const [cashFlowExists, setCashFlowExists] = useState(false);
  const [cashFlowList, setCashFlowList] = useState([]);
  const [cashFlowVersion, setCashFlowVersion] = useState(0);
  const [isOpenFormSelector,setIsOpenFormSelector] = useState(false);
  const [cashFlowFormList,setCashFlowFormList] = useState([]);
  const [noCashflowForms, setNoCashflowForms] = useState(false);
  const [defaultCashflow, setDefaultCashflow] = useState('');
  const [settingDefaultCashflow, setSettingDefaultCashflow] = useState(false);
  const [snackbarSetDefaultCashflowState, setSnackbarSetDefaultCashflowState] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [linkProfilePrompted, setLinkProfilePrompted] = useState(false);

  /* Constants */
  const CASHFLOW_ATTACHMENT = 'attchSalesProof';
  const [editableStatusLookup] = useState([
    'INCOMPLETE',
    'COMPLETED',
    'FAILED',
    'FOR RESUBMISSION',
    'RETURNED',
  ]);
  
  const mobileNumberFields = ['personMobileNo', 'companySpouseMobileNo', 'companyOfcMobileNo', 
  'companyEmpOfcMobileNo', 'personEmergencyMobile', 'personRefMobile1', 
  'personRefMobile2', 'personRefMobile3', 'companyCustMobileNo', 'companySuppMobileNo', 'companyTelNo'];
  const alphabeticFields = ['personFirstName', 'personLastName', 'personMiddleName', 'personSpouseFirstName', 
    'personSpouseLastName', 'personSpouseLastName'];
  const alphanumericFields = ['addressLine1'];
  const sssFields = ['personSSSID', 'personSpouseSSSUMID'];
  const gsisFields = ['personGSISID', 'personSpouseGSIS'];
  const tinFields = ['personTaxReference', 'companyTaxReference', 'personSpouseTIN'];
  /* End of Constants */

  let tabsWithAttachment = [];
  const uploadFileInput = useRef(null);

  /* Global emitter service */
  EmitterService.on('uploadProgress', (value) => {
    setUploadProgress(value);
  });

  EmitterService.on('uploadStatus', (value) => {
    setUploadStatus(value);
    if (value == 'SUCCESS') {
      setUploadSuccess(true);
      remove(failedAttachments, selectedAttachment);
    } else if (value == 'ERROR') {
      attachmentUploadFail = true;
      remove(attachmentNames, selectedAttachment);
      failedAttachments.push(selectedAttachment);
      setUploadSuccess(false);
      setSnackbarUploadState(true);
      setUploadingDialogOpen(false);
    }
  });

  EmitterService.on('deleteStatus', (value) => {
    setDeleteStatus(value);
    setDeleteEmitted(true);
    if (value == 'SUCCESS') {
      let newAttachments = attachmentNames;
      remove(newAttachments, function (attch) {
        return attch == selectedAttachment;
      });
      remove(attachmentNames, selectedAttachment);
      setDeleteSuccess(true);
      setDeletingDialogOpen(false);
      attachmentNames = newAttachments;
      setSnackbarDeleteState(true);
    } else if (value == 'ERROR') {
      attachmentDeleteFail = true;
      setDeleteSuccess(false);
      setSnackbarDeleteState(true);
      setDeletingDialogOpen(false);
    }
    setDeletingFile(false);
  });

  const uploadFile = async (file, fieldCode) => {
		const referenceId = props.application.referenceId;
    
		attachmentUploadFail = false
		setUploadProgress(0);
		setUploadingFile(true);
    setSnackbarReachedFileSizeLimit(false);

		if (!file || file && file.type != "application/pdf") {
			// console.log("not pdf")
		} else if (file.size > 5242880) {
      setSnackbarReachedFileSizeLimit(true);
    } else {
      const temporaryURL = URL.createObjectURL(file);
      let modifiedAttachmentThumbnails = attachmentThumbnails;
			setUploadingDialogOpen(true)
      
      const res = await LoanService.uploadAttachment(referenceId, {
        file,
        fieldCode,
      });

      let successUpload = false;
      
      if (res && res.result && res.result.successAttachments && res.result.successAttachments.length) {
        modifiedAttachmentThumbnails[`${fieldCode ? fieldCode : selectedAttachment}`] = {
          "result": {
            "value": temporaryURL
          }
        };
        remove(failedAttachments, selectedAttachment);
        successUpload = true;
      }
      
      setUploadSuccess(successUpload);
      setAttachmentsv2(modifiedAttachmentThumbnails);
			hashFile(file, fieldCode, successUpload)
		}
	}

  const replaceFileHandler = async (event, fieldCode) => {
    const file = event.target.files[0];
    const referenceId = props.application.referenceId;
    setUploadProgress(0);
    setSnackbarReachedFileSizeLimit(false);

    if (!file || (file && file.type != 'application/pdf')) {
      // console.log("not pdf")
    } else if (file.size > 5242880) {
      setSnackbarReachedFileSizeLimit(true)
    } else {
      const temporaryURL = URL.createObjectURL(file);
      let modifiedAttachmentThumbnails = attachmentThumbnails;
      setUploadingDialogOpen(true);

      // Delete existing attachment and replace with newly uploaded attachment
      await LoanService.deleteAttachment(referenceId, selectedAttachment)
      const res = await LoanService.uploadAttachment(referenceId, {
        file,
        fieldCode: selectedAttachment,
      });

      let successUpload = false;
      
      if (res && res.result && res.result.successAttachments && res.result.successAttachments.length) {
        modifiedAttachmentThumbnails[`${selectedAttachment}`] = {
          "result": {
            "value": temporaryURL
          }
        };
        remove(failedAttachments, selectedAttachment);
        successUpload = true;
      }

      setUploadSuccess(successUpload);
      setAttachmentsv2(modifiedAttachmentThumbnails);
      hashFile(file, selectedAttachment, successUpload);
    }
  }
  
	const deleteFile = async () => {
		setDeletingFile(true)
		const referenceId = props.application.referenceId;
    const res = await LoanService.deleteAttachment(referenceId, selectedAttachment);
		setGettingAttachment(true)
    if (res && res.result && res.result.success) {
      let newAttachments = attachmentNames;
      remove(newAttachments, function (attch) {
        return attch == selectedAttachment;
      });
      remove(attachmentNames, selectedAttachment);
      setDeleteSuccess(true);
      setDeletingDialogOpen(false);
      attachmentNames = newAttachments;
      setSnackbarDeleteState(true);
      await renderImage(selectedAttachment, true)
    } else {
      attachmentDeleteFail = true;
      setDeleteSuccess(false);
      setSnackbarDeleteState(true);
      setDeletingDialogOpen(false);
    }
    setDeletingFile(false);
		await renderImage(selectedAttachment, true)
		setGettingAttachment(false)
		await getStatus();
	}

  async function hashFile(file, fieldCode, success) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async (event) => {
      const fileBuffer = event.target.result;
      const hash = await createHash('sha256')
        .update(
          Buffer.from(fileBuffer, 'utf8')
            ? Buffer.from(fileBuffer, 'utf8')
            : null
        )
        .digest('hex');
      setFileHash(hash);
      editApplicationAttachment(
        {
          fieldCode,
          hash,
        },
        'UPLOAD',
        success
      ); 
    };
  }

  const downloadCashflowPDF = async (key, date, version, referenceId) => {
    const attachmentCode = key.split('/')[3].replace('.pdf', '');

    // Retrieve attachment base64
    const data = await LoanService.getApplicationAttachments(
      props.application.referenceId,
      attachmentCode,
      false,
      false,
      0
    );

    // Convert base64 to blob
    const byteChar = atob(data.result.value.split(',')[1]);
    const bytes = new Array(byteChar.length);

    for (let i = 0; i < byteChar.length; i++) {
      bytes[i] = byteChar.charCodeAt(i);
    }

    const byteArray = new Uint8Array(bytes);
    const blob = new Blob([byteArray], {type: 'application/pdf'});

    // Generate objectURL for blob file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `Cashflow_${referenceId}`);
    document.body.appendChild(link);
    link.click();
    return true;
  }

  async function onDropHandler(files, fieldCode) {
    const file = files[0];
    setSelectedAttachment(fieldCode);
    uploadFile(file, fieldCode);
  }

  const onReplaceClick = () => {
    uploadFileInput.current.click();
  };

  async function getAttachment(attachmentName) {
    if (!previewAttachments[`${attachmentName}`]) {
      setGettingAttachment(true);
      await renderImage(attachmentName, false);
      setGettingAttachment(false);
    }
  }

  async function getAttachmentThumbnails() {
    if (!initializeAttachments) {
      let attchIndex = 0;
      setInitializeAttachments(true);
      setGettingAttachment(true);

      let attachmentList;
      
      attachmentsr1['applicationTabs'].map((tab) => {
        if (tab.name == 'Attachments') {
          attachmentList = tab.fields;
        }
      });

      attachmentList && attachmentList.map(async (item, index) => {
        attachmentNames.push(item.code);
      });
      while (attchIndex <= attachmentNames.length - 1) {
        await renderImage(attachmentNames[attchIndex], true);
        attchIndex++;
        setAttachmentsv2(attachmentThumbnails);
        setRevision(Math.random());
      }
      setGettingAttachment(false);
    }
  }

  async function getSelfieImage() {
    if (!initializeSelfie) {
      setInitializeSelfie(true);
      setGettingSelfie(true);
      let selfieBase64 = await renderImage('selfie', true);
      setSelfieBase64(selfieBase64.data.result.value);
      setGettingSelfie(false);
    }
  }

  async function getApplicationLogs(limit, currentPage) {
    let sortedLogs;
    setGettingApplicationLogs(true);
    let appLogs = await LoanService.getLogs(
      limit ? limit : showItems,
      currentPage ? currentPage : page,
      null,
      null,
      null,
      props.application.referenceId
    );
    if (appLogs && appLogs.result && appLogs.result.logs) {
      sortedLogs = appLogs.result.logs.sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
      );
    }
    currentPage && setPage(currentPage);
    limit && setShowItems(limit);
    setCount(appLogs && appLogs.result ? appLogs.result.count : 0);
    setMaxPage(appLogs && appLogs.result ? appLogs.result.maxPage : 0);
    setTotalCount(appLogs && appLogs.result ? appLogs.result.logs.length : 0);
    setApplicationLogs(
      appLogs && appLogs.result && appLogs.result.logs ? sortedLogs : null
    );
    setGettingApplicationLogs(false);
  }

  async function getStatus() {
		appStatus = await LoanService.getApplicationStatus(props.application.referenceId)

		if (appStatus && appStatus.result && appStatus.result.status) {
			setApplicationStatus(appStatus.result.status)
			if (appStatus.result.status == 'ARCHIVED') {
				setAppArchived(true)
			}
		}
	}

  async function checkIfCashflowExists(attchData) {
    attchData.map((tab) => {
      if (tab.name == 'Cash Flow') {
        getCashflowAnalysisList();
        getCashFlowForm();
        setCashFlowExists(true);
      }
    });
  }

  async function getCashflowAnalysisList(limit, page) {
    const res = await LoanService.getCashFlowAnalysisList(props.application.referenceId, limit, page)
    const cashflowResultList = res && res.result && res.result.result ? res.result.result : [];

    setCashFlowPage(res && res.result.page);
    setCashFlowMaxPage(res && res.result.maxPage);
    setCashFlowCount(res && res.result.count);
    setCashFlowTotalCount(res && res.result && res.result.result.length)
    setCashFlowList(cashflowResultList)
  }

  async function getCashFlowForm(){
    let appProdId = await LoanService.getApplicationProductID(
      props.application.referenceId
    );
    const res = await LoanService.getCashFlowForm(appProdId.result.productId);
    const cashFlowFormResultList = res && res.result && res.result.list ? res.result.list : [];
    setCashFlowFormList(cashFlowFormResultList);
  }

  const checkRender = (field, obj, code) => {
    let visible = false;
    const appFormData = attachmentsr1.applicationTabs;
    let parentField;
    
    // check if element should be visible based on parent value
    if (!modifiedData) {
      if(globalModifiedFormData){
        globalModifiedFormData.map((tabFields) => {
          tabFields.applicationTemplateTabFields.map((subgroup) => {
            if (field && field.value) {
              find(subgroup.fields, function(o) { 
                if(o.field && (o.field.code.toString() == field.code.toString())) {
                  parentField = o;
                }
                // Check if the render field meets the requirement
                if (o.field && (o.field.code.toString() == field.code.toString())
                && (o.field.value && o.field.value.toString() == field.value.toString())) {
                  visible = true;
                }
              });
            }
          });
        })
      } else{
        appFormData.map((tabFields) => {
          find(tabFields.fields, function(o) { 
            if(o && o.code && field && (o.code.toString() == field.code.toString())){
              let removeAttch = attachmentsToRemove;
              if (!removeAttch.includes(code)) {
                remove(removeAttch, code);
              }
              parentField = o;
            }
            // Check if the render field meets the requirement
            if (o && o.code && field && (o.code.toString() == field.code.toString())
              && (o.value.toString() == field.value.toString())) {
                let removeAttch = attachmentsToRemove;
                if (!removeAttch.includes(code)) {
                  removeAttch.push(code)
                }
                visible = true;
            }
          });
        });
      }
    }
    else {
      modifiedData.map((tabFields) => {
        tabFields.applicationTemplateTabFields.map((subgroup) => {
          if (field && field.value) {
            find(subgroup.fields, function(o) { 
              if(o.field && (o.field.code.toString() == field.code.toString())) {
                let removeAttch = attachmentsToRemove;
                parentField = o;
                if (!removeAttch.includes(code)) {
                  removeAttch.push(code)
                }
              }
              // Check if the render field meets the requirement
              if (o.field && (o.field.code.toString() == field.code.toString())
                && (o.field.value && o.field.value.toString() == field.value.toString())) {
                let removeAttch = attachmentsToRemove;
                if (removeAttch.includes(code)) {
                  remove(removeAttch, function(attch) {
                    return attch == code;
                  });
                }
                attachmentsToRemove = removeAttch;
                visible = true;
              } else {
                if (code && code.includes("attch") && o.field.code.toString() == field.code.toString()) {
                  let removeAttch = attachmentsToRemove;
                  if (!removeAttch.includes(code)) {
                    removeAttch.push(code)
                  }
                  attachmentsToRemove = removeAttch;
                }
              }
            });
          }
        });
      })
    }

    if (field && field.code && field.value) {
      // check if parent element is visible even with value
      if(parentField && parentField.field && parentField.field.render 
        && parentField.field.render.fields.length > 0 ){
        let parentVisible = checkRender(parentField.field.render.fields[0]) == 'visible';
        return (parentVisible && visible ? 'visible' : 'hidden');
      } else {
        return visible ? 'visible' : 'hidden';
      }
    } else {
      return 'visible';
    }
  }

  const checkParentTabRender = (parentCode) => {
    const fields = globalApplicationFields && globalApplicationFields.length > 0 
      && globalApplicationFields.filter(field => {
        return field.parentCode == parentCode && 
         checkRender(field.field.render && field.field.render.fields[0]) == "visible";
    });

    return (fields && fields.length > 0);
  };

  const adjustValues = (formData, code, valueType) => {

    // jologs approach : refine later
    if(formData) {
      formData.forEach(tabData => {
        tabData.applicationTemplateTabFields.forEach(groupData => {
          groupData.fields.forEach(field => {
            if(field && field.field.render && 
              field.field.render.fields[0].code == code){
                field.field.value = (valueType == 'Integer' || valueType == 'float') ? null : "";
            }
          })
        })
      })
    }
    return formData;
  }

  async function getData() {
    setIsFetchingFailed(false);
    setInitialized(true);
    setInitializeAttachments(false);
    setInitializeSelfie(false);
    setGettingData(true);
    setSelfieBase64('');
    setDlPDF('');
    setApplicationLogs(null);
    setCustomerId('');
    attachments = {};
    attachmentNames = [];
    attachmentThumbnails = {};
    purposeD = '';
    loanTerm = '';
    paymentAcc = '';
    approvalInfo = {};
    formStructure = null;
    appSignature = '';
    requiredDocuments = [];
    firstName = '';
    middleName = '';
    lastName = '';

    /* Get application status */
		await getStatus();

    if (appStatus && appStatus.result && appStatus.result.status) {
      setApplicationStatus(appStatus.result.status);
      if (appStatus.result.status == 'ARCHIVED') {
        setAppArchived(true);
      }
    }
    let appProdId = await LoanService.getApplicationProductID(
      props.application.referenceId
    );

    formStructure = await LoanService.getApplicationGroupedFields(appProdId && appProdId.result && appProdId.result.productId);
    if (formStructure && formStructure.status != 404) {
      formData = await LoanService.getApplicationData(
        props.application.referenceId
      );
      const attchData = JSON.stringify(formData);
      setAttachmentsr1(JSON.parse(attchData)['result']['applicationForm']);
      // To check whether cashflow attachment exists, to hide or show Cashflow tab
      checkIfCashflowExists(formStructure['result']['productForm']['applicationForm']);

      // Find and set current default cashflow version
      if (formData != null) {
        formData.result.applicationForm.applicationTabs.map((tab) => {
          if (tab.name == 'Cash Flow') {
            tab.fields.map((field) => {
              if (field.code == CASHFLOW_ATTACHMENT) {
                setDefaultCashflow(field.value)
              }
            })
          }
        });
      }
      
      approvalInfo =
        formData.result.applicationForm.approvalInfo &&
        formData.result.applicationForm.approvalInfo;

      setCustomerId(formData.result.customerId ? formData.result.customerId : '')

      if (formStructure != null && formData != null) {
        for (
          let od = 0;
          od < formStructure.result.productForm.applicationForm.length;
          od++
        ) {
          let dataApplicationForm = formStructure.result.productForm.applicationForm[od];
          for (
            let id = 0;
            id < dataApplicationForm.applicationTemplateTabFields.length;
            id++
          ) {
            for (
              let pd = 0;
              pd < dataApplicationForm.applicationTemplateTabFields[id].fields.length;
              pd++
            ) {
              let dataCode =
                dataApplicationForm.applicationTemplateTabFields[id].fields[pd].field.code;
              let dataField =
                dataApplicationForm.applicationTemplateTabFields[id].fields[pd].field;
              if (
                dataApplicationForm.applicationTemplateTabFields[id].fields[pd].isRequired &&
                dataCode.includes('attch')
              )
                requiredDocuments.push(dataCode);
              function innerValueFieldLoop(dataCode, dataField) {
                for (
                  let ov = 0;
                  ov < formData.result.applicationForm.applicationTabs.length;
                  ov++
                ) {
                  let valueFields =
                    formData.result.applicationForm.applicationTabs[ov].fields;
                  for (let iv = 0; iv < valueFields.length; iv++) {
                    let valueCode = valueFields[iv].code;
                    if (dataCode == valueCode) {
                      dataField.value = valueFields[iv].value;
                      valueFields.splice(iv, 1);
                      return;
                    }
                  }
                }
              }
              innerValueFieldLoop(dataCode, dataField);
            }
          }
        }
      }

      formStructure
        ? setApplicationFormData(formStructure)
        : setIsFetchingFailed(true);
      formData && setApplicationFormValues(formData);
      formStructure
        ? setNumTabs(formStructure.result.productForm.applicationForm.length - 1)
        : setNumTabs(0);

      // consolidate all fields in one variable
      if(formStructure && formStructure.result.productForm && formStructure.result.productForm.applicationForm 
        && formStructure.result.productForm.applicationForm.length){
        const appFields = formStructure.result.productForm.applicationForm.map(formField => {
          if(formField.name != "Application Information"){
            const returnApp = formField.applicationTemplateTabFields.map(tabField => {
              const returnTab = tabField.fields.map(field => {
                field.parentCode = tabField.code;
                return field;
              });
              let listTabFields = [];
              returnTab.forEach(tab => {
                listTabFields = listTabFields.concat(tab);
              })
              return listTabFields
            });
            let listAppFields = [];
            returnApp.forEach(app => {
              listAppFields = listAppFields.concat(app);
            });
            return listAppFields;
          } else {
            return [];
          }
        });
        if(appFields.length > 0){
          globalApplicationFields = [];
          appFields.forEach(appField => {
            globalApplicationFields = globalApplicationFields.concat(appField);
          })
        }

      }
      setLoadedApplicationFormData(true);

      appSummary = await LoanService.getApplicationSummary(
        props.application.referenceId
      );
      appSignature = await LoanService.getApplicationSignature(
        props.application.referenceId
      );
      let resRemark = await LoanService.getRemarks(
        props.application.referenceId
      );
      resRemark ? setRemarksData(resRemark) : setIsFetchingFailed(true);
      let downloadablePDF = await LoanService.getDownloadablePDF(
        props.application.referenceId
      );
      downloadablePDF &&
        downloadablePDF.result &&
        downloadablePDF.result.value &&
        setDlPDF(downloadablePDF.result.value);
    } else {
      setIsFetchingFailed(true);
      setLoanProductAvailable(false);
      if (
        appStatus &&
        appStatus.result &&
        appStatus.result.status != 'ARCHIVED'
      ) {
        setConfirmArchiveDialog(true);
      }
    }
    setGettingData(false);
  }

  async function renderImage(
    fileName,
    isThumbnail,
    lastSavedByte,
    lastSavedBase64
  ) {
    let base64 = {};
    let savedBase64 = lastSavedBase64 ? lastSavedBase64 : '';
    let savedByte = lastSavedByte ? lastSavedByte : 0;
    let data;
    setRetrievingFailed(false);
    !isThumbnail && setGettingFullAttch(true);
    data = await LoanService.getApplicationAttachments(
      props.application.referenceId,
      fileName,
      isThumbnail,
      false, 
      savedByte
    );

    // Do not delete commented console logs. Will be used for future debugging
    if (data && !data.code) {
      // Endpoint execution completed
      base64.data = data;
      if (savedBase64) {
        base64.data.result.value = savedBase64 + data.result.value;
        setLastBase64('');
      }
    } else {
      // Endpoint timed out
      setRetrievingFailed(true);
      if (data && data.partialData) {
        setLastBase64(savedBase64 + data.partialData.split('value":"')[1]);
        setLastByte(
          savedBase64.length + data.partialData.split('value":"')[1] &&
            data.partialData.split('value":"')[1].length
        );
      } else {
        setLastBase64(savedBase64);
        setLastByte(savedBase64.length);
      }
    }

    if (openPreview) {
      setOpenPreview(false);
      setTimeout(() => {
        setOpenPreview(true);
      }, 40);
    }

    isThumbnail
      ? (attachmentThumbnails[`${fileName}`] = base64.data)
      : (attachments[`${fileName}`] =
          base64.data &&
          base64.data.result &&
          base64.data.result.value &&
          base64.data.result.value);
    setPreviewAttachments(attachments);
    !isThumbnail && setGettingFullAttch(false);

    return base64;
  }

  async function submitApp(data) {
    setSubmittingApp(true);

    // get cashflow version from list if page is newly loaded, else set as current version
    let cashflowVersion;
    if(cashFlowVersion == 0 && cashFlowList && cashFlowList.length > 0){
      let currentCashflow = cashFlowList.filter(row => row.hash == defaultCashflow);
      cashflowVersion = currentCashflow.length > 0 ? currentCashflow[0].version : 0;
    } else {
      cashflowVersion = cashFlowVersion;
    }
    let res = await LoanService.submitApplication(
      props.application.referenceId, cashflowVersion
    );
    let appStatus = await LoanService.getApplicationStatus(
      props.application.referenceId
    );
    if (appStatus && appStatus.result && appStatus.result.status) {
      setApplicationStatus(appStatus.result.status);
    }
    setSubmittingApp(false);
    setConfirmSubmitDialog(false);
    if ((res && res.status != 200) || res == undefined) {
      setErrorMessage(res && res.message);
      setSubmissionSuccess(false);
      setSnackbarState(true);
    } else {
      setSubmissionSuccess(true);
      setSnackbarState(true);
    }
    setSubmittingApp(false);
  }

  async function archiveApp() {
    let logObject = {
      referenceId: props.application.referenceId,
      status: 'ARCHIVED',
      dateTime: moment().tz('Asia/Singapore').utc().format(),
      remarks: 'NONE',
    };
    setArchivingApp(true);
    let res = await LoanService.setLog(logObject);
    setArchivingApp(false);
    setConfirmArchiveDialog(false);
    if (
      (res && res.status != 200 && res && res.status != 201) ||
      res == undefined
    ) {
      setErrorMessage(res && res.message);
      setArchiveSuccess(false);
      setSnackbarArchiveState(true);
    } else {
      setArchiveSuccess(true);
      setSnackbarArchiveState(true);
    }
    setSubmittingApp(false);
  }

  function addSubsection(fieldCode) {
    if (fieldCode.code == 'applicLoanTerm') {
      loanTerm = fieldCode.value == '' ? 'N/A' : fieldCode.value;
    } else if (fieldCode.code == 'applicLoanPurposeD') {
      purposeD = fieldCode.value == '' ? 'N/A' : fieldCode.value;
    } else if (fieldCode.code == 'applicPaymentAcc') {
      paymentAcc = fieldCode.value == '' ? 'N/A' : fieldCode.value;
    } else if (fieldCode.code == 'personFirstName') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          PERSONAL INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'addressLine1') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          ADDRESS INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'companyEmpDesig') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          OTHER INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'personTaxReference') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          GOVERNMENT IDs
        </div>
      );
    } else if (fieldCode.code == 'personSpouseFirstName') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          SPOUSE'S PERSONAL INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'applicAmount') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          LOAN INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'companyEmpName') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          BUSINESS INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'companyLine1') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          BUSINESS ADDRESS INFORMATION
        </div>
      );
    } else if (fieldCode.code == 'personDepInElementary') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          EDUCATION
        </div>
      );
    } else if (fieldCode.code == 'idUMID') {
      return (
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          ID TYPE
        </div>
      );
    } else {
      return null;
    }
  }

  if (!initialized) {
    getData();
    // setApplicationFormData(MockStubDatav2) // Uncomment this line and comment the line above to use mock data
    setApplicationFormValues(MockStubValue);
    setInitialized(true);
    getRequiredField();
    getMinMaxValue();
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Indexes
    // -1 = Business Documents Tab
    // 0 = Selfie Tab
    // 2 = Logs tab
    if (
      tabsWithAttachment.includes(newValue + 1) ||
      event.target.toString().includes('Business Documents')
    ) {
      getAttachmentThumbnails();
    }
    if (newValue == numTabs) {
      getSelfieImage();
    }
    if (newValue == numTabs + 2) {
      getApplicationLogs();
    }
  };

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        ref={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        format="###-###-###-###"
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  function NumberFormatCustom2(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        ref={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        format="##-#######-#"
      />
    );
  }

  NumberFormatCustom2.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const validateField = (name, value, maxLength, max, min, type) => {
    const required = requiredFieldList;
    const found = required.find((field) => field === name);
    const regexMobile = /^[0-9]{10}$/;
    const regexTIN = /^[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{3}$/;
    const regexSSS = /^[0-9]{2}-[0-9]{7}-[0-9]$/;
    const regexGSIS = /^[0-9]{11}$/;
    const regexAlphabet = /^[a-zA-Z ]*$/;
    const regexAdressLine1 = /^[a-zA-Z0-9 ]*$/;
    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (found && (value === '' || value == null)) {
      setrequiredFieldStat(found);
      setValidationType(true);
      pushToValidationList(name);
      pushToValidationMessage('required');
      setInvalidFieldCount(0);

      return true;
    } else if (mobileNumberFields.includes(name) &&
      !regexMobile.test(value)) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      pushToValidationMessage('mobile');
      return 'mobile'
    }
    else if (
      tinFields.includes(name) &&
      regexTIN.test(value) == false
    ) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      pushToValidationMessage('tin');
      setSnackBarMessage('TIN format should be 123-123-123-123.');
      return 'tin';
    } else if (sssFields.includes(name) && regexSSS.test(value) == false) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      setSnackBarMessage('SSS format should be ##-#######-#.');
      pushToValidationMessage('sss');
      return 'sss';
    } else if (gsisFields.includes(name) && regexGSIS.test(value) == false) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      setSnackBarMessage('GSIS format should be 11 numeric characters.');
      pushToValidationMessage('gsis');
      return 'gsis';
    } else if (alphabeticFields.includes(name) && regexAlphabet.test(value) == false) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      pushToValidationMessage('alphabetic');
      return 'alphabetic';
    } else if (alphanumericFields.includes(name) && regexAdressLine1.test(value) == false) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      pushToValidationMessage('alphanumeric');
      return 'alphanumeric';
    } else if (max && value > max) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      return 'max';
    } else if (min && value < min) {
      setrequiredFieldStat(found);
      pushToValidationList(name);
      return 'min';
    } else if (type == 'Email' && regexEmail.test(value) == false) {
      setrequiredFieldStat(found);
      setInvalidFieldCount(invalidFieldCount + 1);
      pushToValidationList(name);
      pushToValidationMessage('email');
      return 'email';
    } else {
      setrequiredFieldStat(false);
      removeFromValidationList(name);
      setInvalidFieldCount(0);
      removeValidationMessage();
      return false;
    }
  };

  async function setDefaultCashflowVersion(version, hash) {
    setSettingDefaultCashflow(true);
    const res = await LoanService.setDefaultCashflow(props.application.referenceId, { version });
    setSettingDefaultCashflow(false);
    if (res && res.result && res.result.success) {
      setCashFlowVersion(version)
      setDefaultCashflow(hash)
      getStatus();
    } else setSnackbarSetDefaultCashflowState(true);
  }

  const handleMaxLength = (maxLength) => {
    if (maxLength) {
      return maxLength;
    }
  };

  const handleDataType = (valueType) => {
    if (valueType == 'Integer' || valueType == 'float') {
      return 'number';
    }
  };

  const pushToValidationList = (code) => {
    const list = validationList;
    if (!list.includes(code)) {
      list.push(code);
    }
    setValidationList(list);
  };

  const removeFromValidationList = (code) => {
    const list = validationList;
    const newList = list.filter((item) => item !== code);
    setValidationList(newList);
  };

  const pushToValidationMessage = (code) => {
    const list = validationMessage;
    if (!list.includes(code)) {
      list.push(code);
    }
    setValidationMessage(list);
  };

  const removeValidationMessage = () => {
    const list = validationMessage;
    list.pop();
    setValidationMessage(list);
  };

  const convertDataType = (value, type) => {
    if (type === 'Integer') {
      value = parseInt(value);
      return value;
    } else if (type === 'Float') {
      value = parseFloat(value);
      return value;
    } else {
      return value;
    }
  };

  const handleSnackBarMessage = () => {
    const msgType = last(validationMessage);

    let message;
    switch (msgType) {
      case 'required':
        message = 'Missing required field!';
        break;
      case 'mobile':
        message = 'Please key in your 10-digit mobile number using this format 9XXXXXXXXX';
        break;
      case 'tin':
        message = 'TIN format should be 123–123–123–123.';
        break;
      case 'sss':
        message = 'SSS format should be 12–1234567–1.';
        break;
      case 'gsis':
        message = 'GSIS format should be 11 numeric characters';
        break;
      case 'email':
        message = 'Email format is invalid.';
        break;
      case 'alphabetic':
        message = 'Please use only letters (A to Z) and spaces for this field.';
        break;
      case 'email':
        message = 'Please use only letters (A to Z), spaces, and numbers (0 to 9) for this field.';
        break;

      default:
        message = '';
    }
    setSnackBarMessage(message);
  };

  const handleValidationMessage = (value, field, max, min, maxLength) => {
    if (value) {
      if (value == true) {
        return 'This field is required.';
      } else if (value == 'length') {
        return `${field} length should be equal or below ${maxLength}`;
      } else if (value == 'mobile') {
        return 'Please key in your 10-digit mobile number using this format 9XXXXXXXXX.';
      } else if (value == 'tin') {
        return 'TIN format should be 123-123-123-123.';
      } else if (value == 'sss') {
        return 'SSS format should be 12–1234567–1.';
      } else if (value == 'gsis') {
        return 'GSIS format should be 11 numeric characters.';
      } else if (value == 'alphabetic') {
        return `Please use only letters (A to Z) and spaces for this field.`;
      } else if (value == 'alphanumeric') {
        return `Please use only letters (A to Z), spaces, and numbers (0 to 9) for this field.`;
      } else if (value == 'max') {
        return `Maximum ${field} should be ${max}`;
      } else if (value == 'min') {
        return `Minimum ${field} should be ${min}`;
      } else if (value == 'email') {
        return `Email format is invalid.`;
      } else {
        return '';
      }
    }
  };

  async function getRequiredField() {
    let appProdId = await LoanService.getApplicationProductID(
      props.application.referenceId
    );
    const form = await LoanService.getStubData(
      appProdId && appProdId.result && appProdId.result.productId
    );
    const fields = form && form.result.applicationForm;
    const required = await ValidationService.getRequiredFields(fields);
    setRequiredFieldList(required);
  }

  async function getMinMaxValue() {
    let appProdId = await LoanService.getApplicationProductID(
      props.application.referenceId
    );
    const form = await LoanService.getStubData(
      appProdId && appProdId.result && appProdId.result.productId
    );
    const fields = form && form.result.applicationForm;
    const values = await ValidationService.getMinMaxValue(fields);
    setMaxValue(values.max);
    setMinValue(values.min);
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setRemark(value);
  };

  const submitRemark = () => {
    if (remark != '') {
      let userObj = userData;
      let remarkObject = {
        referenceId: props.application.referenceId,
        remarks: remark,
        dateTime: moment(),
        userId: userObj.userInfo.id,
        username: userObj.userInfo.firstName + ' ' + userObj.userInfo.lastName,
      };

      LoanService.postRemarks(remarkObject);
      setRemark('');
      getData();
    } else {
      window.alert('cannot submit an empty remark');
    }
  };

  const renderRemarks = () => {
    let sortedRemarks;
    sortedRemarks = remarksData
      ? remarksData.result.sort(
          (a, b) =>
            new moment(a.dateTime).format('YYYYMMDD') -
            new moment(b.dateTime).format('YYYYMMDD')
        )
      : '';
    const sortedRemark = orderBy(sortedRemarks, 'dateTime', 'desc');

    return sortedRemark ? (
      sortedRemark.map((item) => (
        <TableRow
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            borderColor: 'white',
          }}
        >
          <StyledTableCell style={{ borderBottom: 0, fontSize: 12 }}>
            <div style={{wordBreak: 'break-word'}}>{item.remarks}</div>
          </StyledTableCell>
          <StyledTableCell style={{ borderBottom: 0, fontSize: 12 }}>
            {moment(item.dateTime).format('YYYY-DD-MM hh:mm:ss A')}
          </StyledTableCell>
          <StyledTableCell style={{ borderBottom: 0, fontSize: 12 }}>
            {item.user}
          </StyledTableCell>
        </TableRow>
      ))
    ) : (
      <TableRow
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
        }}
      >
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>No remarks for this application</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    );
  };

  const renderModal = (base64, fieldCode) => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {previewAttachments &&
        previewAttachments[`${fieldCode}`] &&
        previewAttachments[`${fieldCode}`].split(',')[0] !=
          'data:image/pdf;base64' &&
        previewAttachments &&
        previewAttachments[`${fieldCode}`] &&
        previewAttachments[`${fieldCode}`].split(',')[0] !=
          'data:application/pdf;base64' ? (
          <img
            src={previewAttachments[`${fieldCode}`]}
            width="80%"
            height="80%"
            style={{ width: '80vh', border: 'none', height: '80vh' }}
            alt={'Preview Unavailable'}
          />
        ) : (previewAttachments &&
            previewAttachments[`${fieldCode}`] &&
            previewAttachments[`${fieldCode}`].split(',')[0] ==
              'data:image/pdf;base64') ||
          (previewAttachments &&
            previewAttachments[`${fieldCode}`] &&
            previewAttachments[`${fieldCode}`].split(',')[0] ==
              'data:application/pdf;base64') ? (
          <PDFDocumentWrapper2
            style={{
              objectFit: 'cover',
              width: '80vh',
              border: 'none',
              height: '80vh',
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 10,
                backgroundColor: '#E0E0E0',
                right: 10,
                bottom: 10,
              }}
            >
              <Button
                style={{ fontFamily: 'Inter' }}
                href={previewAttachments[`${fieldCode}`]}
                download={props.application.referenceId + '_' + fieldCode}
              >
                <MdIcon path={mdiDownload} size="1.5em" />
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 10,
                backgroundColor: '#E0E0E0',
                right: '36%',
                bottom: 10,
                opacity: 0.7,
              }}
            >
              <Button
                style={{ fontFamily: 'Inter' }}
                disabled={numPages == 1 || pageNumber == 1}
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              >
                {'<'}
              </Button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Page {pageNumber} of {numPages}
              </div>
              <Button
                style={{ fontFamily: 'Inter' }}
                disabled={numPages == 1 || pageNumber == numPages}
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              >
                {'>'}
              </Button>
            </div>
            <Document
              style={{ objectFit: 'cover' }}
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
              file={previewAttachments[`${fieldCode}`]}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </PDFDocumentWrapper2>
        ) : retrievingFailed ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80vh',
              height: '80vh',
              fontFamily: 'Inter',
            }}
          >
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vh',
                height: '80vh',
              }}
            >
              Failed to retrieve attachment
              <Button
                variant="outlined"
                style={{
                  marginTop: 16,
                  backgroundColor: '#F47721',
                  color: '#FFFFFF',
                  fontFamily: 'Inter',
                }}
                onClick={() =>
                  renderImage(fieldCode, false, lastByte, lastBase64)
                }
              >
                Retry
              </Button>
            </Paper>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80vh',
              height: '80vh',
              fontFamily: 'Inter',
            }}
          >
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vh',
                height: '80vh',
              }}
            >
              Loading attachment...
              <LinearProgress
                className={classes.root}
                style={{ width: '50%', marginTop: 12 }}
              />
            </Paper>
          </div>
        )}
      </div>
    );
  };

  const renderSelfie = () => {
    return (
      <div
        style={{
          flexBasis: '90%',
          marginBottom: 12,
          fontFamily: 'Inter',
          fontSize: 14,
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #C4C4C4',
            flexBasis: '100%',
            fontFamily: 'Inter',
            color: '#F5922F',
            fontSize: 12,
            fontWeight: 'bold',
            height: 25,
            marginBottom: 16,
          }}
        >
          SELFIE
        </div>
        <Dropzone
          onDrop={(acceptedFiles) => onDropHandler(acceptedFiles, 0)}
          disabled
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Paper
                  style={{
                    width: '100%',
                    height: 296,
                    backgroundColor: '#F6F6F6',
                  }}
                >
                  {screenIndex == 0 &&
                  selfieBase64 &&
                  selfieBase64.split(',')[0] != 'data:image/pdf;base64' &&
                  selfieBase64 &&
                  selfieBase64.split(',')[0] !=
                    'data:application/pdf;base64' ? (
                    <div
                      style={{
                        backgroundColor: '#000000',
                        position: 'relative',
                        height: 296,
                      }}
                    >
                      <img
                        src={selfieBase64}
                        alt={'Selfie'}
                        style={{
                          width: '100%',
                          height: 296,
                          objectFit: 'cover',
                          opacity: 0.5,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedBase64(selfieBase64);
                          setSelectedField('selfie');
                          getAttachment('selfie', false);
                          setOpenPreview(true);
                        }}
                      />
                      <div
                        style={{
                          color: '#FFFFFF',
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedBase64(selfieBase64);
                          setSelectedField('selfie');
                          getAttachment('selfie', false);
                          setOpenPreview(true);
                        }}
                      >
                        <MdIcon
                          path={mdiMagnifyPlusOutline}
                          size="2.5em"
                          color="#FFFFFF"
                          style={{ width: '100%' }}
                        />
                        <div style={{ fontSize: 16, lineHeight: '24px' }}>
                          Click to Enlarge
                        </div>
                      </div>
                    </div>
                  ) : (selfieBase64 &&
                      selfieBase64.split(',')[0] == 'data:image/pdf;base64') ||
                    (selfieBase64 &&
                      selfieBase64.split(',')[0] ==
                        'data:application/pdf;base64') ? (
                    <div
                      style={{
                        backgroundColor: '#000000',
                        position: 'relative',
                        height: 296,
                      }}
                    >
                      <PDFDocumentWrapper style={{ objectFit: 'cover' }}>
                        <Document
                          style={{ objectFit: 'cover' }}
                          onLoadError={console.error}
                          file={selfieBase64}
                          width={'100%'}
                          height={296}
                          onClick={() => {
                            setSelectedBase64(selfieBase64);
                            setSelectedField('selfie');
                            getAttachment('selfie', false);
                            setOpenPreview(true);
                          }}
                        >
                          <Page pageNumber={1} />
                        </Document>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            position: 'absolute',
                            alignItems: 'center',
                            textAlign: 'center',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            cursor: 'pointer',
                            width: '100%',
                            height: 296,
                            backgroundColor: '#000000',
                            opacity: 0.5,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedBase64(selfieBase64);
                            setSelectedField('selfie');
                            getAttachment('selfie', false);
                            setOpenPreview(true);
                          }}
                        >
                          <MdIcon
                            path={mdiMagnifyPlusOutline}
                            size="2.5em"
                            color="#FFFFFF"
                            style={{ width: '100%' }}
                          />
                          <div style={{ fontSize: 16, lineHeight: '24px' }}>
                            Click to Enlarge
                          </div>
                        </div>
                      </PDFDocumentWrapper>
                    </div>
                  ) : !selfieBase64 ||
                    selfieBase64 == '' ||
                    selfieBase64 == undefined ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: 12,
                        alignItems: 'center',
                        width: '100%',
                        height: 296,
                      }}
                    >
                      <div>
                        {gettingSelfie ? (
                          !selfieBase64 ? (
                            <MdIcon
                              path={mdiAutorenew}
                              size="2.2em"
                              color="#F47721"
                              spin={1.5}
                              style={{ marginBottom: 4 }}
                            />
                          ) : (
                            <img
                              src={icon_previewUnavailable}
                              style={{ marginBottom: 4 }}
                            />
                          )
                        ) : (
                          <img
                            src={icon_noDocumentUploaded}
                            style={{ marginBottom: 4 }}
                          />
                        )}
                      </div>
                      {gettingSelfie
                        ? !selfieBase64
                          ? 'Loading attachment...'
                          : 'Preview unavailable'
                        : 'No Selfie Uploaded'}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: 12,
                        alignItems: 'center',
                        width: '100%',
                        height: 296,
                      }}
                    >
                      <div>
                        <img
                          src={icon_previewUnavailable}
                          style={{ marginBottom: 4 }}
                        />
                      </div>
                      Attachment Unavailable
                    </div>
                  )}
                </Paper>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function renderLazyLoadField(height, width) {
    return (
      <ContentLoader
        speed={1.5}
        width={width ? width : 170}
        height={height ? height : 14}
        viewBox={`0 0 ${width ? width : 170} ${height ? height : 14}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#9e9e9e"
      >
        <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
      </ContentLoader>
    );
  }

  async function constructModifiedJSONData(data) {
    // Remove attachments that are no longer rendered
    if (attachmentsToRemove.length > 0) {
      attachmentsToRemove.map(async (attch) => {
        if (attch) {
          const res = await LoanService.deleteAttachment(props.application.referenceId, attch)
          const attchIndex = attachmentNames.indexOf(attch);
          attachmentNames.splice(attchIndex, 1);
          await renderImage(attch, true);
        }
      });
     
    }
    
    let appData;
    let modifiedDataObj = {
      applicationTabs: [],
    };

    if (data) appData = data;
    else appData = globalModifiedFormData;

    // Iterate on application tabs - attachments
    let attachmentFields = [];
    JSON.parse(JSON.stringify(appData)).filter(tab => tab.type == "attachment")
      .map((appTab) => {
        // Iterate on tab fields
        attachmentFields = attachmentFields.concat(appTab.applicationTemplateTabFields[0] && appTab.applicationTemplateTabFields[0].fields && appTab.applicationTemplateTabFields[0].fields.map((tabField) => {
          // check render logic, dont include node if render value not satisfied
          let render = false;

          if(tabField.field && tabField.field.render && tabField.field.render.fields.length > 0){
            render = checkRender(tabField.field.render.fields[0]) == 'visible';
          }
          // Construct field objects and filter out only code and value
          const fieldObj = {
            code: tabField.field.code,
            value: tabField.field.value ? tabField.field.value : '',
          };
          return render ? fieldObj : null;
        }).filter((tabField) => tabField));

        if (appTab.name != 'Cash Flow') {
          modifiedDataObj.applicationTabs.push({
            id: 0,
            name: 'Attachments',
            type: 'attachments',
            fields: attachmentFields
          });
        } else { // Append cashflow tab as it is
          const cashflowField = appTab.applicationTemplateTabFields[0].fields[0].field;
          const fieldObj = {
            code: cashflowField.code,
            value: defaultCashflow ? defaultCashflow : '',
          };
          modifiedDataObj.applicationTabs.push({
            id: appTab.id,
            name: appTab.name,
            type: 'attachments',
            fields: appTab.applicationTemplateTabFields[0].fields.length == 0 ? [] : [fieldObj]
          });
        }
      });
    
    // Iterate on application tabs - non attachments
    JSON.parse(JSON.stringify(appData)).filter(tab => tab.type != "attachment")
      .map((appTab, index) => {
        // Iterate on template tab fields
        let finalFields = [];
        JSON.parse(JSON.stringify(appTab.applicationTemplateTabFields)).map((tabField, index) => {
          // Iterate on tab fields
          const res = tabField.fields.map((field) => {
            const code = JSON.parse(JSON.stringify(field.field.code));
            let fieldObj = {};
              let value = '';
  
              // Added handling of field without value node
              // This prevents the bug where clicking save button does not trigger the save function
              if (!(field.field.dataType === 'Integer'|| 
              field.field.dataType === 'Float') && field.field.value) {
                value = JSON.parse(JSON.stringify(field.field.value));
              } else if((field.field.dataType === 'Integer'|| 
              field.field.dataType === 'Float')) {
                value = field.field.value;
              }

              // Construct field objects and filter out only code and value
              fieldObj = {
                code,
                value: value,
              };
              if(appTab.name != "Application Information"){
                let render = false;
                if(field.field && field.field.render && field.field.render.fields.length > 0){
                  render = checkRender(field.field.render.fields[0]) == 'visible';
                  if(render){
                    finalFields.push(fieldObj);
                  }
                } else {
                  finalFields.push(fieldObj);
                }
              } else {
                finalFields.push(fieldObj);
              }

            return fieldObj;
          });
          return res;
        });
        modifiedDataObj.applicationTabs.push({
          id: appTab.id,
          name: appTab.name,
          type: appTab.type,
          fields: finalFields,
        });
      });
    return modifiedDataObj;
  }

  async function editApplication() {
    let requestObj = await constructModifiedJSONData(globalModifiedFormData);
    let attchToRemoveList = attachmentsToRemove;

    setSavingData(true);
    setSaveDialogOpen(true);
    setEditSuccess(false);
    attchToRemoveList.length = 0;
    attachmentsToRemove = attchToRemoveList;
    let res = await LoanService.editApplication(
      props.application.referenceId,
      requestObj
    );
    if (!res) {
      setSnackbarEditState(true);
    } else if (res.status == 200) {
      setEditSuccess(true);
      setSnackbarEditState(true);
      setModifiedData('');
      setIsReadOnly(true);
      await getStatus();
    } else {
      setSnackbarEditState(true);
    }
    
    setSavingData(false);
    setSaveDialogOpen(false);
  }

  async function editApplicationAttachment(data, action, success) {
    let res;

    if (success) {
      if (action == 'UPLOAD') {
        if (!attachmentUploadFail) {
          res = await LoanService.editApplication(
            props.application.referenceId,
            data
          );
          attachmentNames.push(data.fieldCode);
          setGettingAttachment(true);
          await renderImage(data.fieldCode, false);
          setGettingAttachment(false);
        }
  
        setSnackbarUploadState(true);
        setUploadingDialogOpen(false);
  
        if (!res) setUploadSuccess(false);
        else setUploadSuccess(true);
  
        setUploadingFile(false);
        await getStatus();
      }
  
      if (action == 'DELETE') {
        setSnackbarDeleteState(true);
        setDeleteDialogOpen(false);
  
        if (!res) setDeleteSuccess(false);
        else setDeleteSuccess(true);
  
        setDeletingFile(false);
        setDeleteEmitted(false);
      }
    } else {
      attachmentUploadFail = true;
      remove(attachmentNames, selectedAttachment);
      failedAttachments.push(selectedAttachment);
      setSnackbarUploadState(true);
      setUploadingDialogOpen(false);
      setUploadSuccess(false);
      setUploadingFile(false);
    }
    }
    

  const saveApplication = async (event) => {
    event.preventDefault();
    handleSnackBarMessage();
    
    if (validationList.length == 0) {
      await editApplication();
    } else {
      setSnackbarRequiredFieldState(true);
    }

    return false;
  }

  const toggleFormSelector = () => {
    setIsOpenFormSelector(!isOpenFormSelector);
  }

  const handleCreateCashflow = (id) => {
    history.push(Paths.CASHFLOW_ANALYSIS.replace(':id', `${id}?redirected=true`));
    props.setTick(Math.random());
  }

  const handleNoForms = (id) => {
    setNoCashflowForms(true);
  }

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  return (
    <div style={{ margin: 20, fontFamily: 'Inter' }}>
      <form onSubmit={saveApplication}>
      <Modal
        open={openPreview}
        modal={gettingAttachment}
        onClose={() => {
          if (!gettingFullAttch) {
            setOpenPreview(false);
            setLastByte(0);
            setLastBase64('');
          }
        }}
      >
        {renderModal(selectedBase64, selectedField)}
      </Modal>
      <Dialog
        onClose={() => setConfirmSubmitDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={confirmSubmitDialog || submittingApp}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '24px 32px 24px 32px',
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 20,
              fontWeight: 600,
              lineHeight: '24px',
              fontFamily: 'Inter',
              marginBottom: 24,
            }}
          >
            Submit to Lending Platform
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 16,
              fontWeight: 'normal',
              lineHeight: '24px',
              fontFamily: 'Inter',
              marginBottom: 48,
            }}
          >
            Make sure all information is complete before you submit this
            application to the Lending Platform. Do you want to continue?
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: '#FFFFFF',
                color: submittingApp ? '#C4C4C4' : '#F47721',
                width: 145,
                marginRight: 10,
                borderColor: submittingApp ? '#C4C4C4' : '#F47721',
                textTransform: 'none',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                setConfirmSubmitDialog(false);
              }}
              disabled={submittingApp}
            >
              Cancel
            </Button>
            <Button
              variant={submittingApp ? 'outlined' : 'contained'}
              style={{
                backgroundColor: submittingApp ? '#FFFFFF' : '#F47721',
                color: '#FFFFFF',
                width: 145,
                borderColor: submittingApp ? '#C4C4C4' : '#F47721',
                textTransform: 'none',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                submitApp(applicationFormData);
              }}
              disabled={submittingApp}
            >
              {submittingApp ? (
                <CircularProgress size={20} style={{ color: '#F47721' }} />
              ) : (
                'Proceed'
              )}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        onClose={() => setConfirmArchiveDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={confirmArchiveDialog || archivingApp}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '24px 32px 24px 32px',
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 20,
              fontWeight: 600,
              lineHeight: '24px',
              fontFamily: 'Inter',
              marginBottom: 24,
            }}
          >
            Archive this Loan Application
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 16,
              fontWeight: 'normal',
              lineHeight: '24px',
              fontFamily: 'Inter',
              marginBottom: 48,
            }}
          >
            This loan product is no longer available. Would you like to archive
            this application?
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: '#FFFFFF',
                color: archivingApp ? '#C4C4C4' : '#F47721',
                width: 145,
                marginRight: 10,
                borderColor: archivingApp ? '#C4C4C4' : '#F47721',
                textTransform: 'none',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                props.setApplication('');
                if (props.navHistory == 'logs') {
                  history.push(Paths.LOGS);
                  props.setNavHistory('');
                } else if (props.navHistory == 'customerprofile') {
                  history.push(Paths.CUSTOMER_PROFILE + '/' + customerId);
                  props.setNavHistory('');
                } else {
                  history.push(Paths.LOAN_APPLICATIONS);
                }
              }}
              disabled={archivingApp}
            >
              Go back
            </Button>
            <Button
              variant={archivingApp ? 'outlined' : 'contained'}
              style={{
                backgroundColor: archivingApp ? '#FFFFFF' : '#F47721',
                color: '#FFFFFF',
                width: 145,
                borderColor: archivingApp ? '#C4C4C4' : '#F47721',
                textTransform: 'none',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                archiveApp();
              }}
              disabled={archivingApp}
            >
              {archivingApp ? (
                <CircularProgress size={20} style={{ color: '#F47721' }} />
              ) : (
                'Yes'
              )}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={saveDialogOpen}
        disableBackdropClick={savingData}
        onClose={() => {
          setSaveDialogOpen(false);
        }}
      >
        <DialogTitle>Saving loan application</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText>
            <div>
              <LinearProgress className={classes.root} />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateDialogOpen}
        disableBackdropClick={updatingData}
        onClose={() => {
          setUpdateDialogOpen(false);
        }}
      >
        <DialogTitle>Updating loan application</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText>
            <div>
              <LinearProgress className={classes.root} />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={uploadingDialogOpen}
        disableBackdropClick={uploadingFile}
        onClose={() => {
          setUploadingDialogOpen(false);
        }}
      >
        <DialogTitle>Uploading File</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 70,
            }}
          >
            <div>
              <LinearProgress
                className={classes.root}
                variant="indeterminate"
                // todo
                // value={uploadProgress}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                disabled={true}
                onClick={() => {
                  setUploadingDialogOpen(false);
                }}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: uploadingFile ? '#C4C4C4' : '#F47721',
                  width: 145,
                  borderColor: uploadingFile ? '#C4C4C4' : '#F47721',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        disableBackdropClick={deletingFile}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 90,
            }}
          >
            <div>{texts.confirmationDeleteDocument}</div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                disabled={deletingFile}
                onClick={() => {
                  setDeleteDialogOpen(false);
                }}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: deletingFile ? '#C4C4C4' : '#F47721',
                  width: 145,
                  marginRight: 10,
                  borderColor: deletingFile ? '#C4C4C4' : '#F47721',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={deletingFile}
                onClick={() => {
                  setDeleteDialogOpen(false);
                  setDeletingDialogOpen(true);
                  deleteFile();
                }}
                style={{
                  backgroundColor: deletingFile ? '#C4C4C4' : '#F47721',
                  color: '#FFFFFF',
                  width: 145,
                  borderColor: deletingFile ? '#C4C4C4' : '#F47721',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                Delete
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deletingDialogOpen}
        disableBackdropClick={deletingFile}
        onClose={() => {
          setDeletingDialogOpen(false);
        }}
      >
        <DialogTitle>Deleting File</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 70,
            }}
          >
            <div>
              <LinearProgress
                className={classes.root}
                variant="indeterminate"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                disabled={true}
                onClick={() => {
                  setDeletingDialogOpen(false);
                }}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: deletingFile ? '#C4C4C4' : '#F47721',
                  width: 145,
                  borderColor: deletingFile ? '#C4C4C4' : '#F47721',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={noCashflowForms}
        onClose={() => {
          setNoCashflowForms(false);
        }}
      >
        <DialogTitle style={{ fontFamily: 'Inter' }}>No available cashflow forms</DialogTitle>
        <DialogContent style={{ width: 522 }}>
          <DialogContentText
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 70,
            }}
          >
            <div style={{ fontFamily: 'Inter' }}>
              Cashflow form must be created first
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setNoCashflowForms(false);
                }}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#F47721',
                  width: 145,
                  borderColor: '#F47721',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                Confirm
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarSetDefaultCashflowState}
        autoHideDuration={6000}
        onClose={() => setSnackbarSetDefaultCashflowState(false)}
      >
        <Alert
          onClose={() => setSnackbarSetDefaultCashflowState(false)}
          severity={'error'}
        >
          Failed to set default cashflow version
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarReachedFileSizeLimit}
        autoHideDuration={6000}
        onClose={() => setSnackbarReachedFileSizeLimit(false)}
      >
        <Alert
          onClose={() => setSnackbarReachedFileSizeLimit(false)}
          severity={'error'}
        >
          <span>{texts.fileSizeLimitReached}</span>
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarUploadState}
        autoHideDuration={6000}
        onClose={() => setSnackbarUploadState(false)}
      >
        <Alert
          onClose={() => setSnackbarUploadState(false)}
          severity={uploadSuccess ? 'success' : 'error'}
        >
          {uploadSuccess ? (
            <span>{texts.successfulUpload}</span>
          ) : (
            <span>{texts.failedUpload}</span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarDeleteState}
        autoHideDuration={6000}
        onClose={() => setSnackbarDeleteState(false)}
      >
        <Alert
          onClose={() => setSnackbarDeleteState(false)}
          severity={deleteSuccess ? 'success' : 'error'}
        >
          {deleteSuccess ? (
            <span>{texts.successfulDelete}</span>
          ) : (
            <span>{texts.failedDelete}</span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={documentValidationState}
        autoHideDuration={6000}
        onClose={() => setDocumentValidationState(false)}
      >
        <Alert
          onClose={() => setDocumentValidationState(false)}
          severity={'error'}
        >
          <span>Please complete all required documents</span>
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarState}
        autoHideDuration={6000}
        onClose={() => setSnackbarState(false)}
      >
        <Alert
          onClose={() => setSnackbarState(false)}
          severity={submissionSuccess ? 'success' : 'error'}
        >
          {submissionSuccess ? (
            <span>
              You have successfully submitted this application to the Lending
              Platform for review
            </span>
          ) : (
            <span>
              An error has occurred while submitting this Loan Application.{' '}
              {errorMessage && `Reason: ${errorMessage}`}
            </span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarArchiveState}
        autoHideDuration={6000}
        onClose={() => setSnackbarArchiveState(false)}
      >
        <Alert
          onClose={() => setSnackbarArchiveState(false)}
          severity={archiveSuccess ? 'success' : 'error'}
        >
          {archiveSuccess ? (
            <span>The loan application has been successfully archived.</span>
          ) : (
            <span>
              The loan application failed to be archived.{' '}
              {errorMessage && `Reason: ${errorMessage}`}
            </span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarEditState}
        autoHideDuration={6000}
        onClose={() => setSnackbarEditState(false)}
      >
        <Alert
          onClose={() => setSnackbarEditState(false)}
          severity={editSuccess ? 'success' : 'error'}
        >
          {editSuccess ? (
            <span>Successfully saved loan application changes</span>
          ) : (
            <span>Failed to save loan application changes</span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarRequiredFieldState}
        autoHideDuration={6000}
        onClose={() => setSnackbarRequiredFieldState(false)}
      >
        <Alert
          onClose={() => setSnackbarRequiredFieldState(false)}
          severity={archiveSuccess ? 'success' : 'error'}
        >
          {archiveSuccess ? (
            <span>Please fill up required fields.</span>
          ) : (
            <span>
              {snackBarMessage}. {errorMessage && `Reason: ${errorMessage}`}
            </span>
          )}
        </Alert>
      </Snackbar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 600,
          fontSize: 28,
          lineHeight: '32px',
        }}
      >
        Review Application
        <div style={{ fontFamily: 'Inter' }}>
          <Button
            variant="outlined"
            style={{
              marginRight: 10,
              color: '#F47721',
              borderColor: '#F47721',
              textTransform: 'none',
              fontFamily: 'Inter',
            }}
            onClick={() => {
              props.setApplication('');
              if (props.navHistory == 'logs') {
                history.push(Paths.LOGS);
                props.setNavHistory('');
              } else if (props.navHistory == 'customerprofile') {
                history.push(Paths.CUSTOMER_PROFILE + '/' + customerId);
                props.setNavHistory('');
              } else {
                history.push(Paths.LOAN_APPLICATIONS);
              }
            }}
          >
            <MdIcon
              path={mdiArrowLeft}
              size="1.5em"
              color="#F47721"
              style={{ marginRight: 10 }}
            />
            Back
          </Button>
          <Button
            variant="outlined"
            style={{
              marginRight: 10,
              color: dlPDF == '' ? '#C4C4C4' : '#F47721',
              borderColor: dlPDF == '' ? '#C4C4C4' : '#F47721',
              textTransform: 'none',
              fontFamily: 'Inter',
            }}
            href={dlPDF}
            download={props.application.referenceId + '_ApplicationData'}
            disabled={dlPDF == ''}
          >
            <MdIcon
              path={mdiFileExportOutline}
              size="1.5em"
              color={dlPDF == '' ? '#C4C4C4' : '#F47721'}
              style={{ marginRight: 10 }}
            />
            Export
          </Button>
          {editableStatusLookup.includes(applicationStatus) ? (
            <Button
              variant="outlined"
              style={{
                marginRight: 10,
                color: modifiedData == '' ? '#C4C4C4' : '#F47721',
                borderColor: modifiedData == '' ? '#C4C4C4' : '#F47721',
                textTransform: 'none',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                // saveApplication();
              }}
              disabled={modifiedData == ''}
              type="submit"
            >
              <MdIcon
                path={mdiContentSave}
                size="1.5em"
                color={modifiedData == '' ? '#C4C4C4' : '#F47721'}
                style={{ marginRight: 10 }}
              />
              Save
            </Button>
          ) : null}
          <Button
            variant="contained"
            style={{
              backgroundColor:
                (applicationStatus != 'COMPLETED' &&
                  applicationStatus != 'FOR RESUBMISSION' &&
                  applicationStatus != 'FAILED') ||
                submittingApp
                  ? '#C4C4C4'
                  : '#F47721',
              color: '#FFFFFF',
              textTransform: 'none',
              fontFamily: 'Inter',
            }}
            onClick={() => {
              let validated = true;
              for (let attch of requiredDocuments) {
                if (
                  !attachmentNames.includes(attch) &&
                  applicationStatus != 'COMPLETED' &&
                  applicationStatus != 'FOR RESUBMISSION' &&
                  applicationStatus != 'FAILED'
                ) {
                  validated = false;
                }
              }
              if (validated) setConfirmSubmitDialog(true);
              else setDocumentValidationState(true);
            }}
            disabled={
              (applicationStatus != 'COMPLETED' &&
                applicationStatus != 'FOR RESUBMISSION' &&
                applicationStatus != 'FAILED') ||
              submittingApp
            }
          >
            Submit to Lending Platform
          </Button>
        </div>
      </div>
      <br />
      <Paper style={{ height: 276, marginBottom: 16, width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}
        >
          {applicationStatus == 'APPROVED' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '32px 32px 32px 64px',
                height: '70%',
              }}
            >
              <div>
                <div style={{ fontSize: 12 }}>Approved Loan Amount</div>
                <div
                  style={{ fontSize: 14, fontWeight: 600, color: '#17CA83' }}
                >
                  {approvalInfo.approvedAmount ? (
                    <NumberFormat
                      style={{ fontSize: 14, fontWeight: 600 }}
                      InputProps={{
                        disableUnderline: isReadOnly,
                      }}
                      value={approvalInfo.approvedAmount}
                      prefix={'PHP '}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      displayType={'text'}
                    />
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div>
                <div style={{ fontSize: 12 }}>Approved Tenor</div>
                <div
                  style={{ fontSize: 14, fontWeight: 600, color: '#17CA83' }}
                >
                  {approvalInfo.approvedTenor
                    ? approvalInfo.approvedTenor + ' months'
                    : !approvalInfo.approvedTenor && gettingData
                    ? renderLazyLoadField(
                        15,
                        Math.floor(Math.random() * (200 - 140 + 1) + 140)
                      )
                    : 'N/A'}
                </div>
              </div>
              <div>
                <div style={{ fontSize: 12 }}>Interest Rate</div>
                <div
                  style={{ fontSize: 14, fontWeight: 600, color: '#17CA83' }}
                >
                  {approvalInfo.approvedInterestRate
                    ? approvalInfo.approvedInterestRate + '%'
                    : !approvalInfo.approvedInterestRate && gettingData
                    ? renderLazyLoadField(
                        15,
                        Math.floor(Math.random() * (200 - 140 + 1) + 140)
                      )
                    : 'N/A'}
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '32px 32px 32px 64px',
              height: '70%',
            }}
          >
            <div>
              <div style={{ fontSize: 12 }}>Reference ID</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {props.application.referenceId}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Applicant Type</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>New Borrower</div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Requested Loan Amount</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                <NumberFormat
                  style={{ fontSize: 14, fontWeight: 600 }}
                  InputProps={{
                    disableUnderline: isReadOnly,
                  }}
                  value={props.application.requestedAmount}
                  prefix={'PHP '}
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                  displayType={'text'}
                />
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Term of Loan</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {loanTerm
                  ? loanTerm + ' months'
                  : !loanTerm && gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (200 - 140 + 1) + 140)
                    )
                  : 'N/A'}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '32px 32px 32px 64px',
              height: '70%',
            }}
          >
            <div style={{ marginBottom: 22 }}>
              <div style={{ fontSize: 12 }}>Loan Product</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {props.application.loanProduct}
              </div>
            </div>
            <div style={{ marginBottom: 22 }}>
              <div style={{ fontSize: 12 }}>Borrower Name</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {props.application.applicantName}
              </div>
            </div>
            <div style={{ marginBottom: 22 }}>
              <div style={{ fontSize: 12 }}>Purpose</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {purposeD
                  ? purposeD
                  : !purposeD && gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (200 - 140 + 1) + 140)
                    )
                  : 'N/A'}
              </div>
            </div>
            <div style={{ marginBottom: 22 }}>
              <div style={{ fontSize: 12 }}>Payment Account</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {paymentAcc
                  ? paymentAcc
                  : !paymentAcc && gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (200 - 140 + 1) + 140)
                    )
                  : 'N/A'}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '32px 64px 32px 32px',
              height: '70%',
            }}
          >
            <div style={{ marginBottom: 24 }}>
              <StatusDisplay status={applicationStatus} />
            </div>
            <div>
              <div style={{ fontSize: 12, marginBottom: 6 }}>Signature</div>
              <div
                style={{
                  width: 200,
                  height: 'auto',
                  maxHeight: 149,
                  backgroundColor: '#F6F6F6',
                }}
              >
                {appSignature &&
                screenIndex == 0 &&
                appSignature.split(',')[0] != 'data:image/pdf;base64' &&
                appSignature &&
                appSignature.split(',')[0] != 'data:application/pdf;base64' ? (
                  <img
                    src={appSignature}
                    style={{ width: '100%', height: 'auto', maxHeight: 149 }}
                  />
                ) : (appSignature &&
                    appSignature.split(',')[0] == 'data:image/pdf;base64') ||
                  (appSignature &&
                    appSignature.split(',')[0] ==
                      'data:application/pdf;base64') ? (
                  <div
                    style={{
                      backgroundColor: '#000000',
                      position: 'relative',
                      height: 'auto',
                      maxHeight: 149,
                    }}
                  >
                    <PDFDocumentWrapper style={{ objectFit: 'cover' }}>
                      <Document
                        style={{ objectFit: 'cover' }}
                        onLoadError={console.error}
                        file={appSignature}
                        width={'100%'}
                        height={'auto'}
                        maxHeight={149}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </PDFDocumentWrapper>
                  </div>
                ) : appArchived || isFetchingFailed ? null : (
                  renderLazyLoadField(50, 170)
                )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <Paper style={{ height: 'auto', width: '100%', minHeight: 308 }}>
        <LinearProgress
          className={classes.root}
          style={{
            visibility: gettingApplicationLogs ? 'visible' : 'hidden',
            backgroundColor: '#E5E5E5',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '16px 8px 16px 8px',
              height: '70%',
              width: '100%',
            }}
          >
            <AppBar position="static" color="default" style={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{ style: { background: '#F47721' } }}
                style={{ display: !applicationFormData && 'none' }}
              >
                {applicationFormData
                  ? applicationFormData.result.productForm.applicationForm.map(
                      (item, index) => {
                        if (item.name != 'Application Information') {
                          return (
                            <Tab
                              label={
                                <span
                                  style={{
                                    color:
                                      value == index - 1
                                        ? '#F47721'
                                        : '#909196',
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  {item.name}
                                </span>
                              }
                              {...a11yProps(index - 1)}
                            />
                          );
                        }
                      }
                    )
                  : null}
                <Tab
                  style={{ minWidth: 92, width: 10, padding: 2 }}
                  label={
                    <span
                      style={{
                        color: value == numTabs ? '#F47721' : '#909196',
                        fontFamily: 'Inter',
                        minWidth: 92,
                      }}
                    >
                      Selfie
                    </span>
                  }
                  {...a11yProps(numTabs)}
                />
                <Tab
                  style={{ minWidth: 92, width: 10, padding: 2 }}
                  label={
                    <span
                      style={{
                        color: value == numTabs + 1 ? '#F47721' : '#909196',
                        fontFamily: 'Inter',
                        minWidth: 92,
                      }}
                    >
                      Remarks
                    </span>
                  }
                  {...a11yProps(numTabs + 1)}
                />
                <Tab
                  style={{ minWidth: 92, width: 10, padding: 2 }}
                  label={
                    <span
                      style={{
                        color: value == numTabs + 2 ? '#F47721' : '#909196',
                        fontFamily: 'Inter',
                        minWidth: 92,
                      }}
                    >
                      Logs
                    </span>
                  }
                  {...a11yProps(numTabs + 2)}
                />
              </Tabs>
            </AppBar>
            {editableStatusLookup.includes(applicationStatus) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  visibility: loadedApplicationFormData ? '' : 'hidden',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      className={classes.root}
                      checked={isReadOnly}
                      onChange={() => {
                        isReadOnly ? setIsReadOnly(false) : setIsReadOnly(true);
                      }}
                      name="readOnly"
                      primary={true}
                    />
                  }
                  label="Read-only"
                  style={{ fontFamily: 'Inter' }}
                />
              </div>
            )}
            <TabPanel value={value} index={numTabs}>
              {renderSelfie()}
            </TabPanel>
            <TabPanel value={value} index={numTabs + 1}>
              <div
                className="remarksPanel"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  marginBottom: '50px',
                }}
              >
                <Table style={{ width: '100%' }}>
                  <TableHead
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '12px',
                      textAlign: 'left',
                    }}
                  >
                    <TableRow>
                      <StyledTableCell>Remarks</StyledTableCell>
                      <StyledTableCell style={{ minWidth: 150 }}>
                        Date
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 230 }}>
                        User
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: '1px solid #C4C4C4' }}>
                    {renderRemarks()}
                  </TableBody>
                </Table>
              </div>
              <div
                className="addRemarks"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <input
                  type="text"
                  name="txtRemark"
                  style={{
                    border: '1px solid #C4C4C4',
                    borderRadius: '4px',
                    width: 439,
                    fontFamily: 'Inter',
                    paddingLeft: 12,
                    height: 34,
                  }}
                  value={remark}
                  placeholder="Type here"
                  onChange={handleInputChange}
                ></input>
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#F47721',
                    textTransform: 'none',
                    fontFamily: 'Inter',
                    border: '1px solid #F47721',
                    marginLeft: 20,
                    width: 130,
                  }}
                  onClick={() => submitRemark()}
                >
                  Add Remark
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={value} index={numTabs + 2}>
              <div
                className="logsPanel"
                style={{ display: 'flex', width: '100%' }}
              >
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Date and Time</StyledTableCell>
                        <StyledTableCell>Reference ID</StyledTableCell>
                        {props.role == 'Agency Banking Product Owner' && (
                          <StyledTableCell align="center">
                            Brand
                          </StyledTableCell>
                        )}
                        {props.role == 'Loan Head' ||
                        props.role == 'Loan Manager' ? (
                          <StyledTableCell align="center">User</StyledTableCell>
                        ) : (
                          <StyledTableCell align="center">
                            Agent
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="left">Status</StyledTableCell>
                        <StyledTableCell align="left">Remarks</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applicationLogs && applicationLogs
                        ? applicationLogs.map((row, index) => (
                            <StyledTableRow
                              style={{ backgroundColor: '#FFFFFF' }}
                            >
                              <StyledTableCell>
                                {row.dateTime
                                  ? moment(
                                      row.dateTime[row.dateTime.length - 1] ===
                                        'Z'
                                        ? row.dateTime
                                        : row.dateTime + 'Z'
                                    )
                                      .tz('Asia/Singapore')
                                      .format('DD MMMM YYYY, hh:mm:ss A')
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.referenceId ? row.referenceId : '-'}
                              </StyledTableCell>
                              {props.role == 'Agency Banking Product Owner' && (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {row.brandName ? row.brandName : '-'}
                                </StyledTableCell>
                              )}
                              {props.role == 'Loan Head' ||
                              props.role == 'Loan Manager' ? (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {row.userFullName ? row.userFullName : '-'}
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {row.agentName ? row.agentName : '-'}
                                </StyledTableCell>
                              )}
                              <StyledTableCell
                                align="left"
                                style={{ fontWeight: 600 }}
                              >
                                {row.status ? row.status : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ fontWeight: 600 }}
                              >
                                {row.remarks ? row.remarks : '-'}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : gettingApplicationLogs
                        ? [1, 2, 3, 4, 5].map((row, index) => (
                            <StyledTableRow
                              style={{ backgroundColor: '#FFFFFF' }}
                            >
                              <StyledTableCell>
                                {renderLazyLoadField(
                                  14,
                                  Math.floor(
                                    Math.random() * (260 - 150 + 1) + 150
                                  )
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {renderLazyLoadField(
                                  14,
                                  Math.floor(
                                    Math.random() * (260 - 150 + 1) + 150
                                  )
                                )}
                              </StyledTableCell>
                              {props.role == 'Agency Banking Product Owner' && (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {renderLazyLoadField(
                                    14,
                                    Math.floor(
                                      Math.random() * (185 - 90 + 1) + 90
                                    )
                                  )}
                                </StyledTableCell>
                              )}
                              {props.role == 'Loan Head' ||
                              props.role == 'Loan Manager' ? (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {renderLazyLoadField(
                                    14,
                                    Math.floor(
                                      Math.random() * (185 - 90 + 1) + 90
                                    )
                                  )}
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell
                                  align="center"
                                  style={{ fontWeight: 600 }}
                                >
                                  {renderLazyLoadField(
                                    14,
                                    Math.floor(
                                      Math.random() * (185 - 90 + 1) + 90
                                    )
                                  )}
                                </StyledTableCell>
                              )}
                              <StyledTableCell
                                align="center"
                                style={{ fontWeight: 600 }}
                              >
                                {renderLazyLoadField(
                                  14,
                                  Math.floor(
                                    Math.random() * (110 - 50 + 1) + 50
                                  )
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {renderLazyLoadField(
                                  14,
                                  Math.floor(
                                    Math.random() * (100 - 60 + 1) + 60
                                  )
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* } */}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '24px 0 0 0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      marginRight: 16,
                      fontSize: 14,
                      fontFamily: 'Inter',
                    }}
                  >
                    Showing{' '}
                    {count === 0
                      ? '-'
                      : `${
                          page != maxPage
                            ? totalCount * page -
                              (showItems - 1) +
                              '-' +
                              totalCount * page
                            : (page - 1) * showItems + 1 != count
                            ? (page - 1) * showItems + 1 + '-' + count
                            : (page - 1) * showItems + 1
                        } `}{' '}
                    of {count === 0 ? '-' : count}
                  </div>
                  <Pagination
                    className={classes.root}
                    page={page}
                    count={maxPage}
                    shape="rounded"
                    onChange={(event, value) => {
                      getApplicationLogs(showItems, value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '50%',
                    width: '100%',
                    minWidth: 200,
                  }}
                >
                  <div
                    style={{
                      marginRight: 16,
                      color: '#909196',
                      fontSize: 14,
                      fontFamily: 'Inter',
                    }}
                  >
                    Show items
                  </div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      className={classes.root}
                      labelId="demo-simple-select-filled-label"
                      defaultValue={showItems}
                      style={{ width: 72 }}
                      onChange={(event) => {
                        getApplicationLogs(event.target.value, 1);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </TabPanel>
            { (firstName && editableStatusLookup.includes(applicationStatus) && !linkProfilePrompted) ? <AddCustomerProfile 
                  applicantName={props.application.applicantName}
                  referenceId={props.application.referenceId} 
                  customerId={customerId}
                  customerName={`${firstName}`}
                  customerBirthdate={applicantBirthdate}
                  customerEmail={applicantEmail}
                  customerMobile={applicantMobile}
                  initialized={initialized}
                  setLinkProfilePrompted={setLinkProfilePrompted}
                  setTick={props.setTick} /> : null
                }
            {applicationFormData ? (
              applicationFormData.result.productForm.applicationForm.map(
                (item, tabIndex) => {
                  if (tabIndex > 0) {
                    let modifiedFormData =
                      applicationFormData.result.productForm.applicationForm;
                    let modifiedFormData2 =
                      applicationFormData.result.productForm.applicationForm;
                    let tabFieldData;
                    let fieldCount = 0, fieldObj = [];

                    return (
                      <TabPanel value={value} index={tabIndex - 1}>
                        <div
                          
                        >
                          {item.applicationTemplateTabFields.map(
                            (tabField, tIndex) => {       
                              tabFieldData = tabField;
                              return (
                                <div>
                                  {checkParentTabRender(tabField.code) &&
                                    (
                                      <div
                                        style={{
                                          borderBottom: '1px solid #C4C4C4',
                                          flexBasis: '100%',
                                          fontFamily: 'Inter',
                                          color: '#F5922F',
                                          fontSize: 12,
                                          fontWeight: 'bold',
                                          height: 25,
                                          marginBottom: 16
                                        }}
                                        id={'div-' + tabFieldData.code}
                                      >
                                        {tabField.label.toUpperCase()}
                                      </div>
                                    )
                                  }

                                  <div style={{
                                    display: item.name != 'Cash Flow' &&'flex',
                                    flexWrap: item.name != 'Cash Flow' && 'wrap',
                                    justifyContent: item.name != 'Cash Flow' && 'space-between',
                                    width: item.name != 'Cash Flow' && '100%',
                                    minHeight: item.name != 'Cash Flow' && 100,
                                  }}
                                  >
                                  {tabFieldData.fields.map(
                                (field, index) => {

                              if (item.name == 'Cash Flow') {
                                return (
                                <div>
                                  <Modal open={isOpenFormSelector}>
                                    <FormSelector
                                      handleCreateCashflow={handleCreateCashflow}
                                      handleNoForms={handleNoForms}
                                      cashFlowFormList={cashFlowFormList}
                                      onFormCancel={toggleFormSelector}/>
                                  </Modal> 
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      style={{
                                        display: "flex",
                                        marginRight: 10,
                                        color: !editableStatusLookup.includes(applicationStatus) ? "#C4C4C4" : "#F47721",
                                        borderColor: !editableStatusLookup.includes(applicationStatus) ? "#C4C4C4" : "#F47721",
                                        textTransform: "none",
                                        fontFamily: "Inter",
                                      }}
                                      onClick={toggleFormSelector}
                                      disabled={!editableStatusLookup.includes(applicationStatus)}
                                    >
                                      <MdIcon
                                        path={mdiPlusCircleOutline}
                                        size="1.5em"
                                        color={!editableStatusLookup.includes(applicationStatus) ? "#C4C4C4" : "#F47721"}
                                        style={{ marginRight: 10 }}
                                      />
                                      Create Cashflow
                                    </Button>
                                  </div>
                                  <div
                                    className="logsPanel"
                                    style={{ display: 'flex', width: '100%' }}
                                  >
                                    <TableContainer>
                                      <Table
                                        className={classes.table}
                                        aria-label="customized table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <StyledTableCell align="left">
                                              Date Generated
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              Version
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              Recommend Amount
                                            </StyledTableCell>
                                            <StyledTableCell>Default</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {cashFlowList &&
                                            cashFlowList.map((row, index) => {
                                              return (
                                                <StyledTableRow
                                                  style={{ backgroundColor: '#FFFFFF' }}
                                                >
                                                  <StyledTableCell align="left">
                                                    {row.dateGenerated
                                                      ? moment(
                                                          row.dateGenerated[row.dateGenerated.length - 1] ===
                                                            'Z'
                                                            ? row.dateGenerated
                                                            : row.dateGenerated + 'Z'
                                                        )
                                                          .tz('Asia/Singapore')
                                                          .format('DD MMMM YYYY, hh:mm:ss A')
                                                      : '-'}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    align="center"
                                                    style={{ color: '#F47721' }}
                                                  >
                                                    {row.version}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    align="right"
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    <NumberFormat
                                                      value={row.recommendedAmount}
                                                      prefix={'PHP '}
                                                      decimalScale={2}
                                                      fixedDecimalScale
                                                      thousandSeparator
                                                      displayType={'text'}
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell>
                                                    <OrangeRadio
                                                      disabled={settingDefaultCashflow || !editableStatusLookup.includes(applicationStatus)}
                                                      className={classes.root}
                                                      checked={cashFlowVersion != 0 ? cashFlowVersion == row.version : defaultCashflow == row.hash}
                                                      onChange={() => setDefaultCashflowVersion(row.version, row.hash)}
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell>
                                                    <Button
                                                      style={{
                                                        color: '#F47721',
                                                        textTransform: 'none',
                                                        fontFamily: 'Inter',
                                                      }}
                                                      onClick={async () => await downloadCashflowPDF(row.attachment, row.dateGenerated, row.version, props.application.referenceId)}
                                                      className={classes.button}
                                                    >
                                                      <MdIcon
                                                        path={mdiDownloadOutline}
                                                        size="1.5em"
                                                        color="rgb(158, 158, 158)"
                                                        style={{ marginRight: 10 }}
                                                      />
                                                      Download
                                                    </Button>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              );
                                            })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    {/* } */}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '24px 0 0 0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: 16,
                                          fontSize: 14,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        Showing{' '}
                                        {cashFlowCount === 0
                                          ? '-'
                                          : `${
                                              cashFlowPage != cashFlowMaxPage
                                                ? cashFlowTotalCount * cashFlowPage -
                                                  (cashFlowShowItems - 1) +
                                                  '-' +
                                                  cashFlowTotalCount * cashFlowPage
                                                : (cashFlowPage - 1) * cashFlowShowItems + 1 !=
                                                  cashFlowCount
                                                ? (cashFlowPage - 1) * cashFlowShowItems +
                                                  1 +
                                                  '-' +
                                                  cashFlowCount
                                                : (cashFlowPage - 1) * cashFlowShowItems + 1
                                            } `}{' '}
                                        of {cashFlowCount === 0 ? '-' : cashFlowCount}
                                      </div>
                                      <Pagination
                                        className={classes.root}
                                        page={cashFlowPage}
                                        count={cashFlowMaxPage}
                                        shape="rounded"
                                        onChange={(event, value) => {
                                          getCashflowAnalysisList(cashFlowShowItems, value);
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        height: '50%',
                                        width: '100%',
                                        minWidth: 200,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: 16,
                                          color: '#909196',
                                          fontSize: 14,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        Show items
                                      </div>
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                      >
                                        <Select
                                          className={classes.root}
                                          labelId="demo-simple-select-filled-label"
                                          defaultValue={cashFlowShowItems}
                                          style={{ width: 72 }}
                                          onChange={(event) => {
                                            setCashFlowShowItems(event.target.value);
                                            getCashflowAnalysisList(event.target.value, 1);
                                          }}
                                        >
                                          <MenuItem value={10}>10</MenuItem>
                                          <MenuItem value={20}>20</MenuItem>
                                          <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>)
                              }                      
                              else if (field.field.fieldType == 'Amount') {
                                if (checkRender(field.field.render && field.field.render.fields[0]) == 'visible') {
                                  return (
                                    <div
                                      className={classes.root}
                                      style={{
                                        flexBasis:
                                          addSubsection(field.field) != null
                                            ? '100%'
                                            : '48%',
                                        marginBottom: 12,
                                        fontFamily: 'Inter',
                                      }}
                                    >

                                      <div
                                        className={isReadOnly && classes.root}
                                        style={{
                                          flexBasis: '48%',
                                          marginBottom: 12,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        <NumberFormat
                                          required={field.isRequired ? "required" : ""}
                                          style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0],
                                            {tabIndex, tIndex, index}) : 'visible' }}
                                          InputProps={{
                                            disableUnderline: isReadOnly,
                                          }}
                                          value={field.field.value}
                                          prefix={'Php '}
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          customInput={TextField}
                                          disabled={isReadOnly}
                                          disableUnderline={isReadOnly}
                                          label={field.name}
                                          error={
                                            modifiedFormData2[tabIndex] &&
                                            modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.validation
                                          }
                                          helperText={
                                            modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.helpMessage
                                          }
                                          onChange={(event) => {
                                            const newValue = event.target.value
                                              .replace(/,(?=\d{3})/g, '')
                                              .replace('Php ', ''); // Removed prefix and thousand separator
                                            modifiedFormData[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.value = convertDataType(newValue, field.field.dataType);
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.validation = validateField(
                                              field.field.code,
                                              event.target.value,
                                              field.field.maxLength,
                                              field.field.max,
                                              field.field.min
                                            );
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.helpMessage = handleValidationMessage(
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation,
                                              modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.name,
                                              field.field.max,
                                              field.field.min,
                                              field.field.maxLength
                                            );
                                            globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                            setModifiedData(globalModifiedFormData);
                                            setRevision(revision + 1);
                                          }}
                                          className={classes.root}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (field.field.fieldType == 'Dropdown') {
                                if (checkRender(field.field.render && field.field.render.fields[0]) == 'visible') {
                                  return (
                                    <div
                                      className={classes.root}
                                      style={{
                                        flexBasis:
                                          addSubsection(field.field) != null
                                            ? '100%'
                                            : '48%',
                                        marginBottom: 12,
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      
                                      <div
                                        className={isReadOnly && classes.root}
                                        style={{
                                          flexBasis: '48%',
                                          marginBottom: 12,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        <FormControl required={field.isRequired}>
                                          <InputLabel>
                                            {field.name}
                                          </InputLabel>
                                          <Select
                                            native
                                            fullWidth={true}
                                            required={field.isRequired ? "required" : ""}
                                            error={
                                              modifiedFormData2[tabIndex] &&
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation
                                            }
                                            helperText={
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.helpMessage
                                            }
                                            disabled={isReadOnly}
                                            disableUnderline={isReadOnly}
                                            IconComponent={() => isReadOnly && null}
                                            onChange={(event) => {
                                              modifiedFormData[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.value = event.target.value;
                                              globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                              setModifiedData(globalModifiedFormData);
                                              setRevision(revision + 1);
                                            }}
                                            defaultValue={field.field.value}
                                          >
                                            <option value={""} />
                                            {field.field.options.map(
                                              (optionItem, index) => {
                                                return (
                                                  <option value={optionItem.code}>
                                                    {optionItem.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (field.field.fieldType == 'InputField') {
                                // Retrieve applicant name, email and mobile number
                                if (field.field.code == 'personFirstName') {
                                  firstName = field.field.value;
                                } else if (field.field.code == 'personMiddleName') {
                                  middleName = field.field.value;
                                } else if (field.field.code == 'personLastName') {
                                  lastName = field.field.value;
                                } else if (field.field.code == 'personEmail') {
                                  applicantEmail = field.field.value;
                                } else if (field.field.code == 'personMobileNo') {
                                  applicantMobile = field.field.value;
                                }

                                if (checkRender(field.field.render && field.field.render.fields[0]) == 'visible') {
                                  return (
                                    <div
                                      className={classes.root}
                                      style={{
                                        flexBasis:
                                          addSubsection(field.field) != null
                                            ? '100%'
                                            : '48%',
                                        marginBottom: 12,
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      <div
                                        className={isReadOnly && classes.root}
                                        style={{
                                          flexBasis: '48%',
                                          marginBottom: 12,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        {tinFields.includes(field.field.code) ? (
                                          <NumberFormat
                                            required={field.isRequired ? "required" : ""}
                                            style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0]) : 'visible'}}
                                            customInput={TextField}
                                            value={field.field.value}
                                            disabled={isReadOnly}
                                            format="###-###-###-###"
                                            error={
                                              modifiedFormData2[tabIndex] &&
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation
                                            }
                                            helperText={
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.helpMessage
                                            }
                                            label={field.name}
                                            onChange={(event) => {
                                              const newValue =
                                                event.target.value.replace(
                                                  /-/g,
                                                  ''
                                                );
                                              modifiedFormData[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.value = convertDataType(newValue, field.field.dataType);
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.validation = validateField(
                                                field.field.code,
                                                event.target.value,
                                                field.field.maxLength,
                                                field.field.max,
                                                field.field.min,
                                                field.field.dataType
                                              );
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.helpMessage = handleValidationMessage(
                                                modifiedFormData2[tabIndex]
                                                  .applicationTemplateTabFields[tIndex]
                                                  .fields[index].field.validation,
                                                modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.name,
                                                field.field.max,
                                                field.field.min,
                                                field.field.maxLength
                                              );
                                              globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                              setModifiedData(globalModifiedFormData);
                                              setRevision(Math.random());
                                            }}
                                            className={classes.root}
                                          />
                                        ) : sssFields.includes(field.field.code) ? (
                                          <NumberFormat
                                            required={field.isRequired ? "required" : ""}
                                            style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0]) : 'visible'}}
                                            customInput={TextField}
                                            value={field.field.value}
                                            disabled={isReadOnly}
                                            format="##-#######-#"
                                            error={
                                              modifiedFormData2[tabIndex] &&
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation
                                            }
                                            helperText={
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.helpMessage
                                            }
                                            label={field.name}
                                            onChange={(event) => {
                                              const newValue =
                                                event.target.value.replace(
                                                  /-/g,
                                                  ''
                                                );
                                              modifiedFormData[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.value = convertDataType(newValue, field.field.dataType);
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.validation = validateField(
                                                field.field.code,
                                                event.target.value,
                                                field.field.maxLength,
                                                field.field.max,
                                                field.field.min,
                                                field.field.dataType
                                              );
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.helpMessage = handleValidationMessage(
                                                modifiedFormData2[tabIndex]
                                                  .applicationTemplateTabFields[tIndex]
                                                  .fields[index].field.validation,
                                                modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.name,
                                                field.field.max,
                                                field.field.min,
                                                field.field.maxLength
                                              );
                                              globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                              setModifiedData(globalModifiedFormData);
                                              setRevision(Math.random());
                                            }}
                                            className={classes.root}
                                          />
                                        ) : (
                                          <TextField
                                            required={field.isRequired ? "required" : ""}
                                            style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0]) : 'visible'}}
                                            id={field.field.code}
                                            key={field.field.code}
                                            error={
                                              modifiedFormData2[tabIndex] &&
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation
                                            }
                                            helperText={
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.helpMessage
                                            }
                                            label={field.name}
                                            fullWidth={true}
                                            type={handleDataType(
                                              field.field.dataType
                                            )}
                                            disabled={isReadOnly}
                                            InputProps={{
                                              disableUnderline: isReadOnly,
                                              inputProps: {
                                                maxLength: handleMaxLength(
                                                  field.field.maxLength
                                                ),
                                              },
                                            }}
                                            onInput={(e) => {
                                              e.target.value = field.field.maxLength && isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value))
                                                .toString()
                                                .slice(0, handleMaxLength(field.field.maxLength))
                                                : e.target.value;
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={(event) => {
                                              if (handleDataType(field.field.dataType) == 'number'){
                                                event.target.value=Math.abs(event.target.value);
                                              }
                                              modifiedFormData[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.value = convertDataType(event.target.value, field.field.dataType);
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.validation = validateField(
                                                field.field.code,
                                                event.target.value,
                                                field.field.maxLength,
                                                field.field.max,
                                                field.field.min,
                                                field.field.dataType
                                              );
                                              modifiedFormData2[
                                                tabIndex
                                              ].applicationTemplateTabFields[
                                                tIndex
                                              ].fields[index].field.helpMessage = handleValidationMessage(
                                                modifiedFormData2[tabIndex]
                                                  .applicationTemplateTabFields[tIndex]
                                                  .fields[index].field.validation,
                                                modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.name,
                                                field.field.max,
                                                field.field.min,
                                                field.field.maxLength
                                              );
                                              globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                              setModifiedData(globalModifiedFormData);
                                              setRevision(Math.random());
                                            }}
                                            onblur={(event) => {
                                              handleSnackBarMessage();
                                            }}
                                            defaultValue={field.field.value}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (field.field.fieldType == 'TextArea') {
                                if (checkRender(field.field.render && field.field.render.fields[0]) == 'visible') {
                                  return (
                                    <div
                                      className={classes.root}
                                      style={{
                                        flexBasis:
                                          addSubsection(field.field) != null
                                            ? '100%'
                                            : '48%',
                                        marginBottom: 12,
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      
                                      <div
                                        className={isReadOnly && classes.root}
                                        style={{
                                          flexBasis: '48%',
                                          marginBottom: 12,
                                          height: 42,
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        <TextField
                                          required={field.isRequired ? "required" : ""}
                                          style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0]) : 'visible'}}
                                          label={field.name}
                                          error={
                                            modifiedFormData2[tabIndex] &&
                                            modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.validation
                                          }
                                          helperText={
                                            modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.helpMessage
                                          }
                                          fullWidth={true}
                                          disabled={isReadOnly}
                                          InputProps={{
                                            disableUnderline: isReadOnly,
                                          }}
                                          onChange={(event) => {
                                            modifiedFormData[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.value = event.target.value;
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.validation = validateField(
                                              field.field.code,
                                              event.target.value
                                            );
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.helpMessage = handleValidationMessage(
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation,
                                              modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.name,
                                              field.field.max,
                                              field.field.min,
                                              field.field.maxLength
                                            );
                                            globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                            setModifiedData(globalModifiedFormData);
                                            setRevision(revision + 1);
                                          }}
                                          defaultValue={field.field.value}
                                          multiline
                                          rows={2}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (field.field.fieldType == 'DatePicker') {
                                // Retrieve applicant birthdate
                                if (field.field.code == 'personBirthdate') {
                                  applicantBirthdate = field.field.value;
                                }

                                if (checkRender(field.field.render && field.field.render.fields[0]) == 'visible') {
                                  return (
                                    <div
                                      className={classes.root}
                                      className={isReadOnly && classes.root}
                                      style={{
                                        flexBasis: '48%',
                                        marginBottom: 12,
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          format="MM/dd/yyyy"
                                          style={{ visibility: field.field.render ? checkRender(field.field.render && field.field.render.fields[0]) : 'visible' }}
                                          margin="normal"
                                          id="date-picker-inline"
                                          label={field.name}
                                          fullWidth={true}
                                          variant="outlined"
                                          KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                          }}
                                          required={field.isRequired ? "required" : ""}
                                          invalidDateMessage={
                                            modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.helpMessage
                                          }
                                          disabled={isReadOnly}
                                          InputProps={{
                                            disableUnderline: isReadOnly,
                                          }}
                                          onChange={(date) => {
                                            field.field.value = moment(date).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                            );
                                            modifiedFormData[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.value = moment(date).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                            );
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.validation = validateField(
                                              field.field.code,
                                              date
                                            );
                                            modifiedFormData2[
                                              tabIndex
                                            ].applicationTemplateTabFields[
                                              tIndex
                                            ].fields[index].field.helpMessage = handleValidationMessage(
                                              modifiedFormData2[tabIndex]
                                                .applicationTemplateTabFields[tIndex]
                                                .fields[index].field.validation,
                                              modifiedFormData2[tabIndex]
                                              .applicationTemplateTabFields[tIndex]
                                              .fields[index].field.name,
                                              field.field.max,
                                              field.field.min,
                                              field.field.maxLength
                                            );
                                            globalModifiedFormData = adjustValues(modifiedFormData, field.field.code, field.field.dataType);
                                            setModifiedData(globalModifiedFormData);
                                            setRevision(
                                              btoa(
                                                moment(date).format('YYYY-MM-DD')
                                              )
                                            );
                                          }}
                                          value={
                                            field.field.value
                                              ? field.field.value
                                              : null
                                          }
                                        />
                                      </MuiPickersUtilsProvider>
                                    </div>
                                  );
                                }
                              } else if (field.field.fieldType == 'ImageFile') {
                                if (checkRender(field.field.render && field.field.render.fields[0],
                                  {tabIndex, tIndex, index}, field.field.code) == 'visible') {
                                fieldCount++;
                                tabsWithAttachment = [
                                  ...tabsWithAttachment,
                                  tabIndex,
                                ];
                                let imgSrc =
                                  attachmentsv2[`${field.field.code}`] &&
                                  attachmentsv2[`${field.field.code}`].result &&
                                  attachmentsv2[`${field.field.code}`].result
                                    .value &&
                                  attachmentsv2[`${field.field.code}`].result
                                    .value;
                                let imgBase64 =
                                  attachments[`${field.field.code}`] &&
                                  attachments[`${field.field.code}`];
                                return (
                                  <div
                                    style={{
                                      flexBasis: '48%',
                                      marginBottom: 12,
                                      fontFamily: 'Inter',
                                      fontSize: 14,
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderBottom: '1px solid #C4C4C4',
                                        flexBasis: '100%',
                                        fontFamily: 'Inter',
                                        color: '#F5922F',
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        height: 25,
                                        marginBottom: 16,
                                      }}
                                    >
                                      {field.name.toUpperCase()}
                                      {requiredDocuments.includes(
                                        field.field.code
                                      ) && '*'}
                                    </div>
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        onDropHandler(
                                          acceptedFiles,
                                          field.field.code
                                        )
                                      }
                                      disabled={
                                        attachmentNames.includes(
                                          field.field.code
                                        ) || isReadOnly
                                      }
                                      accept={'application/pdf'}
                                      multiple={false}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section>
                                          <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Paper
                                              style={{
                                                width: '100%',
                                                height: 200,
                                                backgroundColor: '#F6F6F6',
                                                marginBottom: 18,
                                              }}
                                            >
                                              {imgSrc &&
                                              screenIndex == 0 &&
                                              imgSrc.split(',')[0] !=
                                                'data:image/pdf;base64' &&
                                              imgSrc &&
                                              imgSrc.split(',')[0] !=
                                                'data:application/pdf;base64'? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#FFFFFF',
                                                    position: 'relative',
                                                    height: 200,
                                                  }}
                                                  onMouseEnter={() =>
                                                    setHoveredAttachment(
                                                      field.field.code
                                                    )
                                                  }
                                                  onMouseLeave={() =>
                                                    setHoveredAttachment('')
                                                  }
                                                >
                                                  <object
                                                    data={imgSrc}
                                                    alt={field.name}
                                                    style={
                                                      hoveredAttachment ==
                                                      field.field.code
                                                        ? ContainerStyles.attchHovered
                                                        : ContainerStyles.attchDefault
                                                    }
                                                    onMouseEnter={() =>
                                                      setHoveredAttachment(
                                                        field.field.code
                                                      )
                                                    }
                                                    onMouseLeave={() =>
                                                      setHoveredAttachment('')
                                                    }
                                                  />
                                                  <div
                                                    style={{
                                                      backgroundColor: '#000000',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      height: '100%',
                                                      visibility:
                                                        hoveredAttachment ==
                                                        field.field.code
                                                          ? 'visible'
                                                          : 'hidden',
                                                    }}
                                                  >
                                                    <Button
                                                      variant="outlined"
                                                      style={{
                                                        marginRight: 8,
                                                        borderColor: '#FFFFFF',
                                                        fontFamily: 'Inter',
                                                        color: '#FFFFFF',
                                                        height: 50,
                                                        textTransform: 'none',
                                                      }}
                                                      onMouseEnter={() =>
                                                        setHoveredAttachment(
                                                          field.field.code
                                                        )
                                                      }
                                                      onClick={() => {
                                                        setSelectedBase64(
                                                          imgBase64
                                                        );
                                                        setSelectedField(
                                                          field.field.code
                                                        );
                                                        getAttachment(
                                                          field.field.code,
                                                          false
                                                        );
                                                        setOpenPreview(true);
                                                      }}
                                                    >
                                                      <img
                                                        src={icon_view}
                                                        style={{ marginRight: 8 }}
                                                      />{' '}
                                                      View
                                                    </Button>
                                                    {!isReadOnly && (
                                                      <div>
                                                        <Button
                                                          variant="outlined"
                                                          style={{
                                                            marginRight: 8,
                                                            borderColor:
                                                              '#FFFFFF',
                                                            fontFamily: 'Inter',
                                                            color: '#FFFFFF',
                                                            height: 50,
                                                            textTransform: 'none',
                                                          }}
                                                          onMouseEnter={() =>
                                                            setHoveredAttachment(
                                                              field.field.code
                                                            )
                                                          }
                                                          onClick={() => {
                                                            setSelectedAttachment(
                                                              field.field.code
                                                            );
                                                            onReplaceClick();
                                                          }}
                                                        >
                                                          <img
                                                            src={icon_replace}
                                                            style={{
                                                              marginRight: 8,
                                                            }}
                                                          />{' '}
                                                          Replace
                                                        </Button>
                                                        <input
                                                          type="file"
                                                          id="file"
                                                          ref={uploadFileInput}
                                                          style={{
                                                            display: 'none',
                                                          }}
                                                          accept={
                                                            'application/pdf'
                                                          }
                                                          onInput={(event) =>
                                                            replaceFileHandler(
                                                              event,
                                                              field.field.code
                                                            )
                                                          }
                                                        />
                                                        <Button
                                                          variant="outlined"
                                                          style={{
                                                            marginRight: 8,
                                                            borderColor:
                                                              '#FFFFFF',
                                                            fontFamily: 'Inter',
                                                            color: '#FFFFFF',
                                                            height: 50,
                                                            textTransform: 'none',
                                                          }}
                                                          onMouseEnter={() =>
                                                            setHoveredAttachment(
                                                              field.field.code
                                                            )
                                                          }
                                                          onClick={() => {
                                                            setSelectedAttachment(
                                                              field.field.code
                                                            );
                                                            setDeletingFile(false);
                                                            setDeleteDialogOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={icon_delete}
                                                            style={{
                                                              marginRight: 8,
                                                            }}
                                                          />{' '}
                                                          Delete
                                                        </Button>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : imgSrc && imgSrc.split(':')[0] != 'blob' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#000000',
                                                    position: 'relative',
                                                    height: 200,
                                                  }}
                                                >
                                                  <PDFDocumentWrapper
                                                    style={{ objectFit: 'cover' }}
                                                  >
                                                    <Document
                                                      style={{
                                                        objectFit: 'cover',
                                                      }}
                                                      onLoadError={console.error}
                                                      file={imgSrc}
                                                      width={'100%'}
                                                      height={200}
                                                      onClick={() => {
                                                        setSelectedBase64(
                                                          imgBase64
                                                        );
                                                        setSelectedField(
                                                          field.field.code
                                                        );
                                                        getAttachment(
                                                          field.field.code,
                                                          false
                                                        );
                                                        setOpenPreview(true);
                                                      }}
                                                    >
                                                      <Page pageNumber={1} />
                                                    </Document>
                                                    <div
                                                      style={
                                                        ButtonStyles.container
                                                      }
                                                    >
                                                      <Button
                                                        variant="outlined"
                                                        style={{
                                                          marginRight: 8,
                                                          borderColor: '#FFFFFF',
                                                        }}
                                                        onClick={() => {
                                                          setSelectedBase64(
                                                            imgBase64
                                                          );
                                                          setSelectedField(
                                                            field.field.code
                                                          );
                                                          getAttachment(
                                                            field.field.code,
                                                            false
                                                          );
                                                          setOpenPreview(true);
                                                        }}
                                                      >
                                                        View
                                                      </Button>
                                                      <Button
                                                        variant="outlined"
                                                        style={{
                                                          marginRight: 8,
                                                          borderColor: '#FFFFFF',
                                                        }}
                                                        onClick={() => {}}
                                                      >
                                                        Replace
                                                      </Button>
                                                      <Button
                                                        variant="outlined"
                                                        style={{
                                                          marginRight: 8,
                                                          borderColor: '#FFFFFF',
                                                        }}
                                                        onClick={() => {}}
                                                      >
                                                        Delete
                                                      </Button>
                                                    </div>
                                                  </PDFDocumentWrapper>
                                                </div>
                                              ) : !imgSrc ||
                                                imgSrc == '' ||
                                                imgSrc == undefined ? (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    fontSize: 12,
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: 200,
                                                  }}
                                                >
                                                  <div>
                                                    {attachmentNames.includes(
                                                      field.field.code
                                                    ) ? (
                                                      gettingAttachment ? (
                                                        <MdIcon
                                                          path={mdiAutorenew}
                                                          size="2.2em"
                                                          color="#F47721"
                                                          spin={1.5}
                                                          style={{
                                                            marginBottom: 4,
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            icon_previewUnavailable
                                                          }
                                                          style={{
                                                            marginBottom: 4,
                                                          }}
                                                        />
                                                      )
                                                    ) : (
                                                      <MdIcon
                                                        path={
                                                          mdiFileExportOutline
                                                        }
                                                        size="3.5em"
                                                        color={'#C4C4C4'}
                                                      />
                                                    )}
                                                  </div>
                                                  {attachmentNames.includes(
                                                    field.field.code
                                                  ) ? (
                                                    gettingAttachment ? (
                                                      'Loading attachment...'
                                                    ) : failedAttachments.includes(
                                                        field.field.code
                                                      ) ? (
                                                      'Upload Failed'
                                                    ) : (
                                                      'Preview unavailable'
                                                    )
                                                  ) : !isReadOnly ? (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={{
                                                            marginTop: 24,
                                                            fontSize: 16,
                                                          }}
                                                        >
                                                          Click here to select a
                                                          file
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: '#2B2D32',
                                                          marginTop: 20,
                                                        }}
                                                      >
                                                        (supported format: .pdf)
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    'No Document Uploaded'
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    fontSize: 12,
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: 200,
                                                  }}
                                                >
                                                  <div>
                                                    <img
                                                      src={
                                                        icon_previewUnavailable
                                                      }
                                                      style={{ marginBottom: 4 }}
                                                    />
                                                  </div>
                                                  Attachment Unavailable
                                                </div>
                                              )}
                                            </Paper>
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                );
                              }}
                              fieldObj.push(fieldCount);
                            })}
                            </div>
                                </div>
                              );
                              }
                          )}
                          
                        </div>
                      </TabPanel>
                    );
                  }
                  
                }
              )
            ) : isFetchingFailed ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontFamily: 'Inter',
                  width: '100%',
                  height: 300,
                  alignItems: 'center',
                }}
              >
                {loanProductAvailable ? (
                  <MdIcon
                    path={mdiAlertOctagonOutline}
                    size="4.5em"
                    color="#E24C4C"
                    style={{ marginBottom: 25 }}
                  />
                ) : (
                  <MdIcon
                    path={mdiTextBoxRemoveOutline}
                    size="4.5em"
                    color="#909196"
                    style={{ marginBottom: 25 }}
                  />
                )}
                {loanProductAvailable
                  ? 'Failed to retrieve data'
                  : appArchived
                  ? 'Loan application is archived'
                  : 'Loan Product no longer available'}
                {loanProductAvailable ? (
                  <Button
                    variant="outlined"
                    style={{
                      marginRight: 10,
                      color: '#F47721',
                      borderColor: '#F47721',
                      textTransform: 'none',
                      fontFamily: 'Inter',
                      marginTop: 25,
                    }}
                    onClick={() => getData()}
                  >
                    <MdIcon
                      path={mdiRedoVariant}
                      size="1.5em"
                      color="#F47721"
                    />
                    Retry
                  </Button>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontFamily: 'Inter',
                  width: '100%',
                  height: 300,
                  alignItems: 'center',
                }}
              >
                <MdIcon
                  path={mdiHexagonSlice1}
                  size="4.5em"
                  color="#F47721"
                  spin={2}
                  style={{ marginBottom: 25 }}
                />
                {gettingAttachment ? (
                  <div>Fetching attachments...</div>
                ) : (
                  <div>Retrieving data...</div>
                )}
              </div>
            )}
          </div>
        </div>
      </Paper>
      <br />
      <Paper
        style={{
          backgroundColor: '#F6F6F6',
          height: 216,
          marginBottom: 16,
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '32px 32px 32px 64px',
              height: '70%',
            }}
          >
            <div>
              <div style={{ fontSize: 12 }}>Date Started</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary && appSummary.dateStarted && !gettingData
                  ? appSummary.dateStarted
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Date Completed</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary && appSummary.dateCompleted && !gettingData
                  ? appSummary.dateCompleted
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Agent Assigned</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary && appSummary.agentAssigned && !gettingData
                  ? appSummary.agentAssigned
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '32px 32px 32px 64px',
              height: '70%',
            }}
          >
            <div>
              <div style={{ fontSize: 12 }}>Last Updated</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary && appSummary.lastUpdated && !gettingData
                  ? appSummary.lastUpdated
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Date Submitted</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary && appSummary.dateSubmitted && !gettingData
                  ? appSummary.dateSubmitted
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Application Age</div>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {appSummary &&
                appSummary.age &&
                appSummary.age != 0 &&
                !gettingData
                  ? appSummary.age + ' day(s)'
                  : gettingData
                  ? renderLazyLoadField(
                      15,
                      Math.floor(Math.random() * (220 - 100 + 1) + 100)
                    )
                  : 'N/A'}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '32px 64px 32px 32px',
              height: '70%',
            }}
          ></div>
        </div>
      </Paper>
      </form>
    </div>
  );
};

export default LoanApplications;
