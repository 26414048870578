import React, { Component, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  LinearProgress,
  Paper,
  TextField,
  Snackbar,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Icon as MdIcon } from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { texts } from './helper';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment-timezone';
import Paths from '../../enums/Paths';
import LoanService from '../../services/LoanService';

import { Attachment } from '../common';
import List from 'material-ui/List';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    tooltip: {
      width: 190,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F5922F',
    },
    '& .MuiPaginationItem-page.Mui-selected ': {
      backgroundColor: '#F5922F',
      color: '#FFFFFF',
      fontFamily: 'Inter',
    },
    '& .MuiPaginationItem-rounded': {
      fontFamily: 'Inter',
    },
    '& .MuiInputBase-input': {
      height: 0,
      lineHeight: '0px',
    },
    '& .MuiInputBase-root': {
      height: 40,
      lineHeight: '2px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiPaginationItem-rounded': {
      fontFamily: 'Inter',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter',
    },
    '& .MuiButton-label': {
      width: 240,
    },
  },
  formControl: {
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 12,
      minWidth: 300,
    },
    '& .MuiInputBase-input': {
      height: 0,
      lineHeight: '0px',
    },
    '& .MuiInputBase-root': {
      height: 40,
      lineHeight: '2px',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 12,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: '0px',
      fontFamily: 'Inter',
      fontSize: 12,
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tooltip: {
    width: 190,
    maxWidth: 190,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ReviewChangeRequest = (props) => {
  let history = useHistory();
  let isStatusEditable;
  const classes = useStyles();
  const userData = localStorage.getItem('@@i2i/USER');
  const { referenceId } = props;
  const { findIndex, remove, some, difference } = require('lodash');

  /* States */
  const [updatingData, setUpdatingData] = useState(false);
  const [snackbarApproveState, setSnackbarApproveState] = useState(false);
  const [snackbarRejectState, setSnackbarRejectState] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [action, setAction] = useState('');
  const [remarks, setRemarks] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [revision, setRevision] = useState(0);
  const [isStatusApprovable, setIsStatusApprovable] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  /* Modified Components */
  const HeadTableCell = withStyles({
    root: {
      color: '#909196',
      fontSize: 12,
    },
  })(TableCell);

  const EnhancedTableHead = () => {
    const { onSelectAllClick, numSelected, rowCount, loading, onEmailRefresh } =
      props;
    return (
      <TableHead>
        <TableRow>
          <HeadTableCell padding="checkbox">
            <FormControlLabel
              control={<Checkbox
                indeterminate={selectedItems.length > 0 && selectedItems.length < totalItems}
                checked={selectedItems.length > 0}
                onChange={handleSelectAllClick}
                color="primary"
                disabled={updatingData || totalItems == 0}
              />}
              label="SELECT ALL"
              style={{ minWidth: 300, fontSize: 12 }}
            />
          </HeadTableCell>
          <HeadTableCell>CATEGORY</HeadTableCell>
          <HeadTableCell>CURRENT</HeadTableCell>
          <HeadTableCell>CHANGE REQUEST</HeadTableCell>
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  useEffect(() => {
    handleDataLoad(true, referenceId);
  }, []);

  const handleDataLoad = async (initialLoad, referenceId) => {
    let totalItemCount = 0;
    setUpdatingData(true);
    setSelectedItems([]);
    setRemarks('');

    if (initialLoad) {
      const draftList = await LoanService.getChangeRequests(referenceId);
      const actualCurrentList = await LoanService.getApplicationData(
        referenceId
      );
      const applicationStatus = await LoanService.getApplicationStatus(
        referenceId
      );

      if (
        applicationStatus.result.status === 'INCOMPLETE' ||
        applicationStatus.result.status === 'COMPLETED' ||
        applicationStatus.result.status === 'FAILED' ||
        applicationStatus.result.status === 'FOR RESUBMISSION' ||
        applicationStatus.result.status === 'RETURNED'
      ) {
        setIsStatusApprovable(true);
      }

      if (draftList && actualCurrentList) {
        let currentList = [],
          changesList = [],
          masterList = [];

        const draftChanges = draftList.result.applicationTabs;
        const currentChanges = actualCurrentList.result.applicationForm.applicationTabs;

        /* Compare current and draft changes */
        await Promise.all(currentChanges.map((tab) => {
          let newTab = tab;
          const tabId = findIndex(draftChanges, ['id', newTab.id]);

          if (tabId != -1) {
            tab.fields.map((field) => {
              if (
                findIndex(draftChanges[tabId].fields, ['code', field.code]) !=
                -1
              ) {
                currentList.push(field);
              }
            });
            draftChanges[tabId].fields.map((field) => {
              field.category = draftChanges[tabId].name;
              changesList.push(field);
            });
          }
        }));

        // Iterate changes list
        await Promise.all(changesList.map((change) => {
          const fieldId = findIndex(currentList, ['code', change.code]);
          masterList.push({
            name: change.name,
            category: change.category,
            fieldCode: change.code,
            currentValue: currentList[fieldId] && currentList[fieldId].value ? 
              currentList[fieldId].value : '-',
            draftValue: change.value,
            parent: change.parent,
            renderFields: [],
          });
        }));

        /* Check if there is changes on Selfie and Signature */
        if (draftList.result.selfie) {
          masterList.push({
            name: 'Selfie',
            category: 'Attachments',
            fieldCode: 'selfie',
            currentValue: actualCurrentList.result.applicationForm.selfie.hash,
            draftValue: draftList.result.selfie.hash,
            renderFields: [],
          });
        }
        if (draftList.result.signature) {
          masterList.push({
            name: 'Signature',
            category: 'Attachments',
            fieldCode: 'signature',
            currentValue:
              actualCurrentList.result.applicationForm.signature.hash,
            draftValue: draftList.result.signature.hash,
            renderFields: [],
          });
        }

        /* Collate child (render) fields into their respective parent field */
        await Promise.all(JSON.parse(JSON.stringify(masterList)).map((list) => {
          if (list.parent) {
            const fieldId = findIndex(JSON.parse(JSON.stringify(masterList)), ['fieldCode', list.parent]);
            if (fieldId != -1) {
              masterList[fieldId].renderFields.push(list);
            } else {
              let virtualParentField;
              currentChanges.map((tab) => {
                const parentId = findIndex(tab.fields, ['code', list.parent]);
                if (parentId != -1)
                  virtualParentField = tab.fields[parentId];
              });

              masterList.push({
                name: virtualParentField.code,
                category: list.category,
                fieldCode: virtualParentField.code,
                currentValue: virtualParentField.value,
                draftValue: "-",
                parent: "",
                renderFields: [],
              });

              // Push the child field to the newly created virtual parent field
              // should only execute once
              const fieldId = findIndex(JSON.parse(JSON.stringify(masterList)), ['fieldCode', list.parent]);
              masterList[fieldId].renderFields.push(list);
            }
            
            // Remove render fields to the master list
            // since it is already appended to the parent field as render field
            remove(masterList, ['name', list.name])
          }
        }));
        setMasterList(masterList);

        // Compute total change request count
        await Promise.all(masterList.map((item) => {
          totalItemCount++ ;
          item.renderFields && item.renderFields.map(() => {
            totalItemCount++;
          })
        }));
        setTotalItems(totalItemCount)
      }
    }

    setUpdatingData(false);
  };

  const updateSelectedFields = (data) => {
    setSelectedItems(data)
  }

  const checkIfAllRenderFieldsIncluded = (row, data) => {
    let newSelectedItems = selectedItems;
    let renderFieldList = [], determinate = false, fieldCount = 0;
    data.map((field) => {
      renderFieldList.push(field.category+ '_' + field.fieldCode)
    })
    renderFieldList.map((field) => {
      if (!selectedItems.includes(field)) {
        determinate = true;
        fieldCount++;
      } 
    });

    if (fieldCount == renderFieldList.length) {
      remove(newSelectedItems, function (fieldCode) {
        return fieldCode == row.category + '_' + row.fieldCode;
      });

    }

    // setSelectedItems(newSelectedItems);
    return determinate;
  }

  const checkIfPartialRenderFieldsIncluded = (row, data) => {
    let renderFieldList = [], partial = false, fieldCount = 0;
    let newSelectedItems = selectedItems;

    data && data.map((field) => {
      renderFieldList.push(field.category + '_' + field.fieldCode)
    })

    renderFieldList.map((field) => {
      if (selectedItems.includes(field)) {
        fieldCount++;
      } 
    });

    if (fieldCount == renderFieldList.length) {
      if (row.renderFields && row.renderFields.length > 0) {
        newSelectedItems.push(row.category + '_' + row.fieldCode);
      }
      partial = false;
    } else if (fieldCount > 0 && fieldCount != renderFieldList.length) {
      partial = true;
    } else if (fieldCount == 0) {
      remove(newSelectedItems, function (fieldCode) {
        return fieldCode == row.category + '_' + row.fieldCode;
      });
    }
    
    // setSelectedItems(newSelectedItems);
    return partial;
  }

  const handleClick = (event, value) => {
    let newSelectedItems = JSON.parse(JSON.stringify(selectedItems));
    let beforeSelectedItems = JSON.parse(JSON.stringify(selectedItems));

    if (newSelectedItems.includes(value.category + '_' + value.fieldCode)) {
      let includeAll = false;
      if (value.renderFields && value.renderFields.length > 0) {
        value.renderFields.map((render) => {
          if (!newSelectedItems.includes(render.category + '_' + render.parent) && !includeAll) {
            includeAll = true;
          }
        });
      }
      if (!includeAll) {
        value.renderFields && value.renderFields.map((render) => {
          if (!some(newSelectedItems, render.category + '_' + render.fieldCode)) {
            remove(newSelectedItems, function (fieldCode) {
              return fieldCode == value.category + '_' + value.fieldCode;
            });
          }
        });
      } else {
        value.renderFields && value.renderFields.map((render) => {
          if (!newSelectedItems.includes(render.category + '_' + render.parent)) {
            newSelectedItems.push(render.category + '_' + render.fieldCode);
          } 
        });
      }
    }

    if (newSelectedItems.includes(value.category + '_' + value.fieldCode)) {
      remove(newSelectedItems, function (fieldCode) {
        return fieldCode == value.category + '_' + value.fieldCode;
      });
    } else {
      newSelectedItems.push(value.category + '_' + value.fieldCode);
    }

    // If the interacted item has render fields (a.k.a Parent field)
    if (value.renderFields && value.renderFields.length > 0) {
      let removingAll = false;
      value.renderFields.map((field) => {
        if (newSelectedItems.includes(field.category + '_' + field.fieldCode)) {
          if (!newSelectedItems.includes(value.category + '_' + value.fieldCode)) {
            remove(newSelectedItems, function (fieldCode) {
              return fieldCode == field.category + '_' + field.fieldCode;
            });
          } else {
            const checkItems = beforeSelectedItems.map((item) => {
              return item.split('_')[1]
            })
            const renderItems = value.renderFields.map((item) => {
              return item.fieldCode
            })
            // If all render fields are already checked, remove it all, including the parent field
            if (!difference(renderItems, checkItems).length) {
              removingAll = true;
              remove(newSelectedItems, function (fieldCode) {
                return fieldCode == value.category + '_' + value.fieldCode;
              });
              value.renderFields.map((field) => {
                remove(newSelectedItems, function (fieldCode) {
                  return fieldCode == field.category + '_' + field.fieldCode;
                });
              })
            }
          }
        } else {
          // If the interacted item is unchecked, append it to the selectedItems array
          if (!removingAll) {
            newSelectedItems.push(field.category + '_' + field.fieldCode);
          }
        }
      });
    } else {
      // If the interacted item has parent node
      if (value.parent) {
        // If the interacted item is not yet on the selectedItems array, append it
        if (!newSelectedItems.includes(value.category + '_' + value.parent)) {
          newSelectedItems.push(value.category + '_' + value.parent);
        }
      } else {
        // If the interacted item has no parent node
        let isAdding = false;
        masterList.map((item) => {
          // If the draft item has renderFields
          if (item.renderFields && item.renderFields.length > 0) {
            // Append all renderFields to the selectedItems array
            item.renderFields.map((render) => {
              // If renderField is already selected, remove it from the selectedItems array
              if (newSelectedItems.includes(render.category + '_' + render.parent) && !isAdding) {
                remove(newSelectedItems, function (fieldCode) {
                  return fieldCode == value.category + '_' + value.parent;
                });
              } else {
                // Else add it
                isAdding = true;
                if (!newSelectedItems.includes(render.category + '_' + render.parent)) {
                  newSelectedItems.push(render.category + '_' + render.parent);
                }
              }
            })
          }
        })
      }
    }
    setSelectedItems(newSelectedItems);
    setRevision(Math.random());
  };

  const handleSelectAllClick = (event) => {
    let newSelectedItems = [];
    if (event.target.checked) {
      masterList.map((item) => {
        newSelectedItems.push(item.category + '_' + item.fieldCode);
        if (item.renderFields) {
          item.renderFields.map((field) => {
            newSelectedItems.push(field.category + '_' + field.fieldCode);
          })
        }
      });
    } else {
      masterList.map((item) => {
        remove(newSelectedItems, function (fieldCode) {
          return fieldCode == item.category + '_' + item.fieldCode;
        });
        if (item.renderFields) {
          item.renderFields.map((field) => {
            remove(newSelectedItems, function (fieldCode) {
              return fieldCode == field.category + '_' + field.fieldCode;
            });
          })
        }
      });
      
    }
    setSelectedItems(newSelectedItems);
    setRevision(Math.random());
  };

  const approveChangeRequest = async () => {
    let newSelectedItems = [];

    setUpdatingData(true);
    setApproveSuccess(false);
    setAction('approve');
    selectedItems.map((item) => {
      newSelectedItems.push(item.split('_')[1]);
    });

    const res = await LoanService.approveRejectChangeRequest(
      referenceId,
      'approve',
      newSelectedItems
    );

    if (res && res.status == 200) {
      setApproveSuccess(true);
      setSelectedItems([]);
      if (remarks.length > 0) addRemarks();
    } else {
      setApproveSuccess(false);
    }

    await updateMasterList(newSelectedItems);
    setSnackbarApproveState(true);
    setRevision(Math.random());
    setUpdatingData(false);
  };

  const rejectChangeRequest = async () => {
    let newSelectedItems = [];

    setUpdatingData(true);
    setRejectSuccess(false);
    setAction('reject');
    selectedItems.map((item) => {
      newSelectedItems.push(item.split('_')[1]);
    });

    const res = await LoanService.approveRejectChangeRequest(
      referenceId,
      'reject',
      newSelectedItems
    );

    if (res && res.status == 200) {
      setRejectSuccess(true);
      setSelectedItems([]);
      if (remarks.length > 0) addRemarks();
    } else {
      setRejectSuccess(false);
    }

    await updateMasterList(newSelectedItems);
    setSnackbarRejectState(true);
    setRevision(Math.random());
    setUpdatingData(false);
  };

  const updateMasterList = async (data, index) => {
    let newMasterList = masterList;

    newMasterList.map((list, index) => {
      if (list.renderFields && list.renderFields.length == 0) {
        delete newMasterList[index].renderFields;
      }
    });

    data.map((code, index) => {
        const removeIndex = findIndex(newMasterList, ['fieldCode', code]);
        if (removeIndex == -1) {
          newMasterList.map((fieldCode, index) => {
            if (fieldCode.renderFields) {
              const removeRenderIndex = findIndex(fieldCode.renderFields, ['fieldCode', code]);
              if (newMasterList[index].renderFields[removeRenderIndex]) {
                newMasterList[index].renderFields.splice(removeRenderIndex, 1)
              }
            }
          })
        }
        else if (newMasterList[removeIndex].renderFields && newMasterList[removeIndex].renderFields.length == 0) {
          delete newMasterList[removeIndex];
        } else if (newMasterList[removeIndex].renderFields) {
          newMasterList[index] && newMasterList[index].renderFields && newMasterList[index].renderFields.splice(removeIndex, 1)
        } else {
          delete newMasterList[removeIndex];
        }
    });

    newMasterList.map((list, index) => {
      if (list.renderFields && list.renderFields.length == 0) {
        delete newMasterList[index];
      }
    });

    setMasterList(newMasterList);
  }

  const addRemarks = async () => {
    const userObj = JSON.parse(userData);
    const remarkObject = {
      referenceId: referenceId,
      remarks: remarks,
      dateTime: moment(),
      userId: userObj.userInfo.id,
      username: userObj.userInfo.firstName + ' ' + userObj.userInfo.lastName,
    };

    await LoanService.postRemarks(remarkObject);
  };

  const generateRows = (data) => { 
    return (
      <Fragment>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
        >
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={(data.renderFields && data.renderFields.length > 0 && 
              selectedItems.includes(
              data.category + '_' + data.fieldCode)) && checkIfAllRenderFieldsIncluded(data, data.renderFields)
            }
            onClick={(event) =>
              handleClick(event, { ...data })
            }
            color="primary"
            checked={selectedItems.includes(
              data.category + '_' + data.fieldCode
            ) || checkIfPartialRenderFieldsIncluded(data, data.renderFields)}
            disabled={updatingData}
          /> {data.name}
        </TableCell>
        <TableCell>{data.category}</TableCell>
        <TableCell align="center">
          {data.category == 'Attachments' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left'
              }}
            >
              {/* <div
                style={{ fontSize: 11, color: '#636363' }}
              >
                {data.currentValue}
              </div> */}

              <Attachment 
                referenceId={referenceId}
                fileName={data.fieldCode}
                isThumbnail={true}
                value={data.currentValue}
                isDraft={false}
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  color: '#2B2D33',
                }}
              >
                {data.currentValue}
              </div>
            </div>
          )}
        </TableCell>
        <TableCell align="center">
          {data.category == 'Attachments' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              {/* <div
                style={{ fontSize: 11, color: '#636363' }}
              >
                {data.draftValue}
              </div> */}
              <Attachment 
                referenceId={referenceId}
                fileName={data.fieldCode}
                isThumbnail={true}
                value={data.draftValue}
                isDraft={true}
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  color: '#2B2D33',
                }}
              >
                {data.draftValue}
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
    )
	}

  const generateRenderFields = (fields) => { 
    if (fields && fields.length > 0) {
      return (
      fields.map((data) => {
        return (
          <Fragment>
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              style={{ backgroundColor: '#F5F5F5' }}
            >
            <TableCell padding="checkbox">
              <Checkbox
                onClick={(event) =>
                  handleClick(event, { ...data })
                }
                color="primary"
                checked={selectedItems.includes(
                  data.category + '_' + data.fieldCode
                )}
                disabled={updatingData}
                style={{ marginLeft: 16 }}
              /> {data.name}
            </TableCell>
            <TableCell />
            <TableCell align="center">
              {data.category == 'Attachments' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  {/* <div
                    style={{ fontSize: 11, color: '#636363' }}
                  >
                    {data.currentValue}
                  </div> */}

                  <Attachment 
                    referenceId={referenceId}
                    fileName={data.fieldCode}
                    isThumbnail={true}
                    value={data.currentValue}
                    isDraft={false}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: '#2B2D33',
                    }}
                  >
                    {data.currentValue}
                  </div>
                </div>
              )}
            </TableCell>
            <TableCell align="center">
              {data.category == 'Attachments' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  {/* <div
                    style={{ fontSize: 11, color: '#636363' }}
                  >
                    {data.draftValue}
                  </div> */}
                  <Attachment 
                    referenceId={referenceId}
                    fileName={data.fieldCode}
                    isThumbnail={true}
                    value={data.draftValue}
                    isDraft={true}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: '#2B2D33',
                    }}
                  >
                    {data.draftValue}
                  </div>
                </div>
              )}
            </TableCell>
          </TableRow>
        </Fragment>
        )
      }))
    }
	}

  return (
    <div style={{ fontFamily: 'Inter' }}>
      <div style={{ margin: 20 }}>
        <div
          style={{
            fontWeight: 600,
            fontSize: 28,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MdIcon
            path={mdiArrowLeft}
            color="#909196"
            size={0.8}
            style={{ marginRight: 16, cursor: 'pointer' }}
            onClick={() => {
              history.push(Paths.CHANGE_REQUEST);
              props.setRevision(Math.random());
            }}
          />{' '}
          Review Change Requests
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}></div>
          </div>
        </div>
        <br />
        <LinearProgress
          className={classes.root}
          style={{
            visibility: updatingData ? 'visible' : 'hidden',
            backgroundColor: '#E5E5E5',
          }}
        />
        <Paper>
          <div style={{ padding: 20 }}>
            <b>{referenceId}</b>
            <TableContainer style={{ marginTop: 12 }}>
              <Table className={classes.table} size="medium">
                <EnhancedTableHead
                  classes={classes}
                  // numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {masterList.length > 0
                    ? masterList.map((row) => {
                         return (
                           <Fragment>
                             {generateRows(row)}
                             {generateRenderFields(row.renderFields)}
                          </Fragment>
                         )
                      })
                    : null}
                </TableBody>
              </Table>
              {updatingData && totalItems == 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10% 0 10% 0',
                    color: '#2B2D33',
                  }}
                >
                  Retrieving change requests...
                </div>
              ) : (
                totalItems == 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '10% 0 10% 0',
                      color: '#2B2D33',
                    }}
                  >
                    No change requests
                  </div>
                )
              )}
            </TableContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 18,
              }}
            >
              <div style={{ fontSize: 12 }}>Remarks (optional)</div>
              <TextField
                className={classes.root}
                variant="outlined"
                disabled={totalItems == 0}
                value={remarks}
                onChange={(event) => setRemarks(event.target.value)}
                style={{
                  width: 340,
                  minWidth: 340,
                  height: 50,
                  marginRight: 24,
                  marginTop: 4,
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={selectedItems.length == 0 || updatingData}
                  style={{ marginRight: 8 }}
                  onClick={rejectChangeRequest}
                >
                  Reject
                </Button>
                {isStatusApprovable ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedItems.length == 0 || updatingData}
                    onClick={approveChangeRequest}
                  >
                    Approve
                  </Button>
                ) : (
                  <Tooltip
                    arrow
                    title="Only loan applications that are not yet submitted or processing can be applied with new changes"
                    placement="top"
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={true}
                      >
                        Approve
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>

      {/* Snackbars */}
      <Snackbar
        open={snackbarApproveState}
        autoHideDuration={6000}
        onClose={() => setSnackbarApproveState(false)}
      >
        <Alert
          onClose={() => setSnackbarApproveState(false)}
          severity={approveSuccess ? 'success' : 'error'}
        >
          {approveSuccess ? (
            <span>{'Successfully approved change request'}</span>
          ) : (
            <span>{'There was an error approving the change request'}</span>
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarRejectState}
        autoHideDuration={6000}
        onClose={() => setSnackbarRejectState(false)}
      >
        <Alert
          onClose={() => setSnackbarRejectState(false)}
          severity={rejectSuccess ? 'success' : 'error'}
        >
          {rejectSuccess ? (
            <span>{'Successfully rejected change request'}</span>
          ) : (
            <span>{'There was an error rejecting the change request'}</span>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReviewChangeRequest;
