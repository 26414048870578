import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  LinearProgress,
  ListItem,
  ListItemText,
  Snackbar,
  ListItemIcon
} from '@material-ui/core/';
import Paths from '../../enums/Paths';
import { useStyles, texts, toggleDisplay } from './helper/';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoanService from '../../services/LoanService';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui";
import { v0Theme, UBXTheme } from "../main/theme";
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { Icon as MdIcon } from '@mdi/react';
import {
  mdiCheck
} from '@mdi/js';

const AddCustomerProfile = (props) => {
  
  let history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [tick, setTick] = useState(0);
  
  const { 
    customerId, 
    customerName, 
    customerBirthdate, 
    customerEmail, 
    customerMobile,
    referenceId,
    setLinkProfilePrompted } = props;

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  async function handleDataLoad() {
    // Show modal if loan application is not linked to a customer profile
    (!customerId) && setOpenDialog(true);

    setIsLoading(true);
    const response = await LoanService.getCustomerProfileList(customerName, customerBirthdate, customerEmail, customerMobile, 1000, 1);

    if (response) {
      const list = response.result && response.result.list;
      setProfileList(list);
    }
    setTick(Math.random())
    setIsLoading(false);
  }

  function handleListItemClick(profile) {
    let profileId = profile.customerId;
    if(selectedProfile == profileId){
      setSelectedProfile("");
    } else {
      setSelectedProfile(profileId);
    }
  }

  async function assignCustomer() {
    let customerProfileId = selectedProfile;
    let res = await LoanService.assignCustomerProfile(referenceId, customerProfileId);

    if (res && res.status == 201) {
      setOpenDialog(false);
      setLinkSuccess(true);
    } else {
      setOpenDialog(true);
      setLinkSuccess(false);
    }
  }

  useEffect(() => {
		handleDataLoad();
    return () => {
      setProfileList([]);
    }
	}, 0);



  const renderUI = (data) => {
    return (
      <div>
        <MuiThemeProvider theme={UBXTheme}>
          <V0MuiThemeProvider muiTheme={v0Theme}>
            <Dialog open={openDialog} id="modal-linkcustomer">
              <DialogTitle style={{ fontFamily: 'Inter' }}>{texts.titleAddCxProfile}</DialogTitle>
              <DialogContent style={{ width: 522 }}>
                <DialogContentText
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: 'auto',
                  }}
                >
                  <div style={{ fontFamily: 'Inter' }}>
                    {texts.subtitleAddCxProfile}
                    {!isLoading ?
                      <div>
                        <div className={classes.suggestedProfiles}>
                          {texts.suggestedProfiles}
                        </div>
                        <div className={classes.profileListContainer}>
                          {data.map((profile, index) => {
                            return (
                              <ListItem
                                id={`list-item-${profile.uuid}`}
                                style={{ borderBottom: '1px solid #F6F6F6' }}
                                button key={index}
                                onClick={() => {
                                  handleListItemClick(profile)
                                }}
                              >
                                <ListItemText id={`list-item-text`} primary={`${profile.personFirstName} ${profile.personLastName}`} />
                                <ListItemIcon id={`list-item-icon`} style={{justifyContent: 'flex-end', }}>
                                  {selectedProfile == profile.customerId ?<MdIcon path={mdiCheck} size="1.5em" /> : '' }                                 
                                </ListItemIcon>
                              </ListItem>
                            )
                          })}
                        </div>
                      </div> : <LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />}
                  </div>
                  <div className={classes.modalActionContainer}>
                    <Button
                      id={`btn-return`}
                      className={classes.modalActionButton}
                      variant="outlined"
                      onClick={() => {
                        history.push(Paths.LOAN_APPLICATIONS);
                        props.setTick(Math.random());
                        setOpenDialog(false);
                      }}
                    >
                      {texts.buttonReturnToApplications}
                    </Button>
                    <Button
                      id={`btn-later`}
                      className={classes.modalActionButton}
                      variant="outlined"
                      onClick={() => {
                        setOpenDialog(false);
                        setLinkProfilePrompted(true);
                      }}
                    >
                      {texts.buttonLinkLater}
                    </Button>
                    {!customerId && !selectedProfile ?
                      <Button
                        id={`btn-create-profile`}
                        className={classes.modalActionButtonTwo}
                        variant="contained"
                        disabled={isLoading ? true: false}
                        onClick={() => {
                          localStorage.setItem("@@customerLoanRefId",
                            referenceId
                          );
                          history.push(Paths.CREATE_CUSTOMER_PROFILE);
                          props.setTick(Math.random());
                          setOpenDialog(false)
                        }}
                      >
                        {texts.buttonCreateNew}
                      </Button>
                      :
                      <Button
                        id={`btn-link-profile`}
                        className={classes.modalActionButtonTwo}
                        variant="contained"
                        onClick={() => {
                          assignCustomer()
                        }}
                        disabled={isLoading ? true: false}
                      >
                        {texts.linkProfile}
                      </Button>
                    }
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </V0MuiThemeProvider>
        </MuiThemeProvider>
        <Snackbar
          id={`snackbar-link-success`}
          open={linkSuccess}
          autoHideDuration={6000}
          onClose={() => setLinkSuccess(false)}
        >
          <Alert
            id={`alert-link-success`}
            onClose={() => setLinkSuccess(false)}
            severity={linkSuccess ? 'success' : 'error'}
          >
            {linkSuccess ? (
              <span>{'Successfully linked customer profile'}</span>
            ) : (
                <span>{'There was an error in linking customer profile'}</span>
              )}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  return (
    renderUI(profileList)
  )
}

export default AddCustomerProfile;
