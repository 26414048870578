import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiFileTableOutline, mdiMagnify } from '@mdi/js';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui";
import LoanAgents from '../loanAgents/LoanAgents';
import LoanApplications from '../loanApplications/LoanApplications';
import ReviewApplication from '../reviewApplication/ReviewApplication';
import UserManagement from '../userManagement/UserManagement';
import AdminBrands from '../adminBrands/AdminBrands';
import BrandDetails from '../adminBrands/BrandDetails';
import EditBrand from '../adminBrands/EditBrand';
import ExportApplications from '../exportApplications/ExportApplications';
import ImportApplications from '../importApplications/ImportApplications';
import { FormDesigner, FormList, FormPreview, DropdownList, DropdownData } from '../cashFlow/formBuilder';
import FormPdf from '../cashFlow/formBuilder/FormPdf';
import Logs from '../logs/Logs';
import { v0Theme, UBXTheme } from "./theme";
import { useHistory } from 'react-router-dom'
import ChangeRequest from '../changeRequest/ChangeRequest';
import ReviewChangeRequest from '../changeRequest/ReviewChangeRequest';
import CryptoJS from "crypto-js";
import { CustomerProfile, ProfilePage, CreateProfile } from '../customerProfile';
import CashFlowAnalysis from '../reviewApplication/CashFlowAnalysis';
import qs from 'qs';

const { REACT_APP_CRYPTO_SECRET_KEY } = process.env;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F47721",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#F47721",
    },
    charcoal: {
      main: "#FFFFFF",
      contrastText: "#2B2D33",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  backgroundColor: "#FFFFFF",
});

const Main = (props) => {
	
	const history = useHistory()
	let storedAgencyBankingData = JSON.parse(localStorage.getItem('@@agency/USER'))
	let userData = JSON.parse(localStorage.getItem('@@i2i/USER'));
	if (userData.agentOf.agencyId.length == 11) userData.agentOf.agencyId = CryptoJS.AES.encrypt(userData.agentOf.agencyId, REACT_APP_CRYPTO_SECRET_KEY).toString()

	localStorage.setItem("@@i2i/USER",
		JSON.stringify(userData)
	);

	const [screenIndex, setScreenIndex] = useState(0)
	const [archiveSuccess, setArchiveSuccess] = useState(false)
	const [revision, setRevision] = useState(0)
	const [searchKey, setSearchKeyGlobal] = useState(storedAgencyBankingData && storedAgencyBankingData.searchKey ? storedAgencyBankingData.searchKey : "")
	const [loanProductKey, setLoanProductKeyGlobal] = useState(storedAgencyBankingData && storedAgencyBankingData.loanProductKey ? storedAgencyBankingData.loanProductKey : "")
	const [statusKey, setStatusKeyGlobal] = useState(storedAgencyBankingData && storedAgencyBankingData.statusKey ? storedAgencyBankingData.statusKey : "")
	const [supervisorKey, setSupervisorKeyGlobal] = useState(storedAgencyBankingData && storedAgencyBankingData.supervisorKey ? storedAgencyBankingData.supervisorKey : "")
	const [locationKey, setLocationKeyGlobal] = useState(storedAgencyBankingData && storedAgencyBankingData.locationKey ? storedAgencyBankingData.locationKey : "")
	const [selectedAgent, setSelectedAgent] = useState(storedAgencyBankingData && storedAgencyBankingData.selectedAgent ? storedAgencyBankingData.selectedAgent : "")
	const [selectedBrand, setSelectedBrand] = useState(storedAgencyBankingData && storedAgencyBankingData.selectedBrand ? storedAgencyBankingData.selectedBrand : "")
	const [selectedProduct, setSelectedProduct] = useState(storedAgencyBankingData && storedAgencyBankingData.selectedProduct ? storedAgencyBankingData.selectedProduct : "")
	const [selectedApplication, setSelectedApplication] = useState(storedAgencyBankingData && storedAgencyBankingData.selectedApplication ? storedAgencyBankingData.selectedApplication : "")
	const [selectedReferenceId, setSelectedReferenceId] = useState(storedAgencyBankingData && storedAgencyBankingData.selectedReferenceId ? storedAgencyBankingData.selectedReferenceId : "")
	const [tick, setTick] = useState(storedAgencyBankingData && storedAgencyBankingData.tick ? storedAgencyBankingData.tick : "")
	const [navHistory, setNavHistory] = useState(storedAgencyBankingData && storedAgencyBankingData.navHistory ? storedAgencyBankingData.navHistory : "")
	const [customerName, setCustomerName] = useState("");
	const [renderSnackBar, setRenderSnackBar] = useState(false);
	const [createSuccess, setCreateSuccess] = useState(false);
	
	if (storedAgencyBankingData && storedAgencyBankingData.lastLocation != history.location.pathname) {
		setSearchKeyGlobal("")
		setLoanProductKeyGlobal("")
		setStatusKeyGlobal("")
	}

	let agencyBankingData = {
		selectedAgent: selectedAgent,
		selectedBrand: selectedBrand,
		selectedProduct: selectedProduct,
		selectedApplication: selectedApplication,
		searchKey: searchKey,
		statusKey: statusKey,
		supervisorKey: supervisorKey,
		loanProductKey: loanProductKey,
		locationKey: locationKey,
		lastLocation: history.location.pathname,
		tick: tick,
	}

	localStorage.setItem("@@agency/USER",
		JSON.stringify(agencyBankingData)
	);

	let userRole
	if (userData) {
		userRole = userData.roles[0].name
	}

	if (history.location.pathname == "/loanAgents") {
		return (
			<LoanAgents 
			setAgent={setSelectedAgent} 
			setSearchKeyGlobal={setSearchKeyGlobal} 
			setLocationKeyGlobal={setLocationKeyGlobal} 
			role={userRole} 
			globalSearchKey={searchKey} 
			globalLocationKey={locationKey} />
		)
	}
	else if (history.location.pathname.includes("/cashflow/designer/")) {
		const id = history.location.pathname.split('/')[3];
		return (
			<FormDesigner id={id} setTick={setTick}/>
		)
	}
	else if (history.location.pathname.includes("/cashflow/preview/")) {
		const id = history.location.pathname.split('/')[3];
		return (
			<FormPreview id={id} setTick={setTick} />
		)
	} 
	else if (history.location.pathname.includes("/cashflow/pdf/")) {
		const id = history.location.pathname.split('/')[3];
		return (
			<FormPdf id={id} setTick={setTick} />
		)
	}
	else if (history.location.pathname == "/loanApplications") {
		return (
			<LoanApplications 
				agent={selectedAgent} 
				setApplication={setSelectedApplication} 
				setSearchKeyGlobal={setSearchKeyGlobal} 
				setLoanProductKeyGlobal={setLoanProductKeyGlobal} 
				setNavHistory={setNavHistory}
				setStatusKeyGlobal={setStatusKeyGlobal} 
				setAgent={setSelectedAgent} 
				archiveSuccess={archiveSuccess} 
				setArchiveSuccess={setArchiveSuccess}
				globalSearchKey={searchKey} 
				globalLoanProductKey={loanProductKey}
				globalStatusKey={statusKey} />
		)
	}
	else if (history.location.pathname == "/reviewApplication") {
		return (
			<ReviewApplication 
				application={selectedApplication} 
				setApplication={setSelectedApplication} 
				setArchiveSuccess={setArchiveSuccess}
				setLocationKeyGlobal={setLocationKeyGlobal}
				globalLocationKey={locationKey}
				navHistory={navHistory}
				setNavHistory={setNavHistory}
				userData={userData}
				setTick={setTick} />
		)
	}
	else if (history.location.pathname == "/agencyUsers") {
		return (
			<UserManagement
				setSearchKeyGlobal={setSearchKeyGlobal} 
				setLocationKeyGlobal={setLocationKeyGlobal}
				setSupervisorKeyGlobal={setSupervisorKeyGlobal}
				globalSearchKey={searchKey} 
				globalLocationKey={locationKey}
				globalSupervisorKey={supervisorKey}
			/>
		)
	}
	else if (history.location.pathname == "/brands") {
		return (
			<AdminBrands setSelectedBrand={setSelectedBrand} />
		)
	}
	else if (history.location.pathname == "/brandDetails") {
		return (
			<BrandDetails brand={selectedBrand} setSelectedBrand={setSelectedBrand} setSelectedProduct={setSelectedProduct} />
		)
	}
	else if (history.location.pathname == "/editBrand") {
		return (
			<EditBrand brand={selectedBrand} setSelectedProduct={setSelectedProduct} selectedProduct={selectedProduct} />
		)
	}
	else if (history.location.pathname == "/agencyLogs") {
		return (
			<Logs 
				role={userRole}
				setSearchKeyGlobal={setSearchKeyGlobal}
				setStatusKeyGlobal={setStatusKeyGlobal}
				setApplication={setSelectedApplication}
				setNavHistory={setNavHistory}
				globalSearchKey={searchKey}
				globalStatusKey={statusKey} />
		)
	}
	else if (history.location.pathname == "/export") {
		return (
			<MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
					<ExportApplications />
				</V0MuiThemeProvider>
			</MuiThemeProvider>
		)
	}
	else if (history.location.pathname == "/import") {
		return (
			<MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
					<ImportApplications />
				</V0MuiThemeProvider>
			</MuiThemeProvider>
		)
	}
	else if (history.location.pathname == "/changeRequest") {
		return (
			<MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
					<ChangeRequest setRevision={setRevision}
						setSearchKeyGlobal={setSearchKeyGlobal}
						globalSearchKey={searchKey} />
				</V0MuiThemeProvider>
			</MuiThemeProvider>
		)
	}
	else if (history.location.pathname.includes("/changeRequest/review/")) {
		const referenceId = history.location.pathname.split('/')[3];
		return (
			<MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
					<ReviewChangeRequest referenceId={referenceId} setRevision={setRevision} />
				</V0MuiThemeProvider>
			</MuiThemeProvider>
		)
	}
	else if (history.location.pathname == "/cashflow") {
		return (
			<FormList setTick={setTick} />
		)
	}
	else if (history.location.pathname == "/datasource") {
		return (
			<DropdownList setTick={setTick} />
		)
	}
	else if (history.location.pathname.includes("/datasource/data/")) {
		const referenceId = history.location.pathname.split('/')[3];
		return (
			<DropdownData id={referenceId} setTick={setTick}/>
		)
	}
	else if (history.location.pathname == "/customerProfile") {
		return (
		 <CustomerProfile setRevision={setRevision}
				setSearchKeyGlobal={setSearchKeyGlobal}
				globalSearchKey={searchKey}
				customerName={customerName}
				setCustomerName={setCustomerName}
				renderSnackBar={renderSnackBar}
				createSuccess={createSuccess}
				setRenderSnackBar={setRenderSnackBar}
		 />
		)
	}
	else if (history.location.pathname.includes("/customerProfile/")) {
		const id = history.location.pathname.split('/')[2];
		return (
			<ProfilePage customerId={id} setTick={setTick}
			setSearchKeyGlobal={setSearchKeyGlobal} 
			setLoanProductKeyGlobal={setLoanProductKeyGlobal} 
			setStatusKeyGlobal={setStatusKeyGlobal} 
			globalSearchKey={searchKey} 
			globalLoanProductKey={loanProductKey}
			globalStatusKey={statusKey}
			setNavHistory={setNavHistory}
			setApplication={setSelectedApplication}
			customerName={customerName} />
		)
	}
	else if (history.location.pathname.includes("/reviewApplication/cashFlow/")){
		const referenceId = history.location.pathname.split('/')[3];
		const redirected = qs.parse(history.location.search, { ignoreQueryPrefix: true }).redirected;
		return (
			<CashFlowAnalysis
			id={referenceId}
			redirected={redirected}
			setTick={setTick}
			userData={userData}
			/>
		)
	}
	else if (history.location.pathname == "/createProfile") {
		return (
		 <CreateProfile setRevision={setRevision}
		 setSearchKeyGlobal={setSearchKeyGlobal}
		 globalSearchKey={searchKey}
		 setRenderSnackBar={setRenderSnackBar}
		 setCreateSuccess={setCreateSuccess}/>
		)
	}
	else if (history.location.pathname === "/agentManagement") {
    return (
      <MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
					<div>Agent Management</div>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    );
  }
}

export default Main;
