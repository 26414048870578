/* eslint-disable indent */
import React, { useState } from 'react'
import { new_i2i_logo } from "../../assets/images"
import { Button, IconButton, AppBar, Toolbar, Drawer, MenuList, MenuItem, Popover, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

const NavBar = (props) => {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar position="static" color="inherit" className={classes.appBar}>
				<Toolbar>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<img src={new_i2i_logo} height={24} width={56} alt="i2i Logo" />

						<div>
							{/* <SearchIcon /> */}
						</div>
					</div>
				</Toolbar>
			</AppBar>

		</div>
	)

	// const menuList = <MenuList style={MediaQuery.isMobile() ? { width: '100%' } : { width: 260 }}>
	//   <NavBarMenuItem onClick={() => handleDrawerMenuClick(Paths.home)}>Home</NavBarMenuItem>
	//   <NavBarMenuItem onClick={() => handleDrawerMenuClick(Paths.branches)} disabled>Locate Branches</NavBarMenuItem>
	//   <NavBarMenuItem onClick={() => handleDrawerMenuClick(Paths.banks)}>Partner Banks</NavBarMenuItem>
	//   <NavBarMenuItem onClick={() => handleDrawerMenuClick(Paths.billers)}>View Billers</NavBarMenuItem>
	//   <NavBarMenuItem onClick={() => handleDrawerMenuClick(Paths.about)}>About Us</NavBarMenuItem>
	// </MenuList>

	// const renderMenu = MediaQuery.isMobile() ?
	//   <Drawer open={menu} anchor='right' PaperProps={{ style: { width: '100%' } }}>
	//     <div style={styles.mobile.menuListContainer}>
	//       {menuList}
	//       <NavBarButton color='primary' variant='contained' style={{ width: 328 }} onClick={() => props.history.push(Paths.demo)}>Book a Demo</NavBarButton>
	//     </div>
	//   </Drawer> :
	// <Popover
	//   open={menu}
	//   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
	//   transformOrigin={{ vertical: 'top', horizontal: 'right' }}
	//   onClose={() => openMenu(false)}
	// >
	//   {menuList}
	// </Popover>

	// const renderToolbar = MediaQuery.isMobile() ?
	//   <div style={styles.mobile.container}>
	//     {i2iLogo}
	//     <Toolbar>
	//       <NavBarButton color='primary' onClick={() => props.history.push(Paths.demo)}>Book a Demo</NavBarButton>
	//       <IconButton>
	//         <MenuIcon onClick={() => openMenu(true)} />
	//       </IconButton>
	//     </Toolbar>
	//   </div> :
	// <div style={styles.mobile.container}>
	//   {i2iLogo}
	//   <Toolbar>
	//     <NavBarButton color='charcoal' onClick={() => props.history.push(AppPaths.LOGIN)}>Login</NavBarButton>
	//     <NavBarButton color='primary' onClick={() => props.history.push(Paths.demo)}>Book a Demo</NavBarButton>
	//     <IconButton>
	//       <MenuIcon onClick={() => openMenu(true)} />
	//     </IconButton>
	//   </Toolbar>
	// </div>

	// if (MediaQuery.isTablet()) {
	//   return (
	// <NavBar color='secondary'>
	//   {renderToolbar}
	//   {renderMenu}
	// </NavBar>
	//   )
	// } else {
	//   return (
	//     <NavBar color='secondary'>
	//       <div style={styles.desktop.container}>
	//         <div style={styles.desktop.menu}>
	//           {i2iLogo}
	//           <Toolbar>
	//             <NavBarButton color="charcoal" onClick={() => props.history.push(Paths.home)}>Home</NavBarButton>
	//             <NavBarButton color="charcoal" onClick={() => props.history.push(Paths.branches)} disabled>Locate Branches</NavBarButton>
	//             <NavBarButton color="charcoal" onClick={() => props.history.push(Paths.banks)}>Partner Banks</NavBarButton>
	//             <NavBarButton color="charcoal" onClick={() => props.history.push(Paths.billers)}>View Billers</NavBarButton>
	//             <NavBarButton color="charcoal" onClick={() => props.history.push(Paths.about)}>About Us</NavBarButton>
	//           </Toolbar>
	//         </div>
	//         <Toolbar>
	//           <NavBarButton color="charcoal" onClick={() => props.history.push(AppPaths.LOGIN)}>Login</NavBarButton>
	//           <Button color="primary" variant="contained" onClick={() => props.history.push(Paths.demo)}>Book a Demo</Button>
	//         </Toolbar>
	//       </div>
	//     </NavBar>
	//   );
	// }
}

const styles = {
	bigDesktop: {
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '100%'
		},
		menu: {
			display: 'flex',
		}
	},
	smallDesktop: {
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '100%'
		},
		menu: {
			display: 'flex',
		}
	},
	mobile: {
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '100%'
		},
		menuListContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: 450
		},
		loginWarning: {
			width: 260,
			padding: '40px 16px 17px',
			primaryText: {
				marginBottom: 24,
				fontFamily: 'Inter',
				fontWeight: 'bold',
				fontSize: 24,
				lineHeight: '32px',
				color: '#2B2D33'
			},
			secondaryText: {
				marginBottom: 30,
				fontFamily: 'Inter',
				fontSize: 13,
				lineHeight: '24px',
				letterSpacing: '-0.01em',
				color: '#2B2D33'
			},
			button: {
				marginBottom: 30,
				fontFamily: 'Inter',
				fontWeight: 600,
				fontSize: 13,
				lineHeight: '24px',
				width: '100%'
			},
			tertiaryText: {
				fontFamily: 'Inter',
				fontWeight: 600,
				fontSize: 13,
				lineHeight: '24px',
				color: '#909196'
			},
		}
	}
}

export default NavBar
