import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../helpers/Styles";

const PaginationCustom = (props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "24px 15px 24px 15px",
        backgroundColor: "#F6F6F6",
      }}
    >
      <div style={{ marginRight: 16, fontSize: 14 }}>
        Showing{" "}
        {props.count === 0
          ? "-"
          : `${
              props.page != props.maxPage
                ? props.totalCount * props.page -
                  (props.showItems - 1) +
                  "-" +
                  props.totalCount * props.page
                : (props.page - 1) * props.showItems + 1 != props.count
                ? (props.page - 1) * props.showItems + 1 + "-" + props.count
                : (props.page - 1) * props.showItems + 1
            } `}{" "}
        of {props.count === 0 ? "-" : props.count}
      </div>
      <Pagination
        className={classes.root}
        page={props.page}
        count={props.maxPage}
        shape="rounded"
        onChange={(event, value) => {
          props.setPage(value);
          props.setInitialized(false);
        }}
      />
    </div>
  );
};

export default PaginationCustom;
