import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	IconButton,
    Grid,
    Menu,
    MenuItem,
    TextField,
	FormControl,
    FormControlLabel,
    Switch,
    Table,
	TableContainer,
    TableBody,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline, mdiPlusCircleOutline } from '@mdi/js';
import { View } from '@react-pdf/renderer';
import { ReactSortable } from "react-sortablejs";
import { 
    Alert, 
    newId, 
    useStyles, 
    texts,
    StyledTableSummaryCell,
    StyledTableSummaryRow,
    PdfStyles,
    componentTypes, 
    aligns, 
    limits
 } from '../../helper';
import { 	
    FormTableSummaryDisplayDesigner, 
	FormTableSummaryDisplayPreview,
    FormTableSummaryDisplayPdf,
    FormTableSummarySpacerDesigner,
	FormTableSummarySpacerPreview,
    FormTableSummarySpacerPdf,
    FormTableSummaryFormulaDesigner, 
	FormTableSummaryFormulaPreview,
    FormTableSummaryFormulaPdf,
} from '.';

const FormTableSummaryProperties = (props) => {
    const classes = useStyles();
    const [attached, setAttached] = useState(true);

    const handleAttachedChange = (event, checked) => { 
        setAttached(checked);
        props.updateAttached(checked);
    }

    useEffect(() => {
        setAttached(props.attached);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableSummary}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={attached} onChange={handleAttachedChange} disabled={props.isReadOnly} />}
                        label={attached ? texts.infoValueAttached : texts.infoValueDetached}/>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.propertyInfo}>{texts.infoPropertyTableSummary}</span>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableSummaryDesigner = (props) => {
	const classes = useStyles();
    const [attached, setAttached] = useState(true);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const [isInitialize, setIsInitialize] = useState(false);
    const [children, setChildren] = useState(null);
	const [tick, setTick] = useState(0);
	const components = useRef([]);
	const tracker = useRef({});
	const withError = useRef(false);

    const handleDeleteComponent = () => {
        props.handleComponentDelete(props.id); 
        setIsDeleteAlertOpen(false);
	}

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleInsertDisplay = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLESUMMARYDISPLAY, 
                        value: '',
                        help: '',
                        align: aligns.LEFT,
                        bold: false,
                        width: 0 };
        handleChildInsert(child);
	}

    const handleInsertFormula = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLESUMMARYFORMULA, 
                        formula: [],
                        align: aligns.LEFT,
                        prefix: '',
                        suffix: '',
                        bold: false,
                        width: 0,
                        filler: '',
                        decimal: false,
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertSpacer = () => { 
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLESUMMARYSPACER, 
                        width: 0 };
        handleChildInsert(child);
	}

    const handleUpdateAttached = (attached) => {
        setAttached(attached);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        if (event) {
			event.stopPropagation();
		}
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableSummaryProperties 
                                            id={props.id}
                                            attached={attached}
                                            updateAttached={handleUpdateAttached}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const handleChildInsert = (child) => {
        const data = [...(children || [])];
		data.push(child);
		setChildren(data);
		components.current.push(child);
        handleToggleMenu();
        props.handleTrackChange();
    }

    const handleComponentDelete = (id) => {
        handleComponentSelect();
		components.current = components.current.filter((component) => (component.id !== id));
		const data = [...(children || [])].filter((child) => (child.id !== id));
		setChildren(data);
		delete tracker.current[id];
        props.handleReferenceDelete(id);
        props.handleTrackChange();
	}

    const sendTick = () => {
        if (children && children.length > 0) {
            for (const key of Object.keys(tracker.current)) {
                tracker.current[key] = false;
            }
            withError.current = false;
            const nextTick = tick + 1;
            setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

    const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		const payload = [];
		// Follow the sorting of the components
		for (const child of children) {
			const groupComp = components.current.find(comp => comp.id === child.id);
			if (groupComp) {
				payload.push(groupComp);
			}
        }

		props.updateComponent({ id: props.id, 
                                type: componentTypes.FORMTABLESUMMARY, 
                                attached, 
                                children: payload }, false);
	}

	const updateComponent = (update, error) => {
		if (!withError.current) {
			tracker.current[update.id] = true;
			if (error) {
				withError.current = true;
                // We have an error notify parent component immediately
              props.updateComponent({ id: props.id, 
                                      type: componentTypes.FORMTABLESUMMARY, 
                                      attached }, true);
			} else {
				components.current = components.current.map((component) => {
					if (component.id === update.id) {
						return update;
					} else {
						return component;
					}
				});
				trackUpdates();
			}
		}
	}

	const trackComponent = (id) => {
		tracker.current[id] = false;
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMTABLESUMMARYDISPLAY) {
            return (
                <div key={child.id} className={`${classes.groupTableSummaryCompContainer} ${classes.componentItem}`}>
                    <FormTableSummaryDisplayDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleTrackChange={props.handleTrackChange}		
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        value={child.value}
                        help={child.help}
                        align={child.align}
                        bold={child.bold}
                        width={child.width} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYFORMULA) {
            return (
                <div key={child.id} className={`${classes.groupTableSummaryCompContainer} ${classes.componentItem}`}>
                    <FormTableSummaryFormulaDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        formulaReferences={props.formulaReferences}
                        dropdownReferences={props.dropdownReferences}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleTrackChange={props.handleTrackChange}		
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        formula={child.formula}
                        align={child.align}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        bold={child.bold}
                        width={child.width}
                        filler={child.filler}
                        decimal={child.decimal}
                        numeric={child.numeric}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYSPACER) {
            return (
                <div key={child.id} className={`${classes.groupTableSummaryCompContainer} ${classes.componentItem}`}>
                    <FormTableSummarySpacerDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}	
                        tick={tick}     
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        width={child.width} />
                </div>
            );
        } else {
            return <span/>
        }
    }

    const initialize = (data) => {
        const children = [];
        components.current = [];
        for (const child of data) {
            children.push(child);
            components.current.push(child);
        }
        setChildren(children);
	}

    useEffect(() => {
        if (isInitialize) {
            sendTick();
        } else {
            setIsInitialize(true);
            setAttached(props.attached);
            initialize(props.children || []);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.componentTitleWrapper}>
                            <div className={classes.componentTitleWithIcon}>{texts.infoTypeTableSummary}</div>
                            {!props.isReadOnly &&
                            <Fragment>
                                <IconButton ref={menuAnchorRef} aria-label='Add' size='small' onClick={handleToggleMenu}>
                                    <MdIcon path={mdiPlusCircleOutline} size='1em' />
                                </IconButton>
                                <Menu id='component-input-menu' 
                                    className={classes.componentMenu}
                                    anchorEl={menuAnchorRef.current} 
                                    keepMounted 
                                    open={isMenuOpen} 
                                    elevation={1}
                                    onClose={handleToggleMenu} 
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    <MenuItem onClick={handleInsertDisplay}>{texts.menuTableSummaryDisplay}</MenuItem>
                                    <MenuItem onClick={handleInsertFormula}>{texts.menuTableSummaryFormula}</MenuItem>
                                    <MenuItem onClick={handleInsertSpacer}>{texts.menuTableSummarySpacer}</MenuItem>
                                </Menu>	
                            </Fragment>
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {(children && children.length > 0) && 
                        <ReactSortable 
                            list={children} 
                            setList={setChildren} 
                            animation={200}
                            handle='.group-comp-table-summary-sort-handler'	
                            className={`${classes.groupTableSummaryCompWrapper} ${classes.componentScroll}`}
                            onUpdate={props.handleTrackChange}>
                            {children.map((child) => (
                                renderChild(child)
                            ))}
                        </ReactSortable>
                    }
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableSummary} 
                message={texts.deleteAlertMessageTableSummary}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormTableSummaryPreview = (props) => {
    const classes = useStyles();

    const renderChild = (child, childIndex) => {
        if (child.type === componentTypes.FORMTABLESUMMARYDISPLAY) {
            return (
                <FormTableSummaryDisplayPreview 
                    id={child.id}
                    align={child.align}
                    value={child.value}
                    help={child.help}
                    bold={child.bold}
                    width={child.width}
                    className={(childIndex === 0) ? classes.plCell : null} />
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYFORMULA) {
            return (
                <FormTableSummaryFormulaPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    sources={props.sources}
                    formulaTick={props.formulaTick}
                    id={child.id}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    decimal={child.decimal}
                    number={child.number}
                    reference={child.reference}
                    className={(childIndex === 0) ? classes.plCell : null}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYSPACER) {
            return (
                <FormTableSummarySpacerPreview
                    id={child.id}
                    width={child.width} />
            );
        } else {
            return <span/>
        }
    }

	return (
        <Grid container spacing={3} className={clsx({[classes.tableAttached]: props.attached}, {[classes.tableDetached]: !props.attached})}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table className={classes.tableComponet} aria-label='group table summary'>
                        <TableBody>
                            <StyledTableSummaryRow className={clsx({[classes.tableRowAttached]: props.attached}, {[classes.tableRowDetached]: !props.attached})}>
                                {(props.children || []).map((child, childIndex) => (
                                   renderChild(child, childIndex)
                                ))}
                                <StyledTableSummaryCell className={classes.tableSummaryActionCell}></StyledTableSummaryCell>
                            </StyledTableSummaryRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
       </Grid>
	)
}

export const FormTableSummaryPdf = (props) => {

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMTABLESUMMARYDISPLAY) {
            return (
                <FormTableSummaryDisplayPdf 
                    id={child.id}
                    align={child.align}
                    value={child.value}
                    help={child.help}
                    bold={child.bold}
                    width={child.width}
                    attached={props.attached} />
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYFORMULA) {
            return (
                <FormTableSummaryFormulaPdf 
                    inputData={props.inputData}
                    id={child.id}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    decimal={child.decimal}
                    numeric={child.numeric}
                    attached={props.attached} />
            );
        } else if (child.type === componentTypes.FORMTABLESUMMARYSPACER) {
            return (
                <FormTableSummarySpacerPdf
                    id={child.id}
                    width={child.width}
                    attached={props.attached} />
            );
        } else {
            return <span/>
        }
    }

	return (
        <View style={(props.attached ? PdfStyles.groupTableSummaryAttachedWrapper : PdfStyles.groupTableSummaryDetachedWrapper)}>
            <View style={PdfStyles.groupTableDataRow}>
                {(props.children || []).map((child) => (
                    renderChild(child)
                ))}
            </View>
        </View>
    )
}