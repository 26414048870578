import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
	FormControl,
    Grid,
    InputAdornment,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    useStyles, 
    texts, 
    getWidthStyle,
    getPdfWidthStyle,
    StyledTableSummaryCell,
    PdfStyles,
    componentTypes,
} from '../../helper';

const FormTableSummarySpacerProperties = (props) => {
    const classes = useStyles();
    const [width, setWidth] = useState(0);

    const handleWidthChange = (event) => {
        const width = parseInt(event.target.value || 0);
        if (width >= 0 && width <= 100) {
            setWidth(width);
            props.updateWidth(width);  
        }
    }

    useEffect(() => {
        setWidth(props.width || '');
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableSummarySpacer}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-width'
                            type='number'
                            label={texts.labelComponentWidth}
                            variant='outlined'
                            value={width || ''}
                            onChange={handleWidthChange}
                            InputProps={{
                                inputProps: { min: 1, max: 100 },
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableSummarySpacerDesigner = (props) => {
	const classes = useStyles();
    const [width, setWidth] = useState(0);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateWidth = (width) => {
        setWidth(width);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableSummarySpacerProperties 
                                            id={props.id}
                                            width={width}
                                            updateWidth={handleUpdateWidth}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLESUMMARYSPACER, 
                                    width }, false);
        } else {
            setIsInitialize(true);
            setWidth(props.width);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-summary-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableSummarySpacer}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.componentDisplayBoxBorder}>&nbsp;</div>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableSummarySpacer} 
                message={texts.deleteAlertMessageTableSummarySpacer}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormTableSummarySpacerPreview = (props) => {
    return (
        <StyledTableSummaryCell style={getWidthStyle(props.width)}>
            &nbsp;
        </StyledTableSummaryCell>
    )
}

export const FormTableSummarySpacerPdf = (props) => {
    return (
        <View style={getPdfWidthStyle(props.width, (props.attached ? PdfStyles.groupTableSummaryAttachedCol : PdfStyles.groupTableSummaryDetachedCol))} >
            <Text> </Text>
        </View>
    );
}
