export const texts = {
  buttonDeleteDocument: 'Delete',
  buttonReplaceDocument: 'Replace Document',
  buttonUploadDocument: 'Upload Document',
  buttonReturnToApplications: 'Return to Applications',
  buttonLinkLater: 'Later',
  buttonCreateNew: 'Create New',
  confirmationSaveChanges: 'You have made changes in the following screen. Do you want to save these changes before leaving?',
  confirmationDeleteDocument: 'Are you sure you want to delete this document?',
  successfulUpload: 'Document has been successfully uploaded.',
  successfulDelete: 'Document has been successfully removed.',
  failedUpload: 'Document upload failed. Please try again.',
  failedDelete: 'Document delete failed. Please try again.',
  fileSizeLimitReached: 'Maximum upload file size is 5MB',
  titleAddCxProfile: 'Add Customer Profile',
  subtitleAddCxProfile: 'This loan application is not linked to any Customer Profile. Create new or select from an existing profile below to continue processing this application.',
  suggestedProfiles: 'Suggested Profiles',
  linkProfile: 'Link Profile'
}