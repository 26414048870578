export const texts = {
    headerFormList: 'Form Builder',
    headerDropdownList: 'Dropdown Source',
    headerNewForm: 'Create New Form',
	headerDuplicateForm: 'Duplicate Form',
    headerEditForm: 'Edit Form',
    headerNewSource: 'Create New Source',
    headerEditSource: 'Edit Source',
    headerNewData: 'Add New Data',
    headerEditData: 'Edit Data',

	buttonCreateNewForm: 'Create New Form',
    buttonCreateNewSource: 'Create New Source',
    buttonAddNewData: 'Add New Data',
	buttonNewForm: 'Create Form',
    buttonNewEdit: 'Create and Edit',
    buttonNewSource: 'Create Source',
	buttonDuplicateForm: 'Duplicate Form',
    buttonDuplicateEdit: 'Duplicate and Edit',
    buttonSaveForm: 'Save Form',
    buttonSaveSource: 'Save Source',
    buttonClosePreview: 'Close Preview',
    buttonPdfPreview: 'PDF Preview',
    buttonCancel: 'Cancel',
    buttonDelete: 'Delete',
    buttonInsertComponent: 'Insert Component',
    buttonSaveChanges: 'Save Changes',
    buttonAddItem: 'Add Item',
    buttonClosePdf: 'Close PDF',
    buttonPreview: 'Preview',
	buttonSaveDraft: 'Save As Draft',
	buttonFinish: 'Finish Form',
	buttonAddGroup: 'Add Group',
    buttonConfirmFinish: 'Finish',
    buttonGotIt: 'Got It',
    buttonNext: 'Next',
    buttonBack: 'Back',
    buttonGeneratePdf: 'Generate PDF',
    buttonPreviewPdf: 'Preview PDF',

    labelSearchForms: 'Search forms',
    labelSearchSources: 'Search sources',
	labelType: 'Type',
    labelName: 'Name',
    labelDesc: 'Description',
    labelColumns: 'Column(s)',
	labelAssignedTo: 'Assigned To',
	labelShowItems: 'Show items',
    labelProduct: 'Product',
	labelProducts: 'Products',
	labeltoGlobal: 'Apply to Global',
	labeltoProduct: 'Apply to Product',
    labelTitleGroup: 'Group Title',
    labelComponentType: 'Component Type',
    labelComponentId: 'ID',
    labelComponentLabel: 'Label',
    labelComponentColumnLabel: 'Column Label',
    labelComponentValue: 'Value',
    labelComponentFormula: 'Formula',
    labelComponentAlign: 'Align',
    labelComponentLimit: 'Limit',
    labelComponentSuffix: 'Suffix',
    labelComponentPrefix: 'Prefix',
    labelComponentWidth: 'Width',
    labelComponentFiller: 'Filler',
    labelDataSource: 'Data Source',
    labelFormulaReference: 'Formula Reference',
    labelDetailReference: 'Detail Reference',
    labelDetailSource: 'Detail Source',
    labelAddItemToSource: 'Add Item To',
    labelComponentHelp: 'Help text',
    
    infoPropertyTitle: 'Form Properties',
	infoNoComponent: 'Select a component to edit its properties.',
    infoFinishAlertTitle: 'Finish Form Build Confirmation',
    infoFinishAlertMessageStart: 'Are you sure you want to finish the form build of "',
    infoFinishAlertMessageEnd: '"? Please be noted that this will activate the form.',
    infoValidationAlertTitle: 'Form Validation Error',
    infoValidationAlertSave: 'Form could not be saved, please configure all the required properties.',
    infoValidationAlertPreview: 'Form could not be previewed, please configure all the required properties.',
    infoErrorAlertTitle: 'Form Save Error',
    infoErrorAlertMessage: 'Form could not be saved, error encountered while trying to save the form.',
    infoNoProduct: 'No product',
    infoSearchProducts: 'Search products',
    infoTypeDisplay: 'Display',
    infoConfigDisplay: 'Configure the display value',
    infoNoConfigDisplay: 'No Value Display',
    infoNoTitleGroup: 'Unnamed Group',
    infoNoFormComponent: 'Your forms will appear here when you start adding components.',
    infoTypeInput: 'Input',
    infoPropertyInput: 'Select an input type component to edit its properties.',
    infoTypeInputDisplay: 'Display Input',
    infoConfigInputDisplay: 'Configure the display input',
    infoNoConfigInputDisplay: 'No Display Input Label',
    infoTypeInputDropdown: 'Dropdown Input',
    infoConfigInputDropdown: 'Configure the dropdown input',
    infoValueRequired: 'Input is required',
    infoValueOptional: 'Input is optional',
    infoNoSource: 'No source',
    infoNoConfigInputDropdown: 'No Dropdown Input Label',
    infoTypeInputFormula: 'Formula Input',
    infoConfigInputFormula: 'Configure the formula input',
    infoNoConfigInputFormula: 'No Formula Input Label',
    infoNoFormulaOption: 'No formula option',
    infoTypeInputNumeric: 'Numeric Input',
    infoConfigInputNumeric: 'Configure the numeric input',
    infoNoConfigInputNumeric: 'No Numeric Input Label',
    infoTypeInputText: 'Text Input',
    infoConfigInputText: 'Configure the text input',
    infoNoConfigInputText: 'No Text Input Label',
    infoTypeTable: 'Table',
    infoPropertyTable: 'Select a table component to edit its properties.',
    infoTypeTableDetail: 'Table Detail',
    infoValueManual: 'Add item manual',
    infoValueAuto: 'Add item automatic',
    infoNoSourceTableDetail: 'No source',
    infoNoSourceTableAdd: 'No add item source',
    infoPropertyTableDetail: 'Select a table detail type component to edit its properties.',
    infoTypeTableDetailDisplay: 'Display Detail',
    infoConfigTableDetailDisplay: 'Configure the display detail',
    infoNoConfigTableDetailDisplay: 'No Display Detail Column Label',
    infoTypeTableDetailDropdown: 'Dropdown Detail',
    infoConfigTableDetailDropdown: 'Configure the dropdown detail',
    infoNoConfigTableDetailDropdown: 'No Dropdown Detail Column Label',
    infoTypeTableDetailFormula: 'Formula Detail',
    infoConfigTableDetailFormula: 'Configure the formula detail',
    infoNoConfigTableDetailFormula: 'No Formula Detail Column Label',
    infoTypeTableDetailNumeric: 'Numeric Detail',
    infoConfigTableDetailNumeric: 'Configure the numeric detail',
    infoNoConfigTableDetailNumeric: 'No Numeric Detail Column Label',
    infoTypeTableDetailText: 'Text Detail',
    infoConfigTableDetailText: 'Configure the text detail',
    infoNoConfigTableDetailText: 'No Text Detail Column Label',
    infoTypeTableDisplay: 'Table Display',
    infoConfigTableDisplay: 'Configure the table display value',
    infoNoConfigTableDisplay: 'No Value Table Display',
    infoTypeTableSummary: 'Table Summary',
    infoValueAttached: 'Detail attached',
    infoValueDetached: 'Detail detached',
    infoPropertyTableSummary: 'Select a table summary type component to edit its properties.',
    infoTypeTableSummaryDisplay: 'Display Summary',
    infoConfigTableSummaryDisplay: 'Configure the display summary',
    infoNoConfigTableSummaryDisplay: 'No Value Display Summary',
    infoTypeTableSummaryFormula: 'Formula Summary',
    infoConfigTableSummaryFormula: 'Configure the formula summary',
    infoFormula: '{ formula }',
    infoNoFormulaOption: 'No formula option',
    infoTypeTableSummarySpacer: 'Summary Spacer',
    infoTypeLineBreak: 'Line Break',
    infoFontBold: 'Font bold',
    infoFontNormal: 'Font normal',
    infoFormatDecimal: 'With decimal',
    infoFormatNoDecimal: 'Without decimal',
    infoNumericFormula: 'Numeric Formula',
    infoTextFormula: 'Text Formula',
    infoDropdownEmptyOption: 'Please select',
    infoNoRecord: 'No record found',
    infoFormSaveError: 'Error encountered while saving form',
    infoDropdownColumnNote: 'Column name that starts with # sign will require numeric value',
    infoFormulaTextInvalid: 'Invalid, only 1 reference for non-numeric field',
    infoFormulaNumericInvalid: 'Invalid numeric formula',
    infoSourceSaveError: 'Error encountered while saving source',
    infoSourceDataSaveError: 'Error encountered while saving source data',

    deleteAlertTitleDisplay: 'Delete Display Component Confirmation',
    deleteAlertTitleGroup: 'Delete Group Confirmation',
    deleteAlertTitleInput: 'Delete Input Component Confirmation',
    deleteAlertTitleInputDisplay: 'Delete Display Input Component Confirmation',
    deleteAlertTitleInputDropdown: 'Delete Dropdown Input Component Confirmation',
    deleteAlertTitleInputFormula: 'Delete Formula Input Component Confirmation',
    deleteAlertTitleInputNumeric: 'Delete Numeric Input Component Confirmation',
    deleteAlertTitleInputText: 'Delete Text Input Component Confirmation',
    deleteAlertTitleTable: 'Delete Table Component Confirmation',
    deleteAlertTitleTableDetail: 'Delete Table Detail Component Confirmation',
    deleteAlertTitleTableDetailDisplay: 'Delete Display Detail Component Confirmation',
    deleteAlertTitleTableDetailDropdown: 'Delete Dropdown Detail Component Confirmation',
    deleteAlertTitleTableDetailFormula: 'Delete Formula Detail Component Confirmation',
    deleteAlertTitleTableDetailNumeric: 'Delete Numeric Detail Component Confirmation',
    deleteAlertTitleTableDetailText: 'Delete Text Detail Component Confirmation',
    deleteAlertTitleTableDisplay: 'Delete Table Display Component Confirmation',
    deleteAlertTitleTableSummary: 'Delete Table Summary Component Confirmation',
    deleteAlertTitleTableSummaryDisplay: 'Delete Display Summary Component Confirmation',
    deleteAlertTitleTableSummaryFormula: 'Delete Formula Summary Component Confirmation',
    deleteAlertTitleTableSummarySpacer: 'Delete Summary Spacer Component Confirmation',
    deleteAlertTitleLineBreak: 'Delete Line Break Component Confirmation',
    deleteAlertTitleDropdown: 'Delete Dropdown Source',
    deleteAlertTitleSourceData: 'Delete Source Data',
    deleteAlertTitleForm: 'Delete Form',
    
    deleteAlertMessageInput: 'Are you sure you want to delete "Selected Input Component"? Please be noted that all it\'s child will also be deleted.',
    deleteAlertMessageTable: 'Are you sure you want to delete "Selected Table Component"? Please be noted that all it\'s child will also be deleted.',
    deleteAlertMessageTableDetail: 'Are you sure you want to delete "Selected Table Detail Component"? Please be noted that all it\'s child will also be deleted.',
    deleteAlertMessageTableSummary: 'Are you sure you want to delete "Selected Table Summary Component"? Please be noted that all it\'s child will also be deleted.',
    deleteAlertMessageTableSummaryFormula: 'Are you sure you want to delete "Selected Formula Summary Component"? Please be noted that all it\'s properties will also be deleted.',
    deleteAlertMessageTableSummarySpacer: 'Are you sure you want to delete "Selected Summary Spacer"?',
    deleteAlertMessageLineBreak: 'Are you sure you want to delete "Selected Line Break"?',

    deleteAlertMessageStart: 'Are you sure you want to delete "',
    deleteAlertMessagePropEnd: '"? Please be noted that all it\'s properties will also be deleted.',
    deleteAlertMessageComponentEnd: '"? Please be noted that all it\'s components will also be deleted.',
    deleteAlertMessageDataEnd: '"? Please be noted that all it\'s data will also be deleted.',

    menuGroupDisplay: 'Display',
    menuGroupInput: 'Input',
    menuGroupTable: 'Table',
    menuGroupLineBreak: 'Line Break',
    menuInputText: 'Text Input',
    menuInputNumeric: 'Numeric Input',
    menuInputDropdown: 'Dropdown Input',
    menuInputDisplay: 'Display Input',
    menuInputFormula: 'Formula Input',
    menuTableDisplay: 'Table Display',
    menuTableDetail: 'Table Detail',
    menuTableSummary: 'Table Summary',
    menuTableDetailText: 'Text Detail',
    menuTableDetailNumeric: 'Numeric Detail',
    menuTableDetailDropdown: 'Dropdown Detail',
    menuTableDetailDisplay: 'Display Detail',
    menuTableDetailFormula: 'Formula Detail',
    menuTableSummaryDisplay: 'Display Summary',
    menuTableSummaryFormula: 'Formula Summary',
    menuTableSummarySpacer: 'Summary Spacer',

	columnFormName: 'Form Name',
	columnType: 'Type',
	columnDateCreated: 'Date Created',
	columnAssignedTo: 'Assigned To',
	columnStatus: 'Status',
	columnActions: 'Actions',
    columnSourceName: 'Source Name',
    columnSourceDesc: 'Source Desription',
    columnSourceCols: 'Source Columns',
	pageFrom: 'Showing',
    pageOf: 'of',
	pageTo: 'to',
    optionAll: 'All',
    optionNone: 'None',
    timeZone: 'Asia/Singapore',
    dateTimeFormat: 'YYYY-MM-DD hh:mm:ss A',
    filler: '-',
};