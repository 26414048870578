import React from "react";
import { Dialog, Button } from "@material-ui/core";

const DialogCustom = (props) => {
  return (
    <Dialog
      onClose={() => props.setConfirmDialog(false)}
      aria-labelledby="simple-dialog-title"
      open={props.confirmDialog}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "24px 32px 24px 32px",
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: 20,
            fontWeight: 600,
            lineHeight: "24px",
            fontFamily: "Inter",
            marginBottom: 24,
          }}
        >
          Loan application not found
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: 16,
            fontWeight: "normal",
            lineHeight: "24px",
            fontFamily: "Inter",
            marginBottom: 48,
          }}
        >
          Selected loan application is no longer available.
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#FFFFFF",
              color: "#F47721",
              width: 145,
              borderColor: "#F47721",
              textTransform: "none",
              fontFamily: "Inter",
            }}
            onClick={() => {
              props.setConfirmDialog(false);
            }}
          >
            Go back
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogCustom;
