import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	Button,
	LinearProgress,
	Grid,
} from '@material-ui/core/'
import Paths from '../../../enums/Paths';
import { 
	toggleDisplay, 
	useStyles, 
	texts, 
	PdfStyles, 
	newId,
	Api,
	keys,
	getKeyValue,
	componentTypes,
	getFormulaValue,
} from '../helper';
import { PDFViewer, Document, Page, Font } from '@react-pdf/renderer';
import { FormGroupPdf } from './formComponents';
import inter from '../../../assets/fonts/Inter-Regular.ttf';
import interBold from '../../../assets/fonts/Inter-Bold.ttf';
import interSemiBold from '../../../assets/fonts/Inter-SemiBold.ttf';

Font.register({ family: 'Inter', src: inter });
Font.register({ family: 'Inter Bold', src: interBold });
Font.register({ family: 'Inter SemiBold', src: interSemiBold });

const FormPdf = (props) => {
	// const { id } = useParams();
	const { id } = props;
	const history = useHistory();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const inputKey = `${keys.FORM_INPUT_DATA}_${id}`;
	const inputData = useRef({});
	const formulaData = useRef({});
	const dropdownData = useRef({});
	const sources = useRef({});
	const [detail, setDetail] = useState(null);
	const [children, setChildren] = useState(null);
	

	const handleClosePdf = () => { 
		history.push(Paths.CASHFLOW_FORM_BUILDER_PREVIEW.replace(':id', id)); 
		props.setTick(id);
	}

	const initValues = (data, parent) => {
		let widthPerNotSpecified = 0;
		if (data.type === componentTypes.FORMINPUTDISPLAY) {
			inputData.current[data.id] = (data.value || '');
		} else if (data.type === componentTypes.FORMTABLEDETAILDISPLAY) {
			for (const row of (inputData.current[parent.id] || [])) {
				inputData.current[data.id + '-' + row.rowId] = (data.value || '');
			}
		} else if (data.type === componentTypes.FORMINPUTFORMULA ||
				   data.type === componentTypes.FORMTABLESUMMARYFORMULA) {
			formulaData.current[data.id] = { formula: data.formula };
		} else if (data.type === componentTypes.FORMTABLEDETAILFORMULA) {
			formulaData.current[data.id] = { formula: data.formula, rows: (inputData.current[parent.id] || []) };	
		} else if (data.type === componentTypes.FORMINPUTDROPDOWN) {
			dropdownData.current[data.id] = { source: data.source.id };
		} else if (data.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
			dropdownData.current[data.id] = { source: data.source.id, rows: (inputData.current[parent.id] || []) };	
		} else if (data.type === componentTypes.FORMTABLEDETAIL ||
				   data.type === componentTypes.FORMTABLESUMMARY) {
			let remWidth = 100;
    		let numWidthDefined = 0;
			for (const child of (data.children || [])) {
				if (child.width) {
					remWidth -= child.width;
					numWidthDefined++;
				}
			}
    		widthPerNotSpecified = Math.ceil(remWidth / ((data.children || []).length - numWidthDefined));
		}

		for (const child of (data.children || [])) {
			if ((data.type === componentTypes.FORMTABLEDETAIL ||
				data.type === componentTypes.FORMTABLESUMMARY) &&
				!child.width) {
				child.width = widthPerNotSpecified;
			}
			initValues(child, data);
		}
	}

	const initDropdowns = () => {
		for (const id of Object.keys(dropdownData.current)) {
			const data = dropdownData.current[id];
			if (data.rows) {
				for (const row of (data.rows || [])) {
					const key = (inputData.current[id + '-' + row.rowId] || '');
					const value = ((sources.current[data.source] || []).find((option) => option.Key === key)?.Value || '');
					inputData.current[id + '-' + row.rowId] = (value || '');
				}
			} else {
				const key = (inputData.current[id] || '');
				const value = ((sources.current[data.source] || []).find((option) => option.Key === key)?.Value || '');
				inputData.current[id] = (value || '');
			}
		}
	}

	const isFormulaDependent = (formulas, keys) => {
		for (const key of keys) {
			const isExist = formulas.find(formula => (formula.key && formula.key.indexOf(key) >= 0));
			if (isExist) {
				return true;				
			}
		}
		return false;
	}

	const initFormulas = () => {
		while (Object.keys(formulaData.current).length > 0) {
			const keys = Object.keys(formulaData.current);
			for (const key of keys) {
				const data = formulaData.current[key];
				if (!isFormulaDependent(data.formula, keys)) {
					if (data.rows) {
						for (const row of (data.rows || [])) {
							const value = getFormulaValue(data.formula, inputData.current, sources.current, row.rowId)
							inputData.current[key + '-' + row.rowId] = (value || '');
						}
					} else {
						const value = getFormulaValue(data.formula, inputData.current, sources.current);
						inputData.current[key] = (value || '');
					}
					delete formulaData.current[key];
				}
			}
		}

	}

	const initialize = (schema) => {
		const data = JSON.parse(schema.payload);
		if (data) {
			const children = [];
			for (const child of data) {
				children.push(child);
				initValues(child); 
			}
			initFormulas();
			initDropdowns();			
			setChildren(children);
		}
	}

	const handleDataLoad = async () => {
		setIsLoading(true);

		// Since we are just on preview mode, try to load form data from local storage
		const localInputData = getKeyValue(inputKey);
		if (localInputData && localInputData.length > 0) {
			inputData.current = JSON.parse(localInputData);
		}

		const resSources = await Api.getSources();
		if (resSources && resSources.list) {
			sources.current = {};
			for (const source of resSources.list) {
				if (source.payload) {
					sources.current[source.id] = JSON.parse(source.payload);
				}
			}
		}

		const response = await Api.getForm(id);
		if (response) {
			if (response.schema) {
				initialize(response.schema);
				response.schemaId = (response?.schema?.id || 0);
				delete response.schema;
			}
			setDetail(response);
		}	
		setIsLoading(false);
	}

	useEffect(() => {
		handleDataLoad();
	}, [id]);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1}>
				<Grid item xs={12} sm={12} md={5}>
					<span className={classes.pageTitle}>{(detail?.name || '')}</span>
				</Grid>
				<Grid item xs={12} sm={12} md={7} className={classes.controlEnd}>
					<Button variant='contained' className={classes.secondaryButtonNoMarg} onClick={handleClosePdf}>{texts.buttonClosePdf}</Button>	
				</Grid>
			</Grid>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{!isLoading &&
					<PDFViewer className={classes.pdfFrame}>
						<Document>
							<Page size="LEGAL" orientation="landscape" style={PdfStyles.body}>
								{children.map((child) => (
								<FormGroupPdf 
									inputData={inputData.current}
									id={child.id}
									title={child.title}
									children={child.children} /> 
								))}
							</Page>
						</Document>
					</PDFViewer>
					}
				</Grid>
			</Grid>
		</div>
	)
}

export default FormPdf;