import React, {useState} from 'react';
import {Grid, Button, Paper, LinearProgress, FormControl, TextField, InputAdornment,
  InputLabel, Select, MenuItem, FormControlLabel, TableContainer, Table, TableHead, TableBody, TableRow, IconButton, Snackbar} from '@material-ui/core/';
import Pagination from '@material-ui/lab/Pagination';
import {useStyles, StyledTableCell, StyledTableRow, texts, toggleDisplay}  from './helpers/';
import { mdiPlusCircleOutline,mdiMagnify } from '@mdi/js';
import { Icon as MdIcon } from '@mdi/react';
import { useHistory } from 'react-router-dom'
import LoanService from '../../services/LoanService';
import Paths from '../../enums/Paths';
import MuiAlert from '@material-ui/lab/Alert';

let rows = [];

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};


const CustomerProfile = props => {

	let history = useHistory()
  let currentRecords = 0;
  const totalRecords = 3

  const classes = useStyles();
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
  // const [searchText, setSearchText] = useState(getKeyValue(keys.FORM_SEARCH));
  const [searchText, setSearchText] = useState();
  const [formList,setFormList] = useState([{clientName:'Test Test1', numberOfLoan:'1'},{clientName:'Test Test2', numberOfLoan:'2'}, {clientName:'Test Test3', numberOfLoan:'3'}])
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageStatus, setPageStatus] = useState(`${texts.pageFrom} ${currentPage != totalPages ? 
    (currentRecords * currentPage - (pageLimit - 1) + ` ${texts.pageTo} ` + currentRecords * currentPage) : 
      (((((currentPage - 1) * pageLimit) + 1) != totalRecords) ? 
        (((currentPage - 1) * pageLimit) + 1 + ` ${texts.pageTo} ` + totalRecords) : 
				(((currentPage - 1) * pageLimit) + 1))} ${texts.pageOf} ${totalRecords}`);
	const [searchKey, setSearchKey] = useState('');
	const [page, setPage] = useState(1);
	const [showItems, setShowItems] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [count, setCount] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [hoveredRow, setHoveredRow] = useState(null)
	const [tick, setTick] = useState(0);


	async function getData(){
		setIsFetchingFailed(false);
    setInitialized(true);
    setUpdatingData(true);
		let response = await LoanService.getCustomerProfileList(props.globalSearchKey ? props.globalSearchKey : '', '', '', '', showItems, page);

		rows = [];
		response && response.result && response.result.list.map((data, index) => {
			rows.push(createData(data.customerId, data.personFirstName, data.personLastName, data.applicationCount))
		})
		
		setCount(response && response.result ? response.result.count : 0);
    setMaxPage(response && response.result ? response.result.maxPage : 0);
    setTotalCount(
      response && response.result
        ? response.result.list && response.result.list.length
        : 0
    );
    setHoveredRow(-1);
    setHoveredRow(null);
	}

	if (!initialized) {
    getData();
	}
	
	function createData(
		customerId,
		firstName,
		lastName,
		applicationCount
	) {
		return { customerId, firstName, lastName, applicationCount };
	}

	const updateRender = () => {
    setTick(tick + 1);
  }

  const toggleNewForm = props => {

  };

  const onSearchTextPress = props => {

  }

  const onSearchTextChange = props => {

  }

  const doShowForm = (customerId, customerName) => {
		props.setCustomerName(customerName)
		props.setRevision(Math.random())
		history.push(`/customerProfile/${customerId}`)
		props.setRevision(Math.random())
		updateRender();
  }

  return (
    <div className={classes.pageWrapper}>
      <Grid container className={classes.pageHeader} spacing={1}>
				<Grid item xs={12} sm={6}>
					<span className={classes.pageTitle}>{texts.headerFormList}</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.controlEnd}>
					<Button 
					id='btn-add-profile'
					variant='contained' 
					className={classes.primaryButton}
					onClick={ ()=> {
						history.push(
							Paths.CREATE_CUSTOMER_PROFILE
						);
						localStorage.removeItem("@@customerLoanRefId");
						props.setRevision(Math.random());
					}}>
						{/* <MdIcon path={mdiPlusCircleOutline} size='1.5em' className={classes.buttonIconLeft} /> */}
            {texts.buttonCreateNewClient}
					</Button>
				</Grid>
			</Grid>
      <Paper className={classes.paperWrapper} elevation={0}>
				<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
				<Grid container className={classes.controlWrapper} spacing={3}>
					<Grid item xs={12} sm={9}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={5} md={4} lg={3}>
								<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
								<TextField 
									id='search-form'
									label={texts.labelSearchForms}
									variant='outlined'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<MdIcon path={mdiMagnify} size='24px' />
											</InputAdornment>
										),
									}}
									defaultValue={
										props.globalSearchKey ? props.globalSearchKey : searchKey
									}
									// value={searchText || ''}
									onKeyPress={(event) => {
										if (event.key === 'Enter') {
											setSearchKey(event.target.value);
											props.setSearchKeyGlobal(event.target.value);
											setPage(1);
											setInitialized(false);
										}
									}}
									onChange={onSearchTextChange}
								/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={3} className={classes.controlEnd}>
						<FormControl variant='outlined' className={classes.formControl}>
							<FormControlLabel
								control={<Select
									id='list-items-top'
									className={classes.listItems}
									defaultValue={10}
									onChange={(event) => {
										setShowItems(event.target.value);
										setPage(1);
										setInitialized(false);
									}}>
									<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
									<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
									<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
								</Select>}
								label={texts.labelShowItems}
								labelPlacement='start'/>
						</FormControl>
					</Grid>
				</Grid>
				<div className={classes.tableWrapper}>	
						<TableContainer>
							<Table className={classes.table} aria-label='form builder table'>
								<TableHead>
									<TableRow>
										<StyledTableCell className={classes.plCell}>{texts.columnFormName}</StyledTableCell>
										<StyledTableCell width='18%'>{texts.columnForLoans}</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows && rows.map((data) => (
										<StyledTableRow>
											<StyledTableCell className={classes.plCell}><span className={classes.rowSelector} onClick={() => {
												doShowForm(data.customerId, `${data.lastName}, ${data.firstName}`);
											}}>{`${data.firstName} ${data.lastName}`}</span></StyledTableCell>
											<StyledTableCell>{data.applicationCount}</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
				</div>
			</Paper>
      <Grid container className={classes.footerWrapper}>
				<Grid item xs={12} sm={9}>
					<FormControl variant='outlined' className={classes.formControl}>
					<div style={{ display: 'flex', alignItems: 'center', padding: '24px 15px 24px 15px', backgroundColor: '#F6F6F6' }}>
					<div style={{ marginRight: 16, fontSize: 14, width: 170 }}>Showing {count === 0 ? '-' : `${page != maxPage ? totalCount * page - (showItems - 1) + "-" + totalCount * page : (((page - 1) * showItems) + 1) != count ? ((page - 1) * showItems) + 1 + "-" + count : ((page - 1) * showItems) + 1} `} of {count === 0 ? '-' : count}</div>
						<FormControlLabel
							control={((totalPages > 0) ? 
							<Pagination 
							className={classes.root} 
							page={page} 
							count={maxPage} 
							shape='rounded' 
							onChange={(event, value) => {
								setPage(value);
								setInitialized(false);
							}} /> : <span/>)}
						/>
					</div>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={3} className={classes.controlEnd}>
					<FormControl variant='outlined' className={classes.formControl}>
						<FormControlLabel
							control={<Select
								id='list-items-bottom'
								className={classes.listItems}
								defaultValue={10}
								onChange={(event) => {
									setShowItems(event.target.value);
									setPage(1);
									setInitialized(false);
								}}>
								<MenuItem className={classes.menuItem} value={10}>10</MenuItem>
								<MenuItem className={classes.menuItem} value={20}>20</MenuItem>
								<MenuItem className={classes.menuItem} value={30}>30</MenuItem>
							</Select>}
							label={texts.labelShowItems}
							labelPlacement='start'/>
					</FormControl>
				</Grid>
			</Grid>
			{/* snackbar */}

			<Snackbar
				open={props.renderSnackBar}
				autoHideDuration={6000}
				onClose={() => props.setRenderSnackBar(false)}
			>
				<Alert
					onClose={() => props.setRenderSnackBar(false)}
					severity={props.createSuccess ? 'success' : 'error'}
				>
					{props.createSuccess ? (
						<span>{'Successfully created client profile'}</span>
					) : (
							<span>{'There was an error creating client profile'}</span>
						)}
				</Alert>
			</Snackbar>
    </div>
  );
}

export default CustomerProfile;