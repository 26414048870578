export default {
	search(value, list, type) {
		let searchList = [];
		value ?
			list.forEach((item) => {
				let itemString
				if (type == "APP_NAME") {
					itemString = JSON.stringify(item.applicantName).toLowerCase();
				}
				if (type == "LOCATION") {
					itemString = JSON.stringify(item.location).toLowerCase();
				}
				if (type == "NAME") {
					itemString = JSON.stringify(item.name).toLowerCase();
				}
				if (type == "STATUS") {
					itemString = JSON.stringify(item.status).toLowerCase();
				}
				if (type == "SUPERVISOR") {
					itemString = JSON.stringify(item.supervisorName).toLowerCase();
				}
				if (itemString.includes(value.toLowerCase())) {
					searchList.push(item);
				}
			})
			: searchList = null;
		return searchList;
	}
}

