import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	Button,
	LinearProgress,
	Grid,
	Card,
	CardContent,
} from '@material-ui/core/'
import Paths from '../../../enums/Paths';
import {
	toggleDisplay,
	useStyles,
	texts,
	Api,
	StyledTabs,
	StyledTab,
	getKeyValue,
	setKeyValue,
	keys,
} from '../helper';
import { FormGroupPreview } from './formComponents';

const tabProps = (id) => {
	return {
		id: `group-tab-${id}`,
		'aria-controls': `group-tabpanel-${id}`,
	};
}

const FormPreview = (props) => {
	// const { id } = useParams();
	const { id } = props;
	const history = useHistory();
	const classes = useStyles();
	const [detail, setDetail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [formulaReferences, setFormulaReferences] = useState({});
	const [dropdownReferences, setDropdownReferences] = useState({});
	const [tableReferences, setTableReferences] = useState({});
	const [tab, setTab] = useState(0);
	const [children, setChildren] = useState(null);
	const [sources, setSources] = useState([]);
	const inputKey = `${keys.FORM_INPUT_DATA}_${id}`;
	const inputData = useRef({});
	const inputStore = useRef({});
	const formulaTickCtr = useRef(0);
	const [formulaTick, setFormulaTick] = useState('');
	const tableTickCtr = useRef(0);
	const [tableTick, setTableTick] = useState('');

	const handlePdfPreview = () => {
		// Font/Layout issue if used history
		// window.location.href = Paths.CASHFLOW_FORM_BUILDER_PDF.replace(':id', id);
		history.push(Paths.CASHFLOW_FORM_BUILDER_PDF.replace(':id', id));
		props.setTick(Math.random());
	}

	const handleClosePreview = () => {
		history.push(Paths.CASHFLOW_FORM_BUILDER_DESIGNER.replace(':id', id));
		props.setTick(Math.random());
	}

	const handleTabChange = (event, tab) => {
		setTab(tab);
	};

	const handleFormulaReference = (id, reference) => {
		formulaReferences[id] = reference;
		setFormulaReferences(formulaReferences);
	}

	const handleDropdownReference = (id, reference) => {
		dropdownReferences[id] = reference;
		setDropdownReferences(dropdownReferences);
	}

	const handleTableReference = (id, reference) => {
		tableReferences[id] = reference;
		setTableReferences(tableReferences);
	}

	const handleInputSave = (data, id, rowId) => {
		for (const key of Object.keys(data)) {
			inputStore.current[key] = data[key];
		}
		setKeyValue(inputKey, JSON.stringify(inputStore.current));
		if (id && rowId) {
			handleTableData(id, rowId);
		}
	}

	const handleInputUndo = (rowId) => {
		let isFound = false;
		for (const key of Object.keys(inputStore.current)) {
			if (key.endsWith(rowId)) {
				isFound = true;
				inputData.current[key] = inputStore.current[key];
				const id = key.replace('-' + rowId);
				sendFormulaTick(id);
			}
		}
		// This is just a new cancelled row
		if (!isFound) {
			for (const key of Object.keys(inputData.current)) {
				if (key.endsWith(rowId)) {
					delete inputData.current[key];
					const id = key.replace('-' + rowId);
					sendFormulaTick(id);
				}
			}
		}
	}

	const handleInputDelete = (id, table, rowId) => {
		inputStore.current[id] = table;
		for (const key of Object.keys(inputData.current)) {
			if (key.endsWith(rowId)) {
				delete inputData.current[key];
			}
		}
		for (const key of Object.keys(inputStore.current)) {
			if (key.endsWith(rowId)) {
				delete inputStore.current[key];
			}
		}
		setKeyValue(inputKey, JSON.stringify(inputStore.current));
		sendFormulaTick(id);
		handleTableData(id, rowId, true);
	}

	const handleInputData = (id, value, noTick) => {
		inputData.current[id] = value;
		if (!noTick) {
			sendFormulaTick(id);
		}
	}

	const sendFormulaTick = (id) => {
		formulaTickCtr.current = formulaTickCtr.current + 1;
		setFormulaTick(id + '-' + formulaTickCtr.current);
	}

	const handleTableData = (source, rowId, isDelete) => {
		tableTickCtr.current = tableTickCtr.current + 1;
		setTableTick((rowId ? (isDelete ? '-' : '+') : '!') + (source.key ? source.key : source) +
			'-' + (rowId ? rowId : tableTickCtr.current));
	}

	const initialize = (schema) => {
		const data = JSON.parse(schema.payload);
		if (data) {
			const children = [];
			for (const child of data) {
				children.push(child);
			}
			setChildren(children);
		}
	}

	const handleDataLoad = async () => {
		setIsLoading(true);

		// Since we are just on preview mode, try to load form data from local storage
		const localInputData = getKeyValue(inputKey);

		if (localInputData && localInputData.length > 0) {
			inputStore.current = JSON.parse(localInputData);
			inputData.current = JSON.parse(localInputData);
		}

		const resSources = await Api.getSources();
		if (resSources && resSources.list) {
			const sources = {};
			for (const source of resSources.list) {
				if (source.payload) {
					sources[source.id] = JSON.parse(source.payload);
				}
			}
			setSources(sources)
		}

		const response = await Api.getForm(id);
		if (response) {
			if (response.schema) {
				initialize(response.schema);
				response.schemaId = (response?.schema?.id || 0);
				delete response.schema;
			}
			setDetail(response);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		handleDataLoad();
	}, [id]);

	return (
		<div className={classes.pageWrapper}>
			<Grid container className={classes.pageHeader} spacing={1}>
				<Grid item xs={12} sm={12} md={5}>
					<span className={classes.pageTitle}>{(detail?.name || '')}</span>
				</Grid>
				<Grid item xs={12} sm={12} md={7} className={classes.controlEnd}>
					<Button variant='contained' className={classes.secondaryButton} onClick={handleClosePreview}>{texts.buttonClosePreview}</Button>
					<Button variant='contained' className={classes.secondaryButtonNoMarg} onClick={handlePdfPreview}>{texts.buttonPdfPreview}</Button>
				</Grid>
			</Grid>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Grid container className={classes.builderWrapper} spacing={3}>
				<Grid item xs={12}>
					<Card className={classes.cardWrapper}>
						{children &&
							<CardContent>
								<StyledTabs
									value={tab}
									onChange={handleTabChange}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="From Group">
									{children.map((child, index) => (
										<StyledTab label={child.title} {...tabProps(index)} />
									))}
								</StyledTabs>
								{children.map((child, index) => (
									<FormGroupPreview
										handleFormulaReference={handleFormulaReference}
										formulaReferences={formulaReferences}
										handleDropdownReference={handleDropdownReference}
										dropdownReferences={dropdownReferences}
										handleTableReference={handleTableReference}
										tableReferences={tableReferences}
										sources={sources}
										handleInputSave={handleInputSave}
										handleInputUndo={handleInputUndo}
										handleInputDelete={handleInputDelete}
										handleInputData={handleInputData}
										handleTableData={handleTableData}
										inputData={inputData.current}
										formulaTick={formulaTick}
										tableTick={tableTick}
										tab={tab}
										id={index}
										children={child.children} />
								))}
							</CardContent>
						}
					</Card>
				</Grid>
			</Grid>
		</div>
	)
}

export default FormPreview;