import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import { icon_export } from '../../assets/images';
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import ContentLoader from "react-content-loader"
import { Icon as MdIcon } from '@mdi/react'
import { mdiAccountAlertOutline, mdiAlertCircleOutline, mdiFileExportOutline, mdiCheck, mdiClose, mdiInboxArrowDownOutline, mdiCheckAll, mdiClockFast, mdiClockOutline } from '@mdi/js';

const StatusDisplay = (props) => {

	switch (props.status) {
		case "APPROVED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#17CA83' }}>
					<MdIcon path={mdiCheck} size="24px" style={{ marginRight: 8 }} /> APPROVED
				</div>
			)
		case "ARCHIVED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196' }}>
					<MdIcon path={mdiInboxArrowDownOutline} size="24px" style={{ marginRight: 8 }} /> ARCHIVED
				</div>
			)
		case "COMPLETED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#17CA83', textAlign: 'center' }}>
					<MdIcon path={mdiCheck} size="24px" style={{ marginRight: 8 }} /> COMPLETED
				</div>
			)
		case "CREATED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#17CA83', textAlign: 'center' }}>
					<MdIcon path={mdiCheck} size="24px" style={{ marginRight: 8 }} /> CREATED
				</div>
			)
		case "EXPORTED":
				return (
					<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196', textAlign: 'center' }}>
						<MdIcon path={mdiFileExportOutline} size="24px" style={{ marginRight: 8 }} /> EXPORTED
					</div>
				)
		case "INCOMPLETE":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196', textAlign: 'center' }}>
					<MdIcon path={mdiClockOutline} size="24px" style={{ marginRight: 8 }} /> INCOMPLETE
				</div>
			)
		case "MODIFIED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196', textAlign: 'center' }}>
					<MdIcon path={mdiCheck} size="24px" style={{ marginRight: 8 }} /> MODIFIED
				</div>
			)
		case "REJECTED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiClose} size="24px" style={{ marginRight: 8 }} /> REJECTED
				</div>
			)
		case "RETURNED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiAlertCircleOutline} size="24px" style={{ marginRight: 8 }} /> RETURNED
				</div>
			)
		case "FAILED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiClose} size="24px" style={{ marginRight: 8 }} /> FAILED
				</div>
			)
		case "RESUBMITTED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#17CA83', textAlign: 'center' }}>
					<MdIcon path={mdiCheckAll} size="24px" style={{ marginRight: 8 }} /> RESUBMITTED
				</div>
			)
		case "PROCESSING":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196', textAlign: 'center' }}>
					<MdIcon path={mdiClockFast} size="24px" style={{ marginRight: 8 }} /> PROCESSING
				</div>
			)
		case "SUBMITTED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#17CA83', textAlign: 'center' }}>
					<MdIcon path={mdiCheck} size="24px" style={{ marginRight: 8 }} /> SUBMITTED
				</div>
			)
		case "FOR APPROVAL":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#909196' }}>
					<MdIcon path={mdiAccountAlertOutline} size="24px" style={{ marginRight: 8 }} /> FOR APPROVAL
				</div>
			)
		case "FOR INVESTIGATION":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiAlertCircleOutline} size="24px" style={{ marginRight: 8 }} /> FOR INVESTIGATION
				</div>
			)
		case "FOR RESUBMISSION":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiAccountAlertOutline} size="24px" style={{ marginRight: 8 }} /> FOR RESUBMISSION
				</div>
			)
		case "TERMINATED":
			return (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 'bold', color: '#E24C4C' }}>
					<MdIcon path={mdiClose} size="24px" style={{ marginRight: 8 }} /> TERMINATED
				</div>
			)
		default:
			return (
				<ContentLoader 
    				speed={1.5}
    				width={170}
					height={18}
					viewBox="0 0 170 18"
					backgroundColor="#f3f3f3"
					foregroundColor="#9e9e9e"
				>
					<rect x="0" y="0" rx="0" ry="0" width="170" height="18" /> 
				</ContentLoader>
			)
	}

}

export default StatusDisplay;