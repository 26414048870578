import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
	FormControl,
    Grid,
    Select,
    InputLabel,
    MenuItem,
    Button,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline, mdiPlusCircleOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    isEmpty, 
    aligns, 
    getPdfAlignStyle,
    getAlignStyle, 
    useStyles, 
    texts,
    PdfStyles,
    componentTypes,
} from '../../helper';

const FormTableDisplayProperties = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [source, setSource] = useState({});
    const [sources, setSources] = useState([]);
    const [errors, setErrors] = useState({});

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.updateValue(value);
		errors['value'] = checkRequired(value, texts.labelComponentValue);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleSourceChange = (event, value) => {
        setSource(value);
        props.updateSource(value);
    }

    const initSources = (source, tableReferences) => {
        const sources = [];
        for (const key of Object.keys(tableReferences)) {
            if (source && 
                key === source.key &&
                tableReferences[key].manual) {
                source.value = (tableReferences[key].reference || '-');
            }
            if (tableReferences[key].manual &&
                (tableReferences[key].reference || '').trim().length > 0) {
                sources.push({ key, value: tableReferences[key].reference });
            }
        }
        setSource(source);
        setSources(sources);
    }


    useEffect(() => {
        setValue(props.value);
        setAlign(props.align);
        initSources(props.source, props.tableReferences);
        const errors = {};
        errors['value'] = checkRequired(props.value, texts.labelComponentValue);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableDisplay}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-value'
                            label={texts.labelComponentValue}
                            variant='outlined'
                            value={value || ''}
                            error={errors['value']}
                            helperText={errors['value']}
                            onChange={handleValueChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <Autocomplete
                            id='component-source'
                            options={sources}
                            getOptionSelected={(option, value) => option.key === value.key}
                            getOptionLabel={(option) => option.value}
                            value={source || {}}
                            filterSelectedOptions
                            disableClearable={true}
                            noOptionsText={texts.infoNoSourceTableAdd}
                            onChange={handleSourceChange}
                            disabled={props.isReadOnly}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={texts.labelAddItemToSource}
                                    error={errors['source']}
                                    helperText={errors['source']}
                                />
                            )}
                            classes={{
                                popper: classes.formControl,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableDisplayDesigner = (props) => {
	const classes = useStyles();
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [source, setSource] = useState({});
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id); 
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateValue = (value) => {
        setValue(value);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateSource = (source) => {
        setSource(source);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableDisplayProperties 
                                            id={props.id} 
                                            value={value} 
                                            align={align} 
                                            source={source}
                                            tableReferences={props.tableReferences}
                                            updateValue={handleUpdateValue}
                                            updateAlign={handleUpdateAlign}
                                            updateSource={handleUpdateSource}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
        errors['value'] = checkRequired(value);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLEDISPLAY, 
                                    value,
                                    align,
                                    source }, doValidate());
        } else {
            setIsInitialize(true);
            setValue(props.value);
            setAlign(props.align);
            setSource(props.source);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableDisplay}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div 
                        className={classes.componentDisplay}
                        style={getAlignStyle(align)}>
                        {value || texts.infoConfigTableDisplay}
                    </div>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableDisplay} 
                message={`${texts.deleteAlertMessageStart}${value || texts.infoNoConfigTableDisplay}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormTableDisplayPreview = (props) => {
	const classes = useStyles();

    const handleAddItem = () => { 
        props.handleTableData(props.source);
	}

	return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
                <div 
                    className={classes.groupTableDisplay}
                    style={getAlignStyle(props.align || aligns.LEFT)}>
                    {props.value}
                </div>
            </Grid>
            {!isEmpty(props.source) &&
            <Grid item xs={12} sm={4} className={classes.controlEnd}>
                <Button variant='contained' className={classes.secondaryButtonGroup} onClick={handleAddItem}>
                    <MdIcon path={mdiPlusCircleOutline} size='1.5em' className={classes.buttonIconGroup} />{texts.buttonAddItem}
                </Button>
            </Grid>}
        </Grid>
	)
}

export const FormTableDisplayPdf = (props) => {

	return (
        <View style={getPdfAlignStyle(props.align || aligns.LEFT, PdfStyles.groupTableDisplay)}>
            <Text style={PdfStyles.groupTableDisplayText}>{props.value}</Text>
        </View>
	)
}