import React, { useEffect, useState } from 'react';
import {useStyles} from '../../cashFlow/helper'
import {
  Grid,
  Paper,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { checkRequired } from './../../cashFlow/helper'


const OrangeRadio = withStyles({
  root: {
    // color: green[400],
    '&$checked': {
      color: '#F47721',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const FormSelector = (props) => {
  const classes = useStyles();
  const [formType, setFormType] = useState('global');
  const [formProduct, setFormProduct] = useState(0);
  const [isGlobalTypeEmpty,setIsGlobalTypeEmpty]=useState(false);
  const [isProductTypeEmpty,setIsProductTypeEmpty]=useState(false);
  const [radioDefault,setRadioDefault]=useState('global');
  const [useGlobalForms, setUseGlobalForms] = useState(false);

  const handleCancel = () => {
    props.onFormCancel();
  }

  const handleCreate = () => {
    props.handleCreateCashflow(formProduct);
  }

  const handleNoForms = () => {
    props.handleNoForms(true);
  }

  const handleFormProductChange = (event) => {
		const formProduct = event.target.value;
    setFormProduct(formProduct);
	}

  const handleFormTypeChange = (event) => {
    setFormType(event.target.value);
    setFormProduct('');
  }

  useEffect(() => {
   const foundGlobalType = props.cashFlowFormList.find(data => data.productIds == null);
   const foundProductType = props.cashFlowFormList.find(data => data.productIds != null);

    if (foundGlobalType==undefined){
      setIsGlobalTypeEmpty(true);
    }
    if (foundProductType==undefined){
      setIsProductTypeEmpty(true);
    }
    if (isGlobalTypeEmpty != true){
      setRadioDefault('product');
      if (isProductTypeEmpty != true){
        setRadioDefault('');
      }
    }

    let productForms = [], globalForms = [];

    props.cashFlowFormList.map((cashflowForm) => {
      if (cashflowForm.productIds != null) 
        productForms.push(cashflowForm.id) 
      else globalForms.push(cashflowForm)
    });

    if (productForms.length == 1) {
      // If there is only one product-specific form, skip modal prompt
      props.handleCreateCashflow(productForms[0]);
    } else if (globalForms.length == 1 && !productForms.length) {
      // If there is only one global configured form, skip modal prompt
      props.handleCreateCashflow(globalForms[0].id);
    } else if (globalForms.length >= 1 && !productForms.length) {
      // Force the user to use the latest and active global configured form
      globalForms.sort(function compare(a, b) {
        var d1 = new Date(a.createdAt);
        var d2 = new Date(b.createdAt);
        return d1 - d2;
      }).reverse();
      props.handleCreateCashflow(globalForms[0].id);
    } else if (!globalForms.length && !productForms.length) {
      handleCancel();
      handleNoForms();
    }
  });

  return (

    <div className={classes.pageWrapperModal}>
      <Paper className={classes.paperWrapperModal}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.pageHeaderModal}>
            <span className={classes.pageTitleModal}>Create Cashflow</span>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
              <InputLabel id='form-product-label'>Form</InputLabel>
              <Select
                labelId='form-product-label'
                label='Form'
                id='form-product'
                value={formProduct || ''}
                onChange={handleFormProductChange}>
                {props.cashFlowFormList && props.cashFlowFormList.map((data) => {
                  if (useGlobalForms ? data.productIds == null : data.productIds != null) {
                    return <MenuItem className={classes.menuItem} value={data.id}>{data.name}</MenuItem>
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.controlEndNoWrap}>
            <Button variant='outlined' className={classes.secondaryButtonNoWrap} onClick={handleCancel}>Cancel</Button>
            <Button variant='contained' className={classes.primaryButtonNoWrap} disabled={formProduct == ''} onClick={handleCreate}>Create</Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );

}

export default FormSelector;
