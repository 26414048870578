import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Dialog,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	TextField,
	InputAdornment,
	Select,
	Snackbar,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core/'
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiFileTableOutline, mdiMagnify, mdiAccountPlus } from '@mdi/js';
import SearchUtil from '../../utils/SearchUtil'
import LoanService from '../../services/LoanService';
import Cities from '../../constants/Cities'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	table: {
		fontFamily: 'Inter'
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(id, name, location, supervisorName, supervisorId) {
	return { id, name, location, supervisorName, supervisorId };
}

let data = {}
let supervisors = []
let rows = [
]

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			// backgroundColor: '#FDF3E5'
		},

	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

function filterValue(value, type) {
	if (value) {
		rows = SearchUtil.search(value, data.data, type)
	}
	else {
		rows = []
		data.data.map((data, index) => {
			rows.push(createData(data.id, data.name, data.location, data.supervisorName, data.supervisorId))
		})
	}
}

const UserManagement = (props) => {

	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("")
	const [searchSupervisorKey, setSearchSupervisorKey] = useState("")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [reassignDialog, setReassignDialog] = useState(false)
	const [selectedAgent, setSelectedAgent] = useState("")
	const [selectedSupervisor, setSelectedSupervisor] = useState("")
	const [assignedSupervisor, setAssignedSupervisor] = useState("")
	const [assignedSupervisorName, setAssignedSupervisorName] = useState("")
	const [defaultSupervisor, setDefaultSupervisor] = useState("")
	const [isReassigned, setIsReassigned] = useState(false)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const [updateData, setUpdateData] = useState(false)
	const [mockIndex, setMockIndex] = useState(0)
	const [isReassignFailed, setIsReassignFailed] = useState(false)
	const [updatingData, setUpdatingData] = useState(false)
	const [reassigning, setReassigning] = useState(false)
	const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(false)

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setUpdatingData(true)
		let response = await LoanService.getLoanAgents();
		supervisors = await LoanService.getSupervisors();
		setUpdatingData(false)
		data = {
			data: response
		}
		rows = []
		response && response.map((data, index) => {
			rows.push(createData(data.id, data.name, data.location, data.supervisorName, data.supervisorId))
		})
		setUpdateData(true)
		setReassigning(false)
		filterValue(props.globalSearchKey ? props.globalSearchKey : searchKey, "NAME")
		setHoveredRow(null)
		setIsSelectedSupervisor(false)
		// response ? setApplicationFormData(response) : setIsFetchingFailed(true)
	}

	if (!initialized) {
		getData()
	}

	async function reassignAgent(agentId, supervisorId) {
		setReassigning(true)
		const res = await LoanService.reassignAgent(agentId, supervisorId)
		if (res == undefined || res && res.success != true) {
			setIsReassignFailed(true)
		}
		else {
			setIsReassignFailed(false)
		}
		setReassigning(false)
		setInitialized(false)
		setIsReassigned(true);
		setReassignDialog(false);

	}

	const handleClose = () => {
		setReassignDialog(false)
	};

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={reassignDialog}>
				<div style={{ display: 'flex', flexDirection: 'column', margin: '24px 32px 24px 32px' }}>
					<div style={{ display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: 600, lineHeight: '24px', fontFamily: 'Inter', marginBottom: 24 }}>Reassign Agent {supervisors.i2iId}</div>
					<div style={{ display: 'flex', justifyContent: 'center', fontSize: 16, fontWeight: 'normal', lineHeight: '20px', fontFamily: 'Inter', marginBottom: 48 }}>Which supervisor should <span> <b>{' ' + selectedAgent.name + ' '}</b></span> be reassigned to?</div>
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="demo-simple-select-outlined-label">Assigned Supervisor</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={selectedSupervisor}
							style={{ width: '100%', marginBottom: 32 }}
							onChange={(event) => { setSelectedSupervisor(event.target.value == null ? "All" : event.target.value); setIsSelectedSupervisor(true) }}
							disabled={reassigning}
						>
							{supervisors.map((item, index) => {
								return <MenuItem value={item}>{item.name}</MenuItem>
							})}
						</Select>
					</FormControl>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button variant="outlined" style={{ backgroundColor: '#F47721', color: '#FFFFFF', marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => { setReassignDialog(false) }} disabled={reassigning}>Cancel</Button>
						<Button variant="contained" style={{ backgroundColor: defaultSupervisor.id == selectedSupervisor.i2iId || reassigning || !isSelectedSupervisor ? '#C4C4C4' : '#F47721', color: '#FFFFFF', borderColor: defaultSupervisor.id == selectedSupervisor.i2iId || reassigning || !isSelectedSupervisor ? '#C4C4C4' : '#F47721', textTransform: 'none', fontFamily: 'Inter' }} disabled={defaultSupervisor.id == selectedSupervisor.i2iId || reassigning || !isSelectedSupervisor}
							onClick={() => { reassignAgent(selectedAgent.id, selectedSupervisor.i2iId) }} >Reassign</Button>
					</div>
				</div>
			</Dialog>
			<Snackbar open={isReassigned} autoHideDuration={6000} onClose={() => setIsReassigned(false)}>
				<Alert onClose={() => setIsReassigned(false)} severity={isReassignFailed ? "error" : "success"}>
					{isReassignFailed ? <span>Failed to reassign agent</span>
						: <div><span><b>{selectedAgent.name}</b></span> has been reassigned from <span><b>{defaultSupervisor.supervisorName}</b></span> to <span><b>{selectedSupervisor.name}.</b></span></div>}
				</Alert>
			</Snackbar>
			<div style={{ margin: 20 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: 28, lineHeight: '32px' }}>User Management
					<Button variant="contained" style={{ backgroundColor: '#F47721', color: '#FFFFFF', marginRight: 10, borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }} ><MdIcon path={mdiAccountPlus} size="1.5em" color="#FFFFFF" style={{ marginRight: 10 }} />Add Loan Agent</Button>
				</div><br />
				<LinearProgress className={classes.root} style={{ visibility: updatingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
				<Paper style={{ height: 800 }}>
					<div>
						<div style={{ display: 'flex', padding: '20px 24px 20px 24px' }}>
							<TextField
								className={classes.root}
								id="input-with-icon-textfield"
								label="Search by name of agent"
								variant="outlined"
								style={{ width: 340, minWidth: 250, height: 50, marginRight: 24 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<MdIcon path={mdiMagnify} size="24px" />
										</InputAdornment>
									),
								}}
								defaultValue={ props.globalSearchKey ? props.globalSearchKey : searchKey }
								onChange={(event) => { filterValue(event.target.value, "NAME"); setSearchKey(event.target.value); props.setSearchKeyGlobal(event.target.value) }}
							/>
							<div>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={props.globalLocationKey ? props.globalLocationKey : searchLocationKey}
										style={{ width: 160, marginRight: 24 }}
										onChange={(event) => { filterValue(event.target.value, "LOCATION"); setSearchLocationKey(event.target.value == null ? "All" : event.target.value); props.setLocationKeyGlobal(event.target.value == null ? "All" : event.target.value) }}
									>
										<MenuItem value={""}>All</MenuItem>
										{Cities.map((city, index) => {
											return (<MenuItem value={city}>{city}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</div>
							<div>
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="demo-simple-select-outlined-label">Supervisor</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={props.globalSupervisorKey ? props.globalSupervisorKey : searchSupervisorKey}
										style={{ width: 160 }}
										onChange={(event) => { filterValue(event.target.value, "SUPERVISOR"); setSearchSupervisorKey(event.target.value == null ? "All" : event.target.value); props.setSupervisorKeyGlobal(event.target.value == null ? "All" : event.target.value) }}
									>
										<MenuItem value={""}>All</MenuItem>
										{supervisors.map((item, index) => {
											return <MenuItem value={item.name}>{item.name}</MenuItem>
										})}
									</Select>
								</FormControl>
							</div>
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', minWidth: 200 }}>
							<div style={{ marginRight: 16, color: '#909196', fontSize: 14, fontFamily: 'Inter' }}>Show items</div>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										labelId="demo-simple-select-filled-label"
										defaultValue={10}
										style={{ width: 72 }}
									>
										<MenuItem value={10}>10</MenuItem>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={30}>30</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div style={{}}>
							<TableContainer>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Agent Name</StyledTableCell>
											<StyledTableCell>Location</StyledTableCell>
											<StyledTableCell>Assigned to</StyledTableCell>
											<StyledTableCell align="right">Actions</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows && rows.map((row, index) => (
											<StyledTableRow style={{ backgroundColor: hoveredRow == index ? '#FDF3E5' : '#FFFFFF' }} onMouseOver={() => setHoveredRow(index)} onMouseOut={() => setHoveredRow(null)}>
												<StyledTableCell component="th" scope="row" style={{ fontWeight: 600 }}>
													{row.name}
												</StyledTableCell>
												<StyledTableCell>{row.location}</StyledTableCell>
												<StyledTableCell>{row.supervisorName}</StyledTableCell>
												<StyledTableCell onClick={() => { setSelectedAgent(row); setSelectedSupervisor(row); setDefaultSupervisor(row); setReassignDialog(true); setMockIndex(index) }} align="right"><div style={{ color: '#F47721', cursor: 'pointer', zIndex: 2 }}>Reassign</div></StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
				</Paper>
			</div>
		</div>
	)
}

export default UserManagement;