export const texts = {
  headerFormList: 'Clients',
  buttonCreateNewClient: 'Add New Client',
  labelSearchForms: 'Search Client Name',
  columnFormName:'Client Name',
  columnForLoans:'Loan',
	pageFrom: 'Showing',
  pageOf: 'of',
	pageTo: 'to',
  tabProfile: 'Profile',
  tabApplication: 'Applications',
  successUpdate: 'Successfully updated client profile',
  failUpdate: 'Failed to update client profile',
  buttonBack: 'Back',
  buttonUpdate: 'Update',
}