import axios from "axios";

const {
	REACT_APP_AGENCY_BANKING_API_URI,
	REACT_APP_AGENCY_BANKING_VAS_API_URI,
	REACT_APP_CASHFLOW_API_URI,
	REACT_APP_AGENCY_BANKING_V2_API_URI,
	
} = process.env;

const instance = axios.create({
	baseURL: REACT_APP_AGENCY_BANKING_API_URI,
	timeout: 30000
});

const vas_instance = axios.create({
	baseURL: REACT_APP_AGENCY_BANKING_VAS_API_URI,
	timeout: 30000
});

const i2i_instance = axios.create({
	baseURL: REACT_APP_AGENCY_BANKING_API_URI,
	timeout: 30000
});

const i2i_instance_v2 = axios.create({
	baseURL: REACT_APP_AGENCY_BANKING_V2_API_URI,
	timeout: 30000
});

const cashflow_instance = axios.create({
	baseURL: REACT_APP_CASHFLOW_API_URI,
	timeout: 30000
});

function createHeaders(customHeaders = {}) {
	let headers = Object.assign({}, customHeaders);
	let userData = localStorage.getItem('@@i2i/USER');
	if (userData) {
		let jsonData = JSON.parse(userData);
		headers['Authorization'] = 'Bearer ' + jsonData.genie_token;
	}
	return headers;
}

function createVasHeaders(customHeaders = {}) {
	let headers = Object.assign({}, customHeaders);
	let userData = localStorage.getItem('@@i2i/USER');
	if (userData) {
		let jsonData = JSON.parse(userData)
		headers['Authorization'] = 'Bearer ' + jsonData.token;
	}
	return headers;
}

function createi2iHeaders(customHeaders = {}) {
	let headers = Object.assign({}, customHeaders);
	let userData = localStorage.getItem('@@i2i/USER');
	if (userData) {
		let jsonData = JSON.parse(userData)
		headers['Authorization'] = 'Bearer ' + jsonData.agencyBankingToken;
	}
	return headers;
}

export default {
	instance,
	vas_instance,

	get: async (path, options = {}) => {
		if (options) {
			options.headers = createHeaders(options.headers);
		}
		try {
			return await instance.get(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	get_vas: async (path, options = {}) => {
		if (options) {
			options.headers = createVasHeaders(options.headers);
		}
		try {
			return await vas_instance.get(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	get_i2i: async (path, options = {}) => {
		if (options) {
			options.headers = createi2iHeaders(options.headers);
		}
		try {
			return await i2i_instance.get(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	get_i2i_v2: async (path, options = {}) => {
		if (options) {
			options.headers = createi2iHeaders(options.headers);
		}
		try {
			return await i2i_instance_v2.get(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	post_i2i_v2: async (path,data, options = {}) => {
		if (options) {
			options.headers = createi2iHeaders(options.headers);
		}
		try {
			return await i2i_instance_v2.post(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	patch_i2i_v2: async (path,data, options = {}) => {
		if (options) {
			options.headers = createi2iHeaders(options.headers);
		}
		try {
			return await i2i_instance_v2.patch(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	get_cashflow: async (path, options = {}) => {
		if (options) {
			options.headers = createi2iHeaders(options.headers);
		}
		try {
			return await cashflow_instance.get(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},
	postAgency: async (path, data, options = {}) => {
		options.headers = createi2iHeaders(options.headers);
		try {
			return await i2i_instance.post(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},
	post_vas: async (path, data, options = {}) => {
		options.headers = createVasHeaders(options.headers);
		try {
			return await vas_instance.post(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},
	post: async (path, data, options = {}) => {
		options.headers = createHeaders(options.headers);
		try {
			return await instance.post(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	patch: async (path, data, options = {}) => {
		options.headers = createVasHeaders(options.headers);
		try {
			return await vas_instance.patch(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	put: async (path, data, options = {}) => {
		options.headers = createHeaders(options.headers);
		try {
			return await instance.put(path, data, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	},

	delete: async (path, options = {}) => {
		options.headers = createHeaders(options.headers);
		try {
			return await instance.delete(path, options);
		} catch (err) {
			console.log(err);
			throw err;
		}
	}
};
