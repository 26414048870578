import React, { Fragment } from "react";

const HeaderTitle = (props) => (
  <Fragment>
    <div style={{ fontWeight: 600, fontSize: 28, lineHeight: "32px" }}>
      {props.children}
    </div>
    <br />
  </Fragment>
);

export default HeaderTitle;
