import React, { useState } from 'react';
import {
	Switch,
	Button,
	LinearProgress,
	Paper,
	Grid,
	TextField,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormHelperText,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Icon as MdIcon } from '@mdi/react'
import { mdiDeleteOutline } from '@mdi/js';
import { 
	checkRequired, 
	toggleDisplay, 
	useStyles, 
	texts, 
	toFlatArray, 
	toObjectArray,
	Api,
} from '../helper';

const FormCreate = (props) => {
	const classes = useStyles();
	const [formName, setFormName] = useState((props.detail?.name || ''));
	const [formType, setFormType] = useState((props.detail?.typeId || ''));
	const [formProducts, setFormProducts] = useState(toObjectArray((props.detail?.productIds || ''), (props.detail?.productNames || '')));
	const [isGlobal, setIsGlobal] = useState((props.detail?.productIds || '') === '');
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleFormTypeChange = (event) => {
		const formType = event.target.value;
		errors['formType'] = checkRequired(formType, texts.labelType);
		setErrors(errors);
		setFormType(formType);
	}

	const handleFormNameChange = (event) => {
		const formName = event.target.value;
		errors['formName'] = checkRequired(formName, texts.labelName);
		setErrors(errors);
		setFormName(formName);
	}

	const handleFormProductsChange = (event, value) => {
		errors['formProducts'] =  checkRequired(value, texts.labelProduct);
		setErrors(errors);
		setFormProducts(value);
	}

	const handleGlobalChange = (event, checked) => { 
		setFormProducts([]);
		setIsGlobal(checked);
	}

	const handleCancel = () => {
		setFormName('');
		setFormType('');
		setIsGlobal(true);
		setFormProducts([]);
		props.onFormCancel();
	}

	const handleCreate = async (withEdit) => {
		const errors = {};
		errors['formName'] = checkRequired(formName, texts.labelName);
		errors['formType'] = checkRequired(formType, texts.labelType);
		errors['formProducts'] = (isGlobal ? '' : (checkRequired(formProducts, texts.labelProduct)));
		setErrors(errors);
		if (!errors['formType'] &&
			!errors['formName'] &&
			!errors['formProducts']) {
			setIsLoading(true);
			const response = await Api.saveForm((props.id || 0), 
												formName, 
												formType, 
												(isGlobal ? '' : toFlatArray(formProducts, 'id').join(', ')),
												(props.id ? 0 : (props.detail?.id || 0)));
			setIsLoading(false);
			if (response) {
				const result = (response.result || 0);
				if (result > 0) {
					props.onFormSuccess(result, withEdit);
				} else {
					setErrors({ 'formName':  (response.message || texts.infoFormSaveError) });
				}
			}
		}
	}

	return (
		<div className={classes.pageWrapperModal}>
			<Paper className={classes.paperWrapperModal}>
				<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.pageHeaderModal}>
						<span className={classes.pageTitleModal}>{props.detail ? (props.id ? texts.headerEditForm : texts.headerDuplicateForm) : texts.headerNewForm}</span>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`} error={errors['formType']} >
							<InputLabel id='form-type-label'>{texts.labelType}</InputLabel>
							<Select
								labelId='form-type-label'
								label={texts.labelType}
								id='form-type'
								value={formType || ''}
								onChange={handleFormTypeChange}>
								{props.types && props.types.map((data) => (
									<MenuItem className={classes.menuItem} value={data.id}>{data.name}</MenuItem>
								))}
							</Select>	
							<FormHelperText>{errors['formType']}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<TextField 
								id='form-name'
								label={texts.labelName}
								variant='outlined'
								defaultValue={formName || ''}
								error={errors['formName']}
								helperText={errors['formName']}
								onChange={handleFormNameChange}/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
						 	className={classes.swithControl}
							control={<Switch checked={isGlobal} onChange={handleGlobalChange} />}
							label={isGlobal ? texts.labeltoGlobal : texts.labeltoProduct}/>
					</Grid>
					{!isGlobal && <Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<Autocomplete
								multiple
								id='products'
								options={(props.products || [])}
								getOptionSelected={(option, value) => option.id === value.id}
								getOptionLabel={(option) => option.name}
								value={formProducts || []}
								filterSelectedOptions
								disableClearable={true}
								noOptionsText={texts.infoNoProduct}
								onChange={handleFormProductsChange}
								ChipProps={{deleteIcon: <MdIcon path={mdiDeleteOutline} size='12px' />}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='outlined'
										label={texts.labelProducts}
										placeholder={texts.infoSearchProducts}
										error={errors['formProducts']}
										helperText={errors['formProducts']}
									/>
								)}
								classes={{
									popper: classes.formControl,
								}}/>
						</FormControl>
					</Grid>}
					<Grid item xs={12} className={classes.controlEndNoWrap}>
						<Button variant='outlined' className={classes.secondaryButtonNoWrap} onClick={handleCancel}>{texts.buttonCancel}</Button>
						{!props.id &&
						<Button variant='outlined' className={classes.secondaryButtonNoWrap} onClick={() => handleCreate(true)}>{props.detail ? texts.buttonDuplicateEdit : texts.buttonNewEdit}</Button>
						}
						<Button variant='contained' className={classes.primaryButtonNoWrap} onClick={() => handleCreate(false)}>{props.detail ? (props.id ? texts.buttonSaveForm : texts.buttonDuplicateForm) : texts.buttonNewForm}</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

export default FormCreate;
