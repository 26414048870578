import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
    InputAdornment,
	FormControl,
    FormControlLabel,
    Grid,
    Switch,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiConsoleNetworkOutline, mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignBoldStyle,
    getAlignBoldWidthStyle, 
    useStyles, 
    texts, 
    StyledTableDetailCell,
    getDisplayValue,
    modes,
    PdfStyles,
    getPdfAlignBoldWidthStyle,
    limits,
    componentTypes,
} from '../../helper';

const FormTableDetailTextProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [limit, setLimit] = useState(limits.INPUT_TEXT);
    const [required, setRequired] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [reference, setReference] = useState('');
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [errors, setErrors] = useState({});

    const handleLabelChange = (event) => {
		const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
		errors['label'] = checkRequired(label, texts.labelComponentColumnLabel);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handleLimitChange = (event) => {
        const limit = parseInt(event.target.value || 0);
        if (limit >= 1) {
            setLimit(limit);
            props.updateLimit(limit);
        }
    }

    const handleRequiredChange = (event, checked) => { 
        setRequired(checked);
        props.updateRequired(checked);
    }

    const handlePrefixChange = (event) => {
        const prefix = event.target.value;
        setPrefix(prefix);
        props.updatePrefix(prefix);
    }

    const handleSuffixChange = (event) => {
        const suffix = event.target.value;
        setSuffix(suffix);
        props.updateSuffix(suffix);
    }

    const handleBoldChange = (event, checked) => { 
        setBold(checked);
        props.updateBold(checked);
    }

    const handleWidthChange = (event) => {
        const width = parseInt(event.target.value || 0);
        if (width >= 0 && width <= 100) {
            setWidth(width);
            props.updateWidth(width);  
        }
    }

    const handleFillerChange = (event) => {
        const filler = event.target.value;
        setFiller(filler);
        props.updateFiller(filler);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }
    
    useEffect(() => {
        setLabel(props.label);
        setAlign(props.align);
        setLimit(((props.limit && props.limit > 0) ? props.limit : limits.INPUT_TEXT));
        setRequired(props.required || false);
        setPrefix(props.prefix || '');
        setSuffix(props.suffix || '');
        setBold(props.bold || false);
        setWidth(props.width || '');
        setFiller(props.filler || '');
        setReference(props.reference || '');
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentColumnLabel);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableDetailText}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-label'
                            label={texts.labelComponentColumnLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={required} onChange={handleRequiredChange} disabled={props.isReadOnly} />}
                        label={required ? texts.infoValueRequired : texts.infoValueOptional}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-limit'
                            type='number'
                            label={texts.labelComponentLimit}
                            variant='outlined'
                            value={limit || ''}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={handleLimitChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-prefix'
                            label={texts.labelComponentPrefix}
                            variant='outlined'
                            value={prefix || ''}
                            onChange={handlePrefixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-suffix'
                            label={texts.labelComponentSuffix}
                            variant='outlined'
                            value={suffix || ''}
                            onChange={handleSuffixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={bold} onChange={handleBoldChange} disabled={props.isReadOnly} />}
                        label={bold ? texts.infoFontBold : texts.infoFontNormal}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-width'
                            type='number'
                            label={texts.labelComponentWidth}
                            variant='outlined'
                            value={width || ''}
                            onChange={handleWidthChange}
                            InputProps={{
                                inputProps: { min: 1, max: 100 },
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-filler'
                            label={texts.labelComponentFiller}
                            variant='outlined'
                            value={filler || ''}
                            onChange={handleFillerChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableDetailTextDesigner = (props) => {
	const classes = useStyles();
    const [label, setLabel] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [limit, setLimit] = useState(0);
    const [required, setRequired] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [reference, setReference] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdateLimit = (limit) => {
        setLimit(limit);
        props.handleTrackChange();
    }

    const handleUpdateRequired = (required) => {
        setRequired(required);
        props.handleTrackChange();
    }

    const handleUpdatePrefix = (prefix) => {
        setPrefix(prefix);
        props.handleTrackChange();
    }

    const handleUpdateSuffix = (suffix) => {
        setSuffix(suffix);
        props.handleTrackChange();
    }

    const handleUpdateBold = (bold) => {
        setBold(bold);
        props.handleTrackChange();
    }

    const handleUpdateWidth = (width) => {
        setWidth(width);
        props.handleTrackChange();
    }

    const handleUpdateFiller = (filler) => {
        setFiller(filler);
        props.handleTrackChange();
    }

    const handleUpdateReference = (reference) => {
        setReference(reference);
        props.handleFormulaReference('!' + props.id, reference);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableDetailTextProperties 
                                            id={props.id} 
                                            label={label} 
                                            align={align} 
                                            limit={limit}
                                            required={required}
                                            prefix={prefix}
                                            suffix={suffix}
                                            bold={bold}
                                            width={width}
                                            filler={filler}
                                            reference={reference}
                                            updateLabel={handleUpdateLabel}
                                            updateAlign={handleUpdateAlign}
                                            updateLimit={handleUpdateLimit}
                                            updateRequired={handleUpdateRequired}
                                            updatePrefix={handleUpdatePrefix}
                                            updateSuffix={handleUpdateSuffix}
                                            updateBold={handleUpdateBold}
                                            updateWidth={handleUpdateWidth}
                                            updateFiller={handleUpdateFiller}
                                            updateReference={handleUpdateReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
		errors['label'] = checkRequired(label);
        return (!!errors['label']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLEDETAILTEXT, 
                                    label, 
                                    align,
                                    limit,
                                    required,
                                    prefix,
                                    suffix,
                                    bold,
                                    width,
                                    filler,
                                    reference }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setAlign(props.align);
            setLimit(props.limit);
            setRequired(props.required);
            setPrefix(props.prefix);
            setSuffix(props.suffix);
            setBold(props.bold);
            setWidth(props.width);
            setFiller(props.filler);
            setReference(props.reference);
            if (props.reference) {
                props.handleFormulaReference('!' + props.id, props.reference);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-detail-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableDetailText}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={label || ''}
                            variant='outlined'
                            value={label || texts.infoConfigTableDetailText}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : null),
                                endAdornment:  (suffix ? <InputAdornment position='end'>{suffix}</InputAdornment> : null),
                                inputProps: { style: getAlignBoldStyle(align, bold) }
                            }} />
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableDetailText} 
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigTableDetailText}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

const FormTableDetailTextHeader = (props) => {
    useEffect(() => {
        if (props.reference) {
            props.handleFormulaReference('!' + props.id, props.reference);
        }
    }, []);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {props.label || ''}
        </StyledTableDetailCell>
    )
}

const FormTableDetailTextView = (props) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.inputData[props.id + '-' + props.rowId] || '');
    }, [props.rowId]);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {getDisplayValue(value, props.filler, props.prefix, props.suffix)}
        </StyledTableDetailCell>
    )
}

const FormTableDetailTextEdit = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [errors, setErrors] = useState({});
    const [isInitialize, setIsInitialize] = useState(false);

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.handleInputData((props.id + '-' + props.rowId), value, false, false, true);
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
            setErrors(errors);
        }
	}

    const doValidate = () => {
        const errors = {};
        if (props.required) {
		    errors['value'] = checkRequired(value, (props.label || ''));
        }
        setErrors(errors);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            if (props.tick.startsWith(props.rowId)) {
                props.updateComponent(props.rowId, props.id, value, doValidate());
            }
        } else {
            setIsInitialize(true);
        }
    }, [props.tick]);

    useEffect(() => {
        setValue(props.inputData[props.id + '-' + props.rowId] || '');
        props.trackComponent(props.rowId, props.id);
    }, [props.rowId, props.id]);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull} ${classes.tableControl}`}>
                <TextField 
                    id={props.id}
                    variant='outlined'
                    value={value || ''}
                    error={errors['value']}
                    helperText={errors['value']}
                    InputProps={{
                        startAdornment: (props.prefix ? <InputAdornment position='start'>{props.prefix}</InputAdornment> : null),
                        endAdornment:  (props.suffix ? <InputAdornment position='end'>{props.suffix}</InputAdornment> : null),
                        inputProps: { style: getAlignBoldStyle(props.align, props.bold),
                                        maxLength: (props.limit ? props.limit : limits.INPUT_TEXT), },
                    }}
                    onChange={handleValueChange} />
            </FormControl>
        </StyledTableDetailCell>
    )
}

export const FormTableDetailTextPreview = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailTextHeader {...props} />;
    } else if (props.mode === modes.VIEW) {
        return <FormTableDetailTextView {...props} />;
    } else if (props.mode === modes.EDIT) {
        return <FormTableDetailTextEdit {...props} />;
    } else {
        return <span />
    }
}

const FormTableDetailTextPdfHeader = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailHeaderCol)} wrap={true}>
            <Text>{props.label || ''}</Text>
        </View>
    )
}

const FormTableDetailTextPdfView = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailDataCol)} wrap={true}>
            <Text>{getDisplayValue((props.inputData[props.id + '-' + props.rowId] || ''), props.filler, props.prefix, props.suffix)}</Text>
        </View>
    )
}

export const FormTableDetailTextPdf = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailTextPdfHeader {...props} />;
    } else if (props.mode === modes.VIEW) {
        return <FormTableDetailTextPdfView {...props} />;
    } else {
        return <span />
    }
}