import React, { Component, useState, useEffect } from 'react';
import {
  LinearProgress,
	Paper,
	TableRow,
	TableCell,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

//import { withStyles } from '@material-ui/styles'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { map } from "lodash";
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DropDownHeaderCell = withStyles({
  root: {
    fontSize: 10,
    color: "#909196",
    borderBottom: "none"
  }
})(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		tooltip: {
			width: 190
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		},
		"& .MuiButton-label": {
			width: 240
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	tooltip: {
		width: 190,
		maxWidth: 190
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(referenceId, approvedDate, applicantName, loanProduct, requestedAmount) {
	return { referenceId, approvedDate, applicantName, loanProduct, requestedAmount };
}

let rows = []

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			backgroundColor: '#FDF3E5'
		},
		cursor: 'pointer'
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

const ImportApplications = (props) => {

	let history = useHistory();
  
	const classes = useStyles();
	const [initialized, setInitialized] = useState(false)
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [updatingData, setUpdatingData] = useState(false);

	async function getData() {
		
	}

	async function getIxportSettings(uniqueIds) {

	}

	async function importApplications() {
		
	}

	const handleClose = () => {
    setDateAnchorEl(null);
  };

  if (!initialized) {
		 getData()
	}

  const open = Boolean(dateAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeDate = () => {

  };

  const handleClearDate = () => {

  };

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<div style={{ margin: 20 }}>
				<div style={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', display: "flex", justifyContent: 'space-between' }}>Import Applications
				</div><br />
        <LinearProgress className={classes.root} style={{ visibility: updatingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
				<Paper>
					
				</Paper>
			</div>
		</div>
	)
}

export default ImportApplications;