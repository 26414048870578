export const checkRequired = (field, name) => {
    return (isEmpty(field) ? `${name || ''} is required` : '');
}

export const isEmpty = (field) => {
    if (typeof field === 'string') {
        return  ((field || '').trim().length <= 0);
    } else  if (Array.isArray(field)) {
        return ((field || []).length <= 0);
    } else if (typeof field === 'object') {
        return (JSON.stringify(field || {}) === JSON.stringify({}));
    } else {
        return !field;
    }
}
