import { makeStyles, withStyles } from '@material-ui/core/styles';
import { StyleSheet } from '@react-pdf/renderer';
import {
	TableRow,
	TableCell,
	Tabs,
	Tab,
} from '@material-ui/core/'

const drawerWidth = 200;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& .MuiPaginationItem-page.Mui-selected ': {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		'& .MuiPaginationItem-rounded': {
			fontFamily: 'Inter'
		},
		'& .MuiPaginationItem-page.Mui-selected ': {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		'& .MuiPaginationItem-rounded': {
			fontFamily: 'Inter'
		},
		MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#00ff00',
        "& .MuiPickersToolbar-toolbar": {
          backgroundColor: '#00ff00'
        }
      }
    },
		[theme.breakpoints.down('sm')]: {
			'& .MuiPagination-ul': {
				justifyContent: 'center',
			}
		}
	},
	formControl: {
		'& .MuiFormLabel-root': {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		'& .MuiInputBase-input': {
			height: 0,
			lineHeight: '0px',
			fontSize: 14,
			fontFamily: 'Inter',
			color: '#636363',
		},
		'& .MuiInputBase-root': {
			minHeight: 40,
			lineHeight: '2px',
			backgroundColor: '#F6F6F6',
		},
		'& .MuiSelect-selectMenu': {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		'& .MuiInputLabel-shrink': {
			top: '-2px',
		},
		'& .MuiFormLabel-root': {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		'& .MuiAutocomplete-option': {
			fontFamily: 'Inter',
			fontSize: 14,
			color: '#636363',
		},
		'& .MuiAutocomplete-noOptions': {
            fontFamily: 'Inter',
			fontSize: 14,
			color: '#636363',
        },
		'& .MuiChip-root': {
			height: 'auto',
			background: '#EEEEEE',
			border: '1px solid #6F7585',
			borderRadius: '4px',
		},
		'& .MuiChip-label': {
			padding: '2px 5px',
			lineHeight: '16px',
			fontSize: '12px',
			fontFamily: 'Inter',
			color: 'rgba(43, 45, 50, 0.8)',
		},
		'& .MuiChip-deleteIcon': {
			paddingLeft: '4px',
			color: '#424347',
		},
		'& .MuiFormControlLabel-label': {
			fontFamily: 'Inter',
			fontSize: 14,
			color: '#636363',
			paddingRight: '10px',
			textAlign: 'right',
		},
		'& .MuiFormControlLabel-root': {
			marginLeft: '0px',
			marginRight: '0px',
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: '#F47721',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#F47721',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#F47721',
		},
		'& .MuiTypography-colorTextSecondary': {
			lineHeight: '0px',
			fontSize: 14
        }
	},
	tableControl: {
		'& .MuiInputBase-input': {
			height: 0,
			lineHeight: '0px',
			fontSize: 12,
			fontFamily: 'Inter',
			color: '#2B2D33',
			minWidth: 40,
			[theme.breakpoints.down('sm')]: {
				minWidth: 70,
			}
		},
	},
	table: {
		fontFamily: 'Inter',
		overflow: 'hidden',
		// '& .MuiTableRow-root:nth-child(1n)': {
		// 	backgroundColor: '#FDF3E5',
		// },
		// '& .MuiTableRow-root:nth-child(2n)': {
		// 	backgroundColor: '#FFFFFF',
		// },
		[theme.breakpoints.down('xs')]: {
			'& .MuiTableHead-root': {
				borderTop: '1px solid #e0e0e0',
			},
			'& .MuiTableRow-root': {
				borderBottom: '1px solid #e0e0e0',
			},
			'& .MuiTableCell-root': {
				display: 'block',
    			width: '100%',
    			borderBottom: 'none',
    			padding: '5px 20px',
			},
		},
	},
	pageWrapper: {
		fontFamily: 'Inter',
		margin: 30,
		paddingBottom: 30,
		[theme.breakpoints.down('xs')]: {
			paddingBottom: '10px',
			margin: '10px',
			paddingBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '15px',
			margin: '15px',
			paddingBottom: '15px',
		}
	},
	pageWrapperModal: {
		fontFamily: 'Inter',
		width: '588px',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 70,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	pageWrapperDesigner: {
		fontFamily: 'Inter',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		margin: 30,
		[theme.breakpoints.down('xs')]: {
			paddingBottom: '10px',
			margin: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '15px',
			margin: '15px',
		},
	},
	pageWrapperGroup: {
		fontFamily: 'Inter',
		margin: 30,
		marginBottom: 10,
		[theme.breakpoints.down('xs')]: {
			paddingBottom: 10,
			margin: 10,
			marginBottom: 0,
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 15,
			margin: 15,
			marginBottom: 0,
		}
	},
	pageWrapperDrawer: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: (drawerWidth + 30),
		[theme.breakpoints.down('xs')]: {
			marginRight: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: '15px',
		}
	},
	paperWrapper: {
		position: 'relative',
		borderRadius: '4px 4px 0 0',
		height: 'auto',
	},
	paperWrapperModal: {
		position: 'relative',
		borderRadius: '4px 4px 0 0',
		height: 'auto',
		padding: 20,
	},
	paperChipWrapper: {
		backgroundColor: '#F6F6F6',
		padding: 10,
		marginTop: 2,
		'& .MuiChip-root': {
			height: 'auto',
			background: '#EEEEEE',
			border: '1px solid #6F7585',
			borderRadius: '4px',
			margin: '1px 5px',
		},
		'& .MuiChip-label': {
			padding: '2px 5px',
			lineHeight: '16px',
			fontSize: '12px',
			fontFamily: 'Inter',
			color: 'rgba(43, 45, 50, 0.8)',
		},
		'& .MuiChip-deleteIcon': {
			paddingLeft: '4px',
			color: '#424347',
		},
	},
	pageHeader: {
		marginBottom: 20,
	},
	pageHeaderModal: {
		marginBottom: 10,
	},
	pageTitleWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	pageTitle: {
		fontWeight: 600,
		fontSize: 28,
		lineHeight: '43px',
	},
	pageTitleIcon: {
		marginLeft: 10,
	},
	pageTitleModal: {
		fontWeight: 600,
		fontSize: 20,
		lineHeight: '24px',
	},
	swithControl: {
		marginTop: -10,
		'& .MuiFormControlLabel-label': {
			fontFamily: 'Inter',
			fontSize: 14,
			color: '#636363',
		},
		'& .MuiSwitch-colorSecondary.Mui-checked': {
			color: '#f5922f',
		},
		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#f8b36d',
			opacity: 1,
		}
	},
    swithControlProp: {
        marginTop: -10,
		marginRight: 0,
		'& .MuiFormControlLabel-label': {
			fontFamily: 'Inter',
			fontSize: 12,
			color: '#636363',
		},
		'& .MuiSwitch-colorSecondary.Mui-checked': {
			color: '#f5922f',
		},
		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#f8b36d',
			opacity: 1,
		}
	},
	primaryButton: {
		backgroundColor: '#F47721', 
		color: '#FFFFFF', 
		borderColor: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: '#DC6B1E',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	secondaryButton: {
		border: '2px solid #F47721', 
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		marginRight: '15px',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginBottom: '10px',
		},
	},
	primaryButtonNoWrap: {
		backgroundColor: '#F47721', 
		color: '#FFFFFF', 
		borderColor: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: '#DC6B1E',
		},
	},
	secondaryButtonNoWrap: {
		border: '2px solid #F47721', 
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		marginRight: '15px',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
	},
	secondaryButtonNoMarg: {
		border: '2px solid #F47721', 
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginBottom: '10px',
		},
	},
	secondaryButtonWithIcon: {
		border: '2px solid #F47721', 
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
        fontSize: 12,
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
	},
	secondaryButtonGroup: {
		border: '2px solid #F47721', 
		backgroundColor: '#FFFFFF',
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		boxShadow: 'none',
        fontSize: 12,
		padding: '4px 10px',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#f8ad7a',
		},
	},
	buttonIconLeft: {
		color: '#FFFFFF',
		marginRight: 10,
	},
	buttonIconRight: {
		color: 'inherit',
		marginLeft: 10,
	},
	buttonIconGroup: {
		color: 'inherit',
		marginRight: 10,
	},
	controlWrapper: {
		padding: 20,
	},
	controlEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& .MuiBadge-badge': {
			right: '15px',
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			'& .MuiBadge-root': {
				display: 'inline',
			},
			'& .MuiBadge-badge': {
				right: '0',
			},
		},
	},
	controlEndNoWrap: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	widthFull: {
		width: '100%',
	},
	rowSelector: {
		cursor: 'pointer',
		color: '#F47721',
		fontWeight: 600,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	textInActive: {
		color: '#898989',
	},
	plCell: {
		paddingLeft: '20px !important',
	},
	prCell: {
		paddingRight: '20px !important',
	},
	footerWrapper: {
		padding: '15px 20px', 
		backgroundColor: '#F6F6F6',
		margin: 0,
		width: '100%'
	},
	recordCount: {
		marginRight: 16,
		fontSize: 14,
		color: '#636363'
	},
	listItems: {
		width: 72,
	},
    menuItem: {
        minHeight: '0px',
		fontFamily: 'Inter',
		fontSize: 14,
		color: '#636363',
    },
	loader: {
		backgroundColor: '#E5E5E5',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: '#F5922F'
		},
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: '#909196',
	},
	drawerPaperWith: {
		backgroundColor: '#E1E1E1 !important',
	},
	drawerWrapper: {
		fontFamily: 'Inter',
		margin: 15,
	},
	drawerTitle: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '24px',
		textTransform: 'uppercase',
		color: '#FFFFFF',
		borderBottom: 'solid 1px #FFFFFF',
		width: '100%',
		paddingBottom: '5px',
	},
	drawerTitleWith: {
		color: '#424347 !important',
		borderColor: '#C4C4C4 !important',
	},
	drawerNoComponent: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '16px',
		color: '#FFFFFF',
	},
	hideDrawer: {
		position: 'absolute',
   		right: '1px',
    	top: '1px',
		color: '#FFFFFF',
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	hideDrawerWith: {
		color: '#424347 !important',
	},
	builderWrapper: {
		height: 'auto',
		paddingBottom: 30,
	},
	addGroupWrapper: {
		position: 'relative',
		'& .MuiDivider-root': {
			margin: '10px 20px',
			backgroundColor: '#F5922F',
			height: '2px',
		},
	},
	addGroupButton: {
		color: '#F47721', 
		textTransform: 'none', 
		fontFamily: 'Inter',
		position: 'absolute',
		top: '-17px',
		left: 'calc(50% - 90px)',
		backgroundColor: '#E5E5E5',
		width: '180px',
		'&:hover': {
			boxShadow: 'none',
			color: '#FFFFFF', 
			backgroundColor: '#F47721',
		},
	},
	lineBreak: {
		position: 'relative',
		'& .MuiDivider-root': {
			margin: 0,
			marginBottom: 10,
			backgroundColor: '#F1F1F1',
			height: '1px',
		},
	},
	componentInput: {
        marginTop: 10,
        '& .MuiFormLabel-root.Mui-focused': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgba(0, 0, 0, 0.23)',
		},
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
		},
    },
	componentWrapper: {
        padding: 10,
        paddingTop: 5,
        border: '2px solid #E1E1E1',
        backgroundColor: '#F6F6F6',
        opacity: '0.7',
        boxSizing: 'border-box',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    componentSelected: {
        border: '2px solid #F5922F',
        backgroundColor: '#FFFFFF',
        opacity: '1',
        cursor: 'default',
    },    
	componentTitleWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
	componentTitle: {
        fontFamily: 'Inter',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: '20px',
        width: '100%',
        textAlign: 'center',
        marginTop: 2,
	},
	componentTitleWithIcon: {
        fontFamily: 'Inter',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: '20px',
        textAlign: 'center',
        marginTop: 2,
	},
	propertyWrapper: {
        padding: 5,
        marginTop: 20,
    },
    componentDisplay: {
        backgroundColor: '#F1F1F1',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '24px',
        color: '#909196',
        padding: 5,
    },
	componentDisplayBox: {
        backgroundColor: '#F1F1F1',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: '16px',
        color: '#2B2D33',
        padding: 10,
    },
	componentDisplayBoxBorder: {
        backgroundColor: '#F1F1F1',
        borderBottom: '1px solid #959699',
        borderRadius: '4px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontSize: 12,
        lineHeight: '16px',
        padding: 10,
    },
	cardWrapper: {
		fontFamily: 'Inter',
	},
    cardHeader: {
        padding: 20,
        borderBottom: '1px solid #E1E1E1',
        '& .MuiCardHeader-avatar': {
            marginRight: 5,
            marginLeft: -15,
        },
        '& .MuiCardHeader-action': {
            marginRight: 10,
            marginTop: 8,
            marginLeft: 300,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 100,
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 20,
            },
		},
	},
    cardContent: {
        margin: '20px 30px',
    },
	cardActionButton: {
        marginRight: 15,
    },
	componentMenu: {
        '& .MuiMenu-paper': {
            width: '170px',
        },
        '& .MuiMenuItem-root': {
            color: '#2B2D33', 
            textTransform: 'none', 
            fontFamily: 'Inter',
            fontSize: 14,
        },
        '& .MuiListItem-button:hover': {
            color: '#F47721',
            backgroundColor: '#FFF9F0',
        },
    },
    noComponentWrapper: {
        minHeight: '168px',
        backgroundColor: '#F6F6F6',
    },
    noComponent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 45,
    },
    noComponentIcon: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 10,
        color: '#F47721',
    },
    noComponentText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        alignItems: 'center',
        textAlign: 'center',
        color: '#636363'
    },
	propertyInfo: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '16px',
		color: '#424347',
	},
	componentScroll: {
		overflowX: 'auto',
        overflowY: 'hidden',
        flexWrap: 'unset',
        paddingBottom: '5px',
	},
	componentItem: {
        minWidth: 250,
    },
	groupDisplay: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '18px',
		lineHeight: '24px',
		color: '#636363',
	},
	groupTableDisplay: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '18px',
		lineHeight: '24px',
		color: '#636363',
		marginTop: 5,
	},
	tableComponet: {
		fontFamily: 'Inter',
		minWidth: 550,
	},
	tableDetailBody: {
		'& .MuiTableRow-root': {
			borderBottom: '1px solid #E1E1E1',
		},
		'& .MuiTableRow-root:last-child': {
			borderBottom: '0',
		},
	},
	tableActionCell: {
		paddingLeft: '15px !important',
		paddingRight: '20px !important',
		minWidth: '60px',
	},
	tableSummaryActionCell: {
		paddingLeft: '15px !important',
		paddingRight: '20px !important',
		minWidth: '60px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	tableActionWrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	    alignItems: 'center',
	},
	tableActionIcon: {
		padding: 4
	},
	tableAttached: {
		marginTop: '-35px',
		marginBottom: '-35px',
	},
	tableRowAttached: {
		borderTop: '2px solid #636363',
	},
	tableDetached: {
		marginTop: '20px',
	},
	tableRowDetached: {
		backgroundColor: '#FDF3E5',
		borderRadius: '2px',
	},
	helpText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '10px',
		lineHeight: '16px',
		letterSpacing: '0.04em',
		color: '#636363',
	},
	pdfFrame: {
		width: '100%',
		height: 'calc(100vh - 120px)',
		border: 0,
	},
	sourceDesc: {
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '20px',
		color: '#636363',
	},
	pageStatus: {
		lineHeight: '40px',
	},
	groupWrapper: {
		flexGrow: 0,
		maxWidth: '100%',
		flexBasis: '100%',
	},
	groupContainer: {
		flexGrow: 0,
		maxWidth: '100%',
		flexBasis: '100%',
		padding: '12px',
	},
	groupCompWrapper: {
		width: 'calc(100% + 24px)',
		margin: '-12px',
		display: 'flex',
		flexWrap: 'wrap',
		boxSizing: 'border-box',
	},
	groupCompContainer: {
	    padding: '12px',
		flexGrow: '0',
		maxWidth: '100%',
		flexBasis: '100%',
		margin: '0',
		boxSizing: 'border-box',
	},
	groupInputCompWrapper: {
		width: 'calc(100% + 8px)',
		margin: '-4px',
		display: 'flex',
		flexWrap: 'wrap',
		boxSizing: 'border-box',
	},
	groupInputCompContainer: {
		padding: '4px',
		margin: 0,
		boxSizing: 'border-box',
		[theme.breakpoints.up('sm')]: {
			flexGrow: '0',
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[theme.breakpoints.up('md')]: {
			flexGrow: '0',
			maxWidth: '33.333333%',
			flexBasis: '33.333333%',
		},
	},
	groupTableCompWrapper: {
		width: 'calc(100% + 8px)',
		margin: '-4px',
		display: 'flex',
		flexWrap: 'wrap',
		boxSizing: 'border-box',
	},
	groupTableCompContainer: {
		padding: '4px',
		margin: 0,
		maxWidth: '100%',
		flexBasis: '100%',
		boxSizing: 'border-box',
	},
	groupTableDetailCompWrapper: {
		width: 'calc(100% + 8px)',
		margin: '-4px',
		display: 'flex',
		boxSizing: 'border-box',
	},
	groupTableDetailCompContainer: {
		padding: '4px',
		margin: 0,
		boxSizing: 'border-box',
		[theme.breakpoints.up('xs')]: {
			flexGrow: '0',
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			flexGrow: '0',
			maxWidth: '66.666667%',
			flexBasis: '66.666667%',
		},
		[theme.breakpoints.up('md')]: {
			flexGrow: '0',
			maxWidth: '33.333333%',
			flexBasis: '33.333333%',
		},
	},
	groupTableSummaryCompWrapper: {
		width: 'calc(100% + 8px)',
		margin: '-4px',
		display: 'flex',
		boxSizing: 'border-box',
	},
	groupTableSummaryCompContainer: {
		padding: '4px',
		margin: 0,
		boxSizing: 'border-box',
		[theme.breakpoints.up('xs')]: {
			flexGrow: '0',
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			flexGrow: '0',
			maxWidth: '66.666667%',
			flexBasis: '66.666667%',
		},
		[theme.breakpoints.up('md')]: {
			flexGrow: '0',
			maxWidth: '33.333333%',
			flexBasis: '33.333333%',
		},
	},
	noteText: {
		fontSize: '12px',
		marginTop: '5px',
		marginLeft: '5px',
		fontStyle: 'italic',
		color: '#636363',
	},
	tabSelection: {
		textTransform: "capitalize",
		background: "#F6F6F6",
		color: "#2B2D33",
		fontSize: 18,
		fontWeight: 600,
		fontFamily: 'Inter',
	},
	subGrouping: {
		borderBottom: '1px solid #C4C4C4',
		flexBasis: '100%',
		fontFamily: 'Inter',
		color: '#F5922F',
		fontSize: 12,
		fontWeight: 'bold',
		height: 25,
		marginBottom: 32,
		paddingBottom: 8,
	},
	dynamicComponent: {
		flexBasis: '30%',
		margin: '0 32px 32px 0',
		'& .MuiInputBase-input': {
			height: 6,
			lineHeight: '0px',
			fontSize: 14,
			fontFamily: 'Inter',
			color: '#636363',
		},
		'& .MuiInputLabel-root': {
			fontFamily: 'Inter',
		},
		'& .MuiListItem-root': {
			fontFamily: 'Inter',
		},
		'& .MuiMenuItem-root': {
			fontFamily: 'Inter',
		},
		'& .MuiFormLabel-root': {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		'& .MuiSelect-selectMenu': {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		'& .MuiPickersDatePickerRoot-toolbar': {
      backgroundColor: '#00FF00'
		}
	},
	selectComponent: {
		'& .MuiSelect-selectMenu': {
			minHeight: 0
		}
	},
	modalActionButton: {
		borderColor: '#F47721',
		color: '#F47721',
		textTransform: 'none',
		fontFamily: 'Inter',
		marginRight: 12,
		'&:hover': {
			backgroundColor: '#FFF3E0',
			opacity: 0.8,
	  },
	},
	modalActionButtonTwo: {
		backgroundColor: '#F47721',
		color: '#FFFFFF',
		textTransform: 'none',
		fontFamily: 'Inter',
		'&:hover': {
			backgroundColor: '#F47721',
			opacity: 0.8,
	  },
	},
	profileListContainer: { 
		border: '1px solid #BDBDBD', 
		borderRadius: 6, 
		margin: '0 0 24px 0', 
		overflow: 'auto', 
		maxHeight: 144, 
		minHeight: 40
	},
	suggestedProfiles: {
		fontSize: 12,
		margin: '24px 0 4px 0'
	},
	modalActionContainer: { 
		display: 'flex', 
		justifyContent: 'flex-end',
		marginTop: 24 
	}
}));

export const StyledTableCell = withStyles({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
})(TableCell);

export const StyledTableRow = withStyles({
	root: {
		height: 72,
		cursor: 'default'
	},
	body: {
		fontFamily: 'Inter'	
	},
})(TableRow);

export const StyledTableDetailCell = withStyles({
	head: {
		backgroundColor: '#F6F6F6',
		color: '#636363',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '10px',
		lineHeight: '16px',
		letterSpacing: '0.04em',
		textTransform: 'uppercase',
		border: 0,
		padding: '10px 5px',
	},
	body: {
		color: '#2B2D33',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight:'normal',
		fontSize: '12px',
		lineHeight: '16px',
		padding: '10px 5px',
		border: 0,
	},
})(TableCell);

export const StyledTableDetailRow = withStyles({
	root: {
		height: 64,
		cursor: 'default'
	},
	body: {
		fontFamily: 'Inter'	
	},
})(TableRow);

export const StyledTableSummaryCell = withStyles({
	body: {
		color: '#2B2D33',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight:'normal',
		fontSize: '12px',
		lineHeight: '16px',
		padding: '10px 5px',
		border: 0,
	},
})(TableCell);

export const StyledTableSummaryRow = withStyles({
	root: {
		height: 64,
		cursor: 'default'
	},
	body: {
		fontFamily: 'Inter'	
	},
})(TableRow);


export const StyledTabs = withStyles({
	root: {
		minHeight: 40,
	  	borderBottom: '1px solid #F1F1F1',
	},
	indicator: {
		display: 'none',
	},
	scrollButtons: {
		width: 22,
		height: 32,
	},
  })(Tabs);


export const StyledTab = withStyles({
	root: {
	  	minWidth: 0,
		minHeight: 32,
		padding: '4px 16px',
		margin: '0 1px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '12px',
		lineHeight: '16px',
		textAlign: 'center',
		textTransform: 'uppercase',
		color: '#909196',
		backgroundColor: '#F6F6F6',
	  	'&:hover': {
			color: '#F47721',
			opacity: 0.8,
	  	},
	  	'&$selected': {
			color: '#F47721',
	  	},
	  	'&:focus': {
			color: '#F47721',
			opacity: 0.8,
	  	},
	},
	selected: {
		color: '#F47721',
		backgroundColor: '#FFFFFF',
	},
  })(Tab);


export const PdfStyles = StyleSheet.create({
	body: {
		paddingTop: 40,
		paddingBottom: 40,
		paddingHorizontal: 30,
	},
	groupWrapper: {
		borderBottomWidth: 1,
		borderBottomColor: '#636363',
		borderBottomStyle: 'solid',
		width: '25%',
	},
	groupTitle: {
		fontSize: 10,
		fontFamily: 'Inter Bold',
		color: '#909196',
		textTransform: 'uppercase',
	},
	groupDisplay: {
		marginTop: 5,
		padding: 5,
	},
	groupPadder: {
		paddingBottom: 30,
	},
	groupDisplayText: {
		fontSize: 16,
		fontFamily: 'Inter SemiBold',
		color: '#636363',
	},
	groupInput: {
		width: '100%',
		flexDirection: 'row',
		marginTop: 5,
	},
	groupInputColumn: {
		width: '33.33%',
		flexDirection: 'column',
		padding: 5,
	},
	groupInputLabel: {
		fontSize: 8,
		fontFamily: 'Inter',
		color: '#636363',
	},
	groupInputValue: {
		fontSize: 10,
		fontFamily: 'Inter',
		color: '#2B2D33',
		paddingLeft: 5,
	},
	section: {
		textAlign: 'right',
		padding: 10,
		flexGrow: 1
	},
	lineBreak: {
		borderBottomWidth: 1,
		borderBottomColor: '#F1F1F1',
		borderBottomStyle: 'solid',
		paddingTop: 5,
		margin: 5,
	},
	groupTable: {
		width: '100%',
		flexDirection: 'column',
		marginTop: 5,
	},
	groupTableDisplay: {
		marginTop: 5,
		padding: 5,
	},
	groupTableDisplayText: {
		fontSize: 16,
		fontFamily: 'Inter SemiBold',
		color: '#636363',
	},
	groupTableDetailWrapper: {
		width: '100%', 
		marginTop: 10,
	},
	groupTableHeaderRow: {
		backgroundColor: '#F6F6F6',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	groupTableDetailHeaderCol: {
		flex: 1,
		justifyContent: 'stretch',
		wordWrap: 'break-word',
		whiteSpace: 'pre-wrap',
		backgroundColor: '#F6F6F6', 
		padding: 8, 
		fontFamily: 'Inter', 
		fontSize: 8, 
		textTransform: 'uppercase', 
		color: '#636363', 
	},
	groupTableDataRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	groupTableDetailDataCol: {
		flex: 1,
		justifyContent: 'stretch',
		wordWrap: 'break-word',
		whiteSpace: 'pre-wrap',
		borderBottom:  1, 
		borderColor: '#E1E1E1', 
		padding: 8, 
		fontFamily: 'Inter', 
		fontSize: 10, 
		color: '#2B2D33', 
		height: 40, 
	},
	groupTableSummaryAttachedWrapper: {
		width: '100%', 
		marginTop: -1, 
		marginBottom: 2,
	},
	groupTableSummaryDetachedWrapper: {
		width: '100%', 
		marginTop: 15.
	},
	groupTableSummaryAttachedCol: {
		flex: 1,
		justifyContent: 'stretch',
		wordWrap: 'break-word',
		whiteSpace: 'pre-wrap',
        border: 0, 
		borderTop:  2, 
		borderColor: '#636363', 
		padding: 8, 
		fontFamily: 'Inter',
		fontSize: 10, 
		color: '#2B2D33', 
		height: 40, 
	},
	groupTableSummaryDetachedCol: {
		flex: 1,
		justifyContent: 'stretch',
		wordWrap: 'break-word',
		whiteSpace: 'pre-wrap',
        border: 0,
		backgroundColor: '#FDF3E5',
		padding: 8, 
		fontFamily: 'Inter',
		fontSize: 10, 
		color: '#2B2D33', 
		height: 54, 
	},
	groupTableSummaryPrimaryAttachedText: {
		fontSize: 10, 
		fontFamily: 'Inter', 
		marginBottom: -1,
	},
	groupTableSummarySecondaryAttachedText: {
		fontSize: 6, 
		fontFamily: 'Inter', 
		marginTop: -1,
	},
	groupTableSummaryPrimaryDetachedText: {
		fontSize: 10, 
		fontFamily: 'Inter', 
		marginBottom: -10,
	},
	groupTableSummarySecondaryDetachedText: {
		fontSize: 6, 
		fontFamily: 'Inter', 
		marginTop: -10,
	},
});

export const ButtonStyles = StyleSheet.create({
	delete: { 
    marginRight: 10, 
    color: '#F47721', 
    borderColor: '#F47721', 
    textTransform: 'none', 
    fontFamily: 'Inter' },
  upload: { 
    color: '#F47721', 
    borderColor: '#F47721', 
    textTransform: 'none', 
    fontFamily: 'Inter' },
  
});

export const ContainerStyles = StyleSheet.create({
  attchDefault: {
    display: 'flex', 
		flexDirection: 'column', 
		justifyContent: 'center', 
		color: '#FFFFFF', 
		position: 'absolute', 
		alignItems: 'center', 
		textAlign: 'center', 
		top: '50%', 
		left: '50%', 
		transform: 'translate(-50%, -50%)', 
		width: 'auto', 
		height: 200, 
		backgroundColor: '#000000', 
		opacity: 1, 
  },
  attchHovered: {
    display: 'flex', 
		flexDirection: 'column', 
		justifyContent: 'center', 
		color: '#FFFFFF', 
		position: 'absolute', 
		alignItems: 'center', 
		textAlign: 'center', 
		top: '50%', 
		left: '50%', 
		transform: 'translate(-50%, -50%)', 
		width: 'auto', 
		height: 200, 
		backgroundColor: '#000000', 
		opacity: 0.4, 
  }
});
