import React, { useState, useEffect } from 'react';
import {
	IconButton,
    TextField,
	FormControl,
    Grid,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignStyle, 
    getPdfAlignStyle,
    useStyles, 
    texts, 
    PdfStyles,
    componentTypes,
} from '../../helper';

const FormDisplayProperties = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [errors, setErrors] = useState({});

    const handleValueChange = (event) => {
		const value = event.target.value;
        setValue(value);
        props.updateValue(value);
		errors['value'] = checkRequired(value, texts.labelComponentValue);
		setErrors(errors);
	}

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    useEffect(() => {
        setValue(props.value);
        setAlign(props.align);
        const errors = {};
        errors['value'] = checkRequired(props.value, texts.labelComponentValue);
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeDisplay}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-value'
                            label={texts.labelComponentValue}
                            variant='outlined'
                            value={value || ''}
                            error={errors['value']}
                            helperText={errors['value']}
                            onChange={handleValueChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormDisplayDesigner = (props) => {
	const classes = useStyles();
    const [value, setValue] = useState('');
    const [align, setAlign] = useState(aligns.LEFT);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateValue = (value) => {
        setValue(value);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormDisplayProperties 
                                            id={props.id} 
                                            value={value} 
                                            align={align} 
                                            updateValue={handleUpdateValue}
                                            updateAlign={handleUpdateAlign}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const doValidate = () => {
        const errors = {};
		errors['value'] = checkRequired(value);
        return (!!errors['value']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, type: componentTypes.FORMDISPLAY, value, align }, doValidate());
        } else {
            setIsInitialize(true);
            setValue(props.value);
            setAlign(props.align);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeDisplay}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div 
                        className={classes.componentDisplay}
                        style={getAlignStyle(align)}>
                        {value || texts.infoConfigDisplay}
                    </div>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleDisplay} 
                message={`${texts.deleteAlertMessageStart}${value || texts.infoNoConfigDisplay}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormDisplayPreview = (props) => {
	const classes = useStyles();

	return (
        <div 
            className={classes.groupDisplay}
            style={getAlignStyle(props.align || aligns.LEFT)}>
            {props.value}
        </div>
	)
}

export const FormDisplayPdf = (props) => {
	return (
        <View style={getPdfAlignStyle(props.align || aligns.LEFT, PdfStyles.groupDisplay)}>
            <Text style={PdfStyles.groupDisplayText}>{props.value}</Text>
        </View>
	)
}
