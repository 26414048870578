import React, { useState, useEffect, useRef } from 'react';
import {
	IconButton,
    TextField,
    InputAdornment,
	FormControl,
    FormControlLabel,
    Grid,
    Select,
    Switch,
    InputLabel,
    MenuItem,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline, mdiDeleteOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { 
    Alert, 
    checkRequired, 
    aligns, 
    getAlignBoldStyle, 
    getAlignBoldWidthStyle,
    useStyles, 
    texts,
    StyledTableDetailCell,
    getDisplayValue,
    modes,
    formatIfNumeric,
    PdfStyles,
    getPdfAlignBoldWidthStyle,
    componentTypes,
    mergeFormulaReferences,
    formatFormulaReferences,
    toNumericFormulaReferences,
    isValidNumericFormula,
    getFormulaValue,
    mapFormula,
    isNumericLiteral,
    isNumericKey,
} from '../../helper';

const FormTableDetailFormulaProperties = (props) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const [formula, setFormula] = useState([]);
    const [align, setAlign] = useState(aligns.LEFT);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [decimal, setDecimal] = useState(false);
    const [numeric, setNumeric] = useState(false);
    const [reference, setReference] = useState('');
    const [formulas, setFormulas] = useState([]);
    const [errors, setErrors] = useState({});
    const refFormulas = useRef([]);

    const handleLabelChange = (event) => {
		const label = event.target.value;
        setLabel(label);
        props.updateLabel(label);
		errors['label'] = checkRequired(label, texts.labelComponentColumnLabel);
		setErrors(errors);
	}

    const handleFormulaChange = (event, value) => {
        value = mapFormula(value, formulas);
        setFormula(value);
        props.updateFormula(value);
        errors['formula'] = validateFormula(value);
		setErrors(errors);
    }

    const handleAlignChange = (event) => {
        const align = event.target.value;
        setAlign(align);
        props.updateAlign(align);
    }

    const handlePrefixChange = (event) => {
        const prefix = event.target.value;
        setPrefix(prefix);
        props.updatePrefix(prefix);
    }

    const handleSuffixChange = (event) => {
        const suffix = event.target.value;
        setSuffix(suffix);
        props.updateSuffix(suffix);
    }

    const handleBoldChange = (event, checked) => { 
        setBold(checked);
        props.updateBold(checked);
    }

    const handleWidthChange = (event) => {
        const width = parseInt(event.target.value || 0);
        if (width >= 0 && width <= 100) {
            setWidth(width);
            props.updateWidth(width);  
        }
    }

    const handleFillerChange = (event) => {
        const filler = event.target.value;
        setFiller(filler);
        props.updateFiller(filler);
    }

    const handleDecimalChange = (event, checked) => { 
        setDecimal(checked);
        props.updateDecimal(checked);
    }

    const handleNumericChange = (event, checked) => { 
        setNumeric(checked);
        props.updateNumeric(checked);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateReference(reference);
    }

    const validateFormula = (formula) => {
        let error = '';
        if (formula && formula.length > 0) {
            let isNumeric = false;
            if (formula && formula.length > 0) {
                const frml = formula[0];
                if (frml.key) {
                    isNumeric = isNumericKey(frml.key);
                } else {
                    isNumeric = isNumericLiteral(frml);
                }
            }
           
            if (isNumeric) {
                if (isValidNumericFormula(formula)) {
                    error = '';
                } else {
                    error = texts.infoFormulaNumericInvalid;                   
                }
                setFormulas(toNumericFormulaReferences(refFormulas.current));
            } else {
                if (formula.length > 1) {
                    error = texts.infoFormulaTextInvalid;                   
                } else {
                    error = '';
                }
                setFormulas(refFormulas.current);
            }
        } else {
            error = checkRequired(formula, texts.labelComponentFormula);
            setFormulas(refFormulas.current);
        }
        return error;
    }

    const initialize = (formula, formulaReferences) => {
        refFormulas.current = formatFormulaReferences(formulaReferences, props.id);
        for (const fls of refFormulas.current) {
            for(let fl of formula) {
                if (fl && fls.key === fl.key) {
                    fl.value = fls.value;
                }
            }
        }
        setFormula(formula);
        const errors = {};
        errors['label'] = checkRequired(props.label, texts.labelComponentColumnLabel);
        errors['formula'] = validateFormula(formula);
		setErrors(errors);
    }
  
    useEffect(() => {
        setLabel(props.label);
        setAlign(props.align);
        setPrefix(props.prefix || '');
        setSuffix(props.suffix || '');
        setBold(props.bold || false);
        setWidth(props.width || '');
        setFiller(props.filler || '');
        setDecimal(props.decimal || false);
        setNumeric(props.numeric || false);
        setReference(props.reference || '');
        initialize(props.formula, mergeFormulaReferences(props.formulaReferences, props.dropdownReferences));
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableDetailFormula}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-label'
                            label={texts.labelComponentColumnLabel}
                            variant='outlined'
                            value={label || ''}
                            error={errors['label']}
                            helperText={errors['label']}
                            onChange={handleLabelChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <Autocomplete
                            multiple
                            freeSolo
                            id='component-formula'
                            options={formulas}
                            getOptionSelected={() => false}
                            getOptionLabel={(option) => (option.value || option)}
                            value={formula || {}}
                            disableClearable={true}
                            noOptionsText={texts.infoNoFormulaOption}
                            onChange={handleFormulaChange}
                            ChipProps={{deleteIcon: <MdIcon path={mdiDeleteOutline} size='12px' />}}
                            disabled={props.isReadOnly}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={texts.labelComponentFormula}
                                    error={errors['formula']}
                                    helperText={errors['formula']}
                                />
                            )}
                            classes={{
                                popper: classes.formControl,
                             }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <InputLabel id='component-align-label'>{texts.labelComponentAlign}</InputLabel>
                        <Select
                            labelId='component-align-label'
                            label={texts.labelComponentAlign}
                            id='component-align'
                            value={align || aligns.LEFT}
                            onChange={handleAlignChange}
                            disabled={props.isReadOnly}>
                            <MenuItem className={classes.menuItem} value={aligns.LEFT}>{aligns.LEFT}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.CENTER}>{aligns.CENTER}</MenuItem>
                            <MenuItem className={classes.menuItem} value={aligns.RIGHT}>{aligns.RIGHT}</MenuItem>
                        </Select>	
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-prefix'
                            label={texts.labelComponentPrefix}
                            variant='outlined'
                            value={prefix || ''}
                            onChange={handlePrefixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-suffix'
                            label={texts.labelComponentSuffix}
                            variant='outlined'
                            value={suffix || ''}
                            onChange={handleSuffixChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={bold} onChange={handleBoldChange} disabled={props.isReadOnly} />}
                        label={bold ? texts.infoFontBold : texts.infoFontNormal}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-width'
                            type='number'
                            label={texts.labelComponentWidth}
                            variant='outlined'
                            value={width || ''}
                            onChange={handleWidthChange}
                            InputProps={{
                                inputProps: { min: 1, max: 100 },
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-filler'
                            label={texts.labelComponentFiller}
                            variant='outlined'
                            value={filler || ''}
                            onChange={handleFillerChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={decimal} onChange={handleDecimalChange} disabled={props.isReadOnly} />}
                        label={decimal ? texts.infoFormatDecimal : texts.infoFormatNoDecimal}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={numeric} onChange={handleNumericChange} disabled={props.isReadOnly} />}
                        label={numeric ? texts.infoNumericFormula : texts.infoTextFormula}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelFormulaReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableDetailFormulaDesigner = (props) => {
	const classes = useStyles();
    const [label, setLabel] = useState('');
    const [formula, setFormula] = useState([]);
    const [align, setAlign] = useState(aligns.LEFT);
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [bold, setBold] = useState(false);
    const [width, setWidth] = useState(0);
    const [filler, setFiller] = useState('');
    const [decimal, setDecimal] = useState(false);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);
    const numeric = useRef(false);
    const reference = useRef('');

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleUpdateLabel = (label) => {
        setLabel(label);
        props.handleTrackChange();
    }

    const handleUpdateFormula = (formula) => {
        setFormula(formula);
        props.handleTrackChange();
    }

    const handleUpdateAlign = (align) => {
        setAlign(align);
        props.handleTrackChange();
    }

    const handleUpdatePrefix = (prefix) => {
        setPrefix(prefix);
        props.handleTrackChange();
    }

    const handleUpdateSuffix = (suffix) => {
        setSuffix(suffix);
        props.handleTrackChange();
    }

    const handleUpdateBold = (bold) => {
        setBold(bold);
        props.handleTrackChange();
    }

    const handleUpdateWidth = (width) => {
        setWidth(width);
        props.handleTrackChange();
    }

    const handleUpdateFiller = (filler) => {
        setFiller(filler);
        props.handleTrackChange();
    }

    const handleUpdateDecimal = (decimal) => {
        setDecimal(decimal);
        props.handleTrackChange();
    }

    const handleUpdateNumeric = (num) => {
        numeric.current = num;
        if (reference.current) {
            props.handleFormulaReference('!' + (num ? '#' : '')  + props.id, 
                                                reference.current,
                                            '!' + (num ? '' : '#')  + props.id);
        }
        props.handleTrackChange();
    }

    const handleUpdateReference = (ref) => {
        reference.current = ref;
        props.handleFormulaReference('!' + (numeric.current ? '#' : '')  + props.id, 
                                            ref,
                                        '!' + (numeric.current ? '' : '#')  + props.id);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        event.stopPropagation();
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableDetailFormulaProperties 
                                            id={props.id} 
                                            label={label} 
                                            formula={formula}
                                            align={align} 
                                            prefix={prefix}
                                            suffix={suffix}
                                            bold={bold}
                                            width={width}
                                            filler={filler}
                                            decimal={decimal}
                                            numeric={numeric.current}
                                            reference={reference.current}
                                            formulaReferences={props.formulaReferences}
                                            dropdownReferences={props.dropdownReferences}
                                            updateLabel={handleUpdateLabel}
                                            updateFormula={handleUpdateFormula}
                                            updateAlign={handleUpdateAlign}
                                            updatePrefix={handleUpdatePrefix}
                                            updateSuffix={handleUpdateSuffix}
                                            updateBold={handleUpdateBold}
                                            updateWidth={handleUpdateWidth}
                                            updateFiller={handleUpdateFiller}
                                            updateDecimal={handleUpdateDecimal}
                                            updateNumeric={handleUpdateNumeric}
                                            updateReference={handleUpdateReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const validateFormula = (formula) => {
        if (formula && formula.length > 0) {
            let isNumeric = false;
            if (formula && formula.length > 0) {
                const frml = formula[0];
                if (frml.key) {
                    isNumeric = isNumericKey(frml.key);
                } else {
                    isNumeric = isNumericLiteral(frml);
                }
            }
           
            if (isNumeric) {
                if (isValidNumericFormula(formula)) {
                    return '';
                } else {
                    return texts.infoFormulaNumericInvalid;                   
                }
            } else {
                if (formula.length > 1) {
                    return texts.infoFormulaTextInvalid;                   
                } else {
                    return '';
                }
            }
        } else {
            return checkRequired(formula, texts.labelComponentFormula);
        }
    }
    
    const doValidate = () => {
        const errors = {};
        errors['label'] = checkRequired(label);
        errors['formula'] = validateFormula(formula);
        return (!!errors['label'] || !!errors['formula']);
	}

    useEffect(() => {
        if (isInitialize) {
            props.updateComponent({ id: props.id, 
                                    type: componentTypes.FORMTABLEDETAILFORMULA, 
                                    label, 
                                    formula,
                                    align,
                                    prefix,
                                    suffix,
                                    bold,
                                    width,
                                    filler,
                                    decimal,
                                    numeric: numeric.current,
                                    reference: reference.current }, doValidate());
        } else {
            setIsInitialize(true);
            setLabel(props.label);
            setFormula(props.formula);
            setAlign(props.align);
            setPrefix(props.prefix);
            setSuffix(props.suffix);
            setBold(props.bold);
            setWidth(props.width);
            setFiller(props.filler);
            setDecimal(props.decimal);
            numeric.current = props.numeric;
            reference.current = props.reference;
            if (props.reference) {
                props.handleFormulaReference('!' + (props.numeric ? '#' : '') + props.id, 
                                                props.reference, 
                                                '!' + (props.numeric ? '' : '#') + props.id);
            }
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected ===props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-detail-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.componentTitle}>{texts.infoTypeTableDetailFormula}</div>
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.componentInput} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={label || ''}
                            variant='outlined'
                            value={label || texts.infoConfigTableDetailFormula}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (prefix ? <InputAdornment position='start'>{prefix}</InputAdornment> : null),
                                endAdornment:  (suffix ? <InputAdornment position='end'>{suffix}</InputAdornment> : null),
                                inputProps: { style: getAlignBoldStyle(align, bold) },
                            }} /> 
                    </FormControl>
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableDetailFormula} 
                message={`${texts.deleteAlertMessageStart}${label || texts.infoNoConfigTableDetailFormula}${texts.deleteAlertMessagePropEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

const FormTableDetailFormulaHeader = (props) => {
    useEffect(() => {
        if (props.reference) {
            props.handleFormulaReference('!' + (props.numeric ? '#' : '') + props.id,  props.reference);
        }
    }, []);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {props.label || ''}
        </StyledTableDetailCell>
    )
}

const FormTableDetailFormulaView = (props) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        const newValue = getFormulaValue(props.formula, props.inputData, props.sources, props.rowId);
        if (newValue !== value) {
            setValue(newValue);
            const withTick = (props.formulaTick && props.formulaTick.startsWith(props.id));
            props.handleInputData((props.id + '-' + props.rowId), newValue, true, withTick, props.fromAnalysis);
        }
    }, [props.formulaTick]);

    return (
        <StyledTableDetailCell className={props.className} style={getAlignBoldWidthStyle(props.align, props.bold, props.width)}>
            {getDisplayValue(formatIfNumeric(value, props.decimal), props.filler, props.prefix, props.suffix)}
        </StyledTableDetailCell>
    )
}

export const FormTableDetailFormulaPreview = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailFormulaHeader {...props} />;
    } else if (props.mode === modes.VIEW ||
               props.mode === modes.EDIT) {
        return <FormTableDetailFormulaView {...props} />;
    } else {
        return <span />
    }
}

const FormTableDetailFormulaPdfHeader = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailHeaderCol)} wrap={true}>
            <Text>{props.label || ''}</Text>
        </View>
    )
}

const FormTableDetailFormulaPdfView = (props) => {
    return (
        <View style={getPdfAlignBoldWidthStyle(props.align, props.bold, props.width, PdfStyles.groupTableDetailDataCol)} wrap={true}>
            <Text>{getDisplayValue(formatIfNumeric((props.inputData[props.id + '-' + props.rowId] || ''), props.decimal), props.filler, props.prefix, props.suffix)}</Text>
        </View>
    )
}

export const FormTableDetailFormulaPdf = (props) => {
    if (props.mode === modes.HEADER) {
        return <FormTableDetailFormulaPdfHeader {...props} />;
    } else if (props.mode === modes.VIEW) {
        return <FormTableDetailFormulaPdfView {...props} />;
    } else {
        return <span />
    }
}