import React, { useState } from 'react';
import {
	Button,
	LinearProgress,
	Paper,
	Grid,
	TextField,
	FormControl,
} from '@material-ui/core/'
import { 
	checkRequired, 
	toggleDisplay, 
	useStyles, 
	texts, 
	clone,
	Api, 
} from '../../helper';

const DropdownDataForm = (props) => {
	const classes = useStyles();
	const [formData, setFormData] = useState(clone(props.data));
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleFieldChange = (event, key) => {
		const value = (event.target.value || '');
		errors[key] = checkRequired(value, key);
		setErrors(errors);
		const data = (formData || {});
		data[key] = value;
		setFormData({...data});
	}

	const handleCancel = () => {
		props.onFormCancel();
	}

	const handleForm = async () => {
		const errors = {};
		let withError = false;
		for (const [key, value] of Object.entries(formData)) {
			errors[key] = checkRequired(value, key);
			if (errors[key]) {
				withError = true;
			}
		}
		setErrors(errors);
		if (!withError) {
			setIsLoading(true);
			let payload = (props?.source?.payload || []);
			if (props?.index >= 0) {
				payload = payload.map((data, index) => {
					if (index === props?.index) {
						return formData;
					} else {
						return data;
					}
				});
			} else {
				payload.push(formData);
			}
			const response = await Api.saveSource((props?.source?.id || 0), 
													(props?.source?.name || ''), 
													(props?.source?.description || ''), 
													(props?.source?.columns || []).join(', '),
													JSON.stringify(payload));
			setIsLoading(false);
			if (response) {
				const result = (response.result || 0);
				if (result > 0) {
					props.onFormSuccess(result);
				} else {
					setErrors({ 'Value':  texts.infoSourceDataSaveError });
				}
			}
		}
	}

	return (
		<div className={classes.pageWrapperModal}>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Paper className={classes.paperWrapperModal}>
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.pageHeaderModal}>
						<span className={classes.pageTitleModal}>{props.index < 0 ? texts.headerNewData : texts.headerEditData}</span>
					</Grid>
					{(Object.keys(formData).map((key, index) => 
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<TextField 
								id={`field-${index}`}
								label={key}
								type={key.startsWith('#') ? 'number' : 'text'}
								variant='outlined'
								value={key.startsWith('#') ? (formData[key] || 0) : (formData[key] || '')}
								error={errors[key]}
								helperText={errors[key]}
								onChange={(event) => handleFieldChange(event, key)}/>
						</FormControl>
					</Grid>						
					))}
					<Grid item xs={12} className={classes.controlEndNoWrap}>
						<Button variant='outlined' className={classes.secondaryButtonNoWrap} onClick={handleCancel}>{texts.buttonCancel}</Button>
						<Button variant='contained' className={classes.primaryButtonNoWrap} onClick={handleForm}>{props.index < 0 ? texts.buttonNewForm : texts.buttonSaveSource}</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

export default DropdownDataForm;
