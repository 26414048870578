import React, { useState } from 'react';
import { Icon as MdIcon } from '@mdi/react';
import {
  CircularProgress,
  Modal,
} from '@material-ui/core';

import CryptoJS from 'crypto-js';
  
import LoanService from '../../services/LoanService';
import { mdiImage, mdiImageBroken } from '@mdi/js';
const { REACT_APP_S3_BUCKET, REACT_APP_CRYPTO_SECRET_KEY } = process.env;

const Attachment = (props) => {
  const [initialized, setInitialized] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [pdfSrc, setPdfSrc] = useState('');
  const [modalInitialized, setModalInitialized] = useState(false);
  const [openPreview, setOpenPreview] = useState('');

  if(!initialized){
    getAttachmentDetails(props);
  }

  function renderModal(){
    setModalInitialized(false);
    const properties = {
      referenceId: props.referenceId,
      fileName: props.fileName,
      isThumbnail: false,
      isDraft: props.isDraft,
      value: props.value,
      source: 'modal'
    };
    getAttachmentDetails(properties);
    setOpenPreview(true);
  }

  async function getAttachmentDetails(properties){
    if(properties.value){
      let file = await LoanService.getApplicationAttachments(properties.referenceId, 
          properties.fileName, properties.isThumbnail, properties.isDraft, 0);
      // setImageSrc(response && response.result && response.result.value? response.result.value : '');
      if(properties.source && properties.source == 'modal'){
        setPdfSrc(file && file.result && file.result.value? file.result.value : '');
        setModalInitialized(true);
      }else{
        setImageSrc(file && file.result && file.result.value? file.result.value : '');
      }
    }
    setInitialized(true);
  }

  return (
    <div>
      <Modal
        open={openPreview}
        style={{alignItems:'center',justifyContent:'center'}}
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        {modalInitialized? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
        <object
          style={{
            height:'500px',
            width:"500px"
          }}
          type="application/pdf" 
          data={pdfSrc}
          onLoadStart={()=>{
            setModalInitialized(false);
          }}
          onLoadedData={()=>{
            setModalInitialized(true);
          }}
          alt={props.fileName}
        />
        </div>) : (
        <CircularProgress style={{
          display: 'block',
        }} color="primary"/>)}
      </Modal>
      <CircularProgress style={{
        display: !initialized ? 'block' : 'none',
      }} color="primary"/>
      <div style={{
        display: initialized && !isFetchingFailed && imageSrc ? 'block' : 'none',
        cursor:'pointer'
      }}
        onClick={(event) => {
          event.preventDefault();
          renderModal();
        }}>
        <img
          src={imageSrc}
          style={{
            width:50,
            height:50
          }}
          onError = {() => {
            if(imageSrc){
              setIsFetchingFailed(true);
            }
          }}
        />
      </div>
      <div style={{
        display: initialized && !isFetchingFailed && !imageSrc ? 'flex' : 'none',
        backgroundColor: '#E0E0E0',
        width:50,
        height:50,
        alignItems:'center',justifyContent:'center'
      }} >
        <div>
          <MdIcon
            path={mdiImage} size="1.5em"
            style={{
              display:"block",
              margin:"auto",
            }}
          />
        </div>
        
      </div>
      <div style={{
        display: isFetchingFailed ? 'flex' : 'none',
        backgroundColor: '#E0E0E0',
        width:50,
        height:50,
        alignItems:'center',justifyContent:'center'
      }}>
        <div>
          <MdIcon
            path={mdiImageBroken} size="1.5em"
            style={{
              display:"block",
              margin:"auto",
            }}
          />
        </div>
      </div>
    </div>
  );
  
};

export default Attachment;