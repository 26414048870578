import React, { useState } from 'react';
import {
	Button,
	LinearProgress,
	Paper,
	Grid,
	TextField,
	FormControl,
	Chip,
} from '@material-ui/core/'
import { Icon as MdIcon } from '@mdi/react'
import { mdiDeleteOutline } from '@mdi/js';
import { 
	checkRequired, 
	toggleDisplay, 
	useStyles, 
	texts,
	Api,
} from '../../helper';

const DropdownForm = (props) => {
	const classes = useStyles();
	const [formName, setFormName] = useState((props.detail?.name || ''));
	const [formDescription, setFormDescription] = useState((props.detail?.description || ''));
	const [formColumns, setFormColumns] = useState((props.detail?.columns || 'Key, Value').split(', '));
	const [formColumn, setFormColumn] = useState('');
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleFormNameChange = (event) => {
		const formName = event.target.value;
		errors['formName'] = checkRequired(formName, texts.labelName);
		setErrors(errors);
		setFormName(formName);
	}

	const handleFormDescriptionChange = (event) => {
		const formDescription = event.target.value;
		errors['formDescription'] = checkRequired(formDescription, texts.labelDesc);
		setErrors(errors);
		setFormDescription(formDescription);
	}

	const handleFormColumnChange = (event) => {
		setFormColumn(event.target.value);
	}

	const addToColumns = () => {
		const column = (formColumn || '').trim();
		if (column) {
			const columns = (formColumns || []);
			if (columns.findIndex(item => item.toLowerCase() === column.toLowerCase()) < 0) {
				columns.push(column);
			}
			setFormColumn('');
			setFormColumns(columns);
		}
    }

	const handleFormColumnBlur = () => {
        addToColumns();
    };

	const handleFormColumnPress = (event) => {
        if (event.keyCode === 13) {
            addToColumns();
        }
    };

	const handleColumnDelete = (column) => {
		setFormColumns((formColumns) => formColumns.filter((formColumn) => formColumn !== column));
	}


	const handleCancel = () => {
		setFormName('');
		setFormDescription('');
		setFormColumns(['Key', 'Value']);
		props.onFormCancel();
	}

	const handleForm = async () => {
		const errors = {};
		errors['formName'] = checkRequired(formName, texts.labelName);
		errors['formDescription'] = checkRequired(formDescription, texts.labelDesc);
		setErrors(errors);
		if (!errors['formName'] &&
			!errors['formDescription']) {
			setIsLoading(true);
			const response = await Api.saveSource((props.id || 0), 
													formName, 
													formDescription, 
													formColumns.join(', '),
													(props.detail?.payload || '[]'));
			setIsLoading(false);
			if (response) {
				const result = (response.result || 0);
				if (result > 0) {
					props.onFormSuccess(result);
				} else {
					setErrors({ 'formName':  (response.message || texts.infoSourceSaveError) });
				}
			}
		}
	}

	return (
		<div className={classes.pageWrapperModal}>
			<LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
			<Paper className={classes.paperWrapperModal}>
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.pageHeaderModal}>
						<span className={classes.pageTitleModal}>{props.id ? texts.headerEditSource : texts.headerNewSource}</span>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<TextField 
								id='form-name'
								label={texts.labelName}
								variant='outlined'
								defaultValue={formName || ''}
								error={errors['formName']}
								helperText={errors['formName']}
								InputProps={{ inputProps: { maxLength: 30 } }}
								onChange={handleFormNameChange}/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<TextField 
								id='form-desc'
								label={texts.labelDesc}
								variant='outlined'
								defaultValue={formDescription || ''}
								error={errors['formDescription']}
								helperText={errors['formDescription']}
								InputProps={{ inputProps: { maxLength: 200 } }}
								onChange={handleFormDescriptionChange}/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
							<TextField 
								id='form-column'
								label={texts.labelColumns}
								variant='outlined'
								value={formColumn || ''}
								error={errors['formColumn']}
								helperText={errors['formColumn']}
								InputProps={{ inputProps: { maxLength: 200 } }}
								onChange={handleFormColumnChange}
								onBlur={handleFormColumnBlur}
								onKeyDown={handleFormColumnPress} />
						</FormControl>
						{(formColumns && (formColumns.length > 0) && 
						<Paper variant="outlined" className={classes.paperChipWrapper}>
							{formColumns.map((column) => 
								<Chip
									label={column}
									onDelete={(column === 'Key' || column === 'Value') ? undefined : (() => handleColumnDelete(column))}
									deleteIcon={<MdIcon path={mdiDeleteOutline} size='12px' />} />
							)}
						</Paper>)}
						<div className={classes.noteText}>{texts.infoDropdownColumnNote}</div>
					</Grid>
					<Grid item xs={12} className={classes.controlEndNoWrap}>
						<Button variant='outlined' className={classes.secondaryButtonNoWrap} onClick={handleCancel}>{texts.buttonCancel}</Button>
						<Button variant='contained' className={classes.primaryButtonNoWrap} onClick={handleForm}>{props.id ? texts.buttonSaveSource : texts.buttonNewSource}</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

export default DropdownForm;
