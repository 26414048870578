import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
  LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	TextField,
	InputAdornment,
	Radio,
	RadioGroup,
	Select,
	Snackbar,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@material-ui/core'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
//import { withStyles } from '@material-ui/styles'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiFileTableOutline, mdiMagnify } from '@mdi/js';
import LoanService from '../../services/LoanService';
import Pagination from '@material-ui/lab/Pagination';
import { DatePicker } from "material-ui";
import NumberFormat from 'react-number-format';
import { calendar_gray_logo, icon_download } from "../../assets/images";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import { filter, map, partialRight, pick, pull, remove, sortBy, union, uniqBy } from "lodash";
import { useHistory } from 'react-router-dom'
import download from "downloadjs";
import { convertHexToRGB } from 'material-ui/utils/colorManipulator';

const drawerWidth = 240;

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DropDownHeaderCell = withStyles({
  root: {
    fontSize: 10,
    color: "#909196",
    borderBottom: "none"
  }
})(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		tooltip: {
			width: 190
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		},
		"& .MuiButton-label": {
			width: 240
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	tooltip: {
		width: 190,
		maxWidth: 190
	}
}));

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter'
	},
}))(TableCell);

function createData(referenceId, approvedDate, applicantName, loanProduct, requestedAmount) {
	return { referenceId, approvedDate, applicantName, loanProduct, requestedAmount };
}

let rows = []

const StyledTableRow = withStyles((theme) => ({
	root: {
		height: 72,
		'&:hover': {
			backgroundColor: '#FDF3E5'
		},
		cursor: 'pointer'
	},
	body: {
		fontFamily: 'Inter'
	},
}))(TableRow);

const ExportApplications = (props) => {

	let history = useHistory();
	const classes = useStyles();
	const [screenIndex, setScreenIndex] = useState(0)
	const [searchKey, setSearchKey] = useState("")
	const [searchLocationKey, setSearchLocationKey] = useState("All")
	const [hoveredRow, setHoveredRow] = useState(null)
	const [exportDialogOpen, setExportDialogOpen] = useState(false)
	const [isFetchingFailed, setIsFetchingFailed] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const [updateData, setUpdateData] = useState(false)
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [updatingData, setUpdatingData] = useState(false);
	const [exportingData, setExportingData] = useState(false)
	const [page, setPage] = useState(1)
	const [maxPage, setMaxPage] = useState(0)
	const [count, setCount] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [showItems, setShowItems] = useState(10)
	const [duplicateDates, setDuplicateDates] = useState([])
	const [exportSettings, setExportSettings] = useState([])
	const [uniqueProductIds, setUniqueProductIds] = useState([])
	const [exportType, setExportType] = useState("tab")
	const [csvData, setCsvData] = useState("")
	const [preflightExport, setPreflightExport] = useState([])
	const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [exportFailed, setExportFailed] = useState(false);
	const [exportSettingCount, setExportSettingCount] = useState(0);

	async function getData() {
		setIsFetchingFailed(false)
		setInitialized(true)
		setUpdatingData(true)
		setPreflightExport([]);
		setUniqueProductIds([]);
		setExportSettings([]);

		let response = await LoanService.getApplicationHighLevelSummary(moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD"), showItems, page);
		let uniqueIds = []

		setUpdatingData(false)

		if (response && response.result && response.result.duplicateDates) {
			setDuplicateDates(union(response.result.duplicateDates))
			uniqueIds = uniqBy(response.result.applications, 'productId')
			uniqueIds = map(uniqueIds, partialRight(pick, 'productId'));
			uniqueIds = map(uniqueIds, 'productId');
			setUniqueProductIds(uniqueIds)
		}

    let exportList = response && response.result && response.result.applications;
		// console.log(map(exportList, { 'productId': '8eb0fb9e-38a1-4f7a-a0ca-f69ddf698688' })) // Number of LA per Product
    rows = []
		exportList && exportList.map((data, index) => {
			rows.push(createData(data.referenceId, data.approvedDate, data.applicantName, data.loanProduct, data.requestedAmount))
		});
		
    setCount(response && response.result ? response.result.count : 0)
		setMaxPage(response && response.result ? response.result.maxPage : 0)
		setTotalCount(response && response.result ? response.result.applications && response.result.applications.length : 0)
		setHoveredRow(-1)
		setHoveredRow(null)
		if (exportList && exportList.length > 0) {
			getExportSettings(uniqueIds);
		}
	}

	async function getExportSettings(uniqueIds) {
		setIsFetchingFailed(false)
		setUpdatingData(true)
		let response = await LoanService.getExportSettings('EXPORT', uniqueIds ? uniqueIds : uniqueProductIds)
		setExportSettings(response && sortBy(response.result, ['name']))
		setUpdatingData(false)
	}

	async function exportApplications() {
		let iteration = 0;
		let hasSettings = false;
		let exportFailed = false;
		let hasExported = false;
		setIsFetchingFailed(false)
		setExportingData(true)
		setExportFailed(false);

		for (let productId of exportSettings) {
			for (let exportId of productId.settings) {
					if (preflightExport.includes(exportId.id)) {
						hasSettings = true;
						let data = await LoanService.exportApplications(moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD"), exportType, productId.id, exportId.id)
						if (data) {
							let filename = productId.name + '_' + moment(fromDate).format("YYYY-MM-DD") + '_' + moment(toDate).format("YYYY-MM-DD");
							filename += exportType == 'csv' ? '.csv' : '.txt';
							const mimeType = exportType == 'csv' ? 'text/csv' : 'text/tab-separated-values';
							download(data, filename, mimeType);
							hasExported = true;
						}
					}
			}
			// if (!hasSettings) {
			// 	let data = await LoanService.exportApplications(moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD"), exportType, productId.id)
			// 	if (data) {
			// 		const filename = productId.name + '_' + moment(fromDate).format("YYYY-MM-DD") + '_' + moment(toDate).format("YYYY-MM-DD") + '.csv';
			// 		const mimeType = exportType == 'csv' ? 'text/csv' : 'text/tab-separated-values';
			// 		download(data, filename, mimeType);
			// 	} else {
			// 		setExportFailed(true);
			// 		exportFailed = true;
			// 	}
			// }
			hasSettings = false;
			iteration += 1;
		}
		
		// clear data
		setPreflightExport([]);
		setUniqueProductIds([]);
		setExportDialogOpen(false);

		setTimeout(() => {
			setExportingData(false)

			if (exportFailed && !hasExported) {
				setSnackBarMessage(
					<div>Exporting loan applications has failed.</div>
				)
			} else if (exportFailed && hasExported) {
				setSnackBarMessage(
					<div>Some loan applications has not been exported.</div>
				)
			} else {
				setSnackBarMessage(
					<div><b>{count} loan application(s)</b> have been successfully exported.</div>
				)
			}
			
			setSnackBarOpen(true)
		}, 200);
		
	}

	const handleClose = () => {
    setDateAnchorEl(null);
  };

	const handleCheckbox = (event) => {
		if (!event.target.checked) {
			let modifyPreflight = preflightExport;
			pull(modifyPreflight, event.target.value);
			setPreflightExport(modifyPreflight);
			setExportSettingCount(exportSettingCount - 1);
		} else {
			setPreflightExport([...preflightExport, event.target.value]);
			setExportSettingCount(exportSettingCount + 1);
		}
	}

  if (!initialized) {
		 getData()
	}

  const open = Boolean(dateAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeDate = () => {
    setPage(1);
    getData();
    setDateAnchorEl(null);
  };

  const handleClearDate = () => {
    setFromDate(new Date());
    setToDate(new Date());
  };

	return (
		<div style={{ fontFamily: 'Inter' }}>
			<div style={{ margin: 20 }}>
				<div style={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', display: "flex", justifyContent: 'space-between' }}>Export Applications
					<div style={{ display: "flex", alignItems: "center" }}>
						<div style={{ display: "flex" }}>
							<Button
								style={{
									height: 48,
									fontSize: 16,
									fontWeight: 600,
									width: 142
								}}
								disabled={!count}
								variant="contained"
								color="primary"
								aria-haspopup="true"
								startIcon={<img src={icon_download} style={{ marginRight: 8 }} />}
								onClick={() => {
									setExportDialogOpen(true)}
								}>
								Download
							</Button>
						</div>
					</div>
				</div><br />
        <LinearProgress className={classes.root} style={{ visibility: updatingData ? 'visible' : 'hidden', backgroundColor: '#E5E5E5' }} />
				<Paper>
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px 20px 24px' }}>
            <div style={{ display: 'flex' }}>
                  <Button
                    disabled={updatingData}
                    aria-describedby={id}
                    variant="outlined"
                    style={{
                      background: "whitesmoke",
                      height: 40,
											minWidth: 260,
                      fontSize: 14,
                      color: "#636363",
											border: duplicateDates.length > 0 && "1px solid #E24C4C",
                    }}
                    onClick={event => setDateAnchorEl(event.currentTarget)}
                    endIcon={<img src={calendar_gray_logo} />}
                  >
                    {fromDate ? `${moment(fromDate).format("MM / DD / YYYY")} - ${
                          toDate ? moment(toDate).format("MM / DD / YYYY") : ""
                        }` : <div style={{ marginRight: 151, width: 100 }}>All time</div>}
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={dateAnchorEl}
                    onClose={() => setDateAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <div
                      style={{
                        width: 200,
                        height: 180,
                        padding: "12px 16px"
                      }}
                    >
                      <DatePicker
                        hintText="Click to select"
                        floatingLabelText="From"
                        floatingLabelFixed={true}
                        fullWidth={true}
                        value={fromDate}
                        autoOk={true}
                        onChange={(event, value) => {
                          setFromDate(value);
                        }}
                        dialogContainerStyle={{ zIndex: 10000 }}
                        maxDate={new Date()}
                      />
                      <DatePicker
                        hintText="Click to select"
                        floatingLabelText="To"
                        disabled={!fromDate}
                        floatingLabelFixed={true}
                        fullWidth={true}
                        value={toDate}
                        autoOk={true}
                        onChange={(event, value) => setToDate(value)}
                        dialogContainerStyle={{ zIndex: 10000 }}
                        minDate={fromDate}
                        maxDate={new Date()}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Button
                          onClick={handleClearDate}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: 100,
                            height: 30,
                            fontSize: 16,
                            marginTop: 3,
                            marginRight: 5
                          }}
                        >
                          CLEAR
                        </Button>
                        <Button
                          onClick={handleChangeDate}
                          variant="contained"
                          color="primary"
                          //disabled={!fromDate}
                          style={{
                            width: 100,
                            height: 30,
                            fontSize: 16,
                            marginTop: 3
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    </div>
                  </Popover>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div style={{ display: '-webkit-box', fontSize: 12, color: '#E24C4C', lineHeight: '16px', marginLeft: 24, 
										'-webkit-line-clamp': '2', '-webkit-box-orient': 'vertical', overflow: 'hidden', visibility: duplicateDates.length > 0 ? 'visible' : 'hidden',
										minWidth: 540 }}>
											The selected date range include applications that have been previously exported (
											{
												duplicateDates.map((date, index) => {
													if (duplicateDates.length == index + 1) {
														return <span><b>{date.toString()}</b>)</span>;
													} else {
														return <span><b>{date.toString()}</b>, </span>;
													}
												})
											}
										</div>
									</div>
                </div>
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', minWidth: 200 }}>
								<div style={{ marginRight: 16, color: '#909196', fontSize: 14, fontFamily: 'Inter' }}>Show items</div>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										className={classes.root}
										labelId="demo-simple-select-filled-label"
										defaultValue={10}
										style={{ width: 72 }}
                    onChange={(event) => { setShowItems(event.target.value); setPage(1); setInitialized(false); }}
                    disabled={updatingData}
									>
										<MenuItem value={10}>10</MenuItem>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={30}>30</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div>
							<TableContainer>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Reference Number</StyledTableCell>
											<StyledTableCell>Approval Date</StyledTableCell>
											<StyledTableCell>Applicant Name</StyledTableCell>
											<StyledTableCell align="center">Loan Product</StyledTableCell>
											<StyledTableCell>Requested Amount</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows && rows.map((row, index) => (
											<StyledTableRow style={{ backgroundColor: hoveredRow == index ? '#FDF3E5' : '#FFFFFF' }} onMouseOver={() => setHoveredRow(index)} onMouseOut={() => setHoveredRow(null)}>
												<StyledTableCell><b>{row.referenceId}</b></StyledTableCell>
												<StyledTableCell>{row.approvedDate ? moment(row.approvedDate[row.approvedDate.length - 1] === 'Z' ? row.approvedDate : row.approvedDate + 'Z').tz('Asia/Singapore').format("DD MMMM YYYY, hh:mm:ss A") : '-'}</StyledTableCell>
												<StyledTableCell>{row.applicantName}</StyledTableCell>
												<StyledTableCell align="center">{row.loanProduct}</StyledTableCell>
												<StyledTableCell><b><NumberFormat value={row.requestedAmount} prefix={"PHP "} decimalScale={2} fixedDecimalScale thousandSeparator displayType={'text'} /></b></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', padding: '24px 15px 24px 15px', backgroundColor: '#F6F6F6' }}>
            <div style={{ marginRight: 16, fontSize: 14, width: 170 }}>Showing {count === 0 ? '-' : `${page != maxPage ? totalCount * page - (showItems - 1) + "-" + totalCount * page : (((page - 1) * showItems) + 1) != count ? ((page - 1) * showItems) + 1 + "-" + count : ((page - 1) * showItems) + 1} `} of {count === 0 ? '-' : count}</div>
								<Pagination className={classes.root} page={page} count={maxPage} disabled={updatingData} shape="rounded" onChange={(event, value) => { setPage(value); setInitialized(false) }} />
						</div>
					</div>
				</Paper>
			</div>
			<Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
							<Alert onClose={() => setSnackBarOpen(false)} severity={exportFailed ? "error" : "success"}>
								{snackBarMessage}
							</Alert>
						</Snackbar>
						<Dialog
              open={exportDialogOpen}
							disableBackdropClick={exportingData}
              onClose={() => {
								setPreflightExport([]);
                setExportDialogOpen(false);
              }}
            >
              <DialogTitle>Export applications</DialogTitle>
              <DialogContent style={{ width: 522 }}>
                <DialogContentText>
									{!exportingData ? <div>
                  You are about to export <b>0 of {count} applications.</b>&nbsp; 
									Please select which export settings to execute.
									<div style={{ marginTop: 16, padding: '12px 0 0 16px', backgroundColor: 'whitesmoke' }}>
										<div>
											{exportSettings && exportSettings.map((exportSetting) => {
												return (
													<div>
														<div style={{ fontSize: 14, fontWeight: 500, lineHeight: '20px', marginTop: 4, color: '#636363'}}>
															{exportSetting.name}
														</div>

														<div style={{ display: 'flex', flexDirection: 'column' }}>
														{exportSetting.settings.map((setting) => {
															return (
																<FormControlLabel
																	control={
																		<Checkbox
																			color="primary"
																			value={setting.id}
																			name={setting.name}
																			onClick={handleCheckbox}
																		/>
																	}
																	label={setting.name}
																	style={{ margin: '4px 0 4px 8px', color: '#2B2D33'}}
																/>)
															})}
														</div>
													</div>
												)
											})}
										</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
											<div>Export as:</div>
											<div style={{ display: 'flex' }}>
											<RadioGroup row aria-label="Export" name="export" value={exportType} onChange={(event, value) => setExportType(value)}>
												<FormControlLabel
													control={
														<Radio
															color="primary"
															name="Tab Delimited"
															value="tab"
														/>
													}
													label="Tab Delimited"
													style={{ margin: '4px 0 4px 8px', color: '#2B2D33'}}
												/>
												<FormControlLabel
													control={
														<Radio
															color="primary"
															name="pipe"
															value="pipe"
														/>
													}
													label="Pipe Delimited"
													style={{ margin: '4px 0 4px 8px', color: '#2B2D33'}}
												/>
												<FormControlLabel
													control={
														<Radio
															color="primary"
															name="csv"
															value="csv"
														/>
													}
													label="CSV"
													style={{ margin: '4px 0 4px 8px', color: '#2B2D33'}}
												/>
												</RadioGroup>
											</div>
										</div>
										</div> : <div>
											<div style={{ marginBottom: 8 }}>Exporting...</div>
											<LinearProgress className={classes.root} />
											</div>}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ margin: "0 21px 21px 0" }}>
							<Button
                  onClick={() => {							
                    setExportDialogOpen(false);
                  }}
                  color="primary"
                  variant="outlined"
									disabled={exportingData}
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    exportApplications();
                  }}
                  color="primary"
                  variant="contained"
									disabled={updatingData || exportingData || preflightExport.length == 0}
                  autoFocus
                >
                  Export
                </Button>
								
              </DialogActions>
            </Dialog>
						
		</div>
	)
}

export default ExportApplications;