import { getMuiTheme, colors } from 'material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const v0 = {
  fontFamily: "'Work Sans', sans-serif",
  zIndex: {
    layer: 3000,
  },
  palette: {
    primary1Color: '#F37021',
    primary2Color: '#FF8438',
    accent1Color: '#f99d1c',
    pickerHeaderColor: '#F37021',
    white: '#FFFFFF'
  },
  raisedButton: {
    color: colors.white,
    textColor: colors.grey700,
    disabledColor: colors.grey100
  },
  appBar: {
    color: '#fff',
    textColor: '#777'
  },
  textField: {
    floatingLabelColor: '#78909C',
    borderColor: '#9daeb6'
  }
};

const ubx = {
  palette: {
    primary: {
      main: '#F47721',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#F47721'
    },
    charcoal: {
      main: '#FFFFFF',
      contrastText: '#2B2D33'
    }
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
  },
  typography: {
    fontFamily: "'Inter'"
  },
  backgroundColor: '#FFFFFF',
  preparedStyles: {
    
  }
};

const v0Theme = getMuiTheme(v0);
const UBXTheme = createMuiTheme(ubx);
export { v0Theme, UBXTheme }
