import React, { useState, useRef, Fragment, useEffect } from 'react';
import {
    Card,
    CardHeader,
    Collapse,
	Button,
	IconButton,
    TextField,
	FormControl,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Badge,
} from '@material-ui/core/'
import { Icon as MdIcon } from '@mdi/react'
import { mdiTrashCanOutline, mdiArrowUpDown, mdiChevronUp, mdiChevronDown, mdiFileDocumentEditOutline } from '@mdi/js';
import { View, Text } from '@react-pdf/renderer';
import { ReactSortable } from "react-sortablejs";
import { Alert, checkRequired, newId, useStyles, texts, PdfStyles, componentTypes, aligns } from '../../helper';
import { 
    FormDisplayDesigner, 
    FormDisplayPreview, 
    FormDisplayPdf,
    FormLineBreakDesigner, 
    FormLineBreakPreview, 
    FormLineBreakPdf,
    FormInputDesigner, 
    FormInputPreview, 
    FormInputPdf,
    FormTableDesigner, 
	FormTablePreview,
    FormTablePdf, 
} from '.';

export const FormGroupDesigner = (props) => {
	const classes = useStyles();
    const [title, setTitle] = useState('');
    const [errors, setErrors] = useState({});
    const [isExpanded, setIsExpanded] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const [isInitialize, setIsInitialize] = useState(false);
    const [children, setChildren] = useState(null);
	const [tick, setTick] = useState(0);
	const components = useRef([]);
	const tracker = useRef({});
	const withError = useRef(false);

    const handleTitleChange = (event) => {
		const title = event.target.value;
        setTitle(title);
        const errors = {};
		errors['title'] = checkRequired(title, texts.labelTitleGroup);
		setErrors(errors);
        props.handleTrackChange();
	}

    const handleDeleteGroup = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleInsertDisplay = () => {
        const child = { id: newId(), type: componentTypes.FORMDISPLAY, value: '', align: aligns.LEFT };
		handleChildInsert(child);
    }

    const handleInsertInput = () => {
        const child = { id: newId(), type: componentTypes.FORMINPUT };
        handleChildInsert(child);
    }

    const handleInsertTable = () => {
        const child = { id: newId(), type: componentTypes.FORMTABLE };
        handleChildInsert(child);
    }

    const handleInsertLineBreak = () => {
        const child = { id: newId(), type: componentTypes.FORMLINEBREAK };
		handleChildInsert(child);
    }

    const handleComponentSelect = (event) => { 
        if (event) {
			event.stopPropagation();
		}
        if (props.selected !== props.id) {
            props.handleSelect(props.id, null);
        }
	}

    const handleChildInsert = (child) => {
        const data = [...(children || [])];
		data.push(child);
		setChildren(data);
		components.current.push(child);
        handleToggleMenu();
        props.handleTrackChange();
    }

    const handleComponentDelete = (id) => {
        handleComponentSelect();
		components.current = components.current.filter((component) => (component.id !== id));
		const data = [...(children || [])].filter((child) => (child.id !== id));
		setChildren(data);
		delete tracker.current[id];
        props.handleTrackChange();
	}

    const sendTick = () => {
        if (children && children.length > 0) {
            for (const key of Object.keys(tracker.current)) {
                tracker.current[key] = false;
            }
            withError.current = false;
            const nextTick = tick + 1;
            setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

    const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		const payload = [];
		// Follow the sorting of the components
		for (const child of children) {
			const groupComp = components.current.find(comp => comp.id === child.id);
			if (groupComp) {
				payload.push(groupComp);
			}
        }

		props.updateComponent({ id: props.id, type: componentTypes.FORMGROUP, title, children: payload }, doValidate());
	}

	const updateComponent = (update, error) => {
		if (!withError.current) {
			tracker.current[update.id] = true;
			if (error) {
				withError.current = true;
                // We have an error notify parent component immediately
                props.updateComponent({ id: props.id, type: componentTypes.FORMGROUP, title }, doValidate() || true);
			} else {
				components.current = components.current.map((component) => {
					if (component.id === update.id) {
						return update;
					} else {
						return component;
					}
				});
				trackUpdates();
			}
		}
	}

	const trackComponent = (id) => {
		tracker.current[id] = false;
	}

    const doValidate = () => {
        const errors = {};
		errors['title'] = checkRequired(title, texts.labelTitleGroup);
		setErrors(errors);
        return (!!errors['title']);
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMDISPLAY) {
            return (
                <div key={child.id} className={classes.groupCompContainer}>
                    <FormDisplayDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}
                        isReadOnly={props.isReadOnly}	
                        tick={tick}
                        id={child.id}
                        align={child.align}
                        value={child.value} />
                </div>
            );
        } else if (child.type === componentTypes.FORMINPUT) {
            return (
                <div key={child.id} className={classes.groupCompContainer}>
                    <FormInputDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        formulaReferences= {props.formulaReferences}
                        handleDropdownReference={props.handleDropdownReference}
                        dropdownReferences={props.dropdownReferences}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleReferenceDelete={props.handleReferenceDelete}
                        handleTrackChange={props.handleTrackChange}
                        sources={props.sources}	
                        isReadOnly={props.isReadOnly}
                        tick={tick}
                        id={child.id}
                        children={child.children} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLE) {
            return (
                <div key={child.id} className={classes.groupCompContainer}>
                    <FormTableDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        formulaReferences= {props.formulaReferences}
                        handleDropdownReference={props.handleDropdownReference}
                        dropdownReferences={props.dropdownReferences}
                        handleTableReference={props.handleTableReference}
                        tableReferences= {props.tableReferences}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleReferenceDelete={props.handleReferenceDelete}
                        handleTrackChange={props.handleTrackChange}	
                        sources={props.sources}	
                        isReadOnly={props.isReadOnly}
                        tick={tick}
                        id={child.id}
                        children={child.children} />
                </div>
            );
        } else if (child.type === componentTypes.FORMLINEBREAK) {
            return (
                <div key={child.id} className={classes.groupCompContainer}>
                    <FormLineBreakDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleComponentDelete={handleComponentDelete}	
                        isReadOnly={props.isReadOnly}	
                        id={child.id} />
                </div>
            );
        } else {
            return <span/>
        }
    }

	const initialize = (data) => {
        const children = [];
        components.current = [];
        for (const child of data) {
            children.push(child);
            components.current.push(child);
        }
        setChildren(children);
	}

    useEffect(() => {
        if (isInitialize) {
            sendTick();
        } else {
            setIsInitialize(true);
            setTitle(props.title);
            initialize(props.children || []);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <Card className={classes.cardWrapper} onClick={handleComponentSelect}>
            <CardHeader
                className={classes.cardHeader}
                avatar={ 
                    (!props.isReadOnly ?
                    <IconButton aria-label='Drag' size='small' className='group-sort-handler'>
                        <MdIcon path={mdiArrowUpDown} size='1em' />
                    </IconButton> : null)
                 }
                action={
                    <Fragment>
                        {!props.isReadOnly &&
                        <IconButton aria-label='Delete' size='small' className={classes.cardActionButton} onClick={() => setIsDeleteAlertOpen(true)}>
                            <MdIcon path={mdiTrashCanOutline} size='1em' />
                        </IconButton>
                        }
                        <IconButton aria-label='Toggle' size='small' onClick={() => setIsExpanded(!isExpanded)}>
                            {(isExpanded && <MdIcon path={mdiChevronUp} size='1em' />)}
                            {(!isExpanded && <MdIcon path={mdiChevronDown} size='1em' />)}
                        </IconButton>
                    </Fragment>
                }
                title={
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='group-title'
                            label={texts.labelTitleGroup}
                            variant='outlined'
                            value={title || ''}
                            error={errors['title']}
                            helperText={errors['title']}
                            onChange={handleTitleChange}/>
                    </FormControl>
                }
            />
            <Collapse in={isExpanded} timeout='auto' className={classes.cardContent}>
                <Grid container spacing={3}>
                    {!props.isReadOnly &&
					<Grid item xs={12} className={classes.controlEndNoWrap}>
                        <Button ref={menuAnchorRef} variant='outlined' className={classes.secondaryButtonWithIcon} onClick={handleToggleMenu}>
                            {texts.buttonInsertComponent}<MdIcon path={mdiChevronDown} size='1.5em' className={classes.buttonIconRight} />
                        </Button>
                        <Menu id='component-menu' 
                            className={classes.componentMenu}
                            anchorEl={menuAnchorRef.current} 
                            keepMounted 
                            open={isMenuOpen} 
                            elevation={1}
                            onClose={handleToggleMenu} 
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}>
                            <MenuItem onClick={handleInsertDisplay}>{texts.menuGroupDisplay}</MenuItem>
                            <MenuItem onClick={handleInsertInput}>{texts.menuGroupInput}</MenuItem>
                            <MenuItem onClick={handleInsertTable}>{texts.menuGroupTable}</MenuItem>
                            <MenuItem onClick={handleInsertLineBreak}>{texts.menuGroupLineBreak}</MenuItem>
                        </Menu>	
					</Grid>
                    }
                    <Grid item xs={12}>
                        {(!children || children.length <= 0) && 
                        <Paper  elevation={0} className={classes.noComponentWrapper}>    
                            <div className={classes.noComponent}>
                                <MdIcon path={mdiFileDocumentEditOutline} size='3em' className={classes.noComponentIcon} />
                                <div className={classes.noComponentText}>{texts.infoNoFormComponent}</div>
                            </div>
                        </Paper>
                        }
                        {(children && children.length > 0) && 
                        <ReactSortable 
							list={children} 
							setList={setChildren} 
							animation={200}
							handle='.group-comp-sort-handler'	
							className={classes.groupCompWrapper}
                            onUpdate={props.handleTrackChange}>
                           {children.map((child) => (
                               renderChild(child)
                           ))}
                        </ReactSortable>
                        }
                    </Grid>
                </Grid>
            </Collapse>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleGroup} 
                message={`${texts.deleteAlertMessageStart}${title || texts.infoNoTitleGroup}${texts.deleteAlertMessageComponentEnd}`}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteGroup}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </Card>
	)
}

const TabPanel = (props) => {
    const classes = useStyles();
    return (
        <div
            className={classes.pageWrapperGroup}
            role="tabpanel"
            hidden={props.tab !== props.id}
            id={`group-tabpanel-${props.id}`}
            aria-labelledby={`group-tab-${props.id}`}>
            {props.children}
        </div>
    );
}

export const FormGroupPreview = (props) => {
    const classes = useStyles();
    const [tick, setTick] = useState(0);
    const [withChange, setWithChange] = useState(false);

    const handleInputSave = (data) => {
		props.handleInputSave(data);
        setWithChange(false);
	}

    const handleSaveChanges = () => { 
        const nextTick = tick + 1;
        setTick(nextTick);
	}

    const handleInputData = (id, value, isReadOnly, noTick, stopUpdate) => { 
        props.handleInputData(id, value, noTick, stopUpdate);
        if (!isReadOnly) {
            setWithChange(true);
        }
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMDISPLAY) {
            return (
                <FormDisplayPreview 
                    id={child.id}
                    align={child.align}
                    value={child.value} />
            );
        } else if (child.type === componentTypes.FORMINPUT) {
            return (
                <FormInputPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    formulaReferences= {props.formulaReferences}
                    handleDropdownReference={props.handleDropdownReference}
                    dropdownReferences={props.dropdownReferences}
                    sources={props.sources}	
                    handleInputData={handleInputData}
                    inputData={props.inputData}
                    handleInputSave={handleInputSave}	
                    tick={tick}
                    formulaTick={props.formulaTick}
                    id={child.id}
                    children={child.children} />
            );
        } else if (child.type === componentTypes.FORMTABLE) {
            return (
                <FormTablePreview 
                    handleFormulaReference={props.handleFormulaReference}
                    formulaReferences= {props.formulaReferences}
                    handleDropdownReference={props.handleDropdownReference}
                    dropdownReferences={props.dropdownReferences}
                    handleTableReference={props.handleTableReference}
                    tableReferences= {props.tableReferences}
                    sources={props.sources}
                    handleInputData={handleInputData}
                    inputData={props.inputData}
                    handleInputSave={props.handleInputSave}
                    handleInputUndo={props.handleInputUndo}
                    handleInputDelete={props.handleInputDelete}
                    formulaTick={props.formulaTick}
                    tableTick={props.tableTick}	
                    handleTableData={props.handleTableData}
                    id={child.id}
                    children={child.children} />
            );
        } else if (child.type === componentTypes.FORMLINEBREAK) {
            return (
                <FormLineBreakPreview 
                    id={child.id} />
            );
        } else {
            return <span/>
        }
    }

    const showSaveButton = () => {
        return ((props.children || []).find((child) => child.type === componentTypes.FORMINPUT) || false);
    }

    return (
        <TabPanel tab={props.tab} id={props.id}>
            <Grid container spacing={3}>
                {(props.children || []).map((child) => (
                <Grid item xs={12}>
                    {renderChild(child)}
                </Grid>
                ))}
                {showSaveButton() &&
                <Grid item xs={12} className={classes.controlEndNoWrap}>
                    <Badge color="secondary" badgeContent=" " variant="dot" invisible={!withChange}>
                        <Button variant='contained' className={classes.primaryButton} onClick={handleSaveChanges}>{texts.buttonSaveChanges}</Button>
                    </Badge>
                </Grid>
                }
            </Grid>   
        </TabPanel>
	)
}

export const FormGroupPdf = (props) => {

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMDISPLAY) {
            return (
                <FormDisplayPdf
                    id={child.id}
                    align={child.align}
                    value={child.value} />
            );
        } else if (child.type === componentTypes.FORMINPUT) {
            return (
                <FormInputPdf 
                    inputData={props.inputData}
                    id={child.id}
                    children={child.children} />
            );
        } else if (child.type === componentTypes.FORMTABLE) {
            return (
                <FormTablePdf 
                    inputData={props.inputData}
                    id={child.id}
                    children={child.children} />
            );
        } else if (child.type === componentTypes.FORMLINEBREAK) {
            return (
                <FormLineBreakPdf 
                    id={child.id} />
            );
        } else {
            return <span/>
        }
    }

    return (
        <Fragment>
            <View style={PdfStyles.groupWrapper}>
                <Text style={PdfStyles.groupTitle}>{props.title}</Text>
            </View>
            {(props.children || []).map((child) => (
                renderChild(child)
            ))}
            <View style={PdfStyles.groupPadder}></View>
        </Fragment>
	)
}