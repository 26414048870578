export const aligns = {
    LEFT: 'Left',
    CENTER: 'Center',
    RIGHT: 'Right',
}

export const modes = {
    HEADER: 'Header',
    VIEW: 'View',
    EDIT: 'Edit',
}

export const keys = {
    FORM_SEARCH: 'form_search',
    FORM_TYPE: 'form_type',
    FORM_PRODUCT: 'form_product',
    FORM_INPUT_DATA: 'form_input_data',
    SOURCE_SEARCH: 'source_search',
}

export const componentTypes = {
    FORMGROUP: 'FormGroup',
    FORMDISPLAY: 'FormDisplay',
    FORMINPUT: 'FormInput',
    FORMTABLE: 'FormTable',
    FORMLINEBREAK: 'FormLineBreak',
    FORMINPUTTEXT: 'FormInputText',
    FORMINPUTNUMERIC: 'FormInputNumeric',
    FORMINPUTDROPDOWN: 'FormInputDropdown',
    FORMINPUTDISPLAY: 'FormInputDisplay',
    FORMINPUTFORMULA: 'FormInputFormula',
    FORMTABLEDISPLAY: 'FormTableDisplay',
    FORMTABLEDETAIL: 'FormTableDetail',
    FORMTABLEDETAILTEXT: 'FormTableDetailText',
    FORMTABLEDETAILNUMERIC: 'FormTableDetailNumeric',
    FORMTABLEDETAILDROPDOWN: 'FormTableDetailDropdown',
    FORMTABLEDETAILDISPLAY: 'FormTableDetailDisplay',
    FORMTABLEDETAILFORMULA: 'FormTableDetailFormula',
    FORMTABLESUMMARY: 'FormTableSummary',
    FORMTABLESUMMARYDISPLAY: 'FormTableSummaryDisplay',
    FORMTABLESUMMARYFORMULA: 'FormTableSummaryFormula',
    FORMTABLESUMMARYSPACER: 'FormTableSummarySpacer',
}

export const limits = {
    INPUT_TEXT: 500,
    INPUT_NUMERIC: 20,
}

export const operators = [
    {key: '+', value: '+'},
    {key: '-', value: '-'},
    {key: '*', value: '*'},
    {key: '/', value: '/'},
    {key: '(', value: '('},
    {key: ')', value: ')'},
];

export const saveMode = {
    DRAFT: 'draft',
    FINAL: 'final',
    PREVIEW: 'preview',
}
