import React, { Component, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { NavBar } from '..';
import {
	Button,
	AppBar,
	Toolbar,
	Drawer,
	MenuList,
	MenuItem,
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	LinearProgress,
	TextField,
	InputAdornment,
	Select,
	Snackbar,
	InputLabel,
	FormControl,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip
} from '@material-ui/core/'
import NumberFormat from 'react-number-format';
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import Paths from '../../enums/Paths'
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles';
import { Icon as MdIcon } from '@mdi/react'
import { mdiArrowLeft, mdiMagnify, mdiHelpCircleOutline } from '@mdi/js';
import LoanService from '../../services/LoanService';
import Pagination from '@material-ui/lab/Pagination';
import StatusDisplay from '../common/StatusDisplay';
import SearchUtil from '../../utils/SearchUtil'
import { useHistory } from 'react-router-dom'
import LoanApplicationList from './LoanApplicationList'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		tooltip: {
			width: 190
		},
		"& .MuiLinearProgress-barColorPrimary": {
			backgroundColor: '#F5922F'
		},
		"& .MuiPaginationItem-page.Mui-selected ": {
			backgroundColor: '#F5922F',
			color: '#FFFFFF',
			fontFamily: 'Inter'
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiPaginationItem-rounded": {
			fontFamily: 'Inter'
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	formControl: {
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiInputBase-input": {
			height: 0,
			lineHeight: '0px'
		},
		"& .MuiInputBase-root": {
			height: 40,
			lineHeight: '2px'
		},
		"& .MuiFormLabel-root": {
			lineHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiSelect-selectMenu": {
			minHeight: '0px',
			fontFamily: 'Inter',
			fontSize: 14
		},
		"& .MuiMenuItem-root": {
			fontFamily: 'Inter'
		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	tooltip: {
		width: 190,
		maxWidth: 190
	}
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#FFFFFF',
		color: '#636363',
		fontSize: 12,
		fontFamily: 'Inter'
	},
	body: {
		fontSize: 14,
		fontFamily: 'Inter',
	},
}))(TableCell);

function createData(referenceId, applicantName, loanProduct, requestedAmount, age, status) {
	return { referenceId, applicantName, loanProduct, requestedAmount, age, status };
}

let data = {}
let products = []
let rows = [

];

const LoanApplications = (props) => {

	let history = useHistory();
	const [snackbarArchiveState, setSnackbarArchiveState] = useState(false)


	return (
		<div style={{ fontFamily: 'Inter' }}>
			<Snackbar open={snackbarArchiveState} autoHideDuration={6000} onClose={() => setSnackbarArchiveState(false)}>
				<Alert onClose={() => setSnackbarArchiveState(false)} severity={"success"}>
					<span>The loan application has been successfully archived.</span>
				</Alert>
			</Snackbar>
			<div style={{ margin: '20px 20px 0 20px', width: '100%' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: 28, lineHeight: '32px' }}>Loan Applications
					<Button variant="outlined" style={{ marginRight: 40, color: '#F47721', borderColor: '#F47721', textTransform: 'none', fontFamily: 'Inter' }} onClick={() => { props.setAgent(""); history.push("/loanAgents")}} ><MdIcon path={mdiArrowLeft} size="1.5em" color="#F47721" style={{ marginRight: 10 }} />Back</Button>
					</div>
			</div>
			<LoanApplicationList
				setApplication={props.setApplication}
				setSearchKeyGlobal={props.setSearchKeyGlobal}
				setLoanProductKeyGlobal={props.setLoanProductKeyGlobal}
				setStatusKeyGlobal={props.setStatusKeyGlobal}
				globalSearchKey={props.globalSearchKey}
				globalLoanProductKey={props.globalLoanProductKey}
				globalStatusKey={props.globalStatusKey}
				showPagination={true}
				showFilters={true}
				setNavHistory={props.setNavHistory}
				origin="loanapplications"
				cognitoId={props.agent.cognitoId}>
			</LoanApplicationList>
		</div>
	)
}

export default LoanApplications;