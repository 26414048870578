import React, {useState, Fragment} from 'react';
import { useStyles, texts, toggleDisplay } from './helpers/';
import {
  Button,
  Box,
  AppBar,
  CardMedia,
  CircularProgress,
  Toolbar,
  Dialog,
  Drawer,
  MenuList,
  MenuItem,
  Modal,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
  InputAdornment,
  Select,
  Snackbar,
  Switch,
  InputLabel,
  FormControl,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Typography,
  Radio,
  Backdrop,
  Grid,
  LinearProgress,
  Checkbox
} from '@material-ui/core/';
import { DatePicker } from 'material-ui';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui";
import { v0Theme, UBXTheme } from "../main/theme";
import {
  icon_edit,
} from '../../assets/images';
import LoanService from '../../services/LoanService';
import { useHistory } from 'react-router-dom';
import Paths from '../../enums/Paths';
import moment from 'moment-timezone';
import MuiAlert from '@material-ui/lab/Alert';
import { Icon as MdIcon } from '@mdi/react';
import {
  mdiContentSave,
} from '@mdi/js';
import { set } from 'date-fns';


const { has, find, remove, map } = require('lodash');
let globalModifiedFormData;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};


const CreateProfile = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [updatingData, setUpdatingData] = useState(false);
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [modifiedForm, setModifiedForm] = useState('');
  const [revision, setRevision] = useState('');
  const [snackbarState, setSnackbarState] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [validated, setValidated] = useState(true);
  const [requiredFieldState, setRequiredFieldState] = useState(false);
  const [validationList, setValidationList] = useState([]);
  const [enableSave, setEnableSave] = useState(false);
  const [saveIsOpen, setSaveIsOpen] = useState(false);
  const [resultData, setResultData] = useState('');
  const [sameWithPresentAdd, setSameWithPresentAdd] = useState(false);

  async function getData() {
    setIsFetchingFailed(false);
    setInitialized(true);
    setUpdatingData(true);
    setIsLoading(true);

    let subgroupDataResponse = await LoanService.getCustomerSubgroupFields();
    let subgroupData;
    if (subgroupDataResponse) {
      subgroupData = subgroupDataResponse.result && subgroupDataResponse.result.customerProfileForm;
    }

    setResultData(subgroupData)
    setFormData(subgroupData)
  }

  if (!initialized) {
    getData();
    populateModifiedForm();
  }

  async function populateModifiedForm() {
    let subgroupDataResponse = await LoanService.getCustomerSubgroupFields();
    let subgroupData;
    if (subgroupDataResponse) {
      subgroupData = subgroupDataResponse.result && subgroupDataResponse.result.customerProfileForm;
    }
    setModifiedForm(subgroupData);
    setIsLoading(false);

  }

  const setFieldValue = (value, data, code, type) => {
    let convertedVal = convertDataType(value, type);
    data.map((group, index) =>{
      group.fields.map((field) => {
        if(code == field.code){
          field.value = convertedVal;
        }
      }
      )
    })
    globalModifiedFormData = data;
    return data;
  };

  const renderSubgroup = (data) => {
    {/* Render subgroup label / divider */}
    let formCopy = modifiedForm;
    let groupReadOnly = getGroupIsReadOnly(formCopy, data.code);

    return (
      <div className={classes.subGrouping} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          {data.label.toUpperCase()}
        </div>
        {
            groupReadOnly ?
              <Button
                id={`btn-edit-${data.code}`}
                className={classes.editButtonCP}
                style={{ visibility: currentPage === 1 && isReadOnly == false ? 'hidden' : 'visible' }}
                startIcon={<img src={icon_edit} />}
                onClick={() => {
                  enableFields(formCopy, data.code)
                  setEnableSave(true);
                  setRevision(Math.random())
                }}
              >
                Edit
              </Button>
              :
              <Button
                id={`btn-save-${data.code}`}
                className={classes.editButtonCP}
                style={{ visibility: currentPage === 1 && isReadOnly == false ? 'hidden' : 'visible' }}
                startIcon={<MdIcon
                  path={mdiContentSave}
                  size="17px"
                  color='#1596DB'
                />}
                onClick={() => {
                  saveFields(formCopy, data.code)
                  setEnableSave(false);
                  setRevision(Math.random())
                }}
              >
                Save
             </Button>
          }
      </div>
    )
  }

  const renderTextField = (data) => {
    let formCopy = modifiedForm;

    {/* Render text field element */}
      return (<TextField 
        id={`text-field-${data.code}`}
        label={data.name}
        className={currentPage === 1 ? classes.createProfileComponent : currentPage == 2 && getFieldsToReadOnly(formCopy, data.code) == false ? classes.createProfileComponent : classes.createProfileDisabled}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        disabled={currentPage == 2 ? getFieldsToReadOnly(formCopy, data.code): false}
        onChange={(event)=>{
          setModifiedForm(setFieldValue(event.target.value, formCopy, data.code, data.dataType));
          validate(event.target.value, data.code, data.customFormat, data.validationMessages, data.isRequired);
          setRevision(Math.random());
        }}
        error={getValidationValue(formCopy, data.code)}
        helperText={getMessage(formCopy, data.code)}
        InputProps={{
          inputProps: {
            maxLength: data.maxLength
          },
        }}
        required={data.isRequired}
        type={handleDataType(data.dataType)}
      />)
  }

  const renderTextArea = (data) => {
    let formCopy = modifiedForm;
   
    {/* Render text area element*/}
    return (<TextField 
      id={`text-area-${data.code}`}
      label={data.name}
      className={currentPage === 1 ? classes.createProfileComponent : currentPage == 2 && getFieldsToReadOnly(formCopy, data.code) == false ? classes.createProfileComponent : classes.createProfileDisabled}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      disabled={currentPage == 2 ? getFieldsToReadOnly(formCopy, data.code): false}
      onChange={(event)=>{
        setModifiedForm(setFieldValue(event.target.value, formCopy, data.code, data.dataType));
        validate(event.target.value, data.code, data.customFormat, data.validationMessages, data.isRequired);
        setRevision(Math.random());
      }}
      error={getValidationValue(formCopy, data.code)}
      required={data.isRequired}
      helperText={getMessage(formCopy, data.code)}
    />)
  }

  const renderDropdown = (data) => {
    let formCopy = modifiedForm;
    {/* Render dropdown element */}
    return (<FormControl
      className={currentPage === 1 ? classes.createProfileComponent : currentPage == 2 && getFieldsToReadOnly(formCopy, data.code) == false ? classes.createProfileComponent : classes.createProfileDisabled}
      variant="outlined"
      required={data.isRequired ? "required": ''}
    >
      <InputLabel>{data.name}</InputLabel>
      <Select
        native
        id={`select-${data.code}`}
        className={classes.selectComponent}
        fullWidth={true}
        disabled={currentPage == 2 ? getFieldsToReadOnly(formCopy, data.code): false}
        onChange={(event)=>{
          setModifiedForm(setFieldValue(event.target.value, formCopy, data.code, data.dataType));
          validate(event.target.value, data.code, data.customFormat, data.validationMessages,  data.isRequired);
          setRevision(Math.random());
        }}
        error={getValidationValue(formCopy, data.code)}
        helperText={getMessage(formCopy, data.code)}
        required={data.isRequired ? "required": ''}
      >
        {/* Render dropdown options */}
        <option value={""} />
        {data.options.map((option) => {
        return <option id={`option-${option.code}`} value={option.code}>{option.name}</option>
        })}
      </Select>
    </FormControl>)
  }

  const renderDatePicker = (data) => {
    let formCopy = modifiedForm;
    {/* Render date picker element */}
    return (
    <MuiThemeProvider theme={UBXTheme}>
    <V0MuiThemeProvider muiTheme={v0Theme}>
    <MuiPickersUtilsProvider className={classes.root} utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={`date-picker-${data.code}`}
        label={data.name}
        className={currentPage === 1 ? classes.createProfileComponent : currentPage == 2 && getFieldsToReadOnly(formCopy, data.code) == false ? classes.createProfileComponent : classes.createProfileDisabled}
        format="MM/dd/yyyy"
        margin="normal"
        fullWidth={true}
        inputVariant="outlined"
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{ shrink: true }}  
        disabled={currentPage == 2 ? getFieldsToReadOnly(formCopy, data.code): false}
        onChange={(date)=>{
          data.value = moment(date).format(
            'YYYY-MM-DD');
          setModifiedForm(setFieldValue(moment(date).format(
            'YYYY-MM-DD'
          ), formCopy, data.code, data.dataType));
          validate(date ? moment(date).format(
            'YYYY-MM-DD'
          ): '', data.code, data.customFormat, data.validationMessages,  data.isRequired);
          setRevision(Math.random());
        }}
        value={data.value || null}
        error={getValidationValue(formCopy, data.code)}
        required={data.isRequired ? 'required':''}
        invalidDateMessage={getMessage(formCopy, data.code)}
      />
    </MuiPickersUtilsProvider>
    </V0MuiThemeProvider>
    </MuiThemeProvider>)
  }

  const renderCheckBox = (data) => {
    let formCopy = modifiedForm;
    return (
      <FormControlLabel
      className={currentPage === 1 ? classes.createProfileComponent : currentPage == 2 && getFieldsToReadOnly(formCopy, data.code) == false ? classes.createProfileComponent : classes.createProfileDisabled}
      disabled={currentPage == 2 ? getFieldsToReadOnly(formCopy, data.code): false}
        control={
          <Checkbox
            id={`checkbox-${data.code}`}
            color="primary"
            value="checkbox"
            name="sample"
            onClick={(event)=> {
              handleCheckbox(event)
              setRevision(Math.random())
            }}
          />
        }
        label={data.name}
      />)
  }

  const generateField = (data) => {
    switch(data.fieldType) {
      case "TextField":
        return renderTextField(data);
      case "TextArea":
        return renderTextArea(data);
      case "Dropdown":
        return renderDropdown(data);
      case "DatePicker":
        return renderDatePicker(data);
      default:
        return null;
    }
  }

  const generateCheck = (data) => {
    switch(data.fieldType) {
      case "Checkbox":
        return renderCheckBox(data);
      default:
        return null;
    }
  }

  const generateSubgroup = (data) => {
    return (
      <Fragment>
        {renderSubgroup(data)} 
        <div>
          {data.fields.map((field) => {
            return generateCheck(field)
          })}
        </div>   
        <div style={{ display: 'flex', flexWrap: 'wrap',  }}>
          {data.fields.map((field) => {
            return generateField(field)
          })}
        </div>
      </Fragment>
    )
  }
  const generateDynamicFields = (data) => {
    return data.map((subgroup) => {
      return generateSubgroup(subgroup);
    })
  }

  const renderBackButton = () => {
    if (currentPage === 1) {
      return (
        <Button
        id="btn-back-1"
        variant="outlined"
        className={classes.secondaryButton}
        style={{ width: 140, height: 48 }}
        onClick={() => {
          history.push(
            Paths.CUSTOMER_PROFILE
          );
          props.setRevision(Math.random());
        }}
      >
        Back
      </Button>
      );
    } else {
      return (
        <Button
          id="btn-back-2"
          variant="outlined"
          className={classes.secondaryButton}
          style={{ width: 140, height: 48 }}
          onClick={() => {
            setCurrentPage(1)
            setIsReadOnly(false)
          }}
        >
          Back
        </Button>
      );
      
    }
  }

  const renderButton = () => {
    if (currentPage > 1){
      return (
        <Button
          id="btn-add-client"
          variant="outlined"
          className={classes.primaryButton}
          style={{ width: 140, height: 48 }}
          onClick={() => {
            saveProfile()
          }}
        >
       Add Client
      </Button> 
      );
    } else {
      return (
        <Button
          id="btn-add-continue"
          variant="outlined"
          className={classes.primaryButton}
          style={{ width: 140, height: 48 }}
          onClick={() => {
            setAllFieldsToReadOnly()
          }}
          type='submit'
        >
          Continue
      </Button> 
      );
    }              
  }

  async function addCustomer() {
    if (validationList.length) { 
      setRequiredFieldState(true);
    } else {
      let requestObj = buildSubmitData()
      props.setCreateSuccess(false);
      let res = await LoanService.addCustomerProfile(requestObj);
      if (res && res.status == 201) {
        props.setCreateSuccess(true);
        setSnackbarState(true);
        setRevision(Math.random());
        props.setRenderSnackBar(true);
        const refId = localStorage.getItem("@@customerLoanRefId");
        if(refId && refId != ""){
          localStorage.removeItem("@@customerLoanRefId");
          history.push(
            Paths.REVIEW_APPLICATION
          );
          props.setRevision(Math.random());
        }else{
          history.push(
            Paths.CUSTOMER_PROFILE
          );
          props.setRevision(Math.random());
        }
      } else {
        setCreateSuccess(true);
        props.setCreateSuccess(false);
      }
    }
  }

  function validate(value, code, customFormat, message , isRequired) { 
    let formCopy = modifiedForm ? modifiedForm : [];
    let validation;
    let validationMessage;

    if (!customFormat) {
      if (!value) {
        if (isRequired) {
          validation = true;
          validationMessage = 'This field is required';
          pushToValidationList(code);
        } else {
          validation = false;
          validationMessage = ''
          setValidated(true);
          removeFromValidationList(code);
        }
      } else {
        validation = false;
        validationMessage = ''
        setValidated(true);
        removeFromValidationList(code);
      }
    } else {
      let regex = new RegExp(customFormat);
      if (!value) {
        if (isRequired) {
          validation = true;
          validationMessage = 'This field is required';
          pushToValidationList(code);
        } else {
          validation = false;
          validationMessage = ''
          setValidated(true);
          removeFromValidationList(code);
        }
        
      } else {
        if (!regex.test(value)) {
          validation = true;
          validationMessage = message;
          pushToValidationList(code);
        } else {
          validation = false;
          validationMessage = ''
          removeFromValidationList(code);
        }
      }
    
    }
  
    formCopy.map((group) =>{
      let found = find(group.fields, {code: code});
      if (found) {
        found.validationError = validation;
        found.errorMessage = validationMessage;
      }
    });
    

    setModifiedForm(formCopy);
    
  }

  function getValidationValue(data, code) {
    let errorValue;
    data && data.map((group) => {
      let found = find(group.fields, {code: code});
      if (found) {
        errorValue = found.validationError ? found.validationError : false;
      }
    })
    return errorValue;
  }

  function getMessage(data, code) {
    let message;
    data && data.map((group) => {
      let found = find(group.fields, {code: code});
      if (found) {
        message = found.errorMessage ? found.errorMessage : '';
      }
    })
    return message;
  }

  const nextPage = async (event) => {
    if (validationList.length) {
      event.preventDefault();
      setRequiredFieldState(true);
    } else {
      event.preventDefault();
      setCurrentPage(currentPage + 1)
      setIsReadOnly(true)
      return false;
    }
  }

  const pushToValidationList = (code) => {
    const list = validationList;
    if (!list.includes(code)) {
      list.push(code);
    }
    setValidationList(list);
  };

  const removeFromValidationList = (code) => {
    const list = validationList;
    const newList = list.filter((item) => item !== code);
    setValidationList(newList);
  };

  function enableFields(data, code) {

    const found = find(data, { code: code });
    found.isReadOnly = false;
    found.fields.map((field) => {
      field.isReadOnly = false;
    })

    setModifiedForm(data);
    
  }
  function disableFields(data, code) {

    const found = find(data, { code: code });
    found.isReadOnly = true;
    found.fields.map((field) => {
      field.isReadOnly = true;
    })

    setModifiedForm(data);
    
  }

  function saveFields(formCopy, code) {
    if (validationList.length) {   
      setRequiredFieldState(true);
    } else {
      disableFields(formCopy, code)
      return false;
    }
  }

  function getFieldsToReadOnly(data, code) {

    let readOnly;
    data && data.map((group) => {
      let found = find(group.fields, {code: code});
      if (found) {
        if (found.isReadOnly == undefined) { 
          readOnly = true;
        } else {
          readOnly = found.isReadOnly;
        }
       
      }
    })
    return readOnly;
  }

  function getGroupIsReadOnly(data, code) {
    let readOnly;
    let found = find(data, { code: code });
    if (found) {
      if (found.isReadOnly == undefined) {
        readOnly = true;
      } else {
        readOnly = found.isReadOnly;
      }
    }
    return readOnly;
  }

  function setAllFieldsToReadOnly() {
    let formCopy = modifiedForm && modifiedForm;
    formCopy.map((group) => {
      group.isReadOnly = true
      group.fields.map((field) => {
        field.isReadOnly = true;
      });
    })

    setModifiedForm(formCopy);
    
  }

  function filterSubGroup() {

    const data = resultData
    map(data,(group) => {
      if(group.code === 'permanent_address') {
          remove(group.fields,(field) => {
            return field.code != 'isSameWithPresentAddress';
          })
      }
     
    })
    setResultData(data);
    setRevision(Math.random())
  }

  async function renderPresentAddress(){

    let subgroupDataResponse = await LoanService.getCustomerSubgroupFields();
    let subgroupDataCopy
    if (subgroupDataResponse) {
      subgroupDataCopy  = subgroupDataResponse.result && subgroupDataResponse.result.customerProfileForm;
    }

    const data = subgroupDataCopy;
    setResultData(data);
    setFormData(data);
    setRevision(Math.random())
  }

  const handleCheckbox = (event) => {
    if(event.target.checked) {
      filterSubGroup();
      setSameWithPresentAdd(true)
    } else {
      setSameWithPresentAdd(false)
      renderPresentAddress();
      setRevision(Math.random())
    }
  }


  const handleDataType = (valueType) => {
    if (valueType == 'Integer' || valueType == 'float') {
      return 'number';
    }
  };

  const convertDataType = (value, type) => {
    if (type === 'Integer') {
      value = parseInt(value);
      return value;
    } else if (type === 'Float') {
      value = parseFloat(value);
      return value;
    } else {
      return value;
    }
  };


  function saveProfile() {
    if (enableSave) {
      setSaveIsOpen(true)
    } else {
      addCustomer();
    }
  }



  function buildSubmitData(){

    

    let payload = { address: [{},{}]};
    const refId = localStorage.getItem("@@customerLoanRefId");
    if(refId) {
      payload.referenceId = refId;
    }
    modifiedForm.map((group) => {
      if (group.code == "present_address") {
        group.fields.map((field) => {
          if (field.code != 'isSameWithPresentAddress') {
            payload["address"][0][field.code] = field.value;
          }
        });
      } else if (group.code == "permanent_address") {
        group.fields.map((field) => {
          if (field.code != 'isSameWithPresentAddress') {
            let newCode = field.code.replace("permanent", "");
            newCode = newCode.charAt(0).toLowerCase() + newCode.slice(1);
            payload["address"][1][newCode] = field.value;
          }
        });
      } else {
        group.fields.map((field) => {
          payload[field.code] = field.value;
        });
      }
    });

    if (sameWithPresentAdd) {
      payload['address'].splice(1,1)
    }

    return payload;
  }

  return (
    <div className={classes.pageWrapper}>
       <Grid container className={classes.pageHeader} spacing={1}>
        <Grid item xs={12} sm={6}>
          <span className={classes.pageTitle}>Add New Client</span>
        </Grid>
      </Grid>
      <LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
      <form onSubmit={nextPage}>
      <Paper className={classes.paperWrapper} elevation={0} style={{width: 738}}>
        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 80 }}>
          <span style={{ paddingBottom: 20 }}>Step {currentPage} of 2</span>
          <div id='formTitle' style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: 28, lineHeight: '32px', color: '#F5922F', paddingBottom: 40 }}>{currentPage === 1 ? 'Client Profile' : 'Review Application'}</div>      
          {formData && generateDynamicFields(formData)}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {renderBackButton()}
            {renderButton()}
          </div>
        </div>
      </Paper>
      </form>
      <Snackbar
        id='snackbar-validation'
        open={requiredFieldState}
        autoHideDuration={6000}
        onClose={() => setRequiredFieldState(false)}
      >
        <Alert
          id='alert-validation'
          onClose={() => setRequiredFieldState(false)}
          severity={'error'}
        >
          <span>Cannot proceed with errors</span>
        </Alert>
      </Snackbar>
      <Snackbar
        id='snackbar-add-profile'
        open={createSuccess}
        autoHideDuration={6000}
        onClose={() => setCreateSuccess(false)}
      >
        <Alert
          id='alert-add-profile'
          onClose={() => setCreateSuccess(false)}
          severity={'error'}
        >
          <span>There is an error creating client profile</span>
        </Alert>
      </Snackbar>
      <Snackbar
        id='snackbar-validation'
        open={saveIsOpen}
        autoHideDuration={6000}
        onClose={() => setSaveIsOpen(false)}
      >
        <Alert
          id='alert-validation'
          onClose={() => setSaveIsOpen(false)}
          severity={'error'}
        >
          <span>Cannot proceed with unsave fields</span>
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CreateProfile;