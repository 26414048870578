import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	IconButton,
    Grid,
    Menu,
    MenuItem,
    TextField,
	FormControl,
    FormControlLabel,
    Switch,
    Table,
	TableContainer,
	TableHead,
	TableRow,
    TableBody,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Icon as MdIcon } from '@mdi/react'
import { mdiCursorMove, mdiTrashCanOutline, mdiPlusCircleOutline, mdiPencilOutline, mdiContentSaveOutline, mdiCancel } from '@mdi/js';
import { View } from '@react-pdf/renderer';
import { ReactSortable } from "react-sortablejs";
import { 
    Alert, 
    newId, 
    checkRequired, 
    useStyles, 
    texts, 
    StyledTableDetailCell, 
    StyledTableDetailRow,
    modes, 
    PdfStyles,
    componentTypes, 
    aligns, 
    limits,
    clone,
} from '../../helper';
import { 	
    FormTableDetailTextDesigner, 
	FormTableDetailTextPreview,
    FormTableDetailTextPdf,
    FormTableDetailNumericDesigner,
	FormTableDetailNumericPreview, 
    FormTableDetailNumericPdf,
    FormTableDetailDropdownDesigner, 
	FormTableDetailDropdownPreview, 
    FormTableDetailDropdownPdf,
	FormTableDetailDisplayDesigner, 
	FormTableDetailDisplayPreview,
    FormTableDetailDisplayPdf,
	FormTableDetailFormulaDesigner, 
	FormTableDetailFormulaPreview,
    FormTableDetailFormulaPdf,
} from '.';

const FormTableDetailProperties = (props) => {
    const classes = useStyles();
    const [manual, setManual] = useState(true);
    const [source, setSource] = useState({});
    const [reference, setReference] = useState('');
    const [sources, setSources] = useState([]);
    const [errors, setErrors] = useState({});

    const handleItemAddChange = (event, checked) => { 
        setManual(checked);
        props.updateManualAndReference(checked, reference);
        if (checked) {
            setSource('');
            props.updateSource('');
            errors['source'] = '';
        } else {
            errors['source'] = checkRequired(source, texts.labelDetailSource);
        }
        setErrors(errors);
    }

    const handleReferenceChange = (event) => {
        const reference = event.target.value;
        setReference(reference);
        props.updateManualAndReference(manual, reference);
    }

    const handleSourceChange = (event, value) => {
        setSource(value);
        props.updateSource(value);
        errors['source'] = checkRequired(value, texts.labelDetailSource);
		setErrors(errors);
    }

    const initSources = (source, tableReferences) => {
        const sources = [];
        for (const key of Object.keys(tableReferences)) {
            if (source && key === source.key) {
                source.value = (tableReferences[key].reference || '-');
            }
            if (key !== props.id &&
                (tableReferences[key].reference || '').trim().length > 0) {
                sources.push({ key, value: tableReferences[key].reference });
            }
        }
        setSource(source);
        setSources(sources);
    }

    useEffect(() => {
        setManual(props.manual);
        setReference(props.reference || '');
        initSources(props.source, props.tableReferences);
        const errors = {};
        if (!props.manual) {
            errors['source'] = checkRequired(props.source, texts.labelDetailSource);
        }
		setErrors(errors);
    }, [props.id]);

    return (
        <div className={classes.propertyWrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-type'
                            label={texts.labelComponentType}
                            variant='outlined'
                            value={texts.infoTypeTableDetail}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-id'
                            label={texts.labelComponentId}
                            variant='outlined'
                            value={props.id || ''}
                            InputProps={{
                                readOnly: true,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.swithControlProp}
                        control={<Switch checked={manual} onChange={handleItemAddChange} disabled={props.isReadOnly} />}
                        label={manual ? texts.infoValueManual : texts.infoValueAuto}/>
                </Grid>
                {!manual &&
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <Autocomplete
                            id='component-source'
                            options={sources}
                            getOptionSelected={(option, value) => option.key === value.key}
                            getOptionLabel={(option) => option.value}
                            value={source || {}}
                            filterSelectedOptions
                            disableClearable={true}
                            noOptionsText={texts.infoNoSourceTableDetail}
                            onChange={handleSourceChange}
                            disabled={props.isReadOnly}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={texts.labelDetailSource}
                                    error={errors['source']}
                                    helperText={errors['source']}
                                />
                            )}
                            classes={{
                                popper: classes.formControl,
                            }}/>
                    </FormControl>
                </Grid>}
                <Grid item xs={12}>
                    <FormControl variant='outlined' className={`${classes.formControl} ${classes.widthFull}`}>
                        <TextField 
                            id='component-reference'
                            label={texts.labelDetailReference}
                            variant='outlined'
                            value={reference || ''}
                            onChange={handleReferenceChange}
                            InputProps={{
                                readOnly: props.isReadOnly,
                            }}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.propertyInfo}>{texts.infoPropertyTableDetail}</span>
                </Grid>
            </Grid>
        </div>
    );
}

export const FormTableDetailDesigner = (props) => {
	const classes = useStyles();
    const [manual, setManual] = useState(true);
    const [reference, setReference] = useState('');
    const [source, setSource] = useState({});
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const [isInitialize, setIsInitialize] = useState(false);
    const [children, setChildren] = useState(null);
	const [tick, setTick] = useState(0);
	const components = useRef([]);
	const tracker = useRef({});
	const withError = useRef(false);

    const handleDeleteComponent = () => { 
        props.handleComponentDelete(props.id);
        setIsDeleteAlertOpen(false);
	}

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleInsertText = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLEDETAILTEXT, 
                        label: '',
                        align: aligns.LEFT,
                        limit: limits.INPUT_TEXT,
                        required: false,
                        prefix: '',
                        suffix: '',
                        bold: false,
                        width: 0,
                        filler: '',
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertNumeric = () => { 
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLEDETAILNUMERIC, 
                        label: '',
                        align: aligns.LEFT,
                        limit: limits.INPUT_NUMERIC,
                        required: false,
                        prefix: '',
                        suffix: '',
                        bold: false,
                        width: 0,
                        filler: '',
                        decimal: false,
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertDropdown = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLEDETAILDROPDOWN, 
                        label: '',
                        align: aligns.LEFT,
                        required: false,
                        source: {},
                        bold: false,
                        width: 0,
                        filler: '',
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertDisplay = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLEDETAILDISPLAY, 
                        label: '',
                        value: '',
                        align: aligns.LEFT,
                        prefix: '',
                        suffix: '',
                        bold: false,
                        width: 0,
                        reference: '' };
        handleChildInsert(child);
	}

    const handleInsertFormula = () => {
        const child = { id: newId(), 
                        type: componentTypes.FORMTABLEDETAILFORMULA, 
                        label: '',
                        formula: [],
                        align: aligns.LEFT,
                        prefix: '',
                        suffix: '',
                        bold: false,
                        width: 0,
                        filler: '',
                        decimal: false,
                        reference: '' };
        handleChildInsert(child);
	}

    const handleUpdateSource = (source) => {
        setSource(source);
        props.handleTrackChange();
    }

    const handleUpdateManualAndReference = (manual, reference) => {
        setManual(manual);
        setReference(reference);
        props.handleTableReference(props.id, reference, manual);
        props.handleTrackChange();
    }

    const handleComponentSelect = (event) => { 
        if (event) {
			event.stopPropagation();
		}
        if (props.selected !== props.id) {
            props.handleSelect(props.id, <FormTableDetailProperties 
                                            id={props.id}
                                            manual={manual}
                                            source={source}
                                            reference={reference}
                                            tableReferences={props.tableReferences}
                                            updateSource={handleUpdateSource}
                                            updateManualAndReference={handleUpdateManualAndReference}
                                            isReadOnly={props.isReadOnly} />);
        }
	}

    const handleChildInsert = (child) => {
        const data = [...(children || [])];
		data.push(child);
		setChildren(data);
		components.current.push(child);
        handleToggleMenu();
        props.handleTrackChange();
    }

    const handleComponentDelete = (id) => {
        handleComponentSelect();
		components.current = components.current.filter((component) => (component.id !== id));
		const data = [...(children || [])].filter((child) => (child.id !== id));
		setChildren(data);
		delete tracker.current[id];
        props.handleReferenceDelete(id);
        props.handleTrackChange();
	}

    const sendTick = () => {
        if (children && children.length > 0) {
            for (const key of Object.keys(tracker.current)) {
                tracker.current[key] = false;
            }
            withError.current = false;
            const nextTick = tick + 1;
            setTick(nextTick);
        } else {
            trackUpdates();
        }
	}

    const trackUpdates = () => {
		for (const key of Object.keys(tracker.current)) {
            if (!tracker.current[key]) {
				return;
			}
        }

		const payload = [];
		// Follow the sorting of the components
		for (const child of children) {
			const groupComp = components.current.find(comp => comp.id === child.id);
			if (groupComp) {
				payload.push(groupComp);
			}
        }

		props.updateComponent({ id: props.id, 
                                type: componentTypes.FORMTABLEDETAIL, 
                                manual, 
                                source, 
                                reference,
                                children: payload }, doValidate());
	}

	const updateComponent = (update, error) => {
		if (!withError.current) {
			tracker.current[update.id] = true;
			if (error) {
				withError.current = true;
                // We have an error notify parent component immediately
              props.updateComponent({ id: props.id, 
                                      type: componentTypes.FORMTABLEDETAIL, 
                                      manual, 
                                      source, 
                                      reference }, true);
			} else {
				components.current = components.current.map((component) => {
					if (component.id === update.id) {
						return update;
					} else {
						return component;
					}
				});
				trackUpdates();
			}
		}
	}

	const trackComponent = (id) => {
		tracker.current[id] = false;
	}

    const doValidate = () => {
        const errors = {};
        if (!manual) {
            errors['source'] = checkRequired(source);
        }
        return (!!errors['source']);
	}

    const renderChild = (child) => {
        if (child.type === componentTypes.FORMTABLEDETAILTEXT) {
            return (
                <div key={child.id} className={`${classes.groupTableDetailCompContainer} ${classes.componentItem}`}>
                    <FormTableDetailTextDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}	
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        required={child.required}
                        limit={child.limit}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        bold={child.bold}
                        width={child.width}
                        filler={child.filler}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILNUMERIC) {
            return (
                <div key={child.id} className={`${classes.groupTableDetailCompContainer} ${classes.componentItem}`}>
                    <FormTableDetailNumericDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}	
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        required={child.required}
                        limit={child.limit}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        bold={child.bold}
                        width={child.width}
                        filler={child.filler}
                        decimal={child.decimal}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
            return (
                <div key={child.id} className={`${classes.groupTableDetailCompContainer} ${classes.componentItem}`}>
                    <FormTableDetailDropdownDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        handleDropdownReference={props.handleDropdownReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleTrackChange={props.handleTrackChange}		
                        sources={props.sources}	
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        align={child.align}
                        required={child.required}
                        source={child.source}
                        bold={child.bold}
                        width={child.width}
                        filler={child.filler}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDISPLAY) {
            return (
                <div key={child.id} className={`${classes.groupTableDetailCompContainer} ${classes.componentItem}`}>
                    <FormTableDetailDisplayDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}
                        handleTrackChange={props.handleTrackChange}		
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        value={child.value}
                        align={child.align}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        bold={child.bold}
                        width={child.width}
                        reference={child.reference} />
                </div>
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILFORMULA) {
            return (
                <div key={child.id} className={`${classes.groupTableDetailCompContainer} ${classes.componentItem}`}>
                    <FormTableDetailFormulaDesigner 
                        handleSelect={props.handleSelect} 
                        selected={props.selected} 
                        handleFormulaReference={props.handleFormulaReference}
                        formulaReferences={props.formulaReferences}
                        dropdownReferences={props.dropdownReferences}
                        trackComponent={trackComponent}			
                        updateComponent={updateComponent}	
                        handleComponentDelete={handleComponentDelete}	
                        handleTrackChange={props.handleTrackChange}	
                        tick={tick} 
                        isReadOnly={props.isReadOnly}
                        id={child.id}
                        label={child.label}
                        formula={child.formula}
                        align={child.align}
                        prefix={child.prefix}
                        suffix={child.suffix}
                        bold={child.bold}
                        width={child.width}
                        filler={child.filler}
                        decimal={child.decimal}
                        numeric={child.numeric}
                        reference={child.reference} />
                </div>
            );
        } else {
            return <span/>
        }
    }

    const initialize = (data) => {
        const children = [];
        components.current = [];
        for (const child of data) {
            children.push(child);
            components.current.push(child);
        }
        setChildren(children);
	}

    useEffect(() => {
        if (isInitialize) {
            sendTick();
        } else {
            setIsInitialize(true);
            setManual(props.manual);
            setSource(props.source);
            setReference(props.reference);
            if (props.reference) {
                props.handleTableReference(props.id, props.reference, props.manual);
            }
            initialize(props.children || []);
            props.trackComponent(props.id);
        }
    }, [props.tick]);

	return (
        <div className={clsx(classes.componentWrapper, {[classes.componentSelected]: (props.selected === props.id)})} onClick={handleComponentSelect}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Drag' size='small' className='group-comp-table-sort-handler'>
                                <MdIcon path={mdiCursorMove} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.componentTitleWrapper}>
                            <div className={classes.componentTitleWithIcon}>{texts.infoTypeTableDetail}</div>
                            {!props.isReadOnly &&
                            <Fragment>
                                <IconButton ref={menuAnchorRef} aria-label='Add' size='small' onClick={handleToggleMenu}>
                                    <MdIcon path={mdiPlusCircleOutline} size='1em' />
                                </IconButton>
                                <Menu id='component-input-menu' 
                                    className={classes.componentMenu}
                                    anchorEl={menuAnchorRef.current} 
                                    keepMounted 
                                    open={isMenuOpen} 
                                    elevation={1}
                                    onClose={handleToggleMenu} 
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    <MenuItem onClick={handleInsertText}>{texts.menuTableDetailText}</MenuItem>
                                    <MenuItem onClick={handleInsertNumeric}>{texts.menuTableDetailNumeric}</MenuItem>
                                    <MenuItem onClick={handleInsertDropdown}>{texts.menuTableDetailDropdown}</MenuItem>
                                    <MenuItem onClick={handleInsertDisplay}>{texts.menuTableDetailDisplay}</MenuItem>
                                    <MenuItem onClick={handleInsertFormula}>{texts.menuTableDetailFormula}</MenuItem>
                                </Menu>	
                            </Fragment>
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.controlEndNoWrap}>
                            {!props.isReadOnly &&
                            <IconButton aria-label='Delete' size='small' onClick={() => setIsDeleteAlertOpen(true)}>
                                <MdIcon path={mdiTrashCanOutline} size='1em' />
                            </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {(children && children.length > 0) && 
                        <ReactSortable 
                            list={children} 
                            setList={setChildren} 
                            animation={200}
                            handle='.group-comp-table-detail-sort-handler'	
                            className={`${classes.groupTableDetailCompWrapper} ${classes.componentScroll}`}
                            onUpdate={props.handleTrackChange}>
                            {children.map((child) => (
                                renderChild(child)
                            ))}
                        </ReactSortable>
                    }
                </Grid>
            </Grid>
            <Alert isOpen={isDeleteAlertOpen}
                title={texts.deleteAlertTitleTableDetail} 
                message={texts.deleteAlertMessageTableDetail}
                cancelButton={texts.buttonCancel}
                confirmButton={texts.buttonDelete}
                confirm={handleDeleteComponent}
                cancel={() => setIsDeleteAlertOpen(false)} />
        </div>
	)
}

export const FormTableDetailPreview = (props) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [isInitialize, setIsInitialize] = useState(false);
    const [tick, setTick] = useState('');
    const tickCtr = useRef(0);
    const values = useRef({});
    const tracker = useRef({});
    const withError = useRef({});

    const handleRowEdit = (rowId) => {
        const currTable = [];
        for (const row of rows) {
            if (row.rowId === rowId) {
                row.mode = modes.EDIT;
            }
            currTable.push(row);
        }
        setRows(currTable);
	}

    const handleRowDelete = (rowId) => {
        const currTable = [];
        const saveTable = [];
        
        for (const row of rows) {
            if (row.rowId !== rowId) {
                currTable.push(row);
                if (row.mode === modes.VIEW) {
                    saveTable.push(row);
                }
            }
        }
        setRows(currTable);
        props.handleInputDelete(props.id, saveTable, rowId);
	}

    const handleRowSave = (rowId) => {
        sendTick(rowId);
	}

    const handleRowCancel = (rowId) => {
        const currTable = [];
        for (const row of rows) {
            if (row.rowId !== rowId) {
                currTable.push(row);
            } else if (!row.fresh) {
                row.mode = modes.VIEW;
                currTable.push(row);
            }
        }
        props.handleInputUndo(rowId);
        setRows(currTable);
	}

    const sendTick = (rowId) => {
        if (props.children && props.children.length > 0) {
            for (const key of Object.keys(tracker.current[rowId])) {
                tracker.current[rowId][key] = false;
            }
            values.current[rowId] = {};
            withError.current[rowId] = false;

            tickCtr.current = tickCtr.current + 1;
            setTick(rowId + '-' + + tickCtr.current);
        } else {
            trackUpdates(rowId);
        }
	}

    const trackUpdates = (rowId) => {
		for (const key of Object.keys(tracker.current[rowId])) {
            if (!tracker.current[rowId][key]) {
				return;
			}
        }
        
        // Format to save able keys
        const rowData = values.current[rowId];
        const saveRow = {};
        for (const key of Object.keys(rowData)) {
			saveRow[key + '-' + rowId] = rowData[key];
		}

        // We need to update and save the table rows
        const currTable = [];
        const saveTable = [];
        for (const row of rows) {
			if (row.mode === modes.VIEW) {
                currTable.push(row);
                saveTable.push(row);
            } else if (row.rowId === rowId) {
                row.mode = modes.VIEW;
                delete row.fresh;
                currTable.push(row);
                saveTable.push(row);
            } else {
                currTable.push(row);
            }
		}

        setRows(currTable);
        saveRow[props.id] = saveTable;
        props.handleInputSave(saveRow, props.id, rowId);
	}

    const saveAutoAddRow = (rows, rowId) => {
        const saveRow = {};
        const saveTable = [];
        const dataRows = [...rows];
        for (const row of dataRows) {
			if (row.mode === modes.VIEW) {
                saveTable.push(row);
            } else if (row.rowId === rowId) {
                row.mode = modes.VIEW;
                saveTable.push(row);
            }
		}
        saveRow[props.id] = saveTable;
        props.handleInputSave(saveRow, props.id, rowId);
	}

    const updateComponent = (rowId, id, value, error) => {
		if (!withError.current[rowId]) {
			tracker.current[rowId][id] = true;
			if (error) {
				withError.current[rowId] = true;
            } else {
                values.current[rowId][id] = value;
				trackUpdates(rowId);
			}
		}
	}

    const trackComponent = (rowId, id) => {
        if (!tracker.current[rowId]) {
            tracker.current[rowId] = {};
        }
		tracker.current[rowId][id] = false;
	}

    const renderChildHeader = (child, index) => {
        if (child.type === componentTypes.FORMTABLEDETAILTEXT) {
            return (
                <FormTableDetailTextPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    className={(index === 0) ? classes.plCell : null}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILNUMERIC) {
            return (
                <FormTableDetailNumericPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    className={(index === 0) ? classes.plCell : null}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
            return (
                <FormTableDetailDropdownPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    className={(index === 0) ? classes.plCell : null}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDISPLAY) {
            return (
                <FormTableDetailDisplayPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    className={(index === 0) ? classes.plCell : null}
                    mode={modes.HEADER}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILFORMULA) {
            return (
                <FormTableDetailFormulaPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    className={(index === 0) ? classes.plCell : null}
                    mode={modes.HEADER}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else {
            return <span/>
        }
    }

    const renderChildRow = (child, childIndex, row) => {
        if (child.type === componentTypes.FORMTABLEDETAILTEXT) {
            return (
                <FormTableDetailTextPreview 
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    tick={tick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    reference={child.reference}
                    className={(childIndex === 0) ? classes.plCell : null}
                    rowId={row.rowId}
                    mode={row.mode}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILNUMERIC) {
            return (
                <FormTableDetailNumericPreview 
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    tick={tick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    reference={child.reference}
                    decimal={child.decimal}
                    className={(childIndex === 0) ? classes.plCell : null}
                    rowId={row.rowId}
                    mode={row.mode}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
            return (
                <FormTableDetailDropdownPreview 
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    trackComponent={trackComponent}			
                    updateComponent={updateComponent}	
                    tick={tick}
                    sources={props.sources}	
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    source={child.source}
                    reference={child.reference}
                    className={(childIndex === 0) ? classes.plCell : null}
                    rowId={row.rowId}
                    mode={row.mode}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDISPLAY) {
            return (
                <FormTableDetailDisplayPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    value={child.value}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    reference={child.reference}
                    className={(childIndex === 0) ? classes.plCell : null}
                    rowId={row.rowId}
                    mode={row.mode} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILFORMULA) {
            return (
                <FormTableDetailFormulaPreview 
                    handleFormulaReference={props.handleFormulaReference}
                    handleInputData={props.handleInputData}
                    inputData={props.inputData}
                    sources={props.sources}
                    formulaTick={props.formulaTick}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    decimal={child.decimal}
                    numeric={child.numeric}
                    reference={child.reference}
                    className={(childIndex === 0) ? classes.plCell : null}
                    rowId={row.rowId}
                    mode={row.mode}
                    fromAnalysis={props.fromAnalysis} />
            );
        } else {
            return <span/>
        }
    }

    useEffect(() => {
        if (!isInitialize) {
            setIsInitialize(true);
            setRows(props.inputData[props.id] || []);
        } else if (props.tableTick) {
            if (props.tableTick.startsWith('!' + props.id)) {
                const newRows = [...rows];
                let newRowObj = { rowId: newId(), mode: modes.EDIT, fresh: true };
                newRows.push(newRowObj);
                if(props.handleAddRowDataTable && {}.toString.call(props.handleAddRowDataTable) === '[object Function]'){
                    props.handleAddRowDataTable( props.id, newRowObj.rowId);
                }
                setRows(newRows);
            } else if (props.source && props.source.key &&
                        props.tableTick.startsWith('+' + props.source.key)) {
                const rowId = props.tableTick.replace(('+' + props.source.key + '-'), '');
                const isAdded = rows.find(row => row.rowId === rowId);
                if (!isAdded) {
                    const newRows = [...rows];
                    newRows.push({ rowId, mode: modes.EDIT });
                    setRows(newRows);
                    saveAutoAddRow(newRows, rowId);
                }
            } else if (props.source && props.source.key &&
                        props.tableTick.startsWith('-' + props.source.key)) {
                const rowId = props.tableTick.replace(('-' + props.source.key + '-'), '');
                handleRowDelete(rowId);
            }
        }
    }, [props.tableTick]);

	return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table className={classes.tableComponet} aria-label='group table detail'>
                        <TableHead>
                            <TableRow>
                                {(props.children || []).map((child, index) => (
                                    renderChildHeader(child, index)
                                ))}
                                <StyledTableDetailCell width='60px' align='center' className={classes.tableActionCell}></StyledTableDetailCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableDetailBody}>
                            {(rows || []).map((row) => (
                            <StyledTableDetailRow>
                                {(props.children || []).map((child, childIndex) => (
                                   renderChildRow(child, childIndex, row)
                                ))}
                                <StyledTableDetailCell align='center' className={classes.tableActionCell}>
                                    {((row.mode === modes.VIEW) &&  
                                    <div className={classes.tableActionWrapper}>
                                        <IconButton className={classes.tableActionIcon} aria-label='Edit' onClick={() => handleRowEdit(row.rowId)}>
                                            <MdIcon path={mdiPencilOutline} size='0.8em' />
                                        </IconButton>
                                        {props.manual && 
                                        <IconButton className={classes.tableActionIcon} aria-label='Delete' onClick={() => handleRowDelete(row.rowId)}>
                                            <MdIcon path={mdiTrashCanOutline} size='0.8em' />
                                        </IconButton>
                                        }
                                    </div>
                                    )}
                                    {((row.mode === modes.EDIT) &&  
                                    <div className={classes.tableActionWrapper}>
                                        <IconButton className={classes.tableActionIcon} aria-label='Edit' onClick={() => handleRowSave(row.rowId)}>
                                            <MdIcon path={mdiContentSaveOutline} size='0.8em' />
                                        </IconButton>
                                        <IconButton className={classes.tableActionIcon} aria-label='Delete' onClick={() => handleRowCancel(row.rowId)}>
                                            <MdIcon path={mdiCancel} size='0.8em' />
                                        </IconButton>
                                    </div>
                                    )}
                                </StyledTableDetailCell>
                            </StyledTableDetailRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
       </Grid>
	)
}

export const FormTableDetailPdf = (props) => {

    const renderChildHeader = (child) => {
        if (child.type === componentTypes.FORMTABLEDETAILTEXT) {
            return (
                <FormTableDetailTextPdf 
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILNUMERIC) {
            return (
                <FormTableDetailNumericPdf 
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
            return (
                <FormTableDetailDropdownPdf 
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDISPLAY) {
            return (
                <FormTableDetailDisplayPdf 
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    mode={modes.HEADER} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILFORMULA) {
            return (
                <FormTableDetailFormulaPdf 
                    label={child.label}
                    align={child.align}
                    bold={child.bold}
                    width={child.width}
                    mode={modes.HEADER} />
            );
        } else {
            return <span/>
        }
    }

    const renderChildRow = (child, row) => {
        if (child.type === componentTypes.FORMTABLEDETAILTEXT) {
            return (
                <FormTableDetailTextPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    rowId={row.rowId}
                    mode={modes.VIEW} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILNUMERIC) {
            return (
                <FormTableDetailNumericPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    limit={child.limit}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    decimal={child.decimal}
                    rowId={row.rowId}
                    mode={modes.VIEW} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDROPDOWN) {
            return (
                <FormTableDetailDropdownPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    required={child.required}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    source={child.source}
                    rowId={row.rowId}
                    mode={modes.VIEW} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILDISPLAY) {
            return (
                <FormTableDetailDisplayPdf 
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    value={child.value}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    rowId={row.rowId}
                    mode={modes.VIEW} />
            );
        } else if (child.type === componentTypes.FORMTABLEDETAILFORMULA) {
            return (
                <FormTableDetailFormulaPdf 
                    inputData={props.inputData}
                    id={child.id}
                    label={child.label}
                    align={child.align}
                    formula={child.formula}
                    prefix={child.prefix}
                    suffix={child.suffix}
                    bold={child.bold}
                    width={child.width}
                    filler={child.filler}
                    decimal={child.decimal}
                    numeric={child.numeric}
                    rowId={row.rowId}
                    mode={modes.VIEW} />
            );
        } else {
            return <span/>
        }
    }

	return (
        <View style={PdfStyles.groupTableDetailWrapper}>
            <View style={PdfStyles.groupTableHeaderRow}>
                {(props.children || []).map((child) => (
                    renderChildHeader(child)
                ))}
            </View>
            {(props.inputData[props.id] || []).map((row) => (
            <View style={PdfStyles.groupTableDataRow} wrap={true}>
                {(props.children || []).map((child) => (
                    renderChildRow(child, row)
                ))}
            </View>
            ))}
        </View>
	)
}
