import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid';
import { aligns, isEmpty, texts, operators } from '.'; 

export const toggleDisplay = (display) => {
    return (display ? 'block' : 'none');
}

export const newId = () => {
    return uuidv4();
}

const getAlignText = (align) => {
    if (align === aligns.LEFT) {
        return 'left';
    } else if (align === aligns.CENTER) {
        return 'center';
    } else if (align === aligns.RIGHT) {
        return 'right';
    } else {
        return null;
    }
}

export const getAlignStyle = (align) => {
    const textAlign = getAlignText(align);
    if (textAlign) {
        return { textAlign };
    } else {
        return {};
    }
}

export const getWidthStyle = (width) => {
    if (width) {
       return { width: `${width}%` };
    } else {
        return {}; 
    }
}

export const getAlignBoldStyle = (align, bold) => {
    const style = getAlignStyle(align);
    if (bold) {
        style.fontWeight = 'bold';
    }
    return style;  
}

export const getAlignBoldWidthStyle = (align, bold, width) => {
    const style = getAlignBoldStyle(align, bold);
    if (width) {
       style.width = `${width}%`;
    } 
    return style; 
}

export const getPdfAlignStyle = (align, style) => {
    const pdfStyle = (style || {});
    const textAlign = getAlignText(align);
    if (textAlign) {
        pdfStyle.textAlign = textAlign;
    }
    return pdfStyle;
}

export const getPdfAlignBoldStyle = (align, bold, style) => {
    const pdfStyle = getPdfAlignStyle(align, style);
    if (bold) {
        pdfStyle.fontFamily = 'Inter Bold';
    } else {
        pdfStyle.fontFamily = 'Inter';
    }
    return pdfStyle; 
}

export const getPdfWidthStyle = (width, style) => {
    const pdfStyle = (style || {});
    if (width) {
        pdfStyle.width = `${width}%`;
        pdfStyle.flex = parseFloat(width)/100;
    }
    return pdfStyle; 
}

export const getPdfAlignBoldWidthStyle = (align, bold, width, style) => {
    const pdfStyle = getPdfAlignBoldStyle(align, bold, style);
    return getPdfWidthStyle(width, pdfStyle); 
}

export const formatIfNumeric = (value, decimal) => {
    if (value !== 0 && isEmpty(value)) {
        return '';
    } else {
        if (decimal) {
            const floatValue = parseFloat(value);
            if (isNaN(floatValue)) {
                return value;
            } else {
                return floatValue.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });            
            }
        } else {
            const intValue = parseFloat(value);
            if (isNaN(intValue)) {
                return value;
            } else {
                return intValue.toLocaleString('en');       
            }
        }
    }
}

export const formatNumeric = (value, decimal) => {
    if (value !== 0 && isEmpty(value)) {
        return '';
    } else {
        if (decimal) {
            return parseFloat(value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });            
        } else {
            return parseInt(value).toLocaleString('en');
        }
    }
}

export const getDisplayValue = (value, filler, prefix, suffix) => {
    return (`${prefix || ''} ${isEmpty(value) ? (filler || '') :  value} ${suffix || ''}`).trim();    
}

export const clone = (object) => {
    return JSON.parse(JSON.stringify(object));
}

export const formatDateTime = (dateTime) => {
    if (dateTime) {
        return moment(dateTime[dateTime.length - 1] === 'Z' ? dateTime : dateTime + 'Z').tz(texts.timeZone).format(texts.dateTimeFormat);
    } else {
        return '-';
    }
}

export const getKeyValue = (key) => {
    const value = localStorage.getItem('cashflow_' + key);
    if (value) {
        return value;
    } else {
        return '';
    }
}

export const setKeyValue = (key, value) => {
    localStorage.setItem('cashflow_' + key, value);
}

export const removeFiller = (value) => {
    if (value === texts.filler) {
        return '';
    } else {
        return value;
    }
}

export const toFlatArray = (data, key) => {
    if (data) {
        return data.map(value => {
            return value[key];
        });
    } else {
        return null;
    }
};

export const toObjectArray = (ids, names) => {
    if (ids && ids.trim().length > 0) {
        const arrIds = ids.split(', ');
        const arrNames = names.split(', ');
        return arrIds.map((id, index) => {
            return { id, name: (arrNames[index] || '') };
        });
    } else {
        return [];
    }
};

export const mergeFormulaReferences = (formulaReferences, dropdownReferences) => {
    const references = {};
    for (const key of Object.keys(formulaReferences)) {
        const dropKey = key.replace('&', '').replace('!', '');
        if (dropdownReferences[dropKey]) {
            const dropdown = dropdownReferences[dropKey];
            const columns = dropdown.columns;
            if (columns && columns.length > 2) {
                for(const column of columns) {
                    if (column !== 'Key') {
                        const colKey = (column.startsWith('#') ? '#' : '') +  key + '@' + column + '~' + dropdown.id;
                        references[colKey] = formulaReferences[key] + ' - ' + column;
                    }
                }
            } else {
                references[key] = formulaReferences[key];    
            }
        } else {
            references[key] = formulaReferences[key];
        }
    }
    return references;
}

export const formatFormulaReferences = (formulaReferences, id) => {
    const formulas = [];
    for (const key of Object.keys(formulaReferences)) {
        if (key !== id) {
            formulas.push({ key, value: formulaReferences[key] });
        }
    }
    return formulas;
}

export const toNumericFormulaReferences = (formulaReferences) => {
    const formulas = [...operators];
    for (const formula of formulaReferences) {
        if (formula.key.startsWith('#') || 
            formula.key.startsWith('!#')) {
            formulas.push(formula);
        }
    }
    return formulas;
}

export const mapFormula = (formula, options) => {
    const formulas = [];
    for (const fml of formula) {
        if (!fml.key) {
            const value = options.find(option => option.value.toLowerCase() === (fml || '').toLowerCase());
            if (value) {
                formulas.push(value);
            } else {
                formulas.push(fml);
            }
        } else {
            formulas.push(fml);
        }
    }
    return formulas;
}

export const isNumericLiteral = (value) => {
    return (value === '+' || 
            value === '-' ||
            value === '*' || 
            value === '/' || 
            value === '(' || 
            value === ')' || 
            (!isNaN(value) &&
            !isNaN(parseFloat(value))));
}

export const isNumericKey = (key) => {
    return (key.startsWith('#') || 
            key.startsWith('!#') ||
            key === '+' || 
            key === '-' ||
            key === '*' || 
            key === '/' || 
            key === '(' || 
            key === ')');
}

export const isValidNumericFormula = (formula) => {
    const formulas = [];
    for (const fml of formula) {
        if (fml.key) {
            if (fml.key.length > 1) { // Not an operator
                formulas.push(1);
            } else {
                formulas.push(fml.key);
            }
        } else {
            formulas.push(fml); // This is a literal formula
        }
    }
    let value = null;
    try {
        value = Function('"use strict";return (' + formulas.join(' ') + ')')();
    } catch(err) {}
    return (value !== null);
}

const getNumericValue = (value) => {
    const num = Number(value);
    if (isNaN(num)) {
        return 0;
    } else {
        return num;
    }
}

const getDropdownValue = (sources, id, key, column) => {
    const data = sources[id];
    if (data) {
        const option = data.find(source => source.Key === key);
        if (option) {
            return (option[column] || ''); 
        }
    }
    return '';
}

const getReferenceValue = (reference, inputData, isNumeric, sources, rowId) => {
    let rawId = reference;
    let colName = 'Value';
    let srcId = '';
    const isDropdown = (reference.startsWith('&') || 
                        reference.startsWith('!&') ||
                        reference.startsWith('#!&') ||
                        reference.startsWith('#&'));   
    if (isDropdown) {
        const delInd= reference.indexOf('@');
        if (delInd > 0) {
            const idInd = reference.indexOf('~');
            rawId = reference.substring(0, delInd).replace('&', '').replace('#!', '!');
            colName = reference.substring(delInd + 1, idInd);
            srcId = reference.substring(idInd + 1);
        } else {
            rawId = reference.replace('&', '');
        }
    }
    if (rawId.startsWith('!')) {
        const id = rawId.replace('!', '').replace('#', '');
        if (rowId) { // Get specific data on that row
            let value = inputData[id + '-' + rowId];          
            if (value == null) {
                value = '';
            }
            if (isDropdown) {
                value = getDropdownValue(sources, srcId, value, colName);
            }

            if (isNumeric) {
                return getNumericValue(value);
            } else {
                return value;
            }
        } else {
            if (isNumeric) {
                let total = 0;
                for (const key of Object.keys(inputData)) {
                    if (key.startsWith(id)) {
                        let value = inputData[key];     
                        if (isDropdown) {
                            value = getDropdownValue(sources, srcId, value, colName);
                        }
                        total += getNumericValue(value);
                    }
                }
                return total;
            } else {
                for (const key of Object.keys(inputData)) {
                    if (key.startsWith(id)) {
                        let value = inputData[key];     
                        if (isDropdown) {
                            value = getDropdownValue(sources, srcId, value, colName);
                        }
                        return value;
                    }
                }
            }
        }
    } else { // Input formula reference
        const id = rawId.replace('#', '');
        let value = inputData[id];
        if (value == null) {
            value = '';
        }
        
        if (isDropdown) {
            value = getDropdownValue(sources, srcId, value, colName);
        }

        if (isNumeric) {
            return getNumericValue(value);
        } else {
            return value;
        }
     }
}

export const getFormulaValue = (formula, inputData, sources, rowId) => {
    let isNumeric = false;
    if (formula && formula.length > 0) {
        const frml = formula[0];
        if (frml.key) {
            isNumeric = isNumericKey(frml.key);
        } else {
            isNumeric = isNumericLiteral(frml);
        }     
        if (isNumeric) {
            const formulas = [];
            for (const fml of formula) {
                if (fml.key) {
                    if (fml.key.length > 1) { // Not an operator
                        formulas.push(getReferenceValue(fml.key, inputData, isNumeric, sources, rowId));
                    } else {
                        formulas.push(fml.key);
                    }
                } else {
                    formulas.push(fml); // This is a literal formula
                }
            }
            let value = null;
            try {
                value = Function('"use strict";return (' + formulas.join(' ') + ')')();
            } catch(err) {}
            return (value || 0);
        } else {
            return (getReferenceValue(formula[0].key, inputData, isNumeric, sources, rowId));
        }
    } else {
        return '';
    }
}
