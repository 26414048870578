import React, { Fragment } from "react";
import DisplayTable from "./logsComponents/ui/DisplayTable";
import PaginationCustom from "./logsComponents/ui/PaginationCustom";

const LogDisplayTable = (props) => {
  return (
    <Fragment>
      <DisplayTable
        rows={props.rows}
        hoveredRow={props.hoveredRow}
        setHoveredRow={props.setHoveredRow}
        updatingData={props.updatingData}
        getAdditionalData={props.getAdditionalData}
      />
      <PaginationCustom
        count={props.count}
        page={props.page}
        maxPage={props.maxPage}
        totalCount={props.totalCount}
        showItems={props.showItems}
        setPage={props.setPage}
        setInitialized={props.setInitialized}
      />
    </Fragment>
  );
};

export default LogDisplayTable;
