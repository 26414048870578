import React from "react";

const Wrapper = (props) => {
  return (
    <div style={{ fontFamily: "Inter" }}>
      <div style={{ margin: 20 }}>{props.children}</div>
    </div>
  );
};

export default Wrapper;
