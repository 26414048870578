import React, { Component, useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';

import LoanService from '../../services/LoanService';

const AgentName = (props) => {
  const [initialized, setInitialized] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [agentName, setAgentName] = useState('');
  
  if(!initialized){
    getAgentDetails();
  }

  async function getAgentDetails() {
    let response = await LoanService.getLoanAgents();
    if(response && response.length > 0){
      let agent = response.filter(agent => agent.cognitoId == props.agentId);
      if(agent.length > 0){
        setAgentName(agent[0].name);
      }else{
        setAgentName("");
      }
      setInitialized(true);
    }else{
      setIsFetchingFailed(true);
      setInitialized(true);
    }
  }

  async function retryRetrieve(event) {
    event.stopPropagation();
    setIsFetchingFailed(false);
    setInitialized(false);
    props.triggeredRetry = true;
    await getAgentDetails();
  }

  return (
    <div>
      <CircularProgress style={{
        display: !initialized ? 'block' : 'none',
      }} color="primary"/>
      <div style={{
        display: initialized && !isFetchingFailed ? 'block' : 'none',
      }} >{agentName}</div>
      <div style={{
        display: isFetchingFailed ? 'block' : 'none',
      }}>
      <Button style={{
          marginRight: 8
        }}
        variant="outlined"
        color="primary"
        onClick={retryRetrieve}
      >
        Retry
      </Button></div>
    </div>
  );
};

export default AgentName;