import React, { Fragment, useState } from 'react';
import {
  Grid, Box, Button, Checkbox, Paper, LinearProgress, FormControl, TextField,
  InputLabel, Select, FormControlLabel, Tabs, Tab, Typography
} from '@material-ui/core/';
import { useStyles, texts, toggleDisplay } from './helpers/';
import LoanService from '../../services/LoanService';
import LoanApplicationList from '../loanApplications/LoanApplicationList';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui";
import moment from 'moment-timezone';
import { v0Theme, UBXTheme } from "../main/theme";
import { useHistory } from 'react-router-dom'
import SnackBar from '../common/SnackBar';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core/';
import Paths from '../../enums/Paths';
import { cloneDeep, findIndex } from 'lodash';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProfilePage = props => {

  const history = useHistory();
  let currentRecords = 0;
  const totalRecords = 3

  const classes = useStyles();
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatingData, setUpdatingData] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageStatus, setPageStatus] = useState(`${texts.pageFrom} ${currentPage != totalPages ?
    (currentRecords * currentPage - (pageLimit - 1) + ` ${texts.pageTo} ` + currentRecords * currentPage) :
    (((((currentPage - 1) * pageLimit) + 1) != totalRecords) ?
      (((currentPage - 1) * pageLimit) + 1 + ` ${texts.pageTo} ` + totalRecords) :
      (((currentPage - 1) * pageLimit) + 1))} ${texts.pageOf} ${totalRecords}`);
  const [tabIndex, setTabIndex] = useState(0);
  const [tick, setTick] = useState(0);
  const [formData, setFormData] = useState();
  const [sameWithPresentAdd, setSameWithPresentAdd] = useState(false);
  const [loadedValues, setLoadedValues] = useState(false);
  const [modifiedData, setModifiedData] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [selectedDate, handleDateChange] = useState({});
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showFailSnackbar, setShowFailSnackbar] = useState(false);
  const [hasInvalidDate, setHasInvalidDate] = useState(false);
  const [updating, setUpdating] = useState(false);

  const updateRender = () => {
    setTick(tick + 1);
    props.setTick(tick + 1);
  }

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  }

  async function getData() {
    setIsFetchingFailed(false);
    setInitialized(true);
    setUpdatingData(true);
    setIsLoading(true);

    let subgroupDataResponse = await LoanService.getCustomerSubgroupFields();
    let fieldDataResponse = await LoanService.getCustomerSubgroupFieldData(props.customerId);
    const subgroupData = subgroupDataResponse && subgroupDataResponse.result && subgroupDataResponse.result.customerProfileForm;
    const fieldData = fieldDataResponse && fieldDataResponse.result && fieldDataResponse.result[0];
    /* Check if permanent address is same with present address */
    if (fieldData && fieldData.address.length == 1) {
      setSameWithPresentAdd(true);
    }

    /* Create copy of original form data
       to use on field codes */
    setOriginalFormData(cloneDeep(subgroupData));

    /* Handling for permanent address fields */
    const permanentAddressIndex = findIndex(subgroupData, { code: "permanent_address" });
    subgroupData[permanentAddressIndex].fields.map((field, i) => {
      if (field.code.includes("permanent")) {
        let newCode = field.code.replace("permanent", "");
        newCode = newCode.charAt(0).toLowerCase() + newCode.slice(1);
        subgroupData[permanentAddressIndex].fields[i].code = newCode;
      }
    });

    setFormData(subgroupData)
    applyFieldValues(subgroupData, fieldData);

    /* Trigger UI render */
    /* Fixes dropdown bug value not updating on first load */
    await new Promise(resolve => {
      setTimeout(() => {
        setLoadedValues(true);
        resolve(true);
      }, 10);
    });
    
    setIsLoading(false);
  }

  if (!initialized) {
    getData();
  }

  const applyFieldValues = (subgroupData, fieldData) => {
    const formValueData = fieldData;
    const modifiedStub = subgroupData;
    let modifiedData = { address: [{}, {}] };

    modifiedStub.map((subgroup) => {
      subgroup.fields.map((field) => {
        if (subgroup.code == "present_address") {
          if (field.code != "isSameWithPresentAddress") {
            if (field.dataType == "Integer") {
              formValueData.address[0][field.code] = parseInt(formValueData.address[0][field.code])
            }
            field.value = formValueData.address[0] && formValueData.address[0][field.code];
            if (formValueData.address[0] && formValueData.address[0][field.code]) {
              modifiedData["address"][0][field.code] = formValueData.address[0][field.code];
            }
          }
        } else if (subgroup.code == "permanent_address") {
          if (field.code != "isSameWithPresentAddress") {
            if (field.dataType == "Integer") {
              if (formValueData.address[1] && formValueData.address[1][field.code]) {
                formValueData.address[1][field.code] = parseInt(formValueData.address[1][field.code])
              }
            }
            if (formValueData.address[1]) {
              /* Already handled field codes with "permanent" prefix,
                 this might be handy some time */
              // if (field.code.includes("permanent")) {
              //   let newCode = field.code.replace("permanent", "");
              //   newCode = newCode.charAt(0).toLowerCase() + newCode.slice(1);
              //   delete Object.assign(modifiedData["address"][1], {[newCode]: modifiedData["address"][1][field.code]})[field.code];
              //   modifiedData["address"][1][field.code] = formValueData.address[1][newCode];
              //   field.value = formValueData.address[1] && formValueData.address[1][newCode];
              // }
              field.value = formValueData.address[1] && formValueData.address[1][field.code];
              if (formValueData.address[1] && formValueData.address[1][field.code]) {
                modifiedData["address"][1][field.code] = formValueData.address[1][field.code];
              }
            }
          }
        } else {
          if (field.dataType == "Integer") {
            formValueData[field.code] = parseInt(formValueData[field.code])
          } else if (field.dataType == "Date") {
            handleDateChange({ [field.code]: formValueData[field.code] })
          }
          field.value = formValueData && formValueData[field.code];
          if (formValueData && formValueData[field.code]) {
            modifiedData[field.code] = formValueData[field.code];
          }
        }
      });
    });

    setModifiedData(modifiedData)
    setFormData(modifiedStub);

    // Trigger new UI render
    updateRender();
  }

  const renderSubgroup = (data) => {
    {/* Render subgroup label / divider */}
    return (
      <div className={classes.subGrouping}>
        {data.toUpperCase()}
      </div>
    )
  }

  const handleDataType = (valueType) => {
    if (valueType == 'Integer' || valueType == 'float') {
      return 'number';
    }
  };

  const handleChange = (e, data, subgroupCode) => {
    setChangesMade(true);
    let changeValue = e && e.target && e.target.value ? e.target.value : moment(e).format('YYYY-MM-DD');
    let modifiedDataAddress = modifiedData;

    if (data.dataType == "Date" && changeValue.toString() == "Invalid date") {
      setHasInvalidDate(true)
    } else if (data.dataType == "Date" && changeValue.toString() != "Invalid date") {
      setHasInvalidDate(false)
    }

    if (data.dataType == "Integer") {
      changeValue = parseInt(changeValue)
    }

    if (data.code.includes("person") || data.code.includes("mobile") || data.code.includes("email") || data.code.includes("suffix")) {
      setModifiedData(modifiedData => ({
        ...modifiedData,
        [data.code]: changeValue
      }))
    } else {
      if (subgroupCode.includes("permanent")) {
        let newCode = data.code.replace("permanent", "");
        newCode = newCode.charAt(0).toLowerCase() + newCode.slice(1);
        modifiedDataAddress["address"][1][newCode] = changeValue
      } else {
        modifiedDataAddress["address"][0][data.code] = changeValue
      }
      setModifiedData(modifiedDataAddress)
    }
  }

  const renderTextField = (data, subgroupCode, originalFormData) => {
    {/* Render text field element */}
    return (<TextField 
      id={`text-field-${originalFormData.code}`}
      label={data.name}
      className={classes.dynamicComponent}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        "pattern": data.customFormat,
        "title": data.validationMessages
      }}
      onChange={(e) => handleChange(e, data, subgroupCode)}
      defaultValue={data.value}
      required={data.isRequired}
      type={handleDataType(data.dataType)}
      fullWidth
    />)
  }

  const renderTextArea = (data, subgroupCode, originalFormData) => {
    {/* Render text area element*/}
    return (<TextField 
      id={`text-area-${originalFormData.code}`}
      label={data.name}
      className={classes.dynamicComponent}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        "pattern": data.customFormat,
        "title": data.validationMessages
      }}
      onChange={(e) => handleChange(e, data, subgroupCode)}
      defaultValue={data.value}
      required={data.isRequired}
      fullWidth
    />)
  }

  const renderDropdown = (data, subgroupCode, originalFormData) => {
    {/* Render dropdown element */}
    return (<FormControl
      className={classes.dynamicComponent}
      required={data.isRequired}
      variant="outlined"
    >
      <InputLabel>{data.name}</InputLabel>
      <Select
        id={`select-${originalFormData.code}`}
        className={classes.selectComponent}
        defaultValue={data.value}
        onChange={(e) => handleChange(e, data, subgroupCode)}
        native
        displayEmpty
      >
        {/* Render dropdown options */}
        <option value={""}/>
        {data.options.map((option) => {
          return <option id={`option-${option.code}`} value={option.code}>{option.name}</option>
        })}
      </Select>
    </FormControl>)
  }

  const renderDatePicker = (data, subgroupCode) => {
    {/* Render date picker element */}
    return (
    <MuiThemeProvider theme={UBXTheme}>
    <V0MuiThemeProvider muiTheme={v0Theme}>
    <MuiPickersUtilsProvider className={classes.root} utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={`date-picker-${data.code}`}
        label={data.name}
        className={classes.dynamicComponent}
        format="MM/dd/yyyy"
        margin="normal"
        fullWidth={true}
        inputVariant="outlined"
        InputProps={{
          disableUnderline: true,
        }}
        required={data.isRequired}
        value={selectedDate[data.code]} onChange={(date) => {
          handleDateChange({ [data.code]: date });
          handleChange(date, data, subgroupCode);
        }}
      />
    </MuiPickersUtilsProvider>
    </V0MuiThemeProvider>
    </MuiThemeProvider>)
  }

  const handleCheckbox = (event) => {
    let modifiedDataAddress = modifiedData;
    setSameWithPresentAdd(event.target.checked)

    if (event.target.checked) {
      modifiedDataAddress["address"].splice(1, 1)
      applyFieldValues(formData, modifiedDataAddress);
    } else {
      modifiedDataAddress["address"][1] = {};
      applyFieldValues(formData, modifiedDataAddress);
    }
    
    setModifiedData(modifiedDataAddress);
    setChangesMade(true);
  }

  const renderCheckbox = (data) => {
    {/* Render checkbox element*/ }
    return (
      <MuiThemeProvider theme={UBXTheme}>
        <V0MuiThemeProvider muiTheme={v0Theme}>
          <FormControlLabel
            className={classes.checkboxComponent}
            control={
              <Checkbox
                id={`checkbox-${data.code}`}
                onChange={handleCheckbox}
                defaultChecked={sameWithPresentAdd} />
            }
            label={data.name} />
        </V0MuiThemeProvider>
      </MuiThemeProvider>)
  }

  const generateField = (data, subgroupCode, originalFormData) => {
    switch(data.fieldType) {
      case "TextField":
        return renderTextField(data, subgroupCode, originalFormData);
      case "TextArea":
        return renderTextArea(data, subgroupCode, originalFormData);
      case "Dropdown":
        return renderDropdown(data, subgroupCode, originalFormData);
      case "DatePicker":
        return renderDatePicker(data, subgroupCode);
      case "Checkbox":
        return renderCheckbox(data, subgroupCode);
      default:
        return null;
    }
  }

  const generateSubgroup = (data, originalFormData) => {
    if (data.code == "permanent_address" && sameWithPresentAdd) {
      return (<Fragment>
        {renderSubgroup(data.label)}
        <div id={`div-${data.code}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {data.fields.map((field, i) => {
            if (field.fieldType == "Checkbox") {
              return generateField(field, data.code, originalFormData[i])
            }
          })}
        </div>
      </Fragment>)
    } else {
      return (
        <Fragment>
          {renderSubgroup(data.label)}
          <div id={`div-${data.code}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data.fields.map((field, i) => {
              return generateField(field, data.code, originalFormData.fields[i])
            })}
          </div>
        </Fragment>
      )
    }
  }
  
  const generateDynamicFields = (data, originalFormData) => {
    return data.map((subgroup, i) => {
        return generateSubgroup(subgroup, originalFormData[i]);
    })
  }

  const updateApplication = async (event) => {
    // Prevent default action when submitting HTML5 form
    event.preventDefault();
    let requestBody = modifiedData;

    // Check address validation
    if (modifiedData["address"].length == 2 && Object.keys(modifiedData["address"][1]).length == 0) {
      requestBody["address"].splice(1, 1)
    } 

    setUpdating(true);
    const res = await LoanService.updateCustomerProfile(requestBody, props.customerId)
    setUpdating(false);

    if (res && res.status && (res.status == 200 || res.status == 201)) {
      setShowSuccessSnackbar(true);
      setInitialized(false);
      // Reset data change indicator
      setChangesMade(false);
      getData();
    } else {
      setShowFailSnackbar(true);
    }

    return false;
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div className={classes.pageWrapper}>
      <Grid container className={classes.pageHeader} spacing={1}>
        <Grid item xs={12} sm={6}>
          <span className={classes.pageTitle}>{props.customerName}</span>
        </Grid>
      </Grid>
      <LinearProgress className={classes.loader} style={{ display: toggleDisplay(isLoading) }} />
      <Paper className={classes.paperWrapper} elevation={0}>
        <div>
          <Tabs
            id={`tab-main`}
            value={tabIndex}
            onChange={handleChangeTab}
            variant="fullWidth"
            style={{ height: 32 }}
            TabIndicatorProps={
              { style: 
                { background: '#F6F6F6' }
              }}
          >
            <Tab
              id={`tab-${texts.tabProfile}`}
              disabled={false}
              className={classes.tabSelection}
              label={texts.tabProfile}
              {...a11yProps(0)}
            />
            <Tab
              id={`tab-${texts.tabApplication}`}
              disabled={false}
              className={classes.tabSelection}
              label={texts.tabApplication}
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel id={`tab-panel-${texts.tabProfile}`} value={tabIndex} index={0}>
            <Fragment>
              <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <form onSubmit={updateApplication}>
                  {(formData && loadedValues) && generateDynamicFields(formData, originalFormData)}
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      id={"button-back-update-profile"}
                      variant='outlined'
                      className={classes.secondaryButton}
                      disabled={updating}
                      onClick={() => {
                        history.push(Paths.CUSTOMER_PROFILE);
                        updateRender()}}>
                      {texts.buttonBack}
                    </Button>
                    <Button
                      id={"button-update-update-profile"}
                      variant='contained'
                      className={classes.primaryButton}
                      disabled={!changesMade || updating || hasInvalidDate}
                      type="submit">
                      {texts.buttonUpdate}
                    </Button>
                  </div>
                </form>
              </div>
            </Fragment>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <LoanApplicationList
              setApplication={props.setApplication}
              setSearchKeyGlobal={props.setSearchKeyGlobal}
              setLoanProductKeyGlobal={props.setLoanProductKeyGlobal}
              setStatusKeyGlobal={props.setStatusKeyGlobal}
              globalSearchKey={props.globalSearchKey}
              globalLoanProductKey={props.globalLoanProductKey}
              globalStatusKey={props.globalStatusKey}
              showPagination={true}
              showFilters={false}
              setNavHistory={props.setNavHistory}
              origin="customerprofile"
              customerId={props.customerId}>
            </LoanApplicationList>
          </TabPanel>
        </div>
      </Paper>
      <Snackbar
        id={`snackbar-success`}
        open={showSuccessSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSuccessSnackbar(false)}
      >
        <Alert
          id={`alert-success`}
          onClose={() => setShowSuccessSnackbar(false)}
          severity={'success'}
        >
          {texts.successUpdate}
        </Alert>
      </Snackbar>
      <Snackbar
      id={`snackbar-failed`}
        open={showFailSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowFailSnackbar(false)}
      >
        <Alert
          id={`alert-failed`}
          onClose={() => setShowFailSnackbar(false)}
          severity={'error'}
        >
          {texts.failUpdate}
        </Alert>
      </Snackbar>

      {/* TODO */}
      {/* <SnackBar open={showSuccessSnackbar} message={texts.successUpdate} />
      <SnackBar open={showFailSnackbar} message={texts.failUpdate} /> */}
    </div>
  );
}

export default ProfilePage;