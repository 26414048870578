import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_CASHFLOW_API_URI,
	timeout: 30000
});

function getHeaders() {
	const headers = {};
	let userData = localStorage.getItem('@@i2i/USER');
	if (userData) {
		let jsonData = JSON.parse(userData)
		headers.Authorization = 'Bearer ' + jsonData.agencyBankingToken;
	}
	return headers;
}

const Api = {
    doGet: async (path) => {
        const options = { headers: getHeaders() };
		try {
			const response = await instance.get(path, options);
            if (response && response.data) {
                const data = response.data;
                if (data.status === 200) {
                    return data.result;
                }
            }
            return null;
		} catch (error) {
			throw error;
		}
	},
    doPost: async (path, data, withMessage) => {
        const options = { headers: getHeaders() };
		try {
			const response = await instance.post(path, data, options);
            if (response && response.data) {
                const data = response.data;
				if (data.status === 200) {
					if (withMessage) {
						return { message: data.message,
								 result: data.result };
					} else {
						return data.result;
					}
                }
            }
            return null;
		} catch (error) {
			throw error;
		}
	},
    getForm: async (id) => {
		try {
			const query = `?id=${id || '0'}`;
			return await Api.doGet(`/cashflow/form${query}`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
    getForms: async (search, type, product, limit, page) => {
		try {
			const query = `?search=${search || ''}&type=${type || ''}&product=${product || ''}&limit=${limit || ''}&page=${page || ''}`;
			return await Api.doGet(`/cashflow/form/list${query}`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
    getTypes: async () => {
		try {
			return await Api.doGet(`/cashflow/type/list`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
    getProducts: async () => {
		try {
			return await Api.doGet(`/cashflow/product/list`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
	getSource: async (id) => {
		try {
			const query = `?id=${id || '0'}`;
			return await Api.doGet(`/cashflow/source${query}`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
	getSources: async (search, limit, page) => {
		try {
			const query = `?search=${search || ''}&limit=${limit || '2000'}&page=${page || '1'}`;
			return await Api.doGet(`/cashflow/source/list${query}`);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
	saveForm: async (id, name, type, products, duplicateId, isActive, isDeleted) => {
		try {
			const data = {
				id: (id || 0),
				name: (name || ''),
				typeId: (type || 0),
				productIds: (products || ''),
				isActive: (isActive || false),
				isDeleted: (isDeleted || false),
				duplicateId: (duplicateId || 0),
			}
			return await Api.doPost('/cashflow/form/save', data, true);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
	saveSchema: async (id, formId, payload) => {
		try {
			const data = {
				id: (id || 0),
				formId: (formId || 0),
				payload: (payload || ''),
			}
			return await Api.doPost('/cashflow/schema/save', data);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
	saveSource: async (id, name, description, columns, payload, isDeleted) => {
		try {
			const data = {
				id: (id || 0),
				name: (name || ''),
				description: (description || ''),
				columns: (columns || ''),
				payload: (payload || '[]'),
				isDeleted: (isDeleted || false),
			}
			return await Api.doPost('/cashflow/source/save', data, true);
		} catch (error) {
            console.log(error);
			return null;
		}
	},
}

export default Api;