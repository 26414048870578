import React from "react";
import { useStyles } from "../helpers/Styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const StatusDropdown = (props) => {
  const classes = useStyles();
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.root}>Status</InputLabel>
        <Select
          className={classes.root}
          style={{ width: 158, minWidth: 100, marginRight: 24 }}
          onChange={(event) => {
            props.setStatusKey(
              event.target.value == null ? "All" : event.target.value
            );
            props.setStatusKeyGlobal(
              event.target.value == null ? "All" : event.target.value
            );
            props.setPage(1);
            props.setInitialized(false);
          }}
          shrink={true}
          defaultValue={
            props.globalStatusKey ? props.globalStatusKey : props.statusKey
          }
          //filterValue(event.target.value, "STATUS");
        >
          <MenuItem value={""}>All</MenuItem>
          <MenuItem value={"CREATED"}>Created</MenuItem>
          <MenuItem value={"MODIFIED"}>Modified</MenuItem>
          <MenuItem value={"INCOMPLETE"}>Incomplete</MenuItem>
          <MenuItem value={"EXPORTED"}>Exported</MenuItem>
          <MenuItem value={"COMPLETED"}>Completed</MenuItem>
          <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
          <MenuItem value={"APPROVED"}>Approved</MenuItem>
          <MenuItem value={"REJECTED"}>Rejected</MenuItem>
          <MenuItem value={"RETURNED"}>Returned</MenuItem>
          <MenuItem value={"TERMINATED"}>Terminated</MenuItem>
          <MenuItem value={"FAILED"}>Failed</MenuItem>
          <MenuItem value={"RESUBMITTED"}>Resubmitted</MenuItem>
          <MenuItem value={"FOR RESUBMISSION"}>For Resubmission</MenuItem>
          <MenuItem value={"ARCHIVED"}>Archived</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default StatusDropdown;
